export interface StateConstants {
  asthma: {
    asthmaTabPercentChildrenDiagnosedWithAsthma: number;
    asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: number;
    asthmaTabMedicalPriceInflationFactor: number;
  };
  breastfeeding: {
    breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: number;
    breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: number;
    breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: number;
    breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: number;
    breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: number;
    breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: number;
    breastfeedingTabMedicalPriceInflationFactor20172019: number;
  };
  emergencyDepartment: {
    edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: number;
    edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: number;
    edTabMedicaidPriceInflactionFactor: number;
    edTabStatewideFqhcRateCeilingsForMedicalCare2020: number;
    edTabMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: number;
  };
  healthBirthSpacing: {
    birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: number;
    birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: number;
    birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: number;
    birthsTabCostPerPubliclyFundedBirthShortTerm: number;
    birthsTabCostPerPubliclyFundedBirthLongTerm: number;
    birthsTabGeneralPriceInflationFactor: number;
    birthsTabMedicalPriceInflationFactor: number;
  };
  immunizations: {
    immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: number;
    immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: number;
    immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: number;
    immunizationsTabMedicaidVisitPercentageForChildren: number;
    immunizationsTabMedicalPriceInflationFactor: number;
  };
  intimatePartner: {
    ipvTabPercentageOfWomanInSampleWithHigherIpvScore: number;
    ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: number;
    ipvTabMedicalPriceInflactionFactor: number;
    ipvTabPercentageOfWomanInSampleWithLowerIpvScore: number;
  };
  oralHealth: {
    dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: number;
    dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: number;
    dentalTabMedicalPriceInflaction: number;
  };
  postpartumMaternalDepression: {
    maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: number;
    maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: number;
    maternalDepressionTabMedicaidPriceInflationFactor: number;
  };
  smokingCessation: {
    smokingTabCombinedChildAndAdultsSavingsOver3Years: number;
    smokingTabMedicaidPriceInflactionFactor: number;
    smokingTabRelapseRateYear1: number;
    smokingTabRelapseRateYears2to7: number;
  };
  diapers: {
    diapersTabMedicalPriceInflationFactor: number;
    diapersTabTotalNetSavings: number;
  };
  foodSecurity: {
    foodTabMedicalPriceInflation: number;
    foodTabTotalNetSavings: number;
  }
  earlyDetection: {
    earlydetectionTabEarlyEducationProgramsGeneralPriceInflation: number;
    earlydetectionTabSpecialEducationGeneralPriceInflation: number;
    earlydetectionTabIdeaPartCGeneralPriceInflation: number;
    earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEILow: number;
    earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEIHigh: number;
    earlydetectionTabSpecialEducationCostPerYear: number;
    earlydetectionTabIdeaPartCCostPerYear: number;
    earlydetectionTotalNetSavings: number;
  };
  positiveParenting: {
    positiveparentingTabGeneralPriceInflation: number;
    positiveparentingTabMedicalPriceInflation: number;
  };
}

interface StatesConstants {
  [state: string]: StateConstants;
}

export const States: StatesConstants = {
  DEFAULT: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 0,
      asthmaTabMedicalPriceInflationFactor: 0,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 0,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 0,
      breastfeedingTabMedicalPriceInflationFactor20172019: 0,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 0,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 0,
      edTabMedicaidPriceInflactionFactor: 0,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 0,
      edTabMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 0,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0,
      birthsTabCostPerPubliclyFundedBirthShortTerm: 0,
      birthsTabCostPerPubliclyFundedBirthLongTerm: 0,
      birthsTabGeneralPriceInflationFactor: 0,
      birthsTabMedicalPriceInflationFactor: 0,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0,
      immunizationsTabMedicaidVisitPercentageForChildren: 0,
      immunizationsTabMedicalPriceInflationFactor: 0,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 0,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 0,
      ipvTabMedicalPriceInflactionFactor: 0,
      ipvTabPercentageOfWomanInSampleWithLowerIpvScore: 0,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 0,
      dentalTabMedicalPriceInflaction: 0,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 0,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0,
      maternalDepressionTabMedicaidPriceInflationFactor: 0,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 0,
      smokingTabMedicaidPriceInflactionFactor: 0,
      smokingTabRelapseRateYear1: 0,
      smokingTabRelapseRateYears2to7: 0,
    },
    diapers: {
      diapersTabMedicalPriceInflationFactor: 0,
      diapersTabTotalNetSavings: 0,
    },
    foodSecurity: {
      foodTabMedicalPriceInflation: 0,
      foodTabTotalNetSavings: 0,
    },
    earlyDetection: {
      earlydetectionTabEarlyEducationProgramsGeneralPriceInflation: 0,
      earlydetectionTabSpecialEducationGeneralPriceInflation: 0,
      earlydetectionTabIdeaPartCGeneralPriceInflation: 0,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEILow: 0,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEIHigh: 0,
      earlydetectionTabSpecialEducationCostPerYear: 0,
      earlydetectionTabIdeaPartCCostPerYear: 0,
      earlydetectionTotalNetSavings: 0,
    },
    positiveParenting: {
      positiveparentingTabGeneralPriceInflation: 0,
      positiveparentingTabMedicalPriceInflation: 0,
    },
  },
  AL: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.034,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.4901768707483,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 67,
      edTabMedicaidPriceInflactionFactor: 1.1842001401844,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 202.65,
      edTabMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 93.11,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.179,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.616,
      birthsTabCostPerPubliclyFundedBirthShortTerm: 10006,
      birthsTabCostPerPubliclyFundedBirthLongTerm: 7536,
      birthsTabGeneralPriceInflationFactor: 1.69372984991662,
      birthsTabMedicalPriceInflationFactor: 1.41357649651423,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.724,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
      immunizationsTabMedicalPriceInflationFactor: 1.69889851485149,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 12.280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.26800495662949,
      ipvTabPercentageOfWomanInSampleWithLowerIpvScore: 13.3333333333333,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.323336321832430000000000,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805.07042253521,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.1551832231624,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.29155513320506,
      smokingTabRelapseRateYear1: 0.1,
      smokingTabRelapseRateYears2to7: 0.04,
    },
    diapers: {
      diapersTabMedicalPriceInflationFactor: 1.1842001401844,
      diapersTabTotalNetSavings: 1274.91756756757,
    },
    foodSecurity: {
      foodTabMedicalPriceInflation: 1.1842001401844,
      foodTabTotalNetSavings: 1834,
    },
    earlyDetection: {
      earlydetectionTabEarlyEducationProgramsGeneralPriceInflation: 1.30797529157742,
      earlydetectionTabSpecialEducationGeneralPriceInflation: 1.46956236556028,
      earlydetectionTabIdeaPartCGeneralPriceInflation: 1.17731472000804,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEILow: 0.148,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEIHigh: 0.222,
      earlydetectionTabSpecialEducationCostPerYear: 9369,
      earlydetectionTabIdeaPartCCostPerYear: 4566.62,
      earlydetectionTotalNetSavings: 4567,
    },
    positiveParenting: {
      positiveparentingTabGeneralPriceInflation: 1.354598357777,
      positiveparentingTabMedicalPriceInflation: 1.10166468370608,
    },
  },
  AK: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.034,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.4901768707483,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 67,
      edTabMedicaidPriceInflactionFactor: 1.1842001401844,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 462.86,
      edTabMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 216.4,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.179,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.643,
      birthsTabCostPerPubliclyFundedBirthShortTerm: 23825,
      birthsTabCostPerPubliclyFundedBirthLongTerm: 13583,
      birthsTabGeneralPriceInflationFactor: 1.69372984991662,
      birthsTabMedicalPriceInflationFactor: 1.41357649651423,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.724,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
      immunizationsTabMedicalPriceInflationFactor: 1.69889851485149,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 12.280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.26800495662949,
      ipvTabPercentageOfWomanInSampleWithLowerIpvScore: 13.3333333333333,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.323336321832430000000000,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805.07042253521,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.1551832231624,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.29155513320506,
      smokingTabRelapseRateYear1: 0.1,
      smokingTabRelapseRateYears2to7: 0.04,
    },
    diapers: {
      diapersTabMedicalPriceInflationFactor: 1.1842001401844,
      diapersTabTotalNetSavings: 1274.91756756757,
    },
    foodSecurity: {
      foodTabMedicalPriceInflation: 1.1842001401844,
      foodTabTotalNetSavings: 1834,
    },
    earlyDetection: {
      earlydetectionTabEarlyEducationProgramsGeneralPriceInflation: 1.30797529157742,
      earlydetectionTabSpecialEducationGeneralPriceInflation: 1.46956236556028,
      earlydetectionTabIdeaPartCGeneralPriceInflation: 1.17731472000804,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEILow: 0.148,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEIHigh: 0.222,
      earlydetectionTabSpecialEducationCostPerYear: 9369,
      earlydetectionTabIdeaPartCCostPerYear: 4566.62,
      earlydetectionTotalNetSavings: 4567,
    },
    positiveParenting: {
      positiveparentingTabGeneralPriceInflation: 1.354598357777,
      positiveparentingTabMedicalPriceInflation: 1.10166468370608,
    },
  },
  AR: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.034,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.4901768707483,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 75.76,
      edTabMedicaidPriceInflactionFactor: 1.1842001401844,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 202.65,
      edTabMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 75.76,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.058,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.723,
      birthsTabCostPerPubliclyFundedBirthShortTerm: 12755,
      birthsTabCostPerPubliclyFundedBirthLongTerm: 10824,
      birthsTabGeneralPriceInflationFactor: 1.69372984991662,
      birthsTabMedicalPriceInflationFactor: 1.41357649651423,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.541,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
      immunizationsTabMedicalPriceInflationFactor: 1.69889851485149,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 12.280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.26800495662949,
      ipvTabPercentageOfWomanInSampleWithLowerIpvScore: 13.3333333333333,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.323336321832430000000000,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805.07042253521,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.1551832231624,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.29155513320506,
      smokingTabRelapseRateYear1: 0.1,
      smokingTabRelapseRateYears2to7: 0.04,
    },
    diapers: {
      diapersTabMedicalPriceInflationFactor: 1.1842001401844,
      diapersTabTotalNetSavings: 1274.91756756757,
    },
    foodSecurity: {
      foodTabMedicalPriceInflation: 1.1842001401844,
      foodTabTotalNetSavings: 1834,
    },
    earlyDetection: {
      earlydetectionTabEarlyEducationProgramsGeneralPriceInflation: 1.30797529157742,
      earlydetectionTabSpecialEducationGeneralPriceInflation: 1.46956236556028,
      earlydetectionTabIdeaPartCGeneralPriceInflation: 1.17731472000804,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEILow: 0.148,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEIHigh: 0.222,
      earlydetectionTabSpecialEducationCostPerYear: 9369,
      earlydetectionTabIdeaPartCCostPerYear: 4566.62,
      earlydetectionTotalNetSavings: 4567,
    },
    positiveParenting: {
      positiveparentingTabGeneralPriceInflation: 1.354598357777,
      positiveparentingTabMedicalPriceInflation: 1.10166468370608,
    },
  },
  AZ: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.034,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.4901768707483,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 89.38,
      edTabMedicaidPriceInflactionFactor: 1.1842001401844,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 359.32,
      edTabMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 95.35,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.072,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.646,
      birthsTabCostPerPubliclyFundedBirthShortTerm: 15863,
      birthsTabCostPerPubliclyFundedBirthLongTerm: 11405,
      birthsTabGeneralPriceInflationFactor: 1.69372984991662,
      birthsTabMedicalPriceInflationFactor: 1.41357649651423,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.609,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
      immunizationsTabMedicalPriceInflationFactor: 1.69889851485149,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 12.280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.26800495662949,
      ipvTabPercentageOfWomanInSampleWithLowerIpvScore: 13.3333333333333,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.323336321832430000000000,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805.07042253521,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.1551832231624,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.29155513320506,
      smokingTabRelapseRateYear1: 0.1,
      smokingTabRelapseRateYears2to7: 0.04,
    },
    diapers: {
      diapersTabMedicalPriceInflationFactor: 1.1842001401844,
      diapersTabTotalNetSavings: 1274.91756756757,
    },
    foodSecurity: {
      foodTabMedicalPriceInflation: 1.1842001401844,
      foodTabTotalNetSavings: 1834,
    },
    earlyDetection: {
      earlydetectionTabEarlyEducationProgramsGeneralPriceInflation: 1.30797529157742,
      earlydetectionTabSpecialEducationGeneralPriceInflation: 1.46956236556028,
      earlydetectionTabIdeaPartCGeneralPriceInflation: 1.17731472000804,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEILow: 0.148,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEIHigh: 0.222,
      earlydetectionTabSpecialEducationCostPerYear: 9369,
      earlydetectionTabIdeaPartCCostPerYear: 4566.62,
      earlydetectionTotalNetSavings: 4567,
    },
    positiveParenting: {
      positiveparentingTabGeneralPriceInflation: 1.354598357777,
      positiveparentingTabMedicalPriceInflation: 1.10166468370608,
    },
  },
  CA: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.024,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.4901768707483,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 37.50,
      edTabMedicaidPriceInflactionFactor: 1.1842001401844,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 202.65,
      edTabMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 115.88,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.062,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.643,
      birthsTabCostPerPubliclyFundedBirthShortTerm: 10286,
      birthsTabCostPerPubliclyFundedBirthLongTerm: 5778,
      birthsTabGeneralPriceInflationFactor: 1.69372984991662,
      birthsTabMedicalPriceInflationFactor: 1.41357649651423,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.673,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
      immunizationsTabMedicalPriceInflationFactor: 1.69889851485149,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 12.280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.26800495662949,
      ipvTabPercentageOfWomanInSampleWithLowerIpvScore: 13.3333333333333,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.323336321832430000000000,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805.07042253521,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.1551832231624,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.29155513320506,
      smokingTabRelapseRateYear1: 0.1,
      smokingTabRelapseRateYears2to7: 0.04,
    },
    diapers: {
      diapersTabMedicalPriceInflationFactor: 1.1842001401844,
      diapersTabTotalNetSavings: 1274.91756756757,
    },
    foodSecurity: {
      foodTabMedicalPriceInflation: 1.1842001401844,
      foodTabTotalNetSavings: 1834,
    },
    earlyDetection: {
      earlydetectionTabEarlyEducationProgramsGeneralPriceInflation: 1.30797529157742,
      earlydetectionTabSpecialEducationGeneralPriceInflation: 1.46956236556028,
      earlydetectionTabIdeaPartCGeneralPriceInflation: 1.17731472000804,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEILow: 0.148,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEIHigh: 0.222,
      earlydetectionTabSpecialEducationCostPerYear: 9369,
      earlydetectionTabIdeaPartCCostPerYear: 4566.62,
      earlydetectionTotalNetSavings: 4567,
    },
    positiveParenting: {
      positiveparentingTabGeneralPriceInflation: 1.354598357777,
      positiveparentingTabMedicalPriceInflation: 1.10166468370608,
    },
  },
  CO: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.038,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.4901768707483,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 121.41,
      edTabMedicaidPriceInflactionFactor: 1.1842001401844,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 202.65,
      edTabMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 111.67,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.056,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.638,
      birthsTabCostPerPubliclyFundedBirthShortTerm: 9406,
      birthsTabCostPerPubliclyFundedBirthLongTerm: 6027,
      birthsTabGeneralPriceInflationFactor: 1.69372984991662,
      birthsTabMedicalPriceInflationFactor: 1.41357649651423,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.737,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
      immunizationsTabMedicalPriceInflationFactor: 1.69889851485149,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 12.280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.26800495662949,
      ipvTabPercentageOfWomanInSampleWithLowerIpvScore: 13.3333333333333,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.323336321832430000000000,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805.07042253521,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.1551832231624,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.29155513320506,
      smokingTabRelapseRateYear1: 0.1,
      smokingTabRelapseRateYears2to7: 0.04,
    },
    diapers: {
      diapersTabMedicalPriceInflationFactor: 1.1842001401844,
      diapersTabTotalNetSavings: 1274.91756756757,
    },
    foodSecurity: {
      foodTabMedicalPriceInflation: 1.1842001401844,
      foodTabTotalNetSavings: 1834,
    },
    earlyDetection: {
      earlydetectionTabEarlyEducationProgramsGeneralPriceInflation: 1.30797529157742,
      earlydetectionTabSpecialEducationGeneralPriceInflation: 1.46956236556028,
      earlydetectionTabIdeaPartCGeneralPriceInflation: 1.17731472000804,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEILow: 0.148,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEIHigh: 0.222,
      earlydetectionTabSpecialEducationCostPerYear: 9369,
      earlydetectionTabIdeaPartCCostPerYear: 4566.62,
      earlydetectionTotalNetSavings: 4567,
    },
    positiveParenting: {
      positiveparentingTabGeneralPriceInflation: 1.354598357777,
      positiveparentingTabMedicalPriceInflation: 1.10166468370608,
    },
  },
  CT: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.056,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.4901768707483,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 83.87,
      edTabMedicaidPriceInflactionFactor: 1.1842001401844,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 183.91,
      edTabMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 110.77,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.079,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.608,
      birthsTabCostPerPubliclyFundedBirthShortTerm: 16736,
      birthsTabCostPerPubliclyFundedBirthLongTerm: 9090,
      birthsTabGeneralPriceInflationFactor: 1.69372984991662,
      birthsTabMedicalPriceInflationFactor: 1.41357649651423,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.813,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
      immunizationsTabMedicalPriceInflationFactor: 1.69889851485149,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 12.280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.26800495662949,
      ipvTabPercentageOfWomanInSampleWithLowerIpvScore: 13.3333333333333,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.323336321832430000000000,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805.07042253521,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.1551832231624,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.29155513320506,
      smokingTabRelapseRateYear1: 0.1,
      smokingTabRelapseRateYears2to7: 0.04,
    },
    diapers: {
      diapersTabMedicalPriceInflationFactor: 1.1842001401844,
      diapersTabTotalNetSavings: 1274.91756756757,
    },
    foodSecurity: {
      foodTabMedicalPriceInflation: 1.1842001401844,
      foodTabTotalNetSavings: 1834,
    },
    earlyDetection: {
      earlydetectionTabEarlyEducationProgramsGeneralPriceInflation: 1.30797529157742,
      earlydetectionTabSpecialEducationGeneralPriceInflation: 1.46956236556028,
      earlydetectionTabIdeaPartCGeneralPriceInflation: 1.17731472000804,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEILow: 0.148,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEIHigh: 0.222,
      earlydetectionTabSpecialEducationCostPerYear: 9369,
      earlydetectionTabIdeaPartCCostPerYear: 4566.62,
      earlydetectionTotalNetSavings: 4567,
    },
    positiveParenting: {
      positiveparentingTabGeneralPriceInflation: 1.354598357777,
      positiveparentingTabMedicalPriceInflation: 1.10166468370608,
    },
  },
  DC: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.047,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.4901768707483,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 118.84,
      edTabMedicaidPriceInflactionFactor: 1.1842001401844,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 202.65,
      edTabMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 230.27,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.072,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.846,
      birthsTabCostPerPubliclyFundedBirthShortTerm: 7169,
      birthsTabCostPerPubliclyFundedBirthLongTerm: 10157,
      birthsTabGeneralPriceInflationFactor: 1.69372984991662,
      birthsTabMedicalPriceInflationFactor: 1.41357649651423,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.796,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
      immunizationsTabMedicalPriceInflationFactor: 1.69889851485149,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 12.280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.26800495662949,
      ipvTabPercentageOfWomanInSampleWithLowerIpvScore: 13.3333333333333,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.323336321832430000000000,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805.07042253521,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.1551832231624,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.29155513320506,
      smokingTabRelapseRateYear1: 0.1,
      smokingTabRelapseRateYears2to7: 0.04,
    },
    diapers: {
      diapersTabMedicalPriceInflationFactor: 1.1842001401844,
      diapersTabTotalNetSavings: 1274.91756756757,
    },
    foodSecurity: {
      foodTabMedicalPriceInflation: 1.1842001401844,
      foodTabTotalNetSavings: 1834,
    },
    earlyDetection: {
      earlydetectionTabEarlyEducationProgramsGeneralPriceInflation: 1.30797529157742,
      earlydetectionTabSpecialEducationGeneralPriceInflation: 1.46956236556028,
      earlydetectionTabIdeaPartCGeneralPriceInflation: 1.17731472000804,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEILow: 0.148,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEIHigh: 0.222,
      earlydetectionTabSpecialEducationCostPerYear: 9369,
      earlydetectionTabIdeaPartCCostPerYear: 4566.62,
      earlydetectionTotalNetSavings: 4567,
    },
    positiveParenting: {
      positiveparentingTabGeneralPriceInflation: 1.354598357777,
      positiveparentingTabMedicalPriceInflation: 1.10166468370608,
    },
  },
  DE: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.034,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.4901768707483,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 67,
      edTabMedicaidPriceInflactionFactor: 1.1842001401844,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 202.65,
      edTabMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 123.41,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.179,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.713,
      birthsTabCostPerPubliclyFundedBirthShortTerm: 16736,
      birthsTabCostPerPubliclyFundedBirthLongTerm: 11309,
      birthsTabGeneralPriceInflationFactor: 1.69372984991662,
      birthsTabMedicalPriceInflationFactor: 1.41357649651423,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.724,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
      immunizationsTabMedicalPriceInflationFactor: 1.69889851485149,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 12.280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.26800495662949,
      ipvTabPercentageOfWomanInSampleWithLowerIpvScore: 13.3333333333333,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.323336321832430000000000,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805.07042253521,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.1551832231624,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.29155513320506,
      smokingTabRelapseRateYear1: 0.1,
      smokingTabRelapseRateYears2to7: 0.04,
    },
    diapers: {
      diapersTabMedicalPriceInflationFactor: 1.1842001401844,
      diapersTabTotalNetSavings: 1274.91756756757,
    },
    foodSecurity: {
      foodTabMedicalPriceInflation: 1.1842001401844,
      foodTabTotalNetSavings: 1834,
    },
    earlyDetection: {
      earlydetectionTabEarlyEducationProgramsGeneralPriceInflation: 1.30797529157742,
      earlydetectionTabSpecialEducationGeneralPriceInflation: 1.46956236556028,
      earlydetectionTabIdeaPartCGeneralPriceInflation: 1.17731472000804,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEILow: 0.148,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEIHigh: 0.222,
      earlydetectionTabSpecialEducationCostPerYear: 9369,
      earlydetectionTabIdeaPartCCostPerYear: 4566.62,
      earlydetectionTotalNetSavings: 4567,
    },
    positiveParenting: {
      positiveparentingTabGeneralPriceInflation: 1.354598357777,
      positiveparentingTabMedicalPriceInflation: 1.10166468370608,
    },
  },
  FL: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.046,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.4901768707483,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 48.27,
      edTabMedicaidPriceInflactionFactor: 1.1842001401844,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 164.02,
      edTabMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 53.43,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.08,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.706,
      birthsTabCostPerPubliclyFundedBirthShortTerm: 10748,
      birthsTabCostPerPubliclyFundedBirthLongTerm: 7458,
      birthsTabGeneralPriceInflationFactor: 1.69372984991662,
      birthsTabMedicalPriceInflationFactor: 1.41357649651423,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.567,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
      immunizationsTabMedicalPriceInflationFactor: 1.69889851485149,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 12.280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.26800495662949,
      ipvTabPercentageOfWomanInSampleWithLowerIpvScore: 13.3333333333333,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.323336321832430000000000,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805.07042253521,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.1551832231624,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.29155513320506,
      smokingTabRelapseRateYear1: 0.1,
      smokingTabRelapseRateYears2to7: 0.04,
    },
    diapers: {
      diapersTabMedicalPriceInflationFactor: 1.1842001401844,
      diapersTabTotalNetSavings: 1274.91756756757,
    },
    foodSecurity: {
      foodTabMedicalPriceInflation: 1.1842001401844,
      foodTabTotalNetSavings: 1834,
    },
    earlyDetection: {
      earlydetectionTabEarlyEducationProgramsGeneralPriceInflation: 1.30797529157742,
      earlydetectionTabSpecialEducationGeneralPriceInflation: 1.46956236556028,
      earlydetectionTabIdeaPartCGeneralPriceInflation: 1.17731472000804,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEILow: 0.148,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEIHigh: 0.222,
      earlydetectionTabSpecialEducationCostPerYear: 9369,
      earlydetectionTabIdeaPartCCostPerYear: 4566.62,
      earlydetectionTotalNetSavings: 4567,
    },
    positiveParenting: {
      positiveparentingTabGeneralPriceInflation: 1.354598357777,
      positiveparentingTabMedicalPriceInflation: 1.10166468370608,
    },
  },
  GA: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.032,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.4901768707483,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 62.71,
      edTabMedicaidPriceInflactionFactor: 1.1842001401844,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 138.63,
      edTabMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 115.71,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.087,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.805,
      birthsTabCostPerPubliclyFundedBirthShortTerm: 10837,
      birthsTabCostPerPubliclyFundedBirthLongTerm: 5445,
      birthsTabGeneralPriceInflationFactor: 1.69372984991662,
      birthsTabMedicalPriceInflationFactor: 1.41357649651423,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.598,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
      immunizationsTabMedicalPriceInflationFactor: 1.69889851485149,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 12.280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.26800495662949,
      ipvTabPercentageOfWomanInSampleWithLowerIpvScore: 13.3333333333333,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.323336321832430000000000,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805.07042253521,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.1551832231624,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.29155513320506,
      smokingTabRelapseRateYear1: 0.1,
      smokingTabRelapseRateYears2to7: 0.04,
    },
    diapers: {
      diapersTabMedicalPriceInflationFactor: 1.1842001401844,
      diapersTabTotalNetSavings: 1274.91756756757,
    },
    foodSecurity: {
      foodTabMedicalPriceInflation: 1.1842001401844,
      foodTabTotalNetSavings: 1834,
    },
    earlyDetection: {
      earlydetectionTabEarlyEducationProgramsGeneralPriceInflation: 1.30797529157742,
      earlydetectionTabSpecialEducationGeneralPriceInflation: 1.46956236556028,
      earlydetectionTabIdeaPartCGeneralPriceInflation: 1.17731472000804,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEILow: 0.148,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEIHigh: 0.222,
      earlydetectionTabSpecialEducationCostPerYear: 9369,
      earlydetectionTabIdeaPartCCostPerYear: 4566.62,
      earlydetectionTotalNetSavings: 4567,
    },
    positiveParenting: {
      positiveparentingTabGeneralPriceInflation: 1.354598357777,
      positiveparentingTabMedicalPriceInflation: 1.10166468370608,
    },
  },
  HI: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.056,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.4901768707483,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 56.46,
      edTabMedicaidPriceInflactionFactor: 1.1842001401844,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 419.61,
      edTabMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 100.84,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.153,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.499,
      birthsTabCostPerPubliclyFundedBirthShortTerm: 18080,
      birthsTabCostPerPubliclyFundedBirthLongTerm: 7754,
      birthsTabGeneralPriceInflationFactor: 1.69372984991662,
      birthsTabMedicalPriceInflationFactor: 1.41357649651423,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.67,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
      immunizationsTabMedicalPriceInflationFactor: 1.69889851485149,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 12.280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.26800495662949,
      ipvTabPercentageOfWomanInSampleWithLowerIpvScore: 13.3333333333333,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.323336321832430000000000,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805.07042253521,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.1551832231624,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.29155513320506,
      smokingTabRelapseRateYear1: 0.1,
      smokingTabRelapseRateYears2to7: 0.04,
    },
    diapers: {
      diapersTabMedicalPriceInflationFactor: 1.1842001401844,
      diapersTabTotalNetSavings: 1274.91756756757,
    },
    foodSecurity: {
      foodTabMedicalPriceInflation: 1.1842001401844,
      foodTabTotalNetSavings: 1834,
    },
    earlyDetection: {
      earlydetectionTabEarlyEducationProgramsGeneralPriceInflation: 1.30797529157742,
      earlydetectionTabSpecialEducationGeneralPriceInflation: 1.46956236556028,
      earlydetectionTabIdeaPartCGeneralPriceInflation: 1.17731472000804,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEILow: 0.148,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEIHigh: 0.222,
      earlydetectionTabSpecialEducationCostPerYear: 9369,
      earlydetectionTabIdeaPartCCostPerYear: 4566.62,
      earlydetectionTotalNetSavings: 4567,
    },
    positiveParenting: {
      positiveparentingTabGeneralPriceInflation: 1.354598357777,
      positiveparentingTabMedicalPriceInflation: 1.10166468370608,
    },
  },
  ID: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.039,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.4901768707483,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 71.82,
      edTabMedicaidPriceInflactionFactor: 1.1842001401844,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 202.65,
      edTabMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 118.2,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.075,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.604,
      birthsTabCostPerPubliclyFundedBirthShortTerm: 15457,
      birthsTabCostPerPubliclyFundedBirthLongTerm: 3260,
      birthsTabGeneralPriceInflationFactor: 1.69372984991662,
      birthsTabMedicalPriceInflationFactor: 1.41357649651423,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.699,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
      immunizationsTabMedicalPriceInflationFactor: 1.69889851485149,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 12.280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.26800495662949,
      ipvTabPercentageOfWomanInSampleWithLowerIpvScore: 13.3333333333333,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.323336321832430000000000,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805.07042253521,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.1551832231624,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.29155513320506,
      smokingTabRelapseRateYear1: 0.1,
      smokingTabRelapseRateYears2to7: 0.04,
    },
    diapers: {
      diapersTabMedicalPriceInflationFactor: 1.1842001401844,
      diapersTabTotalNetSavings: 1274.91756756757,
    },
    foodSecurity: {
      foodTabMedicalPriceInflation: 1.1842001401844,
      foodTabTotalNetSavings: 1834,
    },
    earlyDetection: {
      earlydetectionTabEarlyEducationProgramsGeneralPriceInflation: 1.30797529157742,
      earlydetectionTabSpecialEducationGeneralPriceInflation: 1.46956236556028,
      earlydetectionTabIdeaPartCGeneralPriceInflation: 1.17731472000804,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEILow: 0.148,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEIHigh: 0.222,
      earlydetectionTabSpecialEducationCostPerYear: 9369,
      earlydetectionTabIdeaPartCCostPerYear: 4566.62,
      earlydetectionTotalNetSavings: 4567,
    },
    positiveParenting: {
      positiveparentingTabGeneralPriceInflation: 1.354598357777,
      positiveparentingTabMedicalPriceInflation: 1.10166468370608,
    },
  },
  IL: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.039,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.4901768707483,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 71.82,
      edTabMedicaidPriceInflactionFactor: 1.1842001401844,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 191.16,
      edTabMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 96.26,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.075,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.783,
      birthsTabCostPerPubliclyFundedBirthShortTerm: 11152,
      birthsTabCostPerPubliclyFundedBirthLongTerm: 5366,
      birthsTabGeneralPriceInflationFactor: 1.69372984991662,
      birthsTabMedicalPriceInflationFactor: 1.41357649651423,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.699,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
      immunizationsTabMedicalPriceInflationFactor: 1.69889851485149,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 12.280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.26800495662949,
      ipvTabPercentageOfWomanInSampleWithLowerIpvScore: 13.3333333333333,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.323336321832430000000000,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805.07042253521,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.1551832231624,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.29155513320506,
      smokingTabRelapseRateYear1: 0.1,
      smokingTabRelapseRateYears2to7: 0.04,
    },
    diapers: {
      diapersTabMedicalPriceInflationFactor: 1.1842001401844,
      diapersTabTotalNetSavings: 1274.91756756757,
    },
    foodSecurity: {
      foodTabMedicalPriceInflation: 1.1842001401844,
      foodTabTotalNetSavings: 1834,
    },
    earlyDetection: {
      earlydetectionTabEarlyEducationProgramsGeneralPriceInflation: 1.30797529157742,
      earlydetectionTabSpecialEducationGeneralPriceInflation: 1.46956236556028,
      earlydetectionTabIdeaPartCGeneralPriceInflation: 1.17731472000804,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEILow: 0.148,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEIHigh: 0.222,
      earlydetectionTabSpecialEducationCostPerYear: 9369,
      earlydetectionTabIdeaPartCCostPerYear: 4566.62,
      earlydetectionTotalNetSavings: 4567,
    },
    positiveParenting: {
      positiveparentingTabGeneralPriceInflation: 1.354598357777,
      positiveparentingTabMedicalPriceInflation: 1.10166468370608,
    },
  },
  IN: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.039,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.4901768707483,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 71.82,
      edTabMedicaidPriceInflactionFactor: 1.1842001401844,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 202.65,
      edTabMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 119.13,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.075,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.646,
      birthsTabCostPerPubliclyFundedBirthShortTerm: 10460,
      birthsTabCostPerPubliclyFundedBirthLongTerm: 5690,
      birthsTabGeneralPriceInflationFactor: 1.69372984991662,
      birthsTabMedicalPriceInflationFactor: 1.41357649651423,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.699,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
      immunizationsTabMedicalPriceInflationFactor: 1.69889851485149,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 12.280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.26800495662949,
      ipvTabPercentageOfWomanInSampleWithLowerIpvScore: 13.3333333333333,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.323336321832430000000000,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805.07042253521,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.1551832231624,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.29155513320506,
      smokingTabRelapseRateYear1: 0.1,
      smokingTabRelapseRateYears2to7: 0.04,
    },
    diapers: {
      diapersTabMedicalPriceInflationFactor: 1.1842001401844,
      diapersTabTotalNetSavings: 1274.91756756757,
    },
    foodSecurity: {
      foodTabMedicalPriceInflation: 1.1842001401844,
      foodTabTotalNetSavings: 1834,
    },
    earlyDetection: {
      earlydetectionTabEarlyEducationProgramsGeneralPriceInflation: 1.30797529157742,
      earlydetectionTabSpecialEducationGeneralPriceInflation: 1.46956236556028,
      earlydetectionTabIdeaPartCGeneralPriceInflation: 1.17731472000804,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEILow: 0.148,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEIHigh: 0.222,
      earlydetectionTabSpecialEducationCostPerYear: 9369,
      earlydetectionTabIdeaPartCCostPerYear: 4566.62,
      earlydetectionTotalNetSavings: 4567,
    },
    positiveParenting: {
      positiveparentingTabGeneralPriceInflation: 1.354598357777,
      positiveparentingTabMedicalPriceInflation: 1.10166468370608,
    },
  },
  IA: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.039,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.4901768707483,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 71.82,
      edTabMedicaidPriceInflactionFactor: 1.1842001401844,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 179.73,
      edTabMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 66.8,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.075,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.615,
      birthsTabCostPerPubliclyFundedBirthShortTerm: 16518,
      birthsTabCostPerPubliclyFundedBirthLongTerm: 7536,
      birthsTabGeneralPriceInflationFactor: 1.69372984991662,
      birthsTabMedicalPriceInflationFactor: 1.41357649651423,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.699,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
      immunizationsTabMedicalPriceInflationFactor: 1.69889851485149,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 12.280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.26800495662949,
      ipvTabPercentageOfWomanInSampleWithLowerIpvScore: 13.3333333333333,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.323336321832430000000000,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805.07042253521,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.1551832231624,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.29155513320506,
      smokingTabRelapseRateYear1: 0.1,
      smokingTabRelapseRateYears2to7: 0.04,
    },
    diapers: {
      diapersTabMedicalPriceInflationFactor: 1.1842001401844,
      diapersTabTotalNetSavings: 1274.91756756757,
    },
    foodSecurity: {
      foodTabMedicalPriceInflation: 1.1842001401844,
      foodTabTotalNetSavings: 1834,
    },
    earlyDetection: {
      earlydetectionTabEarlyEducationProgramsGeneralPriceInflation: 1.30797529157742,
      earlydetectionTabSpecialEducationGeneralPriceInflation: 1.46956236556028,
      earlydetectionTabIdeaPartCGeneralPriceInflation: 1.17731472000804,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEILow: 0.148,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEIHigh: 0.222,
      earlydetectionTabSpecialEducationCostPerYear: 9369,
      earlydetectionTabIdeaPartCCostPerYear: 4566.62,
      earlydetectionTotalNetSavings: 4567,
    },
    positiveParenting: {
      positiveparentingTabGeneralPriceInflation: 1.354598357777,
      positiveparentingTabMedicalPriceInflation: 1.10166468370608,
    },
  },
  KS: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.039,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.4901768707483,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 66.79,
      edTabMedicaidPriceInflactionFactor: 1.1842001401844,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 179.73,
      edTabMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 66.15,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.214,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.472,
      birthsTabCostPerPubliclyFundedBirthShortTerm: 13947,
      birthsTabCostPerPubliclyFundedBirthLongTerm: 7283,
      birthsTabGeneralPriceInflationFactor: 1.69372984991662,
      birthsTabMedicalPriceInflationFactor: 1.41357649651423,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.669,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
      immunizationsTabMedicalPriceInflationFactor: 1.69889851485149,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 12.280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.26800495662949,
      ipvTabPercentageOfWomanInSampleWithLowerIpvScore: 13.3333333333333,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.323336321832430000000000,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805.07042253521,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.1551832231624,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.29155513320506,
      smokingTabRelapseRateYear1: 0.1,
      smokingTabRelapseRateYears2to7: 0.04,
    },
    diapers: {
      diapersTabMedicalPriceInflationFactor: 1.1842001401844,
      diapersTabTotalNetSavings: 1274.91756756757,
    },
    foodSecurity: {
      foodTabMedicalPriceInflation: 1.1842001401844,
      foodTabTotalNetSavings: 1834,
    },
    earlyDetection: {
      earlydetectionTabEarlyEducationProgramsGeneralPriceInflation: 1.30797529157742,
      earlydetectionTabSpecialEducationGeneralPriceInflation: 1.46956236556028,
      earlydetectionTabIdeaPartCGeneralPriceInflation: 1.17731472000804,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEILow: 0.148,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEIHigh: 0.222,
      earlydetectionTabSpecialEducationCostPerYear: 9369,
      earlydetectionTabIdeaPartCCostPerYear: 4566.62,
      earlydetectionTotalNetSavings: 4567,
    },
    positiveParenting: {
      positiveparentingTabGeneralPriceInflation: 1.354598357777,
      positiveparentingTabMedicalPriceInflation: 1.10166468370608,
    },
  },
  KY: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.018,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.4901768707483,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 67.1,
      edTabMedicaidPriceInflactionFactor: 1.1842001401844,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 179.73,
      edTabMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 72.1,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.072,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.668,
      birthsTabCostPerPubliclyFundedBirthShortTerm: 14887,
      birthsTabCostPerPubliclyFundedBirthLongTerm: 9701,
      birthsTabGeneralPriceInflationFactor: 1.69372984991662,
      birthsTabMedicalPriceInflationFactor: 1.41357649651423,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.605,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
      immunizationsTabMedicalPriceInflationFactor: 1.69889851485149,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 12.280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.26800495662949,
      ipvTabPercentageOfWomanInSampleWithLowerIpvScore: 13.3333333333333,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.323336321832430000000000,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805.07042253521,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.1551832231624,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.29155513320506,
      smokingTabRelapseRateYear1: 0.1,
      smokingTabRelapseRateYears2to7: 0.04,
    },
    diapers: {
      diapersTabMedicalPriceInflationFactor: 1.1842001401844,
      diapersTabTotalNetSavings: 1274.91756756757,
    },
    foodSecurity: {
      foodTabMedicalPriceInflation: 1.1842001401844,
      foodTabTotalNetSavings: 1834,
    },
    earlyDetection: {
      earlydetectionTabEarlyEducationProgramsGeneralPriceInflation: 1.30797529157742,
      earlydetectionTabSpecialEducationGeneralPriceInflation: 1.46956236556028,
      earlydetectionTabIdeaPartCGeneralPriceInflation: 1.17731472000804,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEILow: 0.148,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEIHigh: 0.222,
      earlydetectionTabSpecialEducationCostPerYear: 9369,
      earlydetectionTabIdeaPartCCostPerYear: 4566.62,
      earlydetectionTotalNetSavings: 4567,
    },
    positiveParenting: {
      positiveparentingTabGeneralPriceInflation: 1.354598357777,
      positiveparentingTabMedicalPriceInflation: 1.10166468370608,
    },
  },
  LA: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.039,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.4901768707483,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 71.82,
      edTabMedicaidPriceInflactionFactor: 1.1842001401844,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 197.46,
      edTabMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 75.18,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.075,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.787,
      birthsTabCostPerPubliclyFundedBirthShortTerm: 16779,
      birthsTabCostPerPubliclyFundedBirthLongTerm: 7401,
      birthsTabGeneralPriceInflationFactor: 1.69372984991662,
      birthsTabMedicalPriceInflationFactor: 1.41357649651423,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.699,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
      immunizationsTabMedicalPriceInflationFactor: 1.69889851485149,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 12.280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.26800495662949,
      ipvTabPercentageOfWomanInSampleWithLowerIpvScore: 13.3333333333333,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.323336321832430000000000,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805.07042253521,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.1551832231624,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.29155513320506,
      smokingTabRelapseRateYear1: 0.1,
      smokingTabRelapseRateYears2to7: 0.04,
    },
    diapers: {
      diapersTabMedicalPriceInflationFactor: 1.1842001401844,
      diapersTabTotalNetSavings: 1274.91756756757,
    },
    foodSecurity: {
      foodTabMedicalPriceInflation: 1.1842001401844,
      foodTabTotalNetSavings: 1834,
    },
    earlyDetection: {
      earlydetectionTabEarlyEducationProgramsGeneralPriceInflation: 1.30797529157742,
      earlydetectionTabSpecialEducationGeneralPriceInflation: 1.46956236556028,
      earlydetectionTabIdeaPartCGeneralPriceInflation: 1.17731472000804,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEILow: 0.148,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEIHigh: 0.222,
      earlydetectionTabSpecialEducationCostPerYear: 9369,
      earlydetectionTabIdeaPartCCostPerYear: 4566.62,
      earlydetectionTotalNetSavings: 4567,
    },
    positiveParenting: {
      positiveparentingTabGeneralPriceInflation: 1.354598357777,
      positiveparentingTabMedicalPriceInflation: 1.10166468370608,
    },
  },
  ME: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.039,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.4901768707483,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 71.82,
      edTabMedicaidPriceInflactionFactor: 1.1842001401844,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 272.16,
      edTabMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 118.42,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.075,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.747,
      birthsTabCostPerPubliclyFundedBirthShortTerm: 9414,
      birthsTabCostPerPubliclyFundedBirthLongTerm: 5745,
      birthsTabGeneralPriceInflationFactor: 1.69372984991662,
      birthsTabMedicalPriceInflationFactor: 1.41357649651423,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.699,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
      immunizationsTabMedicalPriceInflationFactor: 1.69889851485149,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 12.280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.26800495662949,
      ipvTabPercentageOfWomanInSampleWithLowerIpvScore: 13.3333333333333,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.323336321832430000000000,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805.07042253521,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.1551832231624,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.29155513320506,
      smokingTabRelapseRateYear1: 0.1,
      smokingTabRelapseRateYears2to7: 0.04,
    },
    diapers: {
      diapersTabMedicalPriceInflationFactor: 1.1842001401844,
      diapersTabTotalNetSavings: 1274.91756756757,
    },
    foodSecurity: {
      foodTabMedicalPriceInflation: 1.1842001401844,
      foodTabTotalNetSavings: 1834,
    },
    earlyDetection: {
      earlydetectionTabEarlyEducationProgramsGeneralPriceInflation: 1.30797529157742,
      earlydetectionTabSpecialEducationGeneralPriceInflation: 1.46956236556028,
      earlydetectionTabIdeaPartCGeneralPriceInflation: 1.17731472000804,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEILow: 0.148,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEIHigh: 0.222,
      earlydetectionTabSpecialEducationCostPerYear: 9369,
      earlydetectionTabIdeaPartCCostPerYear: 4566.62,
      earlydetectionTotalNetSavings: 4567,
    },
    positiveParenting: {
      positiveparentingTabGeneralPriceInflation: 1.354598357777,
      positiveparentingTabMedicalPriceInflation: 1.10166468370608,
    },
  },
  MD: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.035,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.4901768707483,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.04056595807562,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 84.315049981129,
      edTabMedicaidPriceInflactionFactor: 1.1842001401844,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 202.65,
      edTabMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 138.61,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.107,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.582,
      birthsTabCostPerPubliclyFundedBirthShortTerm: 14760,
      birthsTabCostPerPubliclyFundedBirthLongTerm: 9246,
      birthsTabGeneralPriceInflationFactor: 1.69372984991662,
      birthsTabMedicalPriceInflationFactor: 1.41357649651423,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.795,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
      immunizationsTabMedicalPriceInflationFactor: 1.69889851485149,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 12.280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.26800495662949,
      ipvTabPercentageOfWomanInSampleWithLowerIpvScore: 13.3333333333333,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.323336321832430000000000,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805.07042253521,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.1551832231624,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.29155513320506,
      smokingTabRelapseRateYear1: 0.1,
      smokingTabRelapseRateYears2to7: 0.04,
    },
    diapers: {
      diapersTabMedicalPriceInflationFactor: 1.1842001401844,
      diapersTabTotalNetSavings: 1274.91756756757,
    },
    foodSecurity: {
      foodTabMedicalPriceInflation: 1.1842001401844,
      foodTabTotalNetSavings: 1834,
    },
    earlyDetection: {
      earlydetectionTabEarlyEducationProgramsGeneralPriceInflation: 1.30797529157742,
      earlydetectionTabSpecialEducationGeneralPriceInflation: 1.46956236556028,
      earlydetectionTabIdeaPartCGeneralPriceInflation: 1.17731472000804,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEILow: 0.148,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEIHigh: 0.222,
      earlydetectionTabSpecialEducationCostPerYear: 9369,
      earlydetectionTabIdeaPartCCostPerYear: 4566.62,
      earlydetectionTotalNetSavings: 4567,
    },
    positiveParenting: {
      positiveparentingTabGeneralPriceInflation: 1.354598357777,
      positiveparentingTabMedicalPriceInflation: 1.10166468370608,
    },
  },
  MA: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.031,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.4901768707483,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 96.84,
      edTabMedicaidPriceInflactionFactor: 1.1842001401844,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 292.68,
      edTabMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 96.15,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.05,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.564,
      birthsTabCostPerPubliclyFundedBirthShortTerm: 15109,
      birthsTabCostPerPubliclyFundedBirthLongTerm: 11670,
      birthsTabGeneralPriceInflationFactor: 1.69372984991662,
      birthsTabMedicalPriceInflationFactor: 1.41357649651423,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.87,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
      immunizationsTabMedicalPriceInflationFactor: 1.69889851485149,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 12.280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.26800495662949,
      ipvTabPercentageOfWomanInSampleWithLowerIpvScore: 13.3333333333333,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.323336321832430000000000,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805.07042253521,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.1551832231624,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.29155513320506,
      smokingTabRelapseRateYear1: 0.1,
      smokingTabRelapseRateYears2to7: 0.04,
    },
    diapers: {
      diapersTabMedicalPriceInflationFactor: 1.1842001401844,
      diapersTabTotalNetSavings: 1274.91756756757,
    },
    foodSecurity: {
      foodTabMedicalPriceInflation: 1.1842001401844,
      foodTabTotalNetSavings: 1834,
    },
    earlyDetection: {
      earlydetectionTabEarlyEducationProgramsGeneralPriceInflation: 1.30797529157742,
      earlydetectionTabSpecialEducationGeneralPriceInflation: 1.46956236556028,
      earlydetectionTabIdeaPartCGeneralPriceInflation: 1.17731472000804,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEILow: 0.148,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEIHigh: 0.222,
      earlydetectionTabSpecialEducationCostPerYear: 9369,
      earlydetectionTabIdeaPartCCostPerYear: 4566.62,
      earlydetectionTotalNetSavings: 4567,
    },
    positiveParenting: {
      positiveparentingTabGeneralPriceInflation: 1.354598357777,
      positiveparentingTabMedicalPriceInflation: 1.10166468370608,
    },
  },
  MI: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.031,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.4901768707483,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 96.84,
      edTabMedicaidPriceInflactionFactor: 1.1842001401844,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 202.65,
      edTabMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 110.16,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.05,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.719,
      birthsTabCostPerPubliclyFundedBirthShortTerm: 9853,
      birthsTabCostPerPubliclyFundedBirthLongTerm: 7975,
      birthsTabGeneralPriceInflationFactor: 1.69372984991662,
      birthsTabMedicalPriceInflationFactor: 1.41357649651423,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.87,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
      immunizationsTabMedicalPriceInflationFactor: 1.69889851485149,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 12.280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.26800495662949,
      ipvTabPercentageOfWomanInSampleWithLowerIpvScore: 13.3333333333333,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.323336321832430000000000,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805.07042253521,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.1551832231624,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.29155513320506,
      smokingTabRelapseRateYear1: 0.1,
      smokingTabRelapseRateYears2to7: 0.04,
    },
    diapers: {
      diapersTabMedicalPriceInflationFactor: 1.1842001401844,
      diapersTabTotalNetSavings: 1274.91756756757,
    },
    foodSecurity: {
      foodTabMedicalPriceInflation: 1.1842001401844,
      foodTabTotalNetSavings: 1834,
    },
    earlyDetection: {
      earlydetectionTabEarlyEducationProgramsGeneralPriceInflation: 1.30797529157742,
      earlydetectionTabSpecialEducationGeneralPriceInflation: 1.46956236556028,
      earlydetectionTabIdeaPartCGeneralPriceInflation: 1.17731472000804,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEILow: 0.148,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEIHigh: 0.222,
      earlydetectionTabSpecialEducationCostPerYear: 9369,
      earlydetectionTabIdeaPartCCostPerYear: 4566.62,
      earlydetectionTotalNetSavings: 4567,
    },
    positiveParenting: {
      positiveparentingTabGeneralPriceInflation: 1.354598357777,
      positiveparentingTabMedicalPriceInflation: 1.10166468370608,
    },
  },
  MN: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.028,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.4901768707483,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 93.43,
      edTabMedicaidPriceInflactionFactor: 1.1842001401844,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 202.65,
      edTabMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 96.63,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.071,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.667,
      birthsTabCostPerPubliclyFundedBirthShortTerm: 10594,
      birthsTabCostPerPubliclyFundedBirthLongTerm: 11690,
      birthsTabGeneralPriceInflationFactor: 1.69372984991662,
      birthsTabMedicalPriceInflationFactor: 1.41357649651423,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.686,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
      immunizationsTabMedicalPriceInflationFactor: 1.69889851485149,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 12.280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.26800495662949,
      ipvTabPercentageOfWomanInSampleWithLowerIpvScore: 13.3333333333333,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.323336321832430000000000,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805.07042253521,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.1551832231624,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.29155513320506,
      smokingTabRelapseRateYear1: 0.1,
      smokingTabRelapseRateYears2to7: 0.04,
    },
    diapers: {
      diapersTabMedicalPriceInflationFactor: 1.1842001401844,
      diapersTabTotalNetSavings: 1274.91756756757,
    },
    foodSecurity: {
      foodTabMedicalPriceInflation: 1.1842001401844,
      foodTabTotalNetSavings: 1834,
    },
    earlyDetection: {
      earlydetectionTabEarlyEducationProgramsGeneralPriceInflation: 1.30797529157742,
      earlydetectionTabSpecialEducationGeneralPriceInflation: 1.46956236556028,
      earlydetectionTabIdeaPartCGeneralPriceInflation: 1.17731472000804,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEILow: 0.148,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEIHigh: 0.222,
      earlydetectionTabSpecialEducationCostPerYear: 9369,
      earlydetectionTabIdeaPartCCostPerYear: 4566.62,
      earlydetectionTotalNetSavings: 4567,
    },
    positiveParenting: {
      positiveparentingTabGeneralPriceInflation: 1.354598357777,
      positiveparentingTabMedicalPriceInflation: 1.10166468370608,
    },
  },
  MS: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.023,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.4901768707483,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 74.71,
      edTabMedicaidPriceInflactionFactor: 1.1842001401844,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020:174.10,
      edTabMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 116.33,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.072,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.819,
      birthsTabCostPerPubliclyFundedBirthShortTerm: 7090,
      birthsTabCostPerPubliclyFundedBirthLongTerm: 7112,
      birthsTabGeneralPriceInflationFactor: 1.69372984991662,
      birthsTabMedicalPriceInflationFactor: 1.41357649651423,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.543,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
      immunizationsTabMedicalPriceInflationFactor: 1.69889851485149,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 12.280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.26800495662949,
      ipvTabPercentageOfWomanInSampleWithLowerIpvScore: 13.3333333333333,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.323336321832430000000000,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805.07042253521,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.1551832231624,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.29155513320506,
      smokingTabRelapseRateYear1: 0.1,
      smokingTabRelapseRateYears2to7: 0.04,
    },
    diapers: {
      diapersTabMedicalPriceInflationFactor: 1.1842001401844,
      diapersTabTotalNetSavings: 1274.91756756757,
    },
    foodSecurity: {
      foodTabMedicalPriceInflation: 1.1842001401844,
      foodTabTotalNetSavings: 1834,
    },
    earlyDetection: {
      earlydetectionTabEarlyEducationProgramsGeneralPriceInflation: 1.30797529157742,
      earlydetectionTabSpecialEducationGeneralPriceInflation: 1.46956236556028,
      earlydetectionTabIdeaPartCGeneralPriceInflation: 1.17731472000804,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEILow: 0.148,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEIHigh: 0.222,
      earlydetectionTabSpecialEducationCostPerYear: 9369,
      earlydetectionTabIdeaPartCCostPerYear: 4566.62,
      earlydetectionTotalNetSavings: 4567,
    },
    positiveParenting: {
      positiveparentingTabGeneralPriceInflation: 1.354598357777,
      positiveparentingTabMedicalPriceInflation: 1.10166468370608,
    },
  },
  MO: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.078,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.4901768707483,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 58.57,
      edTabMedicaidPriceInflactionFactor: 1.1842001401844,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 147.83,
      edTabMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 106.15,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.059,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.722,
      birthsTabCostPerPubliclyFundedBirthShortTerm: 11572,
      birthsTabCostPerPubliclyFundedBirthLongTerm: 8897,
      birthsTabGeneralPriceInflationFactor: 1.69372984991662,
      birthsTabMedicalPriceInflationFactor: 1.41357649651423,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.692,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
      immunizationsTabMedicalPriceInflationFactor: 1.69889851485149,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 12.280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.26800495662949,
      ipvTabPercentageOfWomanInSampleWithLowerIpvScore: 13.3333333333333,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.323336321832430000000000,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805.07042253521,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.1551832231624,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.29155513320506,
      smokingTabRelapseRateYear1: 0.1,
      smokingTabRelapseRateYears2to7: 0.04,
    },
    diapers: {
      diapersTabMedicalPriceInflationFactor: 1.1842001401844,
      diapersTabTotalNetSavings: 1274.91756756757,
    },
    foodSecurity: {
      foodTabMedicalPriceInflation: 1.1842001401844,
      foodTabTotalNetSavings: 1834,
    },
    earlyDetection: {
      earlydetectionTabEarlyEducationProgramsGeneralPriceInflation: 1.30797529157742,
      earlydetectionTabSpecialEducationGeneralPriceInflation: 1.46956236556028,
      earlydetectionTabIdeaPartCGeneralPriceInflation: 1.17731472000804,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEILow: 0.148,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEIHigh: 0.222,
      earlydetectionTabSpecialEducationCostPerYear: 9369,
      earlydetectionTabIdeaPartCCostPerYear: 4566.62,
      earlydetectionTotalNetSavings: 4567,
    },
    positiveParenting: {
      positiveparentingTabGeneralPriceInflation: 1.354598357777,
      positiveparentingTabMedicalPriceInflation: 1.10166468370608,
    },
  },
  MT: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.031,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.4901768707483,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 71.20,
      edTabMedicaidPriceInflactionFactor: 1.1842001401844,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 202.65,
      edTabMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 169.11,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.116,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.478,
      birthsTabCostPerPubliclyFundedBirthShortTerm: 13079,
      birthsTabCostPerPubliclyFundedBirthLongTerm: 5833,
      birthsTabGeneralPriceInflationFactor: 1.69372984991662,
      birthsTabMedicalPriceInflationFactor: 1.41357649651423,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.833,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
      immunizationsTabMedicalPriceInflationFactor: 1.69889851485149,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 12.280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.26800495662949,
      ipvTabPercentageOfWomanInSampleWithLowerIpvScore: 13.3333333333333,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.323336321832430000000000,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805.07042253521,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.1551832231624,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.29155513320506,
      smokingTabRelapseRateYear1: 0.1,
      smokingTabRelapseRateYears2to7: 0.04,
    },
    diapers: {
      diapersTabMedicalPriceInflationFactor: 1.1842001401844,
      diapersTabTotalNetSavings: 1274.91756756757,
    },
    foodSecurity: {
      foodTabMedicalPriceInflation: 1.1842001401844,
      foodTabTotalNetSavings: 1834,
    },
    earlyDetection: {
      earlydetectionTabEarlyEducationProgramsGeneralPriceInflation: 1.30797529157742,
      earlydetectionTabSpecialEducationGeneralPriceInflation: 1.46956236556028,
      earlydetectionTabIdeaPartCGeneralPriceInflation: 1.17731472000804,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEILow: 0.148,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEIHigh: 0.222,
      earlydetectionTabSpecialEducationCostPerYear: 9369,
      earlydetectionTabIdeaPartCCostPerYear: 4566.62,
      earlydetectionTotalNetSavings: 4567,
    },
    positiveParenting: {
      positiveparentingTabGeneralPriceInflation: 1.354598357777,
      positiveparentingTabMedicalPriceInflation: 1.10166468370608,
    },
  },
  NE: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.031,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.4901768707483,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 71.20,
      edTabMedicaidPriceInflactionFactor: 1.1842001401844,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 245.97,
      edTabMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 74.79,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.116,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.631,
      birthsTabCostPerPubliclyFundedBirthShortTerm: 14411,
      birthsTabCostPerPubliclyFundedBirthLongTerm: 6541,
      birthsTabGeneralPriceInflationFactor: 1.69372984991662,
      birthsTabMedicalPriceInflationFactor: 1.41357649651423,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.833,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
      immunizationsTabMedicalPriceInflationFactor: 1.69889851485149,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 12.280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.26800495662949,
      ipvTabPercentageOfWomanInSampleWithLowerIpvScore: 13.3333333333333,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.323336321832430000000000,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805.07042253521,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.1551832231624,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.29155513320506,
      smokingTabRelapseRateYear1: 0.1,
      smokingTabRelapseRateYears2to7: 0.04,
    },
    diapers: {
      diapersTabMedicalPriceInflationFactor: 1.1842001401844,
      diapersTabTotalNetSavings: 1274.91756756757,
    },
    foodSecurity: {
      foodTabMedicalPriceInflation: 1.1842001401844,
      foodTabTotalNetSavings: 1834,
    },
    earlyDetection: {
      earlydetectionTabEarlyEducationProgramsGeneralPriceInflation: 1.30797529157742,
      earlydetectionTabSpecialEducationGeneralPriceInflation: 1.46956236556028,
      earlydetectionTabIdeaPartCGeneralPriceInflation: 1.17731472000804,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEILow: 0.148,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEIHigh: 0.222,
      earlydetectionTabSpecialEducationCostPerYear: 9369,
      earlydetectionTabIdeaPartCCostPerYear: 4566.62,
      earlydetectionTotalNetSavings: 4567,
    },
    positiveParenting: {
      positiveparentingTabGeneralPriceInflation: 1.354598357777,
      positiveparentingTabMedicalPriceInflation: 1.10166468370608,
    },
  },
  NV: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.031,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.4901768707483,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 71.20,
      edTabMedicaidPriceInflactionFactor: 1.1842001401844,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 202.65,
      edTabMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 110.75,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.116,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.6,
      birthsTabCostPerPubliclyFundedBirthShortTerm: 6759,
      birthsTabCostPerPubliclyFundedBirthLongTerm: 6042,
      birthsTabGeneralPriceInflationFactor: 1.69372984991662,
      birthsTabMedicalPriceInflationFactor: 1.41357649651423,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.833,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
      immunizationsTabMedicalPriceInflationFactor: 1.69889851485149,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 12.280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.26800495662949,
      ipvTabPercentageOfWomanInSampleWithLowerIpvScore: 13.3333333333333,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.323336321832430000000000,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805.07042253521,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.1551832231624,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.29155513320506,
      smokingTabRelapseRateYear1: 0.1,
      smokingTabRelapseRateYears2to7: 0.04,
    },
    diapers: {
      diapersTabMedicalPriceInflationFactor: 1.1842001401844,
      diapersTabTotalNetSavings: 1274.91756756757,
    },
    foodSecurity: {
      foodTabMedicalPriceInflation: 1.1842001401844,
      foodTabTotalNetSavings: 1834,
    },
    earlyDetection: {
      earlydetectionTabEarlyEducationProgramsGeneralPriceInflation: 1.30797529157742,
      earlydetectionTabSpecialEducationGeneralPriceInflation: 1.46956236556028,
      earlydetectionTabIdeaPartCGeneralPriceInflation: 1.17731472000804,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEILow: 0.148,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEIHigh: 0.222,
      earlydetectionTabSpecialEducationCostPerYear: 9369,
      earlydetectionTabIdeaPartCCostPerYear: 4566.62,
      earlydetectionTotalNetSavings: 4567,
    },
    positiveParenting: {
      positiveparentingTabGeneralPriceInflation: 1.354598357777,
      positiveparentingTabMedicalPriceInflation: 1.10166468370608,
    },
  },
  NH: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.031,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.4901768707483,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 71.20,
      edTabMedicaidPriceInflactionFactor: 1.1842001401844,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 306.57,
      edTabMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 193.85,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.116,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.527,
      birthsTabCostPerPubliclyFundedBirthShortTerm: 5848,
      birthsTabCostPerPubliclyFundedBirthLongTerm: 6267,
      birthsTabGeneralPriceInflationFactor: 1.69372984991662,
      birthsTabMedicalPriceInflationFactor: 1.41357649651423,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.833,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
      immunizationsTabMedicalPriceInflationFactor: 1.69889851485149,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 12.280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.26800495662949,
      ipvTabPercentageOfWomanInSampleWithLowerIpvScore: 13.3333333333333,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.323336321832430000000000,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805.07042253521,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.1551832231624,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.29155513320506,
      smokingTabRelapseRateYear1: 0.1,
      smokingTabRelapseRateYears2to7: 0.04,
    },
    diapers: {
      diapersTabMedicalPriceInflationFactor: 1.1842001401844,
      diapersTabTotalNetSavings: 1274.91756756757,
    },
    foodSecurity: {
      foodTabMedicalPriceInflation: 1.1842001401844,
      foodTabTotalNetSavings: 1834,
    },
    earlyDetection: {
      earlydetectionTabEarlyEducationProgramsGeneralPriceInflation: 1.30797529157742,
      earlydetectionTabSpecialEducationGeneralPriceInflation: 1.46956236556028,
      earlydetectionTabIdeaPartCGeneralPriceInflation: 1.17731472000804,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEILow: 0.148,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEIHigh: 0.222,
      earlydetectionTabSpecialEducationCostPerYear: 9369,
      earlydetectionTabIdeaPartCCostPerYear: 4566.62,
      earlydetectionTotalNetSavings: 4567,
    },
    positiveParenting: {
      positiveparentingTabGeneralPriceInflation: 1.354598357777,
      positiveparentingTabMedicalPriceInflation: 1.10166468370608,
    },
  },
  NJ: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.031,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.4901768707483,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 71.20,
      edTabMedicaidPriceInflactionFactor: 1.1842001401844,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 222,
      edTabMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 302.15,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.116,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.524,
      birthsTabCostPerPubliclyFundedBirthShortTerm: 15233,
      birthsTabCostPerPubliclyFundedBirthLongTerm: 7649,
      birthsTabGeneralPriceInflationFactor: 1.69372984991662,
      birthsTabMedicalPriceInflationFactor: 1.41357649651423,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.833,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
      immunizationsTabMedicalPriceInflationFactor: 1.69889851485149,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 12.280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.26800495662949,
      ipvTabPercentageOfWomanInSampleWithLowerIpvScore: 13.3333333333333,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.323336321832430000000000,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805.07042253521,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.1551832231624,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.29155513320506,
      smokingTabRelapseRateYear1: 0.1,
      smokingTabRelapseRateYears2to7: 0.04,
    },
    diapers: {
      diapersTabMedicalPriceInflationFactor: 1.1842001401844,
      diapersTabTotalNetSavings: 1274.91756756757,
    },
    foodSecurity: {
      foodTabMedicalPriceInflation: 1.1842001401844,
      foodTabTotalNetSavings: 1834,
    },
    earlyDetection: {
      earlydetectionTabEarlyEducationProgramsGeneralPriceInflation: 1.30797529157742,
      earlydetectionTabSpecialEducationGeneralPriceInflation: 1.46956236556028,
      earlydetectionTabIdeaPartCGeneralPriceInflation: 1.17731472000804,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEILow: 0.148,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEIHigh: 0.222,
      earlydetectionTabSpecialEducationCostPerYear: 9369,
      earlydetectionTabIdeaPartCCostPerYear: 4566.62,
      earlydetectionTotalNetSavings: 4567,
    },
    positiveParenting: {
      positiveparentingTabGeneralPriceInflation: 1.354598357777,
      positiveparentingTabMedicalPriceInflation: 1.10166468370608,
    },
  },
  NM: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.031,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.4901768707483,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 71.20,
      edTabMedicaidPriceInflactionFactor: 1.1842001401844,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 202.65,
      edTabMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 148.18,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.116,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.771,
      birthsTabCostPerPubliclyFundedBirthShortTerm: 13102,
      birthsTabCostPerPubliclyFundedBirthLongTerm: 9908,
      birthsTabGeneralPriceInflationFactor: 1.69372984991662,
      birthsTabMedicalPriceInflationFactor: 1.41357649651423,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.833,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
      immunizationsTabMedicalPriceInflationFactor: 1.69889851485149,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 12.280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.26800495662949,
      ipvTabPercentageOfWomanInSampleWithLowerIpvScore: 13.3333333333333,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.323336321832430000000000,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805.07042253521,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.1551832231624,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.29155513320506,
      smokingTabRelapseRateYear1: 0.1,
      smokingTabRelapseRateYears2to7: 0.04,
    },
    diapers: {
      diapersTabMedicalPriceInflationFactor: 1.1842001401844,
      diapersTabTotalNetSavings: 1274.91756756757,
    },
    foodSecurity: {
      foodTabMedicalPriceInflation: 1.1842001401844,
      foodTabTotalNetSavings: 1834,
    },
    earlyDetection: {
      earlydetectionTabEarlyEducationProgramsGeneralPriceInflation: 1.30797529157742,
      earlydetectionTabSpecialEducationGeneralPriceInflation: 1.46956236556028,
      earlydetectionTabIdeaPartCGeneralPriceInflation: 1.17731472000804,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEILow: 0.148,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEIHigh: 0.222,
      earlydetectionTabSpecialEducationCostPerYear: 9369,
      earlydetectionTabIdeaPartCCostPerYear: 4566.62,
      earlydetectionTotalNetSavings: 4567,
    },
    positiveParenting: {
      positiveparentingTabGeneralPriceInflation: 1.354598357777,
      positiveparentingTabMedicalPriceInflation: 1.10166468370608,
    },
  },
  NY: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.019,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.4901768707483,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 56.18,
      edTabMedicaidPriceInflactionFactor: 1.1842001401844,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 98.8,
      edTabMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 112.37,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.094,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.702,
      birthsTabCostPerPubliclyFundedBirthShortTerm: 15442,
      birthsTabCostPerPubliclyFundedBirthLongTerm: 9839,
      birthsTabGeneralPriceInflationFactor: 1.69372984991662,
      birthsTabMedicalPriceInflationFactor: 1.41357649651423,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.775,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
      immunizationsTabMedicalPriceInflationFactor: 1.69889851485149,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 12.280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.26800495662949,
      ipvTabPercentageOfWomanInSampleWithLowerIpvScore: 13.3333333333333,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 1,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.323336321832430000000000,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805.07042253521,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.1551832231624,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.29155513320506,
      smokingTabRelapseRateYear1: 0.1,
      smokingTabRelapseRateYears2to7: 0.04,
    },
    diapers: {
      diapersTabMedicalPriceInflationFactor: 1.1842001401844,
      diapersTabTotalNetSavings: 1274.91756756757,
    },
    foodSecurity: {
      foodTabMedicalPriceInflation: 1.1842001401844,
      foodTabTotalNetSavings: 1834,
    },
    earlyDetection: {
      earlydetectionTabEarlyEducationProgramsGeneralPriceInflation: 1.30797529157742,
      earlydetectionTabSpecialEducationGeneralPriceInflation: 1.46956236556028,
      earlydetectionTabIdeaPartCGeneralPriceInflation: 1.17731472000804,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEILow: 0.148,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEIHigh: 0.222,
      earlydetectionTabSpecialEducationCostPerYear: 9369,
      earlydetectionTabIdeaPartCCostPerYear: 4566.62,
      earlydetectionTotalNetSavings: 4567,
    },
    positiveParenting: {
      positiveparentingTabGeneralPriceInflation: 1.354598357777,
      positiveparentingTabMedicalPriceInflation: 1.10166468370608,
    },
  },
  NC: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.034,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.4901768707483,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 81.76,
      edTabMedicaidPriceInflactionFactor: 1.1842001401844,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 117.32,
      edTabMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 81.76,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.107,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.748,
      birthsTabCostPerPubliclyFundedBirthShortTerm: 13299,
      birthsTabCostPerPubliclyFundedBirthLongTerm: 7220,
      birthsTabGeneralPriceInflationFactor: 1.69372984991662,
      birthsTabMedicalPriceInflationFactor: 1.41357649651423,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.622,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
      immunizationsTabMedicalPriceInflationFactor: 1.69889851485149,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 12.280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.26800495662949,
      ipvTabPercentageOfWomanInSampleWithLowerIpvScore: 13.3333333333333,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.323336321832430000000000,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805.07042253521,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.1551832231624,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.29155513320506,
      smokingTabRelapseRateYear1: 0.1,
      smokingTabRelapseRateYears2to7: 0.04,
    },
    diapers: {
      diapersTabMedicalPriceInflationFactor: 1.1842001401844,
      diapersTabTotalNetSavings: 1274.91756756757,
    },
    foodSecurity: {
      foodTabMedicalPriceInflation: 1.1842001401844,
      foodTabTotalNetSavings: 1834,
    },
    earlyDetection: {
      earlydetectionTabEarlyEducationProgramsGeneralPriceInflation: 1.30797529157742,
      earlydetectionTabSpecialEducationGeneralPriceInflation: 1.46956236556028,
      earlydetectionTabIdeaPartCGeneralPriceInflation: 1.17731472000804,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEILow: 0.148,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEIHigh: 0.222,
      earlydetectionTabSpecialEducationCostPerYear: 9369,
      earlydetectionTabIdeaPartCCostPerYear: 4566.62,
      earlydetectionTotalNetSavings: 4567,
    },
    positiveParenting: {
      positiveparentingTabGeneralPriceInflation: 1.354598357777,
      positiveparentingTabMedicalPriceInflation: 1.10166468370608,
    },
  },
  ND: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.034,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.4901768707483,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 81.76,
      edTabMedicaidPriceInflactionFactor: 1.1842001401844,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 493.55,
      edTabMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 132.59,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.107,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.368,
      birthsTabCostPerPubliclyFundedBirthShortTerm: 12338,
      birthsTabCostPerPubliclyFundedBirthLongTerm: 6980,
      birthsTabGeneralPriceInflationFactor: 1.69372984991662,
      birthsTabMedicalPriceInflationFactor: 1.41357649651423,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.622,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
      immunizationsTabMedicalPriceInflationFactor: 1.69889851485149,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 12.280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.26800495662949,
      ipvTabPercentageOfWomanInSampleWithLowerIpvScore: 13.3333333333333,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.323336321832430000000000,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805.07042253521,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.1551832231624,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.29155513320506,
      smokingTabRelapseRateYear1: 0.1,
      smokingTabRelapseRateYears2to7: 0.04,
    },
    diapers: {
      diapersTabMedicalPriceInflationFactor: 1.1842001401844,
      diapersTabTotalNetSavings: 1274.91756756757,
    },
    foodSecurity: {
      foodTabMedicalPriceInflation: 1.1842001401844,
      foodTabTotalNetSavings: 1834,
    },
    earlyDetection: {
      earlydetectionTabEarlyEducationProgramsGeneralPriceInflation: 1.30797529157742,
      earlydetectionTabSpecialEducationGeneralPriceInflation: 1.46956236556028,
      earlydetectionTabIdeaPartCGeneralPriceInflation: 1.17731472000804,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEILow: 0.148,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEIHigh: 0.222,
      earlydetectionTabSpecialEducationCostPerYear: 9369,
      earlydetectionTabIdeaPartCCostPerYear: 4566.62,
      earlydetectionTotalNetSavings: 4567,
    },
    positiveParenting: {
      positiveparentingTabGeneralPriceInflation: 1.354598357777,
      positiveparentingTabMedicalPriceInflation: 1.10166468370608,
    },
  },
  OH: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.052,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.4901768707483,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 66.14,
      edTabMedicaidPriceInflactionFactor: 1.1842001401844,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 202.65,
      edTabMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 134.5,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.088,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.687,
      birthsTabCostPerPubliclyFundedBirthShortTerm: 10925,
      birthsTabCostPerPubliclyFundedBirthLongTerm: 7220,
      birthsTabGeneralPriceInflationFactor: 1.69372984991662,
      birthsTabMedicalPriceInflationFactor: 1.41357649651423,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.694,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
      immunizationsTabMedicalPriceInflationFactor: 1.69889851485149,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 12.280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.26800495662949,
      ipvTabPercentageOfWomanInSampleWithLowerIpvScore: 13.3333333333333,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.323336321832430000000000,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805.07042253521,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.1551832231624,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.29155513320506,
      smokingTabRelapseRateYear1: 0.1,
      smokingTabRelapseRateYears2to7: 0.04,
    },
    diapers: {
      diapersTabMedicalPriceInflationFactor: 1.1842001401844,
      diapersTabTotalNetSavings: 1274.91756756757,
    },
    foodSecurity: {
      foodTabMedicalPriceInflation: 1.1842001401844,
      foodTabTotalNetSavings: 1834,
    },
    earlyDetection: {
      earlydetectionTabEarlyEducationProgramsGeneralPriceInflation: 1.30797529157742,
      earlydetectionTabSpecialEducationGeneralPriceInflation: 1.46956236556028,
      earlydetectionTabIdeaPartCGeneralPriceInflation: 1.17731472000804,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEILow: 0.148,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEIHigh: 0.222,
      earlydetectionTabSpecialEducationCostPerYear: 9369,
      earlydetectionTabIdeaPartCCostPerYear: 4566.62,
      earlydetectionTotalNetSavings: 4567,
    },
    positiveParenting: {
      positiveparentingTabGeneralPriceInflation: 1.354598357777,
      positiveparentingTabMedicalPriceInflation: 1.10166468370608,
    },
  },
  OK: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.034,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.4901768707483,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 115.23,
      edTabMedicaidPriceInflactionFactor: 1.1842001401844,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 183.45,
      edTabMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 113.1,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.062,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.807,
      birthsTabCostPerPubliclyFundedBirthShortTerm: 10176,
      birthsTabCostPerPubliclyFundedBirthLongTerm: 6505,
      birthsTabGeneralPriceInflationFactor: 1.69372984991662,
      birthsTabMedicalPriceInflationFactor: 1.41357649651423,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.611,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
      immunizationsTabMedicalPriceInflationFactor: 1.69889851485149,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 12.280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.26800495662949,
      ipvTabPercentageOfWomanInSampleWithLowerIpvScore: 13.3333333333333,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.323336321832430000000000,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805.07042253521,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.1551832231624,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.29155513320506,
      smokingTabRelapseRateYear1: 0.1,
      smokingTabRelapseRateYears2to7: 0.04,
    },
    diapers: {
      diapersTabMedicalPriceInflationFactor: 1.1842001401844,
      diapersTabTotalNetSavings: 1274.91756756757,
    },
    foodSecurity: {
      foodTabMedicalPriceInflation: 1.1842001401844,
      foodTabTotalNetSavings: 1834,
    },
    earlyDetection: {
      earlydetectionTabEarlyEducationProgramsGeneralPriceInflation: 1.30797529157742,
      earlydetectionTabSpecialEducationGeneralPriceInflation: 1.46956236556028,
      earlydetectionTabIdeaPartCGeneralPriceInflation: 1.17731472000804,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEILow: 0.148,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEIHigh: 0.222,
      earlydetectionTabSpecialEducationCostPerYear: 9369,
      earlydetectionTabIdeaPartCCostPerYear: 4566.62,
      earlydetectionTotalNetSavings: 4567,
    },
    positiveParenting: {
      positiveparentingTabGeneralPriceInflation: 1.354598357777,
      positiveparentingTabMedicalPriceInflation: 1.10166468370608,
    },
  },
  OR: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.034,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.4901768707483,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 100.83,
      edTabMedicaidPriceInflactionFactor: 1.1842001401844,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 185,
      edTabMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 108.17,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.149,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.699,
      birthsTabCostPerPubliclyFundedBirthShortTerm: 7314,
      birthsTabCostPerPubliclyFundedBirthLongTerm: 6956,
      birthsTabGeneralPriceInflationFactor: 1.69372984991662,
      birthsTabMedicalPriceInflationFactor: 1.41357649651423,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.631,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
      immunizationsTabMedicalPriceInflationFactor: 1.69889851485149,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 12.280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.26800495662949,
      ipvTabPercentageOfWomanInSampleWithLowerIpvScore: 13.3333333333333,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.323336321832430000000000,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805.07042253521,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.1551832231624,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.29155513320506,
      smokingTabRelapseRateYear1: 0.1,
      smokingTabRelapseRateYears2to7: 0.04,
    },
    diapers: {
      diapersTabMedicalPriceInflationFactor: 1.1842001401844,
      diapersTabTotalNetSavings: 1274.91756756757,
    },
    foodSecurity: {
      foodTabMedicalPriceInflation: 1.1842001401844,
      foodTabTotalNetSavings: 1834,
    },
    earlyDetection: {
      earlydetectionTabEarlyEducationProgramsGeneralPriceInflation: 1.30797529157742,
      earlydetectionTabSpecialEducationGeneralPriceInflation: 1.46956236556028,
      earlydetectionTabIdeaPartCGeneralPriceInflation: 1.17731472000804,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEILow: 0.148,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEIHigh: 0.222,
      earlydetectionTabSpecialEducationCostPerYear: 9369,
      earlydetectionTabIdeaPartCCostPerYear: 4566.62,
      earlydetectionTotalNetSavings: 4567,
    },
    positiveParenting: {
      positiveparentingTabGeneralPriceInflation: 1.354598357777,
      positiveparentingTabMedicalPriceInflation: 1.10166468370608,
    },
  },
  PA: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.033,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.4901768707483,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 54.42,
      edTabMedicaidPriceInflactionFactor: 1.1842001401844,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 202.65,
      edTabMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 54.42,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.082,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.535,
      birthsTabCostPerPubliclyFundedBirthShortTerm: 11015,
      birthsTabCostPerPubliclyFundedBirthLongTerm: 11580,
      birthsTabGeneralPriceInflationFactor: 1.69372984991662,
      birthsTabMedicalPriceInflationFactor: 1.41357649651423,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.75,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
      immunizationsTabMedicalPriceInflationFactor: 1.69889851485149,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 12.280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.26800495662949,
      ipvTabPercentageOfWomanInSampleWithLowerIpvScore: 13.3333333333333,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.323336321832430000000000,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805.07042253521,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.1551832231624,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.29155513320506,
      smokingTabRelapseRateYear1: 0.1,
      smokingTabRelapseRateYears2to7: 0.04,
    },
    diapers: {
      diapersTabMedicalPriceInflationFactor: 1.1842001401844,
      diapersTabTotalNetSavings: 1274.91756756757,
    },
    foodSecurity: {
      foodTabMedicalPriceInflation: 1.1842001401844,
      foodTabTotalNetSavings: 1834,
    },
    earlyDetection: {
      earlydetectionTabEarlyEducationProgramsGeneralPriceInflation: 1.30797529157742,
      earlydetectionTabSpecialEducationGeneralPriceInflation: 1.46956236556028,
      earlydetectionTabIdeaPartCGeneralPriceInflation: 1.17731472000804,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEILow: 0.148,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEIHigh: 0.222,
      earlydetectionTabSpecialEducationCostPerYear: 9369,
      earlydetectionTabIdeaPartCCostPerYear: 4566.62,
      earlydetectionTotalNetSavings: 4567,
    },
    positiveParenting: {
      positiveparentingTabGeneralPriceInflation: 1.354598357777,
      positiveparentingTabMedicalPriceInflation: 1.10166468370608,
    },
  },
  PR: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.133,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.4901768707483,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 110.43,
      edTabMedicaidPriceInflactionFactor: 1.1842001401844,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 202.65,
      edTabMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 102.69,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.072,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.42,
      birthsTabCostPerPubliclyFundedBirthShortTerm: 12770,
      birthsTabCostPerPubliclyFundedBirthLongTerm: 7947,
      birthsTabGeneralPriceInflationFactor: 1.69372984991662,
      birthsTabMedicalPriceInflationFactor: 1.41357649651423,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.31,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
      immunizationsTabMedicalPriceInflationFactor: 1.69889851485149,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 12.280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.26800495662949,
      ipvTabPercentageOfWomanInSampleWithLowerIpvScore: 13.3333333333333,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.323336321832430000000000,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805.07042253521,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.1551832231624,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.29155513320506,
      smokingTabRelapseRateYear1: 0.1,
      smokingTabRelapseRateYears2to7: 0.04,
    },
    diapers: {
      diapersTabMedicalPriceInflationFactor: 1.1842001401844,
      diapersTabTotalNetSavings: 1274.91756756757,
    },
    foodSecurity: {
      foodTabMedicalPriceInflation: 1.1842001401844,
      foodTabTotalNetSavings: 1834,
    },
    earlyDetection: {
      earlydetectionTabEarlyEducationProgramsGeneralPriceInflation: 1.30797529157742,
      earlydetectionTabSpecialEducationGeneralPriceInflation: 1.46956236556028,
      earlydetectionTabIdeaPartCGeneralPriceInflation: 1.17731472000804,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEILow: 0.148,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEIHigh: 0.222,
      earlydetectionTabSpecialEducationCostPerYear: 9369,
      earlydetectionTabIdeaPartCCostPerYear: 4566.62,
      earlydetectionTotalNetSavings: 4567,
    },
    positiveParenting: {
      positiveparentingTabGeneralPriceInflation: 1.354598357777,
      positiveparentingTabMedicalPriceInflation: 1.10166468370608,
    },
  },
  RI: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.133,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.4901768707483,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 110.43,
      edTabMedicaidPriceInflactionFactor: 1.1842001401844,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 202.65,
      edTabMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 128.78,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.072,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.701,
      birthsTabCostPerPubliclyFundedBirthShortTerm: 14955,
      birthsTabCostPerPubliclyFundedBirthLongTerm: 9841,
      birthsTabGeneralPriceInflationFactor: 1.69372984991662,
      birthsTabMedicalPriceInflationFactor: 1.41357649651423,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.31,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
      immunizationsTabMedicalPriceInflationFactor: 1.69889851485149,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 12.280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.26800495662949,
      ipvTabPercentageOfWomanInSampleWithLowerIpvScore: 13.3333333333333,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.323336321832430000000000,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805.07042253521,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.1551832231624,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.29155513320506,
      smokingTabRelapseRateYear1: 0.1,
      smokingTabRelapseRateYears2to7: 0.04,
    },
    diapers: {
      diapersTabMedicalPriceInflationFactor: 1.1842001401844,
      diapersTabTotalNetSavings: 1274.91756756757,
    },
    foodSecurity: {
      foodTabMedicalPriceInflation: 1.1842001401844,
      foodTabTotalNetSavings: 1834,
    },
    earlyDetection: {
      earlydetectionTabEarlyEducationProgramsGeneralPriceInflation: 1.30797529157742,
      earlydetectionTabSpecialEducationGeneralPriceInflation: 1.46956236556028,
      earlydetectionTabIdeaPartCGeneralPriceInflation: 1.17731472000804,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEILow: 0.148,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEIHigh: 0.222,
      earlydetectionTabSpecialEducationCostPerYear: 9369,
      earlydetectionTabIdeaPartCCostPerYear: 4566.62,
      earlydetectionTotalNetSavings: 4567,
    },
    positiveParenting: {
      positiveparentingTabGeneralPriceInflation: 1.354598357777,
      positiveparentingTabMedicalPriceInflation: 1.10166468370608,
    },
  },
  SC: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.034,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.4901768707483,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 81.15,
      edTabMedicaidPriceInflactionFactor: 1.1842001401844,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 202.65,
      edTabMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 127.5,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.14,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.786,
      birthsTabCostPerPubliclyFundedBirthShortTerm: 13930,
      birthsTabCostPerPubliclyFundedBirthLongTerm: 7492,
      birthsTabGeneralPriceInflationFactor: 1.69372984991662,
      birthsTabMedicalPriceInflationFactor: 1.41357649651423,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.601,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
      immunizationsTabMedicalPriceInflationFactor: 1.69889851485149,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 12.280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.26800495662949,
      ipvTabPercentageOfWomanInSampleWithLowerIpvScore: 13.3333333333333,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.323336321832430000000000,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805.07042253521,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.1551832231624,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.29155513320506,
      smokingTabRelapseRateYear1: 0.1,
      smokingTabRelapseRateYears2to7: 0.04,
    },
    diapers: {
      diapersTabMedicalPriceInflationFactor: 1.1842001401844,
      diapersTabTotalNetSavings: 1274.91756756757,
    },
    foodSecurity: {
      foodTabMedicalPriceInflation: 1.1842001401844,
      foodTabTotalNetSavings: 1834,
    },
    earlyDetection: {
      earlydetectionTabEarlyEducationProgramsGeneralPriceInflation: 1.30797529157742,
      earlydetectionTabSpecialEducationGeneralPriceInflation: 1.46956236556028,
      earlydetectionTabIdeaPartCGeneralPriceInflation: 1.17731472000804,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEILow: 0.148,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEIHigh: 0.222,
      earlydetectionTabSpecialEducationCostPerYear: 9369,
      earlydetectionTabIdeaPartCCostPerYear: 4566.62,
      earlydetectionTotalNetSavings: 4567,
    },
    positiveParenting: {
      positiveparentingTabGeneralPriceInflation: 1.354598357777,
      positiveparentingTabMedicalPriceInflation: 1.10166468370608,
    },
  },
  SD: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.034,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.4901768707483,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 81.15,
      edTabMedicaidPriceInflactionFactor: 1.1842001401844,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 202.65,
      edTabMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 112.75,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.14,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.462,
      birthsTabCostPerPubliclyFundedBirthShortTerm: 13830,
      birthsTabCostPerPubliclyFundedBirthLongTerm: 6898,
      birthsTabGeneralPriceInflationFactor: 1.69372984991662,
      birthsTabMedicalPriceInflationFactor: 1.41357649651423,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.601,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
      immunizationsTabMedicalPriceInflationFactor: 1.69889851485149,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 12.280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.26800495662949,
      ipvTabPercentageOfWomanInSampleWithLowerIpvScore: 13.3333333333333,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.323336321832430000000000,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805.07042253521,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.1551832231624,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.29155513320506,
      smokingTabRelapseRateYear1: 0.1,
      smokingTabRelapseRateYears2to7: 0.04,
    },
    diapers: {
      diapersTabMedicalPriceInflationFactor: 1.1842001401844,
      diapersTabTotalNetSavings: 1274.91756756757,
    },
    foodSecurity: {
      foodTabMedicalPriceInflation: 1.1842001401844,
      foodTabTotalNetSavings: 1834,
    },
    earlyDetection: {
      earlydetectionTabEarlyEducationProgramsGeneralPriceInflation: 1.30797529157742,
      earlydetectionTabSpecialEducationGeneralPriceInflation: 1.46956236556028,
      earlydetectionTabIdeaPartCGeneralPriceInflation: 1.17731472000804,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEILow: 0.148,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEIHigh: 0.222,
      earlydetectionTabSpecialEducationCostPerYear: 9369,
      earlydetectionTabIdeaPartCCostPerYear: 4566.62,
      earlydetectionTotalNetSavings: 4567,
    },
    positiveParenting: {
      positiveparentingTabGeneralPriceInflation: 1.354598357777,
      positiveparentingTabMedicalPriceInflation: 1.10166468370608,
    },
  },
  TN: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.054,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.4901768707483,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 52.86,
      edTabMedicaidPriceInflactionFactor: 1.1842001401844,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 202.65,
      edTabMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 79.18,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.054,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.737,
      birthsTabCostPerPubliclyFundedBirthShortTerm: 7657,
      birthsTabCostPerPubliclyFundedBirthLongTerm: 10539,
      birthsTabGeneralPriceInflationFactor: 1.69372984991662,
      birthsTabMedicalPriceInflationFactor: 1.41357649651423,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.649,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
      immunizationsTabMedicalPriceInflationFactor: 1.69889851485149,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 12.280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.26800495662949,
      ipvTabPercentageOfWomanInSampleWithLowerIpvScore: 13.3333333333333,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.323336321832430000000000,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805.07042253521,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.1551832231624,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.29155513320506,
      smokingTabRelapseRateYear1: 0.1,
      smokingTabRelapseRateYears2to7: 0.04,
    },
    diapers: {
      diapersTabMedicalPriceInflationFactor: 1.1842001401844,
      diapersTabTotalNetSavings: 1274.91756756757,
    },
    foodSecurity: {
      foodTabMedicalPriceInflation: 1.1842001401844,
      foodTabTotalNetSavings: 1834,
    },
    earlyDetection: {
      earlydetectionTabEarlyEducationProgramsGeneralPriceInflation: 1.30797529157742,
      earlydetectionTabSpecialEducationGeneralPriceInflation: 1.46956236556028,
      earlydetectionTabIdeaPartCGeneralPriceInflation: 1.17731472000804,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEILow: 0.148,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEIHigh: 0.222,
      earlydetectionTabSpecialEducationCostPerYear: 9369,
      earlydetectionTabIdeaPartCCostPerYear: 4566.62,
      earlydetectionTotalNetSavings: 4567,
    },
    positiveParenting: {
      positiveparentingTabGeneralPriceInflation: 1.354598357777,
      positiveparentingTabMedicalPriceInflation: 1.10166468370608,
    },
  },
  TX: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.054,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.4901768707483,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 52.86,
      edTabMedicaidPriceInflactionFactor: 1.1842001401844,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 251.87,
      edTabMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 56.03,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.054,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.737,
      birthsTabCostPerPubliclyFundedBirthShortTerm: 11574,
      birthsTabCostPerPubliclyFundedBirthLongTerm: 9924,
      birthsTabGeneralPriceInflationFactor: 1.69372984991662,
      birthsTabMedicalPriceInflationFactor: 1.41357649651423,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.649,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
      immunizationsTabMedicalPriceInflationFactor: 1.69889851485149,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 12.280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.26800495662949,
      ipvTabPercentageOfWomanInSampleWithLowerIpvScore: 13.3333333333333,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.323336321832430000000000,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805.07042253521,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.1551832231624,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.29155513320506,
      smokingTabRelapseRateYear1: 0.1,
      smokingTabRelapseRateYears2to7: 0.04,
    },
    diapers: {
      diapersTabMedicalPriceInflationFactor: 1.1842001401844,
      diapersTabTotalNetSavings: 1274.91756756757,
    },
    foodSecurity: {
      foodTabMedicalPriceInflation: 1.1842001401844,
      foodTabTotalNetSavings: 1834,
    },
    earlyDetection: {
      earlydetectionTabEarlyEducationProgramsGeneralPriceInflation: 1.30797529157742,
      earlydetectionTabSpecialEducationGeneralPriceInflation: 1.46956236556028,
      earlydetectionTabIdeaPartCGeneralPriceInflation: 1.17731472000804,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEILow: 0.148,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEIHigh: 0.222,
      earlydetectionTabSpecialEducationCostPerYear: 9369,
      earlydetectionTabIdeaPartCCostPerYear: 4566.62,
      earlydetectionTotalNetSavings: 4567,
    },
    positiveParenting: {
      positiveparentingTabGeneralPriceInflation: 1.354598357777,
      positiveparentingTabMedicalPriceInflation: 1.10166468370608,
    },
  },
  UT: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.054,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.4901768707483,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 52.86,
      edTabMedicaidPriceInflactionFactor: 1.1842001401844,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 202.65,
      edTabMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 62,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.054,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.533,
      birthsTabCostPerPubliclyFundedBirthShortTerm: 12552,
      birthsTabCostPerPubliclyFundedBirthLongTerm: 4673,
      birthsTabGeneralPriceInflationFactor: 1.69372984991662,
      birthsTabMedicalPriceInflationFactor: 1.41357649651423,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.649,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
      immunizationsTabMedicalPriceInflationFactor: 1.69889851485149,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 12.280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.26800495662949,
      ipvTabPercentageOfWomanInSampleWithLowerIpvScore: 13.3333333333333,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.323336321832430000000000,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805.07042253521,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.1551832231624,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.29155513320506,
      smokingTabRelapseRateYear1: 0.1,
      smokingTabRelapseRateYears2to7: 0.04,
    },
    diapers: {
      diapersTabMedicalPriceInflationFactor: 1.1842001401844,
      diapersTabTotalNetSavings: 1274.91756756757,
    },
    foodSecurity: {
      foodTabMedicalPriceInflation: 1.1842001401844,
      foodTabTotalNetSavings: 1834,
    },
    earlyDetection: {
      earlydetectionTabEarlyEducationProgramsGeneralPriceInflation: 1.30797529157742,
      earlydetectionTabSpecialEducationGeneralPriceInflation: 1.46956236556028,
      earlydetectionTabIdeaPartCGeneralPriceInflation: 1.17731472000804,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEILow: 0.148,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEIHigh: 0.222,
      earlydetectionTabSpecialEducationCostPerYear: 9369,
      earlydetectionTabIdeaPartCCostPerYear: 4566.62,
      earlydetectionTotalNetSavings: 4567,
    },
    positiveParenting: {
      positiveparentingTabGeneralPriceInflation: 1.354598357777,
      positiveparentingTabMedicalPriceInflation: 1.10166468370608,
    },
  },
  VT: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.054,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.4901768707483,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 52.86,
      edTabMedicaidPriceInflactionFactor: 1.1842001401844,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 202.65,
      edTabMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 122.43,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.054,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.735,
      birthsTabCostPerPubliclyFundedBirthShortTerm: 10857,
      birthsTabCostPerPubliclyFundedBirthLongTerm: 8225,
      birthsTabGeneralPriceInflationFactor: 1.69372984991662,
      birthsTabMedicalPriceInflationFactor: 1.41357649651423,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.649,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
      immunizationsTabMedicalPriceInflationFactor: 1.69889851485149,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 12.280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.26800495662949,
      ipvTabPercentageOfWomanInSampleWithLowerIpvScore: 13.3333333333333,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.323336321832430000000000,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805.07042253521,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.1551832231624,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.29155513320506,
      smokingTabRelapseRateYear1: 0.1,
      smokingTabRelapseRateYears2to7: 0.04,
    },
    diapers: {
      diapersTabMedicalPriceInflationFactor: 1.1842001401844,
      diapersTabTotalNetSavings: 1274.91756756757,
    },
    foodSecurity: {
      foodTabMedicalPriceInflation: 1.1842001401844,
      foodTabTotalNetSavings: 1834,
    },
    earlyDetection: {
      earlydetectionTabEarlyEducationProgramsGeneralPriceInflation: 1.30797529157742,
      earlydetectionTabSpecialEducationGeneralPriceInflation: 1.46956236556028,
      earlydetectionTabIdeaPartCGeneralPriceInflation: 1.17731472000804,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEILow: 0.148,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEIHigh: 0.222,
      earlydetectionTabSpecialEducationCostPerYear: 9369,
      earlydetectionTabIdeaPartCCostPerYear: 4566.62,
      earlydetectionTotalNetSavings: 4567,
    },
    positiveParenting: {
      positiveparentingTabGeneralPriceInflation: 1.354598357777,
      positiveparentingTabMedicalPriceInflation: 1.10166468370608,
    },
  },
  VA: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.039,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.4901768707483,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 91.35,
      edTabMedicaidPriceInflactionFactor: 1.1842001401844,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 202.65,
      edTabMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 94.29,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.226,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.454,
      birthsTabCostPerPubliclyFundedBirthShortTerm: 16946,
      birthsTabCostPerPubliclyFundedBirthLongTerm: 8163,
      birthsTabGeneralPriceInflationFactor: 1.69372984991662,
      birthsTabMedicalPriceInflationFactor: 1.41357649651423,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.696,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
      immunizationsTabMedicalPriceInflationFactor: 1.69889851485149,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 12.280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.26800495662949,
      ipvTabPercentageOfWomanInSampleWithLowerIpvScore: 13.3333333333333,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.323336321832430000000000,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805.07042253521,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.1551832231624,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.29155513320506,
      smokingTabRelapseRateYear1: 0.1,
      smokingTabRelapseRateYears2to7: 0.04,
    },
    diapers: {
      diapersTabMedicalPriceInflationFactor: 1.1842001401844,
      diapersTabTotalNetSavings: 1274.91756756757,
    },
    foodSecurity: {
      foodTabMedicalPriceInflation: 1.1842001401844,
      foodTabTotalNetSavings: 1834,
    },
    earlyDetection: {
      earlydetectionTabEarlyEducationProgramsGeneralPriceInflation: 1.30797529157742,
      earlydetectionTabSpecialEducationGeneralPriceInflation: 1.46956236556028,
      earlydetectionTabIdeaPartCGeneralPriceInflation: 1.17731472000804,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEILow: 0.148,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEIHigh: 0.222,
      earlydetectionTabSpecialEducationCostPerYear: 9369,
      earlydetectionTabIdeaPartCCostPerYear: 4566.62,
      earlydetectionTotalNetSavings: 4567,
    },
    positiveParenting: {
      positiveparentingTabGeneralPriceInflation: 1.354598357777,
      positiveparentingTabMedicalPriceInflation: 1.10166468370608,
    },
  },
  WA: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.039,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.4901768707483,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 91.35,
      edTabMedicaidPriceInflactionFactor: 1.1842001401844,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 376.74,
      edTabMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 119.09,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.226,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.631,
      birthsTabCostPerPubliclyFundedBirthShortTerm: 15886,
      birthsTabCostPerPubliclyFundedBirthLongTerm: 6929,
      birthsTabGeneralPriceInflationFactor: 1.69372984991662,
      birthsTabMedicalPriceInflationFactor: 1.41357649651423,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.696,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
      immunizationsTabMedicalPriceInflationFactor: 1.69889851485149,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 12.280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.26800495662949,
      ipvTabPercentageOfWomanInSampleWithLowerIpvScore: 13.3333333333333,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.323336321832430000000000,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805.07042253521,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.1551832231624,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.29155513320506,
      smokingTabRelapseRateYear1: 0.1,
      smokingTabRelapseRateYears2to7: 0.04,
    },
    diapers: {
      diapersTabMedicalPriceInflationFactor: 1.1842001401844,
      diapersTabTotalNetSavings: 1274.91756756757,
    },
    foodSecurity: {
      foodTabMedicalPriceInflation: 1.1842001401844,
      foodTabTotalNetSavings: 1834,
    },
    earlyDetection: {
      earlydetectionTabEarlyEducationProgramsGeneralPriceInflation: 1.30797529157742,
      earlydetectionTabSpecialEducationGeneralPriceInflation: 1.46956236556028,
      earlydetectionTabIdeaPartCGeneralPriceInflation: 1.17731472000804,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEILow: 0.148,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEIHigh: 0.222,
      earlydetectionTabSpecialEducationCostPerYear: 9369,
      earlydetectionTabIdeaPartCCostPerYear: 4566.62,
      earlydetectionTotalNetSavings: 4567,
    },
    positiveParenting: {
      positiveparentingTabGeneralPriceInflation: 1.354598357777,
      positiveparentingTabMedicalPriceInflation: 1.10166468370608,
    },
  },
  WV: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.039,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.4901768707483,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 91.35,
      edTabMedicaidPriceInflactionFactor: 1.1842001401844,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 202.65,
      edTabMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 89.93,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.226,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.76,
      birthsTabCostPerPubliclyFundedBirthShortTerm: 13017,
      birthsTabCostPerPubliclyFundedBirthLongTerm: 7169,
      birthsTabGeneralPriceInflationFactor: 1.69372984991662,
      birthsTabMedicalPriceInflationFactor: 1.41357649651423,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.696,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
      immunizationsTabMedicalPriceInflationFactor: 1.69889851485149,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 12.280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.26800495662949,
      ipvTabPercentageOfWomanInSampleWithLowerIpvScore: 13.3333333333333,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.323336321832430000000000,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805.07042253521,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.1551832231624,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.29155513320506,
      smokingTabRelapseRateYear1: 0.1,
      smokingTabRelapseRateYears2to7: 0.04,
    },
    diapers: {
      diapersTabMedicalPriceInflationFactor: 1.1842001401844,
      diapersTabTotalNetSavings: 1274.91756756757,
    },
    foodSecurity: {
      foodTabMedicalPriceInflation: 1.1842001401844,
      foodTabTotalNetSavings: 1834,
    },
    earlyDetection: {
      earlydetectionTabEarlyEducationProgramsGeneralPriceInflation: 1.30797529157742,
      earlydetectionTabSpecialEducationGeneralPriceInflation: 1.46956236556028,
      earlydetectionTabIdeaPartCGeneralPriceInflation: 1.17731472000804,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEILow: 0.148,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEIHigh: 0.222,
      earlydetectionTabSpecialEducationCostPerYear: 9369,
      earlydetectionTabIdeaPartCCostPerYear: 4566.62,
      earlydetectionTotalNetSavings: 4567,
    },
    positiveParenting: {
      positiveparentingTabGeneralPriceInflation: 1.354598357777,
      positiveparentingTabMedicalPriceInflation: 1.10166468370608,
    },
  },
  WI: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.039,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.4901768707483,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 91.35,
      edTabMedicaidPriceInflactionFactor: 1.1842001401844,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 202.65,
      edTabMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 85.58,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.226,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.62,
      birthsTabCostPerPubliclyFundedBirthShortTerm: 12667,
      birthsTabCostPerPubliclyFundedBirthLongTerm: 5643,
      birthsTabGeneralPriceInflationFactor: 1.69372984991662,
      birthsTabMedicalPriceInflationFactor: 1.41357649651423,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.696,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
      immunizationsTabMedicalPriceInflationFactor: 1.69889851485149,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 12.280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.26800495662949,
      ipvTabPercentageOfWomanInSampleWithLowerIpvScore: 13.3333333333333,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.323336321832430000000000,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805.07042253521,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.1551832231624,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.29155513320506,
      smokingTabRelapseRateYear1: 0.1,
      smokingTabRelapseRateYears2to7: 0.04,
    },
    diapers: {
      diapersTabMedicalPriceInflationFactor: 1.1842001401844,
      diapersTabTotalNetSavings: 1274.91756756757,
    },
    foodSecurity: {
      foodTabMedicalPriceInflation: 1.1842001401844,
      foodTabTotalNetSavings: 1834,
    },
    earlyDetection: {
      earlydetectionTabEarlyEducationProgramsGeneralPriceInflation: 1.30797529157742,
      earlydetectionTabSpecialEducationGeneralPriceInflation: 1.46956236556028,
      earlydetectionTabIdeaPartCGeneralPriceInflation: 1.17731472000804,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEILow: 0.148,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEIHigh: 0.222,
      earlydetectionTabSpecialEducationCostPerYear: 9369,
      earlydetectionTabIdeaPartCCostPerYear: 4566.62,
      earlydetectionTotalNetSavings: 4567,
    },
    positiveParenting: {
      positiveparentingTabGeneralPriceInflation: 1.354598357777,
      positiveparentingTabMedicalPriceInflation: 1.10166468370608,
    },
  },
  WY: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.039,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.4901768707483,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 91.35,
      edTabMedicaidPriceInflactionFactor: 1.1842001401844,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 202.65,
      edTabMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 91.28,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.226,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.674,
      birthsTabCostPerPubliclyFundedBirthShortTerm: 21036,
      birthsTabCostPerPubliclyFundedBirthLongTerm: 7460,
      birthsTabGeneralPriceInflationFactor: 1.69372984991662,
      birthsTabMedicalPriceInflationFactor: 1.41357649651423,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.696,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
      immunizationsTabMedicalPriceInflationFactor: 1.69889851485149,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 12.280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.26800495662949,
      ipvTabPercentageOfWomanInSampleWithLowerIpvScore: 13.3333333333333,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.323336321832430000000000,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805.07042253521,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.1551832231624,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.29155513320506,
      smokingTabRelapseRateYear1: 0.1,
      smokingTabRelapseRateYears2to7: 0.04,
    },
    diapers: {
      diapersTabMedicalPriceInflationFactor: 1.1842001401844,
      diapersTabTotalNetSavings: 1274.91756756757,
    },
    foodSecurity: {
      foodTabMedicalPriceInflation: 1.1842001401844,
      foodTabTotalNetSavings: 1834,
    },
    earlyDetection: {
      earlydetectionTabEarlyEducationProgramsGeneralPriceInflation: 1.30797529157742,
      earlydetectionTabSpecialEducationGeneralPriceInflation: 1.46956236556028,
      earlydetectionTabIdeaPartCGeneralPriceInflation: 1.17731472000804,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEILow: 0.148,
      earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEIHigh: 0.222,
      earlydetectionTabSpecialEducationCostPerYear: 9369,
      earlydetectionTabIdeaPartCCostPerYear: 4566.62,
      earlydetectionTotalNetSavings: 4567,
    },
    positiveParenting: {
      positiveparentingTabGeneralPriceInflation: 1.354598357777,
      positiveparentingTabMedicalPriceInflation: 1.10166468370608,
    },
  },
};
