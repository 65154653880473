/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
import React, { FunctionComponent, useRef } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
// import { Grid, Typography } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import Report from './Report/Report';
import {
  selectReportName,
  selectIsReportSubmitted,
  selectSummaryReport,
  selectIsReportFinished,
  selectLastDateUpdated,
  selectInterventionsChecklist,
} from '../../../redux/calculatorSlice';
import Footer from '../Footer/Footer';
import styles from './CalculatorReport.styles';
import { ISummaryReport } from '../../../interfaces/calculator.interface';
import { StringFormatter, DateUtils } from '../../../utils';
// eslint-disable-next-line import/named
import paths  from '../../paths';

const CalculatorReport: FunctionComponent<WithStylesType<typeof styles>> = ({
  classes,
}) => {
  // const testid = 'report';
  const componentRef = useRef(null);
  const report = useSelector(selectSummaryReport);
  const isSubmitted = useSelector(selectIsReportSubmitted);
  const isFinished = useSelector(selectIsReportFinished);
  const lastDateUpdated = useSelector(selectLastDateUpdated);
  const interventionsChecklist = useSelector(selectInterventionsChecklist);

  const reportName = useSelector(selectReportName);

  const onSubmit = (values: ISummaryReport) => {
    // eslint-disable-next-line no-restricted-globals
    StringFormatter.replaceWithReportName(
      reportName,
      paths.dashboard,
    );
  };

  const form = useFormik({
    initialValues: report,
    onSubmit,
    validateOnBlur: false,
    validateOnChange: false,
  });

  const getReportDate = () => 
    lastDateUpdated ?
    (isFinished ? 'Completed on ' : 'Last Edited on ') + DateUtils.formatDate(new Date(lastDateUpdated), 'MM/DD/YYYY | hh:mm a')
    : '';

  return (
    <div>
      <div className={classes.container}>
        <Grid container spacing={4}>
          <Grid item xs={12} className={classes.completedDate}>{getReportDate()}</Grid>
          <Grid item xs={12}>
            <Report
              titleText={reportName}
              report={report}
              interventionsChecklist={interventionsChecklist}
              printClass={classes.pdfTitle}
              ref={componentRef}
              isFinished={isFinished}
              lastDateUpdated={lastDateUpdated}
            />
          </Grid>
        </Grid>
      </div>
      {!isSubmitted && <Footer currentForm={form} />}
    </div>
  );
};
export default withStyles(styles)(CalculatorReport);
