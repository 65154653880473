import ServiceData from './serviceData';
import Calculator from './shared/base';

export default class PositiveParenting extends Calculator {
  public serviceData: ServiceData;

  sliderPositiveParentingLowEstimate: number;

  sliderPositiveParentingHighEstimate: number;

  numberOfFamiliesReferredToPostNatalHomeVisitingPrograms: number;

  numberOfGuardiansProvidedPositiveParentingOrReferredToParentingProgram: number;

  constructor({
    state,
    serviceData,
    sliderPositiveParentingLowEstimate,
    sliderPositiveParentingHighEstimate,
    numberOfFamiliesReferredToPostNatalHomeVisitingPrograms,
    numberOfGuardiansProvidedPositiveParentingOrReferredToParentingProgram,
  }: {
    state: string;
    serviceData: ServiceData;
    sliderPositiveParentingLowEstimate: number;
    sliderPositiveParentingHighEstimate: number;
    numberOfFamiliesReferredToPostNatalHomeVisitingPrograms: number;
    numberOfGuardiansProvidedPositiveParentingOrReferredToParentingProgram: number;
  }) {
    super({ state });
    this.serviceData = serviceData;
    this.numberOfFamiliesReferredToPostNatalHomeVisitingPrograms = numberOfFamiliesReferredToPostNatalHomeVisitingPrograms;
    this.sliderPositiveParentingLowEstimate = sliderPositiveParentingLowEstimate / 100;
    this.sliderPositiveParentingHighEstimate = sliderPositiveParentingHighEstimate / 100;
    this.numberOfGuardiansProvidedPositiveParentingOrReferredToParentingProgram = numberOfGuardiansProvidedPositiveParentingOrReferredToParentingProgram;
  }

  /**
   * @name positive_parenting_tab_general_price_inflation
   * @excel Constant
   * @constant 1.354598357777
   */
  public readonly positiveparentingTabGeneralPriceInflation = this
    .constants.positiveParenting
    .positiveparentingTabGeneralPriceInflation;

  /**
   * @name positive_parenting_tab_medical_price_inflation
   * @excel Constant
   * @constant 1.10166468370608
   */
  public readonly positiveparentingTabMedicalPriceInflation = this
    .constants.positiveParenting
    .positiveparentingTabMedicalPriceInflation;

  /**
   * @name positive_parenting_tab_supports_and_services_crime
   * @excel Constant
   * @constant 42
   */
  public readonly positiveparentingTabSupportsAndServicesCrime = 42;

  /**
   * @name positive_parenting_tab_supports_and_services_abuse_neglect
   * @excel Constant
   * @constant 240
   */
  public readonly positiveparentingTabSupportsAndServicesAbuseNeglect = 240;

  /**
   * @name positive_parenting_tab_supports_and_services_education
   * @excel Constant
   * @constant 9
   */
  public readonly positiveparentingTabSupportsAndServicesEducation = 9;

  /**
   * @name positive_parenting_tab_supports_and_services_healthcare
   * @excel Constant
   * @constant 12
   */
  public readonly positiveparentingTabSupportsAndServicesHealthcare = 12;

  /**
   * @name positive_parenting_tab_supports_and_services_cost_per_child
   * @excel Constant
   * @constant 143
   */
  public readonly positiveparentingTabSupportsAndServicesCostPerChild = 143;
  
  /**
   * @name positive_parenting_tab_estimated_number_families_receieving_home_visiting_services_programs_low
   * @excel New Calculation
   * @formula positiveparentingTabNumberFamiliesReferredToPostNatalHomeVisitingPrograms*positiveparentingTabEstimatedPercentageReceievingHomeVisitingServicesProgramsLow
   */
  public positiveparentingTabEstimatedNumberFamiliesReceievingHomeVisitingServicesProgramsLow = () => {
    return this.isFiniteNumber(this.numberOfFamiliesReferredToPostNatalHomeVisitingPrograms * 
      this.sliderPositiveParentingLowEstimate
    );
  };

  /**
   * @name positive_parenting_tab_estimated_number_families_receieving_home_visiting_services_programs_high
   * @excel New Calculation
   * @formula positiveparentingTabNumberFamiliesReferredToPostNatalHomeVisitingPrograms*positiveparentingTabEstimatedPercentageReceievingHomeVisitingServicesProgramsHigh
   */
  public positiveparentingTabEstimatedNumberFamiliesReceievingHomeVisitingServicesProgramsHigh = () => {
    return this.isFiniteNumber(this.numberOfFamiliesReferredToPostNatalHomeVisitingPrograms * 
        this.sliderPositiveParentingHighEstimate
    );
  };

  /**
   * @name positive_parenting_tab_estimated_number_families_receieving_home_visiting_services_programs_high
   * @excel New Calculation
   * @formula positiveparentingTabNumberFamiliesReferredToPostNatalHomeVisitingPrograms*positiveparentingTabEstimatedPercentageReceievingHomeVisitingServicesProgramsHigh
   */
  public positiveparentingTabEstimatedSavingsForReferralsToHomeVisitingPrograms = () => {
    const numerator = 1517 * this.positiveparentingTabMedicalPriceInflation;
    const denominator = Math.pow(1 + 0.03, 1); 
    return this.isFiniteNumber(numerator / denominator);
  };
    
  /**
   * @name positive_parenting_tab_estimated_savings_families_recieving_home_visiting_services_programs_low
   * @excel New Calculation
   * @formula positiveparentingTabEstimatedSavingsForReferralsToHomeVisitingPrograms*positiveparentingTabEstimatedNumberFamiliesReceievingHomeVisitingServicesProgramsLow
   */
  public positiveparentingTabEstimatedSavingsFamiliesRecievingHomeVisitingServicesProgramsLow = () => {
    return this.isFiniteNumber(this.positiveparentingTabEstimatedSavingsForReferralsToHomeVisitingPrograms() *
     this.positiveparentingTabEstimatedNumberFamiliesReceievingHomeVisitingServicesProgramsLow()
    );
  };

  /**
   * @name positive_parenting_tab_estimated_savings_families_recieving_home_visiting_services_programs_high
   * @excel New Calculation
   * @formula positiveparentingTabEstimatedSavingsForReferralsToHomeVisitingPrograms*positiveparentingTabEstimatedNumberFamiliesReceievingHomeVisitingServicesProgramsHigh
   */
  public positiveparentingTabEstimatedSavingsFamiliesRecievingHomeVisitingServicesProgramsHigh = () => {
    return this.isFiniteNumber(this.positiveparentingTabEstimatedSavingsForReferralsToHomeVisitingPrograms() *
       this.positiveparentingTabEstimatedNumberFamiliesReceievingHomeVisitingServicesProgramsHigh()
    );
  };

  /**
   * @name positive_parenting_tab_estimated_savings_families_recieving_home_visiting_services_programs_high
   * @excel New Calculation
   * @formula (positiveparentingTabSupportsAndServicesCrime+positiveparentingTabSupportsAndServicesAbuseNeglect+positiveparentingTabSupportsAndServicesEducation+positiveparentingTabSupportsAndServicesHealthcare)-positiveparentingTabSupportsAndServicesCostPerChild
   */
  public positiveparentingTabSupportsAndServicesNetSavingsPerChild = () => {
    return this.isFiniteNumber((this.positiveparentingTabSupportsAndServicesCrime + this.positiveparentingTabSupportsAndServicesAbuseNeglect
      + this.positiveparentingTabSupportsAndServicesEducation + this.positiveparentingTabSupportsAndServicesHealthcare)-
      this.positiveparentingTabSupportsAndServicesCostPerChild
    );
  };

  /**
   * @name positive_parenting_tab_supports_and_services_total_cost_savings_per_child
   * @excel New Calculation
   * @formula positiveparentingTabSupportsAndServicesNetSavingsPerChild*positiveparentingTabGeneralPriceInflation
   */
  public positiveparentingTabSupportsAndServicesTotalCostSavingsPerChild = () => {
    return this.isFiniteNumber(this.positiveparentingTabSupportsAndServicesNetSavingsPerChild() *
      this.positiveparentingTabGeneralPriceInflation
    );
  };

  /**
   * @name positiveparentingTabCalculatedSavingsForPositiveParentingSupportsAndServices
   * @excel New Calculation
   * @formula positiveparentingTabSupportsAndServicesTotalCostSavingsPerChild*positiveparentingTabNumberGuardiansProvidedPositiveParentingOrReferredToParentingProgram
   */
  public positiveparentingTabCalculatedSavingsForPositiveParentingSupportsAndServices = () => {
    return this.isFiniteNumber(this.positiveparentingTabSupportsAndServicesTotalCostSavingsPerChild() * 
    this.numberOfGuardiansProvidedPositiveParentingOrReferredToParentingProgram
    );
  };

  /**
   * @name positive_parenting_estimated_savings_low
   * @excel New Calculations
   * @formula positiveparentingTabCalculatedSavingsForPositiveParentingSupportsAndServices+positiveparentingTabEstimatedSavingsFamiliesRecievingHomeVisitingServicesProgramsLow
   */
  public positiveParentingEstimatedSavingsLow = () => {
    return this.isFiniteNumber(this.positiveparentingTabCalculatedSavingsForPositiveParentingSupportsAndServices() +
    this.positiveparentingTabEstimatedSavingsFamiliesRecievingHomeVisitingServicesProgramsLow());
  };
  
  /**
     * @name positive_parenting_estimated_savings_high
     * @excel New Calculations
     * @formula positiveparentingTabCalculatedSavingsForPositiveParentingSupportsAndServices+positiveparentingTabEstimatedSavingsFamiliesRecievingHomeVisitingServicesProgramsHigh
     */
  public positiveParentingEstimatedSavingsHigh = () => {
    return this.isFiniteNumber(this.positiveparentingTabCalculatedSavingsForPositiveParentingSupportsAndServices() + 
      this.positiveparentingTabEstimatedSavingsFamiliesRecievingHomeVisitingServicesProgramsHigh());
  };

  /**
     * @name positive_parenting_estimated_savings_high
     * @excel New Calculations
     * @formula positiveparentingTabCalculatedSavingsForPositiveParentingSupportsAndServices+positiveparentingTabEstimatedSavingsFamiliesRecievingHomeVisitingServicesProgramsHigh
     */
  public positiveParentingEstimatedSavingsAverage = () => {
    return this.isFiniteNumber((this.positiveParentingEstimatedSavingsLow() + 
      this.positiveParentingEstimatedSavingsHigh())/2);
  };

  /**
     * @name positive_parenting_estimated_savings_high
     * @excel New Calculations
     * @formula positiveparentingTabCalculatedSavingsForPositiveParentingSupportsAndServices+positiveparentingTabEstimatedSavingsFamiliesRecievingHomeVisitingServicesProgramsHigh
     */
  public positiveParentingLongTermMedicaidSavings = () => {
    return this.isFiniteNumber((this.positiveParentingEstimatedSavingsHigh() * 
    ((12 + this.positiveparentingTabEstimatedSavingsForReferralsToHomeVisitingPrograms()) / (303 + this.positiveparentingTabEstimatedSavingsForReferralsToHomeVisitingPrograms()))
   ))
  };

  public positiveparentingTabEstimatedSavingsFamiliesRecievingHomeVisitingServicesProgramsAverage = () => {
    return this.isFiniteNumber((this.positiveparentingTabEstimatedSavingsFamiliesRecievingHomeVisitingServicesProgramsLow() + this.positiveparentingTabEstimatedSavingsFamiliesRecievingHomeVisitingServicesProgramsHigh()))/2;
  };

  public positiveParentingEducationService = () => {
    return this.isFiniteNumber( this.positiveparentingTabCalculatedSavingsForPositiveParentingSupportsAndServices() * (9/303));
  }

  public positiveParentingAbuseFosterCare = () => {
    return this.isFiniteNumber( this.positiveparentingTabCalculatedSavingsForPositiveParentingSupportsAndServices() * (240/303));
  }

  public positiveParentingCrime = () => {
    return this.isFiniteNumber( this.positiveparentingTabCalculatedSavingsForPositiveParentingSupportsAndServices() * (42/303));
  }

  public positiveParentingHealthService = () => {
    return this.isFiniteNumber( this.positiveparentingTabCalculatedSavingsForPositiveParentingSupportsAndServices() * (12/303));
  }

  public positiveParentingTotalHealthService = () => {
    return this.isFiniteNumber( this.positiveParentingHealthService() + this.positiveparentingTabEstimatedSavingsFamiliesRecievingHomeVisitingServicesProgramsAverage());
  }

  public positiveParentingTotalServices = () => {
    return this.isFiniteNumber(this.positiveParentingTotalHealthService() + this.positiveParentingEducationService()
    + this.positiveParentingAbuseFosterCare() + this.positiveParentingCrime());
  }
}