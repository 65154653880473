/* eslint-disable react/no-this-in-sfc */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import {
  selectInterventionsChecklistValue,
  calculatorActions,
  selectServiceDataForm,
  selectInterventionsValue,
  selectEmergencyDepartment,
  selectReportName,
  selectState,
  selectTouched,
} from '../../../../redux/calculatorSlice';
import {
  InterventionsChecklistForm,
  BreastfeedingForm,
  IBreastfeeding,
  InterventionsCommonForm,
} from '../../../../interfaces/interventions.interface';
import { CalculatorEnum } from '../../../../interfaces/calculator.interface';
import {
  InterventionHeader,
  InterventionBody,
  InterventionFooter,
  InterventionContainer,
  InterventionFieldNoteDialog,
} from '../Intervention';
import { FormValidations, StringFormatter, Constants } from '../../../../utils';
import { Breastfeeding as BreastfeedingClass } from '../../../../calculations';
import useDebounceEffect from '../../../../hooks/useDebounceEffect';
import { paths } from '../../..';

const { DIALOG_MODES } = Constants;

const Breastfeeding: FunctionComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const switchValue = useSelector(
    selectInterventionsChecklistValue(InterventionsChecklistForm.Breastfeeding)
  );
  const breastfeedingData = useSelector(
    selectInterventionsValue(CalculatorEnum.Breastfeeding)
  );

  const serviceData = useSelector(selectServiceDataForm);
  const selectedState = useSelector(selectState);

  const emergencyDepartment = useSelector(selectEmergencyDepartment);

  const reportName = useSelector(selectReportName);
  const touched = useSelector(selectTouched);

  const [reinitialize, setReinitialize] = useState(true);
  const [noteName, setNoteName] = useState<string>('');
  const [noteAction, setNoteAction] = useState<'add' | 'edit' | 'delete'>(DIALOG_MODES.ADD);
  const [isNoteDialogOpen, setIsNoteDialogOpen] = useState(false);

  const fields = [
    {
      text: 'Number of caregivers who discussed a need for breastfeeding advice/assistance with a HS Specialist',
      id: BreastfeedingForm.DiscussedNeedForBreastfeeding,
      showError: true,
    },
    {
      text: 'Number of caregivers (of Medicaid/CHIP-enrolled children) received counseling or related services from a HS Specialist and/or were referred for such services',
      id: BreastfeedingForm.ReceivedCounseling,
      showError: true,
    },
  ];

  const onSubmit = (values: IBreastfeeding) => {
    if (touched) {
      dispatch(
        calculatorActions.saveReport({
          reportName,
          update: true,
        })
      );
    }

    history.push(
      StringFormatter.replaceWithReportName(
        reportName,
        paths.interventionsSmokingCessation
      )
    );
  };

  useEffect(() => {
    if (switchValue) {
      const timeout = setTimeout(() => {
        setReinitialize(false);
      }, 250);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [switchValue]);

  const form = useFormik({
    initialValues: breastfeedingData,
    onSubmit,
    validationSchema:
      switchValue &&
      FormValidations.intervention({
        [BreastfeedingForm.DiscussedNeedForBreastfeeding]: FormValidations.required(
          ''
        )
        .test(FormValidations.getInterventionNumberOfChildsConfig(BreastfeedingForm.DiscussedNeedForBreastfeeding, serviceData.csInput3)),
        [BreastfeedingForm.ReceivedCounseling]: Yup.string()
          .test(
            'receivedCounselingValidation',
            'Must be lower than or equal to the total number of mothers who discussed a need for breastfeeding advice/assistance with HS Specialist in the past year',
            function test(value) {
              const validValue = !value ? 0 : value;
              if (+validValue > +this.parent[BreastfeedingForm.DiscussedNeedForBreastfeeding]) {
                return false;
              }
              return true;
            }
          )
          .test(FormValidations.getInterventionNumberOfChildsConfig(BreastfeedingForm.ReceivedCounseling, serviceData.csInput3)),
        [BreastfeedingForm.ReferredForServices]: Yup.string()
          .test(
            'refeerredForServicesValidation',
            'Must be lower than or equal to the total number of mothers who discussed a need for breastfeeding advice/assistance with HS Specialist in the past year',
            function test(value) {
              const validValue = !value ? 0 : value;
              if (+validValue > +this.parent[BreastfeedingForm.DiscussedNeedForBreastfeeding]) {
                return false;
              }

              return true;
            }
          )
          .test(FormValidations.getInterventionNumberOfChildsConfig(BreastfeedingForm.ReferredForServices, serviceData.csInput3)),
        [InterventionsCommonForm.LowEstimateExclusive]: FormValidations.maxPercentageRequired(
          ''
        ),
        [InterventionsCommonForm.HighEstimateExclusive]: FormValidations.maxPercentageRequired(
          ''
        ).test(
          'The high estimate value must be bigger than the low estimate',
          'The high estimate value must be bigger than the low estimate',
          function test(this: any, value: any) {
            if (!value) return false;

            if (
              +value >
              +(this.parent[InterventionsCommonForm.LowEstimateExclusive] || '')
            ) {
              return true;
            }

            return false;
          }
        ),
      }),
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: reinitialize || !switchValue,
  });

  useEffect(() => {
    if (!switchValue) {
      form.resetForm();
      dispatch(
        calculatorActions.clearInterventionData(CalculatorEnum.Breastfeeding)
      );
    }
  }, [switchValue]);

  useDebounceEffect(() => {
    const hasBeenEdited = Object.keys(form.values).some(
      (key) => !!form.values[key]
    );
    if (!form.dirty && !hasBeenEdited) return;
    /** Calculations happen */
    const breastfeeding = new BreastfeedingClass({
      state: selectedState,
      serviceData,
      emergencyDepartment,
      sliderComponentContinuedBreastfeedingLow: +form.values[
        InterventionsCommonForm.LowEstimate
      ],
      sliderComponentContinuedBreastfeedingHigh: +form.values[
        InterventionsCommonForm.HighEstimate
      ],
      sliderComponentContinuedExclusiveBreastfeedingLow: +form.values[
        InterventionsCommonForm.LowEstimateExclusive
      ],
      sliderComponentContinuedExclusiveBreastfeedingHigh: +form.values[
        InterventionsCommonForm.HighEstimateExclusive
      ],
      input1TotalNumberOfHsMothersWhoDiscussedANeed: +form.values[
        BreastfeedingForm.DiscussedNeedForBreastfeeding
      ],
      input2TotalNumberOfHsMothersWhoReceivedCounselling: +form.values[
        BreastfeedingForm.ReceivedCounseling
      ]
    });

    const savingsLow = breastfeeding.breastfeedingEstimatedSavingsLow();
    const savingsHigh = breastfeeding.breastfeedingEstimatedSavingsHigh();

    if (
      +form.values[InterventionsCommonForm.SavingsLow] !== savingsLow ||
      +form.values[InterventionsCommonForm.SavingsHigh] !== savingsHigh
    ) {
      form.setValues({
        ...form.values,
        [InterventionsCommonForm.SavingsLow]: savingsLow.toString(),
        [InterventionsCommonForm.SavingsHigh]: savingsHigh.toString(),
      });
    }

    form.validateForm().then((errors) => {
      const isValid = !Object.keys(errors).length;

      if (form.dirty) {
        dispatch(
          calculatorActions.setInterventionData({
            intervention: CalculatorEnum.Breastfeeding,
            value: { ...form.values, isValid: !!isValid },
          })
        );
        dispatch(calculatorActions.setSummaryData());
      }
    });
  }, [form.values]);

  const onSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      calculatorActions.toggleInterventionsChecklistValue({
        key: InterventionsChecklistForm.Breastfeeding,
        value: e.target.checked,
      })
    );
  };

  const onNote = (noteName: string, noteAction: 'add' | 'edit' | 'delete') => {
    setNoteName(noteName);
    setNoteAction(noteAction);
    setIsNoteDialogOpen(true);
  };

  const onDialogNoteClose = () => {
    setIsNoteDialogOpen(false);
  };

  return (
    <>
      <InterventionContainer
        disabled={!switchValue}
        onSubmit={form.handleSubmit}
        header={
          <InterventionHeader
            title="Breastfeeding"
            checked={switchValue}
            onChange={onSwitchChange}
          />
        }
        body={
          <InterventionBody
            description={
              <>
                Some HS Specialists talk with caregivers about breastfeeding.
                The following questions ask about the actions taken by caregivers
                in these situations during the 12-month date range.
              </>
            }
            form={form}
            fields={fields}
            onNote={onNote}
          />
        }
        footer={
          <InterventionFooter
            form={form}
            savingsText="Breastfeeding"
            description="Percentage of caregivers who continued breastfeeding due to HealthySteps"
            exclusiveDescription="Percentage of caregivers who continued <u>exclusive</u> breastfeeding due to HealthySteps"
            onNote={onNote}
            hideLongTerm={true}
          />
        }
      />
      <InterventionFieldNoteDialog
        id={`${noteName}__${noteAction}-dialog`}
        name={noteName}
        value={form.values[noteName]}
        mode={noteAction}
        open={isNoteDialogOpen}
        onClose={onDialogNoteClose}
        form={form} />
    </>
  );
};

export default Breastfeeding;
