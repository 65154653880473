/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FunctionComponent } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { FormikProps } from 'formik';
import { StringFormatter } from '../../../../utils';
import styles from '../Intervention/InterventionFooter/InterventionFooter.styles';

interface IProps extends WithStylesType<typeof styles> {
  form: FormikProps<any>;
}

const ImmunizationInterventionCalculation: FunctionComponent<IProps> = ({
  classes,
  form,
}) => {
  return (
    <div>
      <Typography className={classes.calculationsTitle}>
        Calculations
      </Typography>
      {/* Short Term Calculations */}
      <Typography className={classes.calculationsSubTitle}>
        Short-term Estimated Savings
      </Typography>
      <Typography className={classes.savings}>
        {/* <span>{`${savingsText} Low`}</span> */}
        <span>Low</span>
        <span>{StringFormatter.money(form.values.savings_low)}</span>
      </Typography>
      <Typography className={classes.savings}>
        {/* <span>{`${savingsText} High`}</span> */}
        <span>High</span>
        <span>{StringFormatter.money(form.values.savings_high)}</span>
      </Typography>
      {/* Long Term Calculations */}
      <Typography className={classes.calculationsSubTitle}>
        Long-term Estimated Savings
      </Typography>
      <Typography className={classes.savings}>
        {/* <span>{`${savingsText} Low`}</span> */}
        <span>Low</span>
        <span>
          {StringFormatter.money(form.values.long_term_savings_low)}
        </span>
      </Typography>
      <Typography className={classes.savings}>
        {/* <span>{`${savingsText} High`}</span> */}
        <span>High</span>
        <span>
          {StringFormatter.money(form.values.long_term_savings_high)}
        </span>
      </Typography>
    </div>
  );
};

export default withStyles(styles)(ImmunizationInterventionCalculation);
