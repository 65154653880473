
import { IBreakdownReport, breakdownReportState } from '../../../../interfaces/calculator.interface';
import { IInterventionsChecklist } from '../../../../interfaces/interventions.interface';
import { StringFormatter, Constants } from '../../../../utils';

export const getReportBreakdown = (
  report: IBreakdownReport = breakdownReportState,
  interventionsChecklist: IInterventionsChecklist
) => {

  const formatMoney = (value: string) =>
    (value && value !== Constants.STRING_TYPES.EMPTY) ?
      StringFormatter.money(value) : Constants.STRING_TYPES.EMPTY;

  return [
    {
      title: 'HealthySteps Overview Data',
      subCategories: [
        {
          title: 'General Information',
          visible: true,
          lines: [
            {
              description: 'HealthySteps Site/System Name',
              values: [report.siteName],
              displayDefault: false,
            },
            {
              description: 'Select Site State Location',
              values: [report.siteLocation],
              displayDefault: false,
            },
            {
              description: 'Enter Date Range',
              values: [`${report.dateFrom} - ${report.dateTo}`],
              displayDefault: false,
            },
            {
              description: 'Select if data for ROI calculations will apply to:',
              values: [report.calculationsApplyTo],
              displayDefault: false,
            },
            {
              description: 'Number of sites ROI calculation will apply to:',
              values: [report.numberOfSitesCalculationsApplyTo],
              displayDefault: false,
            }
          ]
        },
        {
          title: 'HealthySteps Service Delivery Costs',
          visible: true,
          lines: [
            {
              description: 'Total of HS Specialists delivering services',
              values: [report.numberOfSpecialistsDeliveringServices],
              displayDefault: false,
              note: report.numberOfSpecialistsDeliveringServicesNote,
            },
            {
              description: 'HS Specialist(s) salary and benefits (should include fringe)',
              values: [formatMoney(report.specialistSalaryCost)],
              displayDefault: false,
              note: report.specialistSalaryCostNote,
            },
            {
              description: 'Other direct costs including professional services, printing, publications, supplies, communication, travel, and meetings. Do not include the costs of the initial HealthySteps Institute or professional development stipends',
              values: [formatMoney(report.directCost)],
              displayDefault: false,
              note: report.directCostNote
            },
            {
              description: 'Other (for example: food for parent groups, mileage, annual cell phone cost, etc.)',
              values: [formatMoney(report.otherCost)],
              displayDefault: false,
              note: report.otherCostNote,
            },
            {
              description: 'Intermediary costs (if applicable)',
              values: [formatMoney(report.intermediaryCost)],
              displayDefault: false,
              note: report.intermediaryCostNote,
            },
            {
              description: 'Total site service delivery cost during the 12-month date range',
              values: [formatMoney(report.siteServiceCost)],
              displayDefault: false,
              note: report.siteServiceCostNote
            },
          ]
        },
        {
          title: 'Children Served',
          visible: true,
          lines: [
            {
              description: 'Total children ages 0-3 served (panel size) during the 12-month date range',
              values: [report.children0To3],
              displayDefault: false,
              note: report.children0To3Note,
            },
            {
              description: 'Total children with Medicaid ages 0-3 served (panel size) within the 12-month date range',
              values: [report.medicaidChildren0To3],
              displayDefault: false,
              note: report.medicaidChildren0To3Note,
            },
            {
              description: 'Total children ages 0-3 in Tiers 2 & 3 served by the HS Specialist(s) within the 12-month date range',
              values: [report.children0To3Tier2And3],
              displayDefault: false,
              note: report.children0To3Tier2And3Note,
            },
            {
              description: 'Total children with Medicaid ages 0-3 in Tiers 2 & 3 served by the HS Specialist(s) within the 12-month date range',
              values: [report.medicaidChildren0To3Tier2And3],
              displayDefault: false,
              note: report.medicaidChildren0To3Tier2And3Note,
            },
            {
              description: 'Total children with Medicaid under age 1, in Tiers 2 & 3 served by the HS Specialist(s) within the 12-month date range',
              values: [report.medicaidChildrenUnder1Tier2And3],
              displayDefault: false,
              note: report.medicaidChildrenUnder1Tier2And3Note,
            },
          ]
        },
      ],
    }
  ];
};

export default {
  getReportBreakdown
};