import paths from './paths';
import { IRoutes } from '../interfaces/router.interface';
import SignIn from './SignIn/SignIn';
import ForgotPassword from './ForgotPassword/ForgotPassword';
import ResetPassword from './ResetPassword/ResetPassword';
import NewPasswordChallenge from './NewPasswordChallenge/NewPasswordChallenge';
import VerifyEmail from './VerifyEmail/VerifyEmail';
import Dashboard from './Dashboard/Dashboard';
import CalculatorDashboard from './Dashboard/CalculatorDashboard/CalculatorDashboard';
import ContactUsDashboard from './Dashboard/ContactUs/ContactUsPage';
import ReviewsDashboard from './Dashboard/ReviewsDashboard/ReviewsDashboard';
import CalculationsDashboard from './Dashboard/Calculations/CalculationsDashboard';
import UsersDashboard from './Dashboard/UsersDashboard/UsersDashboard';
import ResourcesDashboard from './Dashboard/ResourcesDashboard/ResourcesDashboard';
import Calculator from './Calculator/Calculator';
import CalculatorServiceData from './Calculator/CalculatorServiceData/CalculatorServiceData';
import CalculatorReport from './Calculator/CalculatorReport/CalculatorReport';
import CalculatorInterventionsChecklist from './Calculator/CalculatorInterventionsChecklist/CalculatorInterventionsChecklist';
import CalculatorInterventions from './Calculator/CalculatorInterventions/CalculatorInterventions';
import OralHealth from './Calculator/CalculatorInterventions/OralHealth/OralHealth';
import EmergencyDepartmentUse from './Calculator/CalculatorInterventions/EmergencyDepartmentUse/EmergencyDepartmentUse';
import Immunizations from './Calculator/CalculatorInterventions/Immunizations/Immunizations';
import Diapers from './Calculator/CalculatorInterventions/Diapers/Diapers';
import Asthma from './Calculator/CalculatorInterventions/Asthma/Asthma';
import PostpartumMaternalDepression from './Calculator/CalculatorInterventions/PostpartumMaternalDepression/PostpartumMaternalDepression';
import HealthyBirthSpacing from './Calculator/CalculatorInterventions/HealthyBirthSpacing/HealthyBirthSpacing';
import IntimatePartnerViolence from './Calculator/CalculatorInterventions/IntimatePartnerViolence/IntimatePartnerViolence';
import Breastfeeding from './Calculator/CalculatorInterventions/Breastfeeding/Breastfeeding';
import SmokingCessation from './Calculator/CalculatorInterventions/SmokingCessation/SmokingCessation';
import FoodSecurity from './Calculator/CalculatorInterventions/FoodSecurity/FoodSecurity';
import EarlyDetection from './Calculator/CalculatorInterventions/EarlyDetection/EarlyDetection';
import PositiveParenting from './Calculator/CalculatorInterventions/PositiveParenting/PositiveParenting';

const signIn = {
  component: SignIn,
  path: paths.signIn,
  exact: true,
  public: true,
};

const forgotPassword = {
  component: ForgotPassword,
  path: paths.forgotPassword,
  exact: true,
  public: true,
};

const resetPassword = {
  component: ResetPassword,
  path: paths.resetPassword,
  exact: true,
  public: true,
};

const newPasswordChallenge = {
  component: NewPasswordChallenge,
  path: paths.newPasswordChallenge,
  exact: true,
};

const verifyEmail = {
  component: VerifyEmail,
  path: paths.verifyEmail,
  exact: true,
};

const dashboard = {
  component: Dashboard,
  path: paths.dashboard,
  childRoutes: [
    {
      component: CalculatorDashboard,
      path: paths.calculatorDashboard,
    },
    {
      component: UsersDashboard,
      path: paths.usersDashboard,
      admin: true,
    },
    {
      component: ResourcesDashboard,
      path: paths.resourcesDashboard,
    },
    {
      component: ContactUsDashboard,
      path: paths.contactusDashboard,
    },
    {
      component: ReviewsDashboard,
      path: paths.reviewsDashboard,
      admin: true
    },
    {
      component: CalculationsDashboard,
      path: paths.adminCalculationsDashboard,
      admin: true,
    }
  ],
};

const calculator = {
  component: Calculator,
  path: paths.calculator,
  childRoutes: [
    {
      component: CalculatorServiceData,
      path: paths.calculatorServiceData,
    },
    {
      component: CalculatorInterventionsChecklist,
      path: paths.calculatorInterventionsChecklist,
    },
    {
      component: CalculatorInterventions,
      path: paths.calculatorInterventions,
      childRoutes: [
        {
          component: OralHealth,
          path: paths.interventionsOralHealth,
        },
        {
          component: EmergencyDepartmentUse,
          path: paths.interventionsEmergencyDepartmentUse,
        },
        {
          component: Immunizations,
          path: paths.interventionsImmunizations,
        },
        {
          component: Diapers,
          path: paths.interventionsDiapers,
        },
        {
          component: Asthma,
          path: paths.interventionsAsthma,
        },
        {
          component: PostpartumMaternalDepression,
          path: paths.interventionsPostpartumMaternalDepression,
        },
        {
          component: HealthyBirthSpacing,
          path: paths.interventionsHealthyBirthSpacing,
        },

        {
          component: IntimatePartnerViolence,
          path: paths.interventionsIntimatePartnerViolence,
        },
        {
          component: Breastfeeding,
          path: paths.interventionsBreastfeeding,
        },
        {
          component: SmokingCessation,
          path: paths.interventionsSmokingCessation,
        },
        {
          component: FoodSecurity,
          path: paths.interventionsFoodSecurity,
        },
        {
          component: EarlyDetection,
          path: paths.interventionsEarlyDetection,
        },
        {
          component: PositiveParenting,
          path: paths.interventionsPositiveParenting,
        },
      ],
    },
    {
      component: CalculatorReport,
      path: paths.calculatorReport,
    },
  ],
};

const routes: IRoutes = [
  signIn,
  forgotPassword,
  resetPassword,
  newPasswordChallenge,
  verifyEmail,
  dashboard,
  calculator
];

export default routes;
