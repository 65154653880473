import ServiceData from './serviceData';
import Calculator from './shared/base';

export default class EarlyDetection extends Calculator {
  public serviceData: ServiceData;

  sliderEarlyDetectionLowEstimate: number;

  sliderEarlyDetectionHighEstimate: number;

  numberOfChildrenReferredToEarlyEducationPrograms: number;

  numberOfChildrenReferredToEarlyIntervention: number;

  percentageOfChildrenReceivingEarlyInterventionServices: number;

  constructor({
    state,
    serviceData,
    sliderEarlyDetectionLowEstimate,
    sliderEarlyDetectionHighEstimate,
    numberOfChildrenReferredToEarlyEducationPrograms,
    numberOfChildrenReferredToEarlyIntervention,
    percentageOfChildrenReceivingEarlyInterventionServices
  }: {
    state: string;
    serviceData: ServiceData;
    sliderEarlyDetectionLowEstimate: number;
    sliderEarlyDetectionHighEstimate: number;
    numberOfChildrenReferredToEarlyEducationPrograms: number;
    numberOfChildrenReferredToEarlyIntervention: number;
    percentageOfChildrenReceivingEarlyInterventionServices: number;
  }) {
    super({ state });
    this.serviceData = serviceData;
    this.numberOfChildrenReferredToEarlyEducationPrograms = numberOfChildrenReferredToEarlyEducationPrograms;
    this.sliderEarlyDetectionLowEstimate = sliderEarlyDetectionLowEstimate / 100;
    this.sliderEarlyDetectionHighEstimate = sliderEarlyDetectionHighEstimate / 100;
    this.numberOfChildrenReferredToEarlyIntervention = numberOfChildrenReferredToEarlyIntervention;
    this.percentageOfChildrenReceivingEarlyInterventionServices = percentageOfChildrenReceivingEarlyInterventionServices / 100;
  }

  /**
   * @name early_detection_tab_early_education_programs_general_price_inflation
   * @excel Constant
   * @constant 1.30797529157742
   */
  public readonly earlydetectionTabEarlyEducationProgramsGeneralPriceInflation = this
    .constants.earlyDetection
    .earlydetectionTabEarlyEducationProgramsGeneralPriceInflation;

  /**
   * @name early_detection_tab_special_education_general_price_inflation
   * @excel Constant
   * @constant 1.46956236556028
   */
  public readonly earlydetectionTabSpecialEducationGeneralPriceInflation = this.constants
    .earlyDetection.earlydetectionTabSpecialEducationGeneralPriceInflation;

  /**
   * @name early_detection_tab_idea_part_c_general_price_inflation
   * @excel Constant
   * @constant 1.17731472000804
   */
  public readonly earlydetectionTabIdeaPartCGeneralPriceInflation = this.constants
    .earlyDetection.earlydetectionTabIdeaPartCGeneralPriceInflation;

  /**
   * @name early_detection_tab_estimated_percentage_avoiding_special_education_due_to_EI_Low
   * @excel Constant
   * @constant 0.148
   */
  public readonly earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEILow = this.constants
    .earlyDetection.earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEILow;

  /**
   * @name early_detection_tab_estimated_percentage_avoiding_special_education_due_to_EI_High
   * @excel Constant
   * @constant 0.222
   */
  public readonly earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEIHigh = this.constants
    .earlyDetection.earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEIHigh;

  /**
   * @name early_detection_tab_special_education_cost_per_year
   * @excel Constant
   * @constant 9369
   */
  public readonly earlydetectionTabSpecialEducationCostPerYear = this.constants
    .earlyDetection.earlydetectionTabSpecialEducationCostPerYear;


  /**
   * @name early_detection_tab_idea_part_C_cost_per_year
   * @excel Constant
   * @constant 4566.62
   */
  public readonly earlydetectionTabIdeaPartCCostPerYear = this.constants
    .earlyDetection.earlydetectionTabIdeaPartCCostPerYear;

  /**
   * @name earlydetection_total_net_savings
   * @excel Constant
   * @constant 4567
   */
  public readonly earlydetectionTotalNetSavings = this.constants
    .earlyDetection.earlydetectionTotalNetSavings;


  /**
   * @name early_detection_tab_estimated_number_children_avoiding_special_education_due_to_EI_Low
   * @excel New Calculation
   * @formula earlydetectionTabNumberChildrenReferredToEarlyIntervention*earlydetectionTabPercentageChildrenReceivingEarlyInterventionServices*earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEILow
   */
  public earlydetectionTabEstimatedNumberChildrenAvoidingSpecialEducationDueToEILow = () => {
    return this.isFiniteNumber(this.numberOfChildrenReferredToEarlyIntervention *
        this.percentageOfChildrenReceivingEarlyInterventionServices * this.earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEILow
    );
  };

  /**
   * @name early_detection_tab_estimated_number_children_avoiding_special_education_due_to_EI_Low
   * @excel New Calculation
   * @formula earlydetectionTabNumberChildrenReferredToEarlyIntervention*earlydetectionTabPercentageChildrenReceivingEarlyInterventionServices*earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEIHigh
   */
  public earlydetectionTabEstimatedNumberChildrenAvoidingSpecialEducationDueToEIHigh = () => {
    return this.isFiniteNumber(this.numberOfChildrenReferredToEarlyIntervention *
          this.percentageOfChildrenReceivingEarlyInterventionServices * this.earlydetectionTabEstimatedPercentageAvoidingSpecialEducationDueToEIHigh
    );
  };

  /**
   * @name early_detection_tab_estimated_number_children_enroll_and_attend_early_education_programs_low
   * @excel New Calculation
   * @formula this.numberOfChildrenReferredToEarlyEducationPrograms * this.sliderEarlyDetectionLowEstimate
   */
  public earlydetectionTabEstimatedNumberChildrenEnrollAndAttendEarlyEducationProgramsLow = () => {
    return this.isFiniteNumber(this.numberOfChildrenReferredToEarlyEducationPrograms *
      this.sliderEarlyDetectionLowEstimate
    );
  };

  /**
   * @name early_detection_tab_estimated_number_children_enroll_and_attend_early_education_programs_high
   * @excel New Calculation
   * @formula this.numberOfChildrenReferredToEarlyEducationPrograms * this.sliderEarlyDetectionHighEstimate
   */
  public earlydetectionTabEstimatedNumberChildrenEnrollAndAttendEarlyEducationProgramsHigh = () => {
    return this.isFiniteNumber(this.numberOfChildrenReferredToEarlyEducationPrograms *
      this.sliderEarlyDetectionHighEstimate
    );
  };

  /**
   * @name early_detection_tab_early_education_programs_total_adjusted_net_savings
   * @excel New Calculation
   * @formula this.earlydetectionTabIdeaPartCCostPerYear *
      this.earlydetectionTabEarlyEducationProgramsGeneralPriceInflation
   */
  public earlydetectionTabEarlyEducationProgramsTotalAdjustedNetSavings = () => {
    return this.isFiniteNumber(this.earlydetectionTotalNetSavings *
      this.earlydetectionTabEarlyEducationProgramsGeneralPriceInflation
    );
  };
  
  /**
 * @name early_detection_tab_early_education_programs_total_net_savings_low
 * @excel New Calculations
 * @formula this.earlydetectionTabEarlyEducationProgramsTotalAdjustedNetSavings() * this.earlydetectionTabEstimatedNumberChildrenEnrollAndAttendEarlyEducationProgramsLow()
 */
  public earlydetectionTabEarlyEducationProgramsTotalNetSavingsLow = () => {
    return this.isFiniteNumber(this.earlydetectionTabEarlyEducationProgramsTotalAdjustedNetSavings() *
      this.earlydetectionTabEstimatedNumberChildrenEnrollAndAttendEarlyEducationProgramsLow()
    );
  };

  /**
 * @name early_detection_tab_early_education_programs_total_net_savings_high
 * @excel New Calculations
 * @formula this.earlydetectionTabEarlyEducationProgramsTotalAdjustedNetSavings() * this.earlydetectionTabEstimatedNumberChildrenEnrollAndAttendEarlyEducationProgramsHigh()
 */
  public earlydetectionTabEarlyEducationProgramsTotalNetSavingsHigh = () => {
    return this.isFiniteNumber(this.earlydetectionTabEarlyEducationProgramsTotalAdjustedNetSavings() *
      this.earlydetectionTabEstimatedNumberChildrenEnrollAndAttendEarlyEducationProgramsHigh()
    );
  };

  /**
 * @name early_detection_tab_special_education_five_year_cost
 * @excel New Calculations
 * @formula 5*(this.earlydetectionTabSpecialEducationCostPerYear * this.earlydetectionTabSpecialEducationGeneralPriceInflation)
 */
  public earlydetectionTabSpecialEducationFiveYearCost = () => {
    return this.isFiniteNumber(5*(this.earlydetectionTabSpecialEducationCostPerYear *
          this.earlydetectionTabSpecialEducationGeneralPriceInflation)
    );
  };

  /**
 * @name estimated_number_of_tiers_hs_medicaid_children_who_are_able_to_avoid_ED_visits_due_to_HS_high
 * @excel New Calculations
 * @formula earlydetectionTabIdeaPartCCostPerYear*earlydetectionTabIdeaPartCGeneralPriceInflation
 */
  public earlydetectionTabIdeaPartCTotalAdjustedNetCost = () => {
    return this.isFiniteNumber(this.earlydetectionTabIdeaPartCCostPerYear *
        this.earlydetectionTabIdeaPartCGeneralPriceInflation
    );
  };

  /**
 * @name estimated_number_of_tiers_hs_medicaid_children_who_are_able_to_avoid_ED_visits_due_to_HS_high
 * @excel New Calculations
 * @formula (earlydetectionTabSpecialEducationFiveYearCost-earlydetectionTabIdeaPartCTotalAdjustedNetCost)/(1+0.03)^5
 */
  public earlydetectionTabEarlyInterventionTotalFutureSavings = () => {
    const numerator =
    this.earlydetectionTabSpecialEducationFiveYearCost() -
    this.earlydetectionTabIdeaPartCTotalAdjustedNetCost();
    const denominator = Math.pow(1 + 0.03, 5); 
    return this.isFiniteNumber(numerator / denominator);
  };

  /**
 * @name estimated_number_of_tiers_hs_medicaid_children_who_are_able_to_avoid_ED_visits_due_to_HS_high
 * @excel New Calculations
 * @formula this.isFiniteNumber(this.earlydetectionTabEarlyInterventionTotalFutureSavings() * this.earlydetectionTabEstimatedNumberChildrenAvoidingSpecialEducationDueToEILow()
 */
  public earlydetectionTabEarlyInterventionTotalNetSavingsLow = () => {
    return this.isFiniteNumber(this.earlydetectionTabEarlyInterventionTotalFutureSavings() *
          this.earlydetectionTabEstimatedNumberChildrenAvoidingSpecialEducationDueToEILow()
    );
  };

  /**
 * @name estimated_number_of_tiers_hs_medicaid_children_who_are_able_to_avoid_ED_visits_due_to_HS_high
 * @excel New Calculations
 * @formula this.isFiniteNumber(this.earlydetectionTabEarlyInterventionTotalFutureSavings() * this.earlydetectionTabEstimatedNumberChildrenAvoidingSpecialEducationDueToEIHigh()
 */
  public earlydetectionTabEarlyInterventionTotalNetSavingsHigh = () => {
    return this.isFiniteNumber(this.earlydetectionTabEarlyInterventionTotalFutureSavings() *
            this.earlydetectionTabEstimatedNumberChildrenAvoidingSpecialEducationDueToEIHigh()
    );
  };

  /**
   * @name early_detection_estimated_low
   * @excel New Calculations
   * @formula this.earlydetectionTabEarlyEducationProgramsTotalNetSavingsLow() + this.earlydetectionTabEarlyInterventionTotalNetSavingsLow()
   */
  public earlyDetectionEstimatedLow = () => {
    return this.isFiniteNumber(this.earlydetectionTabEarlyEducationProgramsTotalNetSavingsLow() +
    this.earlydetectionTabEarlyInterventionTotalNetSavingsLow());
  };
  
  /**
     * @name early_detection_estimated_high
     * @excel New Calculations
     * @formula this.earlydetectionTabEarlyEducationProgramsTotalNetSavingsHigh() + this.earlydetectionTabEarlyInterventionTotalNetSavingsHigh()
     */
  public earlyDetectionEstimatedHigh = () => {
    return this.isFiniteNumber(this.earlydetectionTabEarlyEducationProgramsTotalNetSavingsHigh() +
    this.earlydetectionTabEarlyInterventionTotalNetSavingsHigh());
  };

  /**
     * @name early_detection_estimated_high
     * @excel New Calculations
     * @formula this.earlydetectionTabEarlyEducationProgramsTotalNetSavingsHigh() + this.earlydetectionTabEarlyInterventionTotalNetSavingsHigh()
     */
  public earlyDetectionEstimatedAverage = () => {
    return this.isFiniteNumber((this.earlyDetectionEstimatedLow() +
    this.earlyDetectionEstimatedHigh())/2);
  };

  /**
     * @name early_detection_estimated_high
     * @excel New Calculations
     * @formula this.earlydetectionTabEarlyEducationProgramsTotalNetSavingsHigh() + this.earlydetectionTabEarlyInterventionTotalNetSavingsHigh()
     */
  public earlydetectionTabEarlyInterventionTotalNetSavingsAverage = () => {
    return this.isFiniteNumber((this.earlydetectionTabEarlyInterventionTotalNetSavingsLow() +
    this.earlydetectionTabEarlyInterventionTotalNetSavingsHigh())/2);
  };

  /**
     * @name early_detection_estimated_high
     * @excel New Calculations
     * @formula this.earlydetectionTabEarlyEducationProgramsTotalNetSavingsHigh() + this.earlydetectionTabEarlyInterventionTotalNetSavingsHigh()
     */
  public earlydetectionTabEarlyEducationProgramsTotalNetSavingsAverage = () => {
    return this.isFiniteNumber((this.earlydetectionTabEarlyEducationProgramsTotalNetSavingsLow() +
    this.earlydetectionTabEarlyEducationProgramsTotalNetSavingsHigh())/2);
  };
}
