import ServiceData from './serviceData';
import Calculator from './shared/base';

export default class Diapers extends Calculator {
  public serviceData: ServiceData;

  public numberOfCaregiversProvidedDiapersOrReferred:number;

  constructor({
    state,
    serviceData,
    lowEstimatedSlider,
    highEstimatedSlider,
    numberOfCaregiversProvidedDiapersOrReferred,
  }: {
    state: string;
    serviceData: ServiceData;
    lowEstimatedSlider: number;
    highEstimatedSlider: number;
    numberOfCaregiversProvidedDiapersOrReferred:number;
  }) {
    super({ state });
    this.serviceData = serviceData;
    this.lowEstimatedSlider = lowEstimatedSlider / 100;
    this.highEstimatedSlider = highEstimatedSlider / 100;
    this.numberOfCaregiversProvidedDiapersOrReferred = numberOfCaregiversProvidedDiapersOrReferred;
  }

  /**
   * @name diapers_tab_medical_price_inflation_factor
   * @excel Excel Diapers Tab B4
   * @constant 2.26
   */
  public readonly diapersTabMedicalPriceInflationFactor = this.constants.diapers
    .diapersTabMedicalPriceInflationFactor;

  /**
   * @name diapers_tab_medical_price_inflation_factor
   * @excel Excel Diapers Tab B4
   * @constant 2.26
   */
  public readonly diapersTabTotalNetSavings = this.constants.diapers
    .diapersTabTotalNetSavings;

  /**
   * low_estimated_slider (from app)
   * @name low_estimated_slider
   */
  public lowEstimatedSlider;

  /**
   * high_estimated_slider (from app)
   * @name high_estimated_slider
   */
  public highEstimatedSlider;

  /**
   * @name diapers_tab_estimated_number_caregivers_receiving_diapers_low
   * @excel New Calculation
   * @formula number_of_caregivers_provided_diapers_or_referred * low_estimated_slider
   */

  public diapersTabEstimatedNumberCaregiversReceivingDiapersLow = () => {
    return this.isFiniteNumber(
      this.numberOfCaregiversProvidedDiapersOrReferred * this.lowEstimatedSlider
    );
  };

  /**
   * @name diapers_tab_estimated_number_caregivers_receiving_diapers_low
   * @excel New Calculation
   * @formula number_of_caregivers_provided_diapers_or_referred * high_estimated_slider
   */

  public diapersTabEstimatedNumberCaregiversReceivingDiapersHigh= () => {
    return this.isFiniteNumber(
      this.numberOfCaregiversProvidedDiapersOrReferred * this.highEstimatedSlider
    );
  };

  /**
   * @name diapers_tab_total_adjusted_net_savings
   * @excel New Calculation
   * @formula diapers_tab_total_net_savings * diapers_tab_medical_price_inflation_factor
   */

  public diapersTabTotalAdjustedNetSavings = () => {
    return this.isFiniteNumber(
      this.diapersTabTotalNetSavings * this.diapersTabMedicalPriceInflationFactor
    );
  };

  /**
   * @name diapers_estimated_savings_low
   * @excel Excel F61
   * @formula diapers_tab_total_adjusted_net_savings * diapers_tab_estimated_number_caregivers_receiving_diapers_low
   */
  public diapersEstimatedSavingsLow = () => {
    return this.isFiniteNumber(
      this.diapersTabTotalAdjustedNetSavings() *
        this.diapersTabEstimatedNumberCaregiversReceivingDiapersLow()
    );
  };

  /**
   * @name diapers_estimated_savings_high
   * @excel Excel F61
   * @formula diapers_tab_total_adjusted_net_savings * diapers_tab_estimated_number_caregivers_receiving_diapers_high
   */
  public diapersEstimatedSavingsHigh = () => {
    return this.isFiniteNumber(
      this.diapersTabTotalAdjustedNetSavings() *
      this.diapersTabEstimatedNumberCaregiversReceivingDiapersHigh()
    );
  };

  /**
   * @name diapers_estimated_savings_high
   * @excel Excel F61
   * @formula diapers_tab_total_adjusted_net_savings * diapers_tab_estimated_number_caregivers_receiving_diapers_high
   */
  public diapersEstimatedSavingsAverage = () => {
    return this.isFiniteNumber(
      (this.diapersEstimatedSavingsLow() +
      this.diapersEstimatedSavingsHigh())/2
    );
  };
}
