import React, { FunctionComponent } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import { Table, TableRow, TableBody, TableCell as TC, TableHead, Typography } from '@material-ui/core';
import { StringFormatter } from '../../../../utils';
import { ISummaryReport } from '../../../../interfaces/calculator.interface';
import styles, { tableCellStyles } from './SavingsBySector.styles';
import { IInterventionsChecklist, InterventionsChecklistForm } from '../../../../interfaces/interventions.interface';

export const TableCell = withStyles(tableCellStyles)(TC);

interface IProps extends WithStylesType<typeof styles> {
  report: ISummaryReport;
  interventionsChecklist: IInterventionsChecklist;
}

const SavingsBySector: FunctionComponent<IProps> = 
({ classes, report, interventionsChecklist }) => {
  const tableRows = [
    {
      titleClass: classes.sectionTitle,
      title: 'Oral Health',
      firstAmountClass: classes.sectionAmount,
      firstAmount: StringFormatter.roundedMoney(
        report?.dentalEstimatedSavingAverage?.toString()
      ),
      secondAmountClass: classes.sectionAmount,
      secondAmount: '-',
      thirdAmountClass: classes.sectionAmount,
      thirdAmount: '-',
      fourthAmountClass: classes.sectionAmount,
      fourthAmount: '-',
      fifthAmountClass: classes.sectionAmount,
      fifthAmount: '-',
      sixthAmountClass: classes.sectionAmount,
      sixthAmount: '-',
      seventhAmountClass: classes.sectionAmount,
      seventhAmount: StringFormatter.roundedMoney(
        report?.dentalEstimatedSavingAverage?.toString()
      ),
      visible: interventionsChecklist[InterventionsChecklistForm.OralHealth],
    },
    {
      titleClass: classes.sectionTitle,
      title: 'Appropriate Use of Care',
      firstAmountClass: classes.sectionAmount,
      firstAmount: StringFormatter.roundedMoney(
        report?.emergencyDepartmentEstimatedSavingAverage?.toString()
      ),
      secondAmountClass: classes.sectionAmount,
      secondAmount: '-',
      thirdAmountClass: classes.sectionAmount,
      thirdAmount: '-',
      fourthAmountClass: classes.sectionAmount,
      fourthAmount: '-',
      fifthAmountClass: classes.sectionAmount,
      fifthAmount: '-',
      sixthAmountClass: classes.sectionAmount,
      sixthAmount: '-',
      seventhAmountClass: classes.sectionAmount,
      seventhAmount: StringFormatter.roundedMoney(
        report?.emergencyDepartmentEstimatedSavingAverage?.toString()
      ),
      visible: interventionsChecklist.emergencyDepartmentUse,
    },
    {
      titleClass: classes.sectionTitle,
      title: 'Immunizations',
      firstAmountClass: classes.sectionAmount,
      firstAmount: StringFormatter.roundedMoney(
        report?.immunizationsEstimatedTotalSavingsAverage?.toString()
      ),
      secondAmountClass: classes.sectionAmount,
      secondAmount: '-',
      thirdAmountClass: classes.sectionAmount,
      thirdAmount: '-',
      fourthAmountClass: classes.sectionAmount,
      fourthAmount: '-',
      fifthAmountClass: classes.sectionAmount,
      fifthAmount: '-',
      sixthAmountClass: classes.sectionAmount,
      sixthAmount: '-',
      seventhAmountClass: classes.sectionAmount,
      seventhAmount: StringFormatter.roundedMoney(
        report?.immunizationsEstimatedTotalSavingsAverage?.toString()
      ),
      visible: interventionsChecklist.immunizations,
    },
    {
      titleClass: classes.sectionTitle,
      title: 'Asthma',
      firstAmountClass: classes.sectionAmount,
      firstAmount: StringFormatter.roundedMoney(
        report?.asthmaEstimatedSavingsAverage?.toString()
      ),
      secondAmountClass: classes.sectionAmount,
      secondAmount: '-',
      thirdAmountClass: classes.sectionAmount,
      thirdAmount: '-',
      fourthAmountClass: classes.sectionAmount,
      fourthAmount: '-',
      fifthAmountClass: classes.sectionAmount,
      fifthAmount: '-',
      sixthAmountClass: classes.sectionAmount,
      sixthAmount: '-',
      seventhAmountClass: classes.sectionAmount,
      seventhAmount: StringFormatter.roundedMoney(
        report?.asthmaEstimatedSavingsAverage?.toString()
      ),
      visible: interventionsChecklist.asthma,
    },
    {
      titleClass: classes.sectionTitle,
      title: 'Postpartum Maternal Depression',
      firstAmountClass: classes.sectionAmount,
      firstAmount: StringFormatter.roundedMoney(
        report?.maternalDepressionEstimatedTotalTermSavingsAverage?.toString() || '0'
      ),
      secondAmountClass: classes.sectionAmount,
      secondAmount: '-',
      thirdAmountClass: classes.sectionAmount,
      thirdAmount: '-',
      fourthAmountClass: classes.sectionAmount,
      fourthAmount: '-',
      fifthAmountClass: classes.sectionAmount,
      fifthAmount: '-',
      sixthAmountClass: classes.sectionAmount,
      sixthAmount: '-',
      seventhAmountClass: classes.sectionAmount,
      seventhAmount: StringFormatter.roundedMoney(
        report?.maternalDepressionEstimatedTotalTermSavingsAverage?.toString()
      ),
      visible: true,
    },
    {
      titleClass: classes.sectionTitle,
      title: 'Birth Spacing',
      firstAmountClass: classes.sectionAmount,
      firstAmount: StringFormatter.roundedMoney(
        report?.birthsHealthcareSavings?.toString() || '0'
      ),
      secondAmountClass: classes.sectionAmount,
      secondAmount: '-',
      thirdAmountClass: classes.sectionAmount,
      thirdAmount: StringFormatter.roundedMoney(report?.birthsPublicAssistanceSavings?.toString() || '0'),
      fourthAmountClass: classes.sectionAmount,
      fourthAmount: StringFormatter.roundedMoney(report?.birthsHeadStartSavings?.toString() || '0'),
      fifthAmountClass: classes.sectionAmount,
      fifthAmount: StringFormatter.roundedMoney(report?.birthsChildWelfareSavings?.toString() || '0'),
      sixthAmountClass: classes.sectionAmount,
      sixthAmount: '-',
      seventhAmountClass: classes.sectionAmount,
      seventhAmount: StringFormatter.roundedMoney(
        report?.healthBirthSpacingEstimatedSavingsAverage?.toString()
      ),
      visible: true,
    },
    {
      titleClass: classes.sectionTitle,
      title: 'Intimate Partner Violence',
      firstAmountClass: classes.sectionAmount,
      firstAmount: StringFormatter.roundedMoney(
        report?.intimatePartnerViolenceEstimatedSavingsAverage?.toString() || '0'
      ),
      secondAmountClass: classes.sectionAmount,
      secondAmount: '-',
      thirdAmountClass: classes.sectionAmount,
      thirdAmount: '-',
      fourthAmountClass: classes.sectionAmount,
      fourthAmount: '-',
      fifthAmountClass: classes.sectionAmount,
      fifthAmount: '-',
      sixthAmountClass: classes.sectionAmount,
      sixthAmount: '-',
      seventhAmountClass: classes.sectionAmount,
      seventhAmount: StringFormatter.roundedMoney(
        report?.intimatePartnerViolenceEstimatedSavingsAverage?.toString() || '0'
      ),
      visible: true,
    },
    {
      titleClass: classes.sectionTitle,
      title: 'Breastfeeding',
      firstAmountClass: classes.sectionAmount,
      firstAmount: StringFormatter.roundedMoney(
          report?.breastfeedingEstimatedSavingsAverage?.toString() || '0'
      ),
      secondAmountClass: classes.sectionAmount,
      secondAmount: '-',
      thirdAmountClass: classes.sectionAmount,
      thirdAmount: '-',
      fourthAmountClass: classes.sectionAmount,
      fourthAmount: '-',
      fifthAmountClass: classes.sectionAmount,
      fifthAmount: '-',
      sixthAmountClass: classes.sectionAmount,
      sixthAmount: '-',
      seventhAmountClass: classes.sectionAmount,
      seventhAmount: StringFormatter.roundedMoney(
        report?.breastfeedingEstimatedSavingsAverage?.toString()
      ),
      visible: true,
    },
    {
      titleClass: classes.sectionTitle,
      title: 'Smoking Cessation',
      firstAmountClass: classes.sectionAmount,
      firstAmount: StringFormatter.roundedMoney(
          report?.smokingTabTotalSavingsAdultAndChildAverage?.toString() || '0'
      ),
      secondAmountClass: classes.sectionAmount,
      secondAmount: '-',
      thirdAmountClass: classes.sectionAmount,
      thirdAmount: '-',
      fourthAmountClass: classes.sectionAmount,
      fourthAmount: '-',
      fifthAmountClass: classes.sectionAmount,
      fifthAmount: '-',
      sixthAmountClass: classes.sectionAmount,
      sixthAmount: '-',
      seventhAmountClass: classes.sectionAmount,
      seventhAmount: StringFormatter.roundedMoney(
        report?.smokingTabTotalSavingsAdultAndChildAverage?.toString()
      ),
      visible: true,
    },
    {
      titleClass: classes.sectionTitle,
      title: 'Food Security',
      firstAmountClass: classes.sectionAmount,
      firstAmount: StringFormatter.roundedMoney(
          report?.foodSecurityEstimatedSavingsAverage?.toString() || '0'
      ),
      secondAmountClass: classes.sectionAmount,
      secondAmount: '-',
      thirdAmountClass: classes.sectionAmount,
      thirdAmount: '-',
      fourthAmountClass: classes.sectionAmount,
      fourthAmount: '-',
      fifthAmountClass: classes.sectionAmount,
      fifthAmount: '-',
      sixthAmountClass: classes.sectionAmount,
      sixthAmount: '-',
      seventhAmountClass: classes.sectionAmount,
      seventhAmount: StringFormatter.roundedMoney(
        report?.foodSecurityEstimatedSavingsAverage?.toString()
      ),
      visible: true,
    },
    {
      titleClass: classes.sectionTitle,
      title: 'Diapers',
      firstAmountClass: classes.sectionAmount,
      firstAmount: StringFormatter.roundedMoney(
        report?.diapersEstimatedSavingsAverage?.toString() || '0'
      ),
      secondAmountClass: classes.sectionAmount,
      secondAmount: '-',
      thirdAmountClass: classes.sectionAmount,
      thirdAmount: '-',
      fourthAmountClass: classes.sectionAmount,
      fourthAmount: '-',
      fifthAmountClass: classes.sectionAmount,
      fifthAmount: '-',
      sixthAmountClass: classes.sectionAmount,
      sixthAmount: '-',
      seventhAmountClass: classes.sectionAmount,
      seventhAmount: StringFormatter.roundedMoney(
        report?.diapersEstimatedSavingsAverage?.toString()
      ),
      visible: true,
    },
    {
      titleClass: classes.sectionTitle,
      title: 'Early Detection',
      firstAmountClass: classes.sectionAmount,
      firstAmount: '-',
      secondAmountClass: classes.sectionAmount,
      secondAmount: StringFormatter.roundedMoney(
        report?.earlydetectionTabEarlyInterventionTotalNetSavingsAverage?.toString() || '0'
      ),
      thirdAmountClass: classes.sectionAmount,
      thirdAmount: StringFormatter.roundedMoney(report?.earlydetectionTabEarlyEducationProgramsTotalNetSavingsAverage?.toString() || '0'),
      fourthAmountClass: classes.sectionAmount,
      fourthAmount: '-',
      fifthAmountClass: classes.sectionAmount,
      fifthAmount: '-',
      sixthAmountClass: classes.sectionAmount,
      sixthAmount: '-',
      seventhAmountClass: classes.sectionAmount,
      seventhAmount: StringFormatter.roundedMoney(
        report?.earlyDetectionEstimatedAverage?.toString()
      ),
      visible: true,
    },
    {
      titleClass: classes.subsectionTitle,
      title: 'Referral to Early Head Start/Other Preschool Programs',
      firstAmountClass: classes.subsectionAmount,
      firstAmount: '-',
      secondAmountClass: classes.subsectionAmount,
      secondAmount: '-',
      thirdAmountClass: classes.subsectionAmount,
      thirdAmount: StringFormatter.roundedMoney(report?.earlydetectionTabEarlyEducationProgramsTotalNetSavingsAverage?.toString() || '0'),
      fourthAmountClass: classes.sectionAmount,
      fourthAmount: '-',
      fifthAmountClass: classes.sectionAmount,
      fifthAmount: '-',
      sixthAmountClass: classes.sectionAmount,
      sixthAmount: '-',
      seventhAmountClass: classes.sectionAmount,
      seventhAmount: StringFormatter.roundedMoney(
        report?.earlydetectionTabEarlyEducationProgramsTotalNetSavingsAverage?.toString()
      ),
      visible: true,
    },
    {
      titleClass: classes.subsectionTitle,
      title: 'Referral to Early Intervention/IDEA Part C',
      firstAmountClass: classes.subsectionAmount,
      firstAmount: '-',
      secondAmountClass: classes.subsectionAmount,
      secondAmount: StringFormatter.roundedMoney(
        report?.earlydetectionTabEarlyInterventionTotalNetSavingsAverage?.toString() || '0'
    ),
      thirdAmountClass: classes.subsectionAmount,
      thirdAmount:'-',
      fourthAmountClass: classes.sectionAmount,
      fourthAmount: '-',
      fifthAmountClass: classes.sectionAmount,
      fifthAmount: '-',
      sixthAmountClass: classes.sectionAmount,
      sixthAmount: '-',
      seventhAmountClass: classes.sectionAmount,
      seventhAmount: StringFormatter.roundedMoney(
        report?.earlydetectionTabEarlyInterventionTotalNetSavingsAverage?.toString()
      ),
      visible: true,
    },
    {
      titleClass: classes.sectionTitle,
      title: 'Positive Parenting',
      firstAmountClass: classes.sectionAmount,
      firstAmount: StringFormatter.roundedMoney(
            report?.positiveParentingTotalHealthService?.toString() || '0'
      ),
      secondAmountClass: classes.sectionAmount,
      secondAmount: StringFormatter.roundedMoney(
            report?.positiveParentingEducationService?.toString() || '0'
      ),
      thirdAmountClass: classes.sectionAmount,
      thirdAmount: '-',
      fourthAmountClass: classes.sectionAmount,
      fourthAmount: '-',
      fifthAmountClass: classes.sectionAmount,
      fifthAmount: StringFormatter.roundedMoney(
        report?.positiveParentingAbuseFosterCare?.toString()
      ),
      sixthAmountClass: classes.sectionAmount,
      sixthAmount: StringFormatter.roundedMoney(
        report?.positiveParentingCrime?.toString()
      ),
      seventhAmountClass: classes.sectionAmount,
      seventhAmount: StringFormatter.roundedMoney(
        report?.positiveParentingTotalServices?.toString()
      ),
      visible: true,
    },
    {
      titleClass: classes.subsectionTitle,
      title: 'Referral to Home Visting Programs',
      firstAmountClass: classes.subsectionAmount,
      firstAmount: StringFormatter.roundedMoney(
        report?.positiveparentingTabEstimatedSavingsFamiliesRecievingHomeVisitingServicesProgramsAverage?.toString()
      ),
      secondAmountClass: classes.subsectionAmount,
      secondAmount: '-',
      thirdAmountClass: classes.subsectionAmount,
      thirdAmount: '-',
      fourthAmountClass: classes.sectionAmount,
      fourthAmount: '-',
      fifthAmountClass: classes.sectionAmount,
      fifthAmount: '-',
      sixthAmountClass: classes.sectionAmount,
      sixthAmount: '-',
      seventhAmountClass: classes.sectionAmount,
      seventhAmount: StringFormatter.roundedMoney(
        report?.positiveparentingTabEstimatedSavingsFamiliesRecievingHomeVisitingServicesProgramsAverage?.toString()
      ),
      visible: true,
    },
    {
      titleClass: classes.subsectionTitle,
      title: 'Other Parenting Supports/Services',
      firstAmountClass: classes.subsectionAmount,
      firstAmount: StringFormatter.roundedMoney(
        report?.positiveParentingHealthService?.toString()
      ),
      secondAmountClass: classes.subsectionAmount,
      secondAmount: StringFormatter.roundedMoney(
          report?.positiveParentingEducationService?.toString() || '0'
      ),
      thirdAmountClass: classes.subsectionAmount,
      thirdAmount: '-',
      fourthAmountClass: classes.sectionAmount,
      fourthAmount: '-',
      fifthAmountClass: classes.sectionAmount,
      fifthAmount: StringFormatter.roundedMoney(
        report?.positiveParentingAbuseFosterCare?.toString()
      ),
      sixthAmountClass: classes.sectionAmount,
      sixthAmount: StringFormatter.roundedMoney(
        report?.positiveParentingCrime?.toString()
      ),
      seventhAmountClass: classes.sectionAmount,
      seventhAmount: StringFormatter.roundedMoney(
        report?.positiveparentingTabCalculatedSavingsForPositiveParentingSupportsAndServices?.toString()
      ),
      visible: true,
    },
    {
      titleClass: classes.columnTitle,
      title: 'Total Savings',
      firstAmountClass: classes.columnTitle,
      firstAmount: StringFormatter.roundedMoney(report?.totalHealthCareSavings?.toString() || '0'),
      secondAmountClass: classes.columnTitle,
      secondAmount: StringFormatter.roundedMoney(report?.totalSpecialEducationSavings?.toString() || '0'),
      thirdAmountClass: classes.columnTitle,
      thirdAmount: StringFormatter.roundedMoney(report?.totalPublicAssistanceSavings?.toString() || '0'),
      fourthAmountClass: classes.sectionAmount,
      fourthAmount:  StringFormatter.roundedMoney(report?.birthsHeadStartSavings?.toString() || '0'),
      fifthAmountClass: classes.sectionAmount,
      fifthAmount:  StringFormatter.roundedMoney(report?.totalChildWelfareSavings?.toString() || '0'),
      sixthAmountClass: classes.sectionAmount,
      sixthAmount: StringFormatter.roundedMoney(
        report?.positiveParentingCrime?.toString()
      ),
      seventhAmountClass: classes.sectionAmount,
      seventhAmount: StringFormatter.roundedMoney(
        report?.totalSavingsSector?.toString()
      ),
      visible: true,
    },
  ];
  return (
    <div style={{ paddingLeft:'35px', marginTop:'20px' }}>
      <Typography variant="h2" className={classes.contentTitle}>Savings by Sector</Typography>
      <Table style={{ backgroundColor: 'white', borderRadius:'5px' }}>
        <TableHead>
          <TableCell align="left" className={classes.columnTitle}>
            HealthySteps Services
          </TableCell>
          <TableCell align="center" className={classes.columnSubTitle}>
            Healthcare(Medicaid)
          </TableCell>
          <TableCell align="center" className={classes.columnSubTitle}>
            Special Education
          </TableCell>
          <TableCell align="center" className={classes.columnSubTitle}>
            Public Assistance
          </TableCell>
          <TableCell align="center" className={classes.columnSubTitle}>
            Head Start
          </TableCell>
          <TableCell align="center" className={classes.columnSubTitle}>
            Child Welfare
          </TableCell>
          <TableCell align="center" className={classes.columnSubTitle}>
            Criminal Justice
          </TableCell>
          <TableCell align="center" className={classes.columnSubTitle}>
            Total
          </TableCell>
        </TableHead>
        <TableBody>
          {tableRows.map((row, idx) => (
            row.visible ?
              <TableRow key={idx}>
                <TableCell className={row.titleClass} scope="row" style={{width:'30%'}}>
                  {row.title}
                </TableCell>
                <TableCell className={row.firstAmountClass} align="right">
                  {row.firstAmount}
                </TableCell>
                <TableCell className={row.secondAmountClass} align="right">
                  {row.secondAmount}
                </TableCell>
                <TableCell className={row.thirdAmountClass} align="right">
                  {row.thirdAmount}
                </TableCell>
                <TableCell className={row.firstAmountClass} align="right">
                  {row.fourthAmount}
                </TableCell>
                <TableCell className={row.thirdAmountClass} align="right">
                  {row.fifthAmount}
                </TableCell>
                <TableCell className={row.thirdAmountClass} align="right">
                  {row.sixthAmount}
                </TableCell>
                <TableCell className={row.secondAmountClass} align="right">
                  {row.seventhAmount}
                </TableCell>
              </TableRow>
              : <></>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default withStyles(styles)(SavingsBySector);
