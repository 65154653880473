import { createStyles, StyleRules } from '@material-ui/core/styles';
import { Colors } from '../../../../assets/styles';

export default (): StyleRules => {
  return createStyles({
    title: {
      textAlign: 'center',
      paddingBottom: 60,
      fontSize: 22,
      height: 45,
      '@media print': {
        marginBottom: '20px',
      }
    },
    content: {
      borderRadius: 4,
      backgroundColor: Colors.White,
      padding: '35px 16px',
      '@media print': {
        paddingBottom: '0px',
      }
    },
    download: {
      width: 950,
      // paddingTop: '20px',
    },
    pdfTitle: {
      paddingBottom: 20,
      fontSize: 22,
    },
    pageDivider: {
      margin: '50px 0',
    },
    topMarginPrint: {
      margin: '35px 0',
    },
    completedDate: {
      textAlign: 'right',
      color: Colors.Gray6,
      paddingRight: '0 !important',
      fontSize: 13
    }
  });
};
