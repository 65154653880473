import React, { forwardRef } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import cx from 'classnames';
// import { Typography, Box, Grid } from '@material-ui/core';
import { Box, Grid } from '@material-ui/core';
import ReportHeader from '../ReportHeader/ReportHeader';
import ReportBreakdown from '../ReportBreakdown/ReportBreakdown';
// import { Constants, DateUtils } from '../../../../utils';
import { Constants } from '../../../../utils';
import { ISummaryReport, breakdownReportState, IBreakdownReport } from '../../../../interfaces/calculator.interface';
import { IInterventionsChecklist } from '../../../../interfaces/interventions.interface';
import styles from './Report.styles';
import TotalSavingsByPopulation from '../TotalSavings/TotalSavingsByPopulation';
import TotalSavingsByTimeframe from '../TotalSavings/TotalSavingsByTimeframe';
import ROIBySavings from '../ReturnOnInvestment/ROIBySavings';
import ROIByServiceProvider from '../ReturnOnInvestment/ROIByServiceProvider';
import CostSavingsReportBreakdown from '../ReportBreakdown/CostSavingsReportBreakdown';
import SavingsBySector from '../ReturnOnInvestment/SavingsBySector';

interface IProps extends WithStylesType<typeof styles> {
  report: ISummaryReport;
  interventionsChecklist: IInterventionsChecklist;
  id?: string;
  printClass?: string;
  isFinished: boolean;
  lastDateUpdated: string;
  titleText?: string;
}

const Report = forwardRef<HTMLDivElement, IProps>(
  ({ classes, id, report, interventionsChecklist, printClass, isFinished, lastDateUpdated, titleText }, ref) => {
    const sanitizeReportValues = (reportToParse: IBreakdownReport) => {
      if (!reportToParse) return breakdownReportState;

      return Object.entries(reportToParse)
        .reduce((acc, [key, val]) => {
          const isNote = key.toLowerCase().includes('note');
          
          return ({
            ...acc,
            [key]: (val === '' && !isNote) ? Constants.STRING_TYPES.EMPTY : val,
          });
        },
        breakdownReportState);
    };

    // const getReportDate = () => 
    //   lastDateUpdated ?
    //     (isFinished ? 'Completed on ' : 'Last Edited on ') + DateUtils.formatDate(new Date(lastDateUpdated), 'MM/DD/YYYY | hh:mm a')
    //     : '';

    return (
      <div
        className={cx(classes.content, {
          [classes.download]: id,
        })}
        ref={ref}
        id={id}
      >

        <ReportHeader report={report} titleText={titleText} printClass= {printClass} />
        {/* <Box className={`${classes.completedDate} ${printClass || ''}`}>{getReportDate()}</Box> */}
        <Box className={classes.topMarginPrint} displayPrint="block" display="none" />          
        <ROIBySavings report={report} />     
        <Grid container spacing={2} style={{ paddingLeft:'35px' }}>
          <Grid item xs={6}>
            <TotalSavingsByPopulation
              report={report}
            />
          </Grid>
          <Grid item xs={6}>
            <TotalSavingsByTimeframe
              report={report}
            />
          </Grid>
        </Grid>
        <ROIByServiceProvider report={report} interventionsChecklist={interventionsChecklist}/>
        <SavingsBySector report={report} interventionsChecklist={interventionsChecklist}/>
        {/* <ReportBody report={report} interventionsChecklist={interventionsChecklist} /> */}
        <Box className={classes.pageDivider} displayPrint="block" display="none" />
        <ReportBreakdown
          report={sanitizeReportValues(report.breakdownData)}
          interventionsChecklist={interventionsChecklist}
        />
        <CostSavingsReportBreakdown
          report={sanitizeReportValues(report.breakdownData)}
          interventionsChecklist={interventionsChecklist}
        />
      </div>
    );
  }
);

export default withStyles(styles)(Report);
