
import { IBreakdownReport, breakdownReportState } from '../../../../interfaces/calculator.interface';
import { IInterventionsChecklist, InterventionsChecklistForm } from '../../../../interfaces/interventions.interface';
import { StringFormatter, Constants } from '../../../../utils';

export const getCostSavingsReportBreakdown = (
  report: IBreakdownReport = breakdownReportState,
  interventionsChecklist: IInterventionsChecklist
) => {

  const formatPerc = (value: string) => 
    (value && value !== Constants.STRING_TYPES.EMPTY) ?
      StringFormatter.percentage(value) : Constants.STRING_TYPES.EMPTY;

  return [
    {
      title: 'Interventions for Children',
      resultsHeaders: ['# or %', 'Low Estimate', 'High Estimate'],
      subCategories: [
        {
          title: 'Oral Health',
          visible: interventionsChecklist[InterventionsChecklistForm.OralHealth],
          lines: [
            {
              description: 'Number of caregivers who discussed a need for oral health services for their child(ren) with a HS Specialist and/or were referred for services',
              values: [report.oralHealth_needForOralHealth, '', ''],
              displayDefault: true,
              note: report.oralHealth_needForOralHealthNote,
            },
            {
              description: 'What percentage of children had a first dental visit by age 3 due to HealthySteps Specialist(s) support?',
              values: ['', formatPerc(report.oralHealth_lowEstimate), formatPerc(report.oralHealth_highEstimate)],
              displayDefault: true,
              note: report.oralHealth_estimatesNote,
            }
          ]
        },
        {
          title: 'Asthma',
          visible: interventionsChecklist.asthma,
          lines: [
            {
              description: 'Number of Tier 2 and 3 children who had asthma or were wheezing and at risk for asthma',
              values: [report.asthma_numberHaveAsthma, '', ''],
              displayDefault: true,
              note: report.asthma_numberHaveAsthmaNote,
            },
            {
              description: 'Percentage of children with asthma or related symptoms who avoid emergency department visits due to HealthySteps',
              values: ['', formatPerc(report.asthma_lowEstimate), formatPerc(report.asthma_highEstimate)],
              displayDefault: true,
              note: report.asthma_estimatesNote,
            }
          ],
        },
        {
          title: 'Appropriate Use of Care',
          visible: interventionsChecklist.emergencyDepartmentUse,
          lines: [
            {
              description: 'Number of caregivers who contacted HS Specialist(s) seeking advice about whether to bring a sick child in for a visit',
              values: [report.emergencyDepartment_contactedByPhone, '', ''],
              displayDefault: true,
              note: report.emergencyDepartment_contactedByPhoneNote,
            },
            {
              description: 'Percentage of caregivers seeking advice about a sick child who ended up treating the child at home for a minor illness but would have brought the child in for a visit (for example, at a doctor’s office, urgent care, or hospital emergency department) if they had not talked with a HS Specialist',
              values: [formatPerc(report.emergencyDepartment_treatedAtHome), '', ''],
              displayDefault: true,
              note: report.emergencyDepartment_treatedAtHomeNote,
            },
            {
              description: 'Percentage of caregivers seeking advice about a sick child who ended up bringing the child in for a clinic or other office-based visit (including urgent care) but would have brought the child in for a hospital emergency department visit if they had not talked with a HS Specialist',
              values: [formatPerc(report.emergencyDepartment_clinicVisit), '', ''],
              displayDefault: true,
              note: report.emergencyDepartment_clinicVisitNote,
            }
          ],
        },
        {
          title: 'Immunizations',
          visible: interventionsChecklist.immunizations,
          lines: [
            {
              description: 'Number of caregivers who discussed a need for flu vaccines for their Medicaid/CHIP-enrolled children with a HS Specialist and/or were assisted in pursuing the flu vaccine',
              values: [report.immunizations_needForFluVaccinations, '', ''],
              displayDefault: true,
              note: report.immunizations_needForFluVaccinationsNote,
            },
            {
              description: 'Number of well-child visits for Medicaid/CHIP-enrolled children that occurred due to HS Specialist(s) contacting families to triage barriers and/or encourage attendance',
              values: [report.immunizations_numberOfEncourageVisits, '', ''],
              displayDefault: true,
              note: report.immunizations_numberOfEncourageVisitsNote,
            },
            {
              description: 'Percentage of Medicaid/CHIP-enrolled children who were unvaccinated and received the flu vaccine due to HealthySteps involvement',
              values: ['', formatPerc(report.immunizations_lowEstimate), formatPerc(report.immunizations_highEstimate)],
              displayDefault: true,
              note: report.immunizations_estimatesNote,
            }
          ] 
        },
      ],
    },
    {
      title: 'Interventions for Caregivers',
      subCategories: [
        {
          title: 'Postpartum Maternal Depression',
          visible: interventionsChecklist.postpartumMaternalDepression,
          lines: [
            {
              description: 'Number of Medicaid-enrolled caregivers who discussed postpartum maternal depression within 12 months of delivery with a HS Specialist (either in-person or telephonically)',
              values: [report.postpartumMaternalDepression_discussedProblem, '', ''],
              displayDefault: true,
              note: report.postpartumMaternalDepression_discussedProblemNote,
            },
            {
              description: 'Number of Medicaid-enrolled mothers who received counseling or related services from a HS Specialist directly and/or were referred for additional services for postpartum maternal depression',
              values: [report.postpartumMaternalDepression_receivedCounseling, '', ''],
              displayDefault: true,
              note: report.postpartumMaternalDepression_receivedCounselingNote,
            },
            // {
            //   description: 'Number of mothers that the HS Specialist(s) referred for counseling or other services for postpartum maternal depression',
            //   values: [report.postpartumMaternalDepression_referredForServices, '', ''],
            //   displayDefault: true,
            //   note: report.postpartumMaternalDepression_referredForServicesNote,
            // },
            {
              description: 'Percentage reduction in number of mothers with persistent depression due to HealthySteps',
              values: ['', formatPerc(report.postpartumMaternalDepression_lowEstimate), formatPerc(report.postpartumMaternalDepression_highEstimate)],
              displayDefault: true,
              note: report.postpartumMaternalDepression_estimatesNote,
            }
          ]
        },
        {
          title: 'Healthy Birth Spacing',
          visible: interventionsChecklist.healthyBirthSpacing,
          lines: [
            {
              description: 'Number of mothers who discussed a need for contraception or other family planning services with a HS Specialist and/or were referred for services (e.g., postpartum visit, family planning, contraception, etc.',
              values: [report.healthyBirthSpacing_needForContraception, '', ''],
              displayDefault: true,
              note: report.healthyBirthSpacing_needForContraceptionNote,
            },
            {
              description: 'Percentage of mothers who followed up on referrals for services (e.g., postpartum visit, family planning, contraception, etc.)',
              values: [formatPerc(report.healthyBirthSpacing_followedUpOnReferals), '', ''],
              displayDefault: true,
              note: report.healthyBirthSpacing_followedUpOnReferalsNote,
            },
            
            {
              description: 'Estimated percentage of unintended pregnancies/births averted due to HealthySteps',
              values: ['', formatPerc(report.healthyBirthSpacing_lowEstimate), formatPerc(report.healthyBirthSpacing_highEstimate)],
              displayDefault: true,
              note: report.healthyBirthSpacing_estimatesNote
            },
          ]
        },
        {
          title: 'Intimate Partner Violence',
          visible: interventionsChecklist.intimidatePartnerViolence,
          lines: [
            {
              description: 'Number of caregivers who discussed a concern related to IPV with a HS Specialist',
              values: [report.intimatePartnerViolence_discussedProblem, '', ''],
              displayDefault: true,
              note: report.intimatePartnerViolence_discussedProblemNote,
            },
            {
              description: 'Number of HS mothers enrolled in Medicaid who received counseling or related services from HSS and/or were referred for services',
              values: [report.intimatePartnerViolence_receivedCounseling, '', ''],
              displayDefault: true,
              note: report.intimatePartnerViolence_receivedCounselingNote,
            },
            {
              description: 'Percentage of severe IPV cases with improvement due to HealthySteps',
              values: ['', formatPerc(report.intimatePartnerViolence_lowEstimate), formatPerc(report.intimatePartnerViolence_highEstimate)],
              displayDefault: true,
              note: report.intimatePartnerViolence_estimatesNote,
            },
          ],
        },
        {
          title: 'Breastfeeding',
          visible: interventionsChecklist.breastfeeding,
          lines: [
            {
              description: 'Number of caregivers who discussed a need for breastfeeding advice/assistance with a HS Specialist',
              values: [report.breastfeeding_discussedProblem, '', ''],
              displayDefault: true,
              note: report.breastfeeding_discussedProblemNote,
            },
            {
              description: 'Number of caregivers (of Medicaid/CHIP-enrolled children) received counseling or related services from a HS Specialist and/or were referred for such services',
              values: [report.breastfeeding_receivedCounseling, '', ''],
              displayDefault: true,
              note: report.breastfeeding_receivedCounselingNote,
            },
            {
              description: 'Percentage of caregivers who continued breastfeeding due to HealthySteps',
              values: ['', formatPerc(report.breastfeeding_lowEstimate), formatPerc(report.breastfeeding_highEstimate)],
              displayDefault: true,
              note: report.breastfeeding_estimatesNote,
            },
            {
              description: 'Percentage of caregivers who continued exclusive breastfeeding due to HealthySteps',
              values: ['', formatPerc(report.breastfeeding_lowEstimateExclusive), formatPerc(report.breastfeeding_highEstimateExclusive)],
              displayDefault: true,
              note: report.breastfeeding_estimatesExclusiveNote,
            },
          ],
        },
        {
          title: 'Smoking Cessation',
          visible: interventionsChecklist.smokeCessation,
          lines: [
            {
              description: 'Number of Medicaid-enrolled caregivers who discussed a problem with smoking or any other tobacco product use with a HS Specialist and/or were referred for services',
              values: [report.smokeCessation_discussedProblem, '', ''],
              displayDefault: true,
              note: report.smokeCessation_discussedProblemNote,
            },
            {
              description: 'Percentage of Medicaid-enrolled caregivers who smoke or use tobacco products, and quit due to HealthySteps',
              values: ['', formatPerc(report.smokeCessation_lowEstimate), formatPerc(report.smokeCessation_highEstimate)],
              displayDefault: true,
              note: report.smokeCessation_estimatesNote,
            },
          ]
        },
        {
          title: 'Diapers',
          visible: interventionsChecklist.diapers,
          lines: [
            {
              description: 'Number of caregivers (of Medicaid/CHIP-enrolled children) provided diapers onsite or referred externally to a diaper bank/local non-profit',
              values: [report.diapers_numberCaregiversProvidedDiapersOrReferred, '', ''],
              displayDefault: true,
            },
            {
              description: 'Percentage of Medicaid-enrolled caregivers who smoke or use tobacco products, and quit due to HealthySteps',
              values: ['', formatPerc(report.diapers_lowEstimate), formatPerc(report.diapers_highEstimate)],
              displayDefault: true,
            },
          ]
        },
        {
          title: 'Food Security',
          visible: interventionsChecklist.foodSecurity,
          lines: [
            {
              description: 'Number of Medicaid-enrolled caregivers identified through screening and/or surveillance as food insecure and referred for SNAP, WIC, or other long-term ongoing food support',
              values: [report.foodSecurity_numberOfCaregiversFoodInsecureAndReferred, '', ''],
              displayDefault: true,
              note: report.foodSecurity_numberOfCaregiversFoodInsecureAndReferredNote,
            },
            {
              description: 'Percentage of children with foodSecurity or related symptoms who avoid emergency department visits due to HealthySteps',
              values: ['', formatPerc(report.foodSecurity_lowEstimate), formatPerc(report.foodSecurity_highEstimate)],
              displayDefault: true,
              note: report.foodSecurity_estimatesNote,
            },
          ]
        },
      ]
    },
    {
      title: '',
      subCategories: [
        {
          title: '',
          visible: true,
          isInfoBlock: true,
          lines: [
            {
              description: `**HealthySteps Return on Investment (ROI)** is a measure of cross-sector savings generated by a specific HealthySteps site compared to program costs.

              Results are based on HealthySteps services provided to children and families over a one-year time period and associated costs to operate HealthySteps over the same time period. Savings are estimated for the population served during the one-year timeframe and accrue in both the short-term and long-term (e.g. a child served by HealthySteps may generate reduced medical costs within 1 year and reduced special education costs over 15 years). Savings and operating costs are displayed in current dollars.

              **Short-term ROI** includes savings that accrue over a one-year timeframe.

              **Long-term ROI** includes savings that accrue over a timeframe of one year or longer.

              **A negative ROI** indicates that program costs generated by the HealthySteps site exceed savings generated over the associated timeframe.

              **A positive ROI** indicates that savings generated by the HealthySteps site exceed program costs over the associated timeframe.`,
              values: ['', '', ''],
              displayDefault: true,
            }
          ]
        },
        {
          title: '',
          visible: true,
          lines: [{
            description: '',
            values: ['', '', ''],
            displayDefault: true
          }]
        },
        {
          title: '',
          visible: true,
          isFootnoteBlock: true,
          lines: [
            {
              description: `**NOTE:** HealthySteps Savings and ROI are restricted to quantifiable cost savings only, and do not include total benefits of HS services.

              * Long-term savings reflect those that accrue at least one year after receipt of the given intervention. These savings include a 3% annual discount. Discounting is a method to determine the present value of money received in the future. Dollars are worth more today than in the future, due to value that could be gained through interest if invested or value that would be lost through inflation if not invested. As the majority of savings are within the healthcare sector, we look to the average annual medical CPI (consumer price index) trend from the last ten years (3%) to determine our annual discounting factor. See "medical CPI" tab for more detail.

              ** Long-term ROI includes savings generated in both the short and longer term.`,
              values: ['', '', ''],
              displayDefault: true,
            }
          ]
        }
      ]
    }
  ];
};

export default {
  getCostSavingsReportBreakdown
};