import ServiceData from './serviceData';
import Calculator from './shared/base';

export default class FoodSecurity extends Calculator {
  public serviceData: ServiceData;

  public numberOfCaregiversFoodInsecureAndReferred:number;

  constructor({
    state,
    serviceData,
    lowEstimatedSlider,
    highEstimatedSlider,
    numberOfCaregiversFoodInsecureAndReferred,
  }: {
    state: string;
    serviceData: ServiceData;
    lowEstimatedSlider: number;
    highEstimatedSlider: number;
    numberOfCaregiversFoodInsecureAndReferred:number;
  }) {
    super({ state });
    this.serviceData = serviceData;
    this.lowEstimatedSlider = lowEstimatedSlider / 100;
    this.highEstimatedSlider = highEstimatedSlider / 100;
    this.numberOfCaregiversFoodInsecureAndReferred = numberOfCaregiversFoodInsecureAndReferred;
  }

  /**
   * @name foodSecurity_tab_medical_price_inflation_factor
   * @excel Excel FoodSecurity Tab B4
   * @constant 1.1842001401844
   */
  public readonly foodSecurityTabMedicalPriceInflationFactor = this.constants.foodSecurity
    .foodTabMedicalPriceInflation;

  /**
   * @name food_tab_total_net_savings
   * @excel Excel FoodSecurity Tab B4
   * @constant 1834
   */
  public readonly foodTabTotalNetSavings = this.constants.foodSecurity
    .foodTabTotalNetSavings;

  /**
   * low_estimated_slider (from app)
   * @name low_estimated_slider
   */
  public lowEstimatedSlider;

  /**
   * high_estimated_slider (from app)
   * @name high_estimated_slider
   */
  public highEstimatedSlider;

  /**
   * @name food_tab_estimated_percentage_receiving_food_low
   * @excel Excel E63
   * @calculation New calculation
   */
  public foodTabEstimatedPercentageReceivingFoodLow = () => {
    return this.isFiniteNumber(this.lowEstimatedSlider);
  };

  /**
   * @name food_tab_estimated_percentage_receiving_food_high
   * @excel Excel E64
   * @calculation New calculation
   */
  public foodTabEstimatedPercentageReceivingFoodHigh = () => {
    return this.isFiniteNumber(this.highEstimatedSlider);
  };

  /**
   * @name food_tab_estimated_number_caregivers_receiving_food_low
   * @excel New Calculation
   * @formula number_of_caregivers_food_insecure_and_referred * food_tab_estimated_percentage_receiving_food_low
   */
  public foodTabEstimatedNumberCaregiversReceivingFoodLow = () => {
    return this.isFiniteNumber(
      this.numberOfCaregiversFoodInsecureAndReferred *
        this.foodTabEstimatedPercentageReceivingFoodLow()
    );
  };

  /**
   * @name food_tab_estimated_number_caregivers_receiving_food_high
   * @excel New Calculation
   * @formula number_of_caregivers_food_insecure_and_referred * food_tab_estimated_percentage_receiving_food_high
   */
  public foodTabEstimatedNumberCaregiversReceivingFoodHigh = () => {
    return this.isFiniteNumber(
      this.numberOfCaregiversFoodInsecureAndReferred *
        this.foodTabEstimatedPercentageReceivingFoodHigh()
    );
  };

  /**
   * @name foodTabTotalAdjustedNetSavings
   * @excel New Calculation
   * @formula food_tab_total_net_savings * foodSecurity_tab_medical_price_inflation_factor
   */
  public foodTabTotalAdjustedNetSavings = () => {
    return this.isFiniteNumber(
      this.foodTabTotalNetSavings *
        this.foodSecurityTabMedicalPriceInflationFactor
    );
  };

  /**
   * @name food_tab_total_net_savings_low
   * @excel Excel E67
   * @formula food_tab_total_adjusted_net_savings * food_tab_estimated_number_caregivers_receiving_food_low
   */
  public foodSecurityEstimatedSavingsLow = () => {
    return this.isFiniteNumber(
      this.foodTabTotalAdjustedNetSavings() *
        this.foodTabEstimatedNumberCaregiversReceivingFoodLow()
    );
  };

  /**
  * @name food_tab_total_net_savings_high
  * @excel Excel E67
  * @formula food_tab_total_adjusted_net_savings * food_tab_estimated_number_caregivers_receiving_food_high
  */
  public foodSecurityEstimatedSavingsHigh = () => {
    return this.isFiniteNumber(
      this
        .foodTabTotalAdjustedNetSavings() *
        this.foodTabEstimatedNumberCaregiversReceivingFoodHigh()
    );
  };

  /**
  * @name food_tab_total_net_savings_high
  * @excel Excel E67
  * @formula food_tab_total_adjusted_net_savings * food_tab_estimated_number_caregivers_receiving_food_high
  */
  public foodSecurityEstimatedSavingsAverage = () => {
    return this.isFiniteNumber(
      (this
        .foodSecurityEstimatedSavingsLow() +
        this.foodSecurityEstimatedSavingsHigh())/2
    );
  };

}
