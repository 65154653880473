import React, { FunctionComponent } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import { FormikProps } from 'formik';
import ServiceSectionLayout from '../../../ServiceSectionLayout/ServiceSectionLayout';
import { Tooltip } from '../../../../../components';
import {
  IServiceData,
  ServiceDataForm,
} from '../../../../../interfaces/serviceData.interface';
import ServiceItem from '../../ServiceItem/ServiceItem';
import { Constants } from '../../../../../utils';
import styles from './ChildrenServed.styles';

const testid = 'children-served-section';

const childrenServedOptions = [
  {
    inputFormId: ServiceDataForm.Children0To3,
    noteFormId: ServiceDataForm.Children0To3Note,
    title: (
      <>
        Total children ages 0-3 served (panel size) during the 12-month date range
        <Tooltip
          id={`${testid}__children-ages-0-to-3-tooltip`}
          title="Any child who had at least 1 well-child visit to the practice during the 12-month date range before their 4th birthday."
          placement="right"
        >
          <span></span>
        </Tooltip>
      </>
    ),
    hasChild: true,
  },
  {
    inputFormId: ServiceDataForm.MedicaidChildren0To3,
    noteFormId: ServiceDataForm.MedicaidChildren0To3Note,
    title:
      'Total children with Medicaid ages 0-3 served (panel size) within the 12-month date range',
    childDepth: 1,
    subLabel: (form: FormikProps<IServiceData>) => {
      const {
        [ServiceDataForm.Children0To3]: children0To3,
        [ServiceDataForm.MedicaidChildren0To3]: medicaidChildren0To3
      } = form.values;
      if (children0To3 && medicaidChildren0To3) {
        const children0To3Number = parseInt(children0To3);
        const medicaidChildren0To3Number = parseInt(medicaidChildren0To3);
        if (children0To3Number) {
          return `(${Math.floor((medicaidChildren0To3Number / children0To3Number) * 100)}% of Total)`;
        }
      }
      return '';
    }
  },
  {
    inputFormId: ServiceDataForm.Children0To3Tier2And3,
    noteFormId: ServiceDataForm.Children0To3Tier2And3Note,
    title: (
      <>
        Total children ages 0-3 in Tiers 2 & 3 served by the HS Specialist(s) within the 12-month date range
        <Tooltip
          id={`${testid}__children-ages-0-to-3-tier-2-and-3-tooltip`}
          title="Tier 2 includes children ages 0-3 (and their families) who have mild concerns that were addressed by HS Specialist(s) in 1-3 visits during the 12-month date range. Tier 3 includes children ages 0-3 (and their families) who have received at least one preventive team-based well-child visit that included a HS Specialist (i.e., HS Specialist presence before, during, or after the well-visit) during the 12-month date range."
          placement="right"
        >
          <span></span>
        </Tooltip>
      </>
    ),
    extraDetails: 'This value will be used as the denominator for many of the cost savings interventions.',
    hasChild: true,
    showError: true,
  },
  {
    inputFormId: ServiceDataForm.MedicaidChildren0To3Tier2And3,
    noteFormId: ServiceDataForm.MedicaidChildren0To3Tier2And3Note,
    title:
      'Total children with Medicaid ages 0-3 in Tiers 2 & 3 served by the HS Specialist(s) within the 12-month date range',
    hasChild: true,
    childDepth: 1,
    subLabel: (form: FormikProps<IServiceData>) => {
      const {
        [ServiceDataForm.Children0To3Tier2And3]: children0To3Tier2And3,
        [ServiceDataForm.MedicaidChildren0To3Tier2And3]: medicaidChildren0To3Tier2And3
      } = form.values;
      if (children0To3Tier2And3 && medicaidChildren0To3Tier2And3) {
        const children0To3Tier2And3Number = parseInt(children0To3Tier2And3);
        const medicaidChildren0To3Tier2And3Number = parseInt(medicaidChildren0To3Tier2And3);
        if (children0To3Tier2And3Number) {
          return `(${Math.floor((medicaidChildren0To3Tier2And3Number / children0To3Tier2And3Number) * 100)}% of Tier 2 & 3)`;
        }
      }
      return '';
    }
  },
  {
    inputFormId: ServiceDataForm.MedicaidChildrenUnder1Tier2And3,
    noteFormId: ServiceDataForm.MedicaidChildrenUnder1Tier2And3Note,
    title:
      'Total children with Medicaid under age 1, in Tiers 2 & 3 served by the HS Specialist(s) within the 12-month date range',
    childDepth: 2,
    subLabel: (form: FormikProps<IServiceData>) => {
      const {
        [ServiceDataForm.Children0To3Tier2And3]: children0To3Tier2And3,
        [ServiceDataForm.MedicaidChildrenUnder1Tier2And3]: medicaidChildrenUnder1Tier2And3
      } = form.values;
      if (children0To3Tier2And3 && medicaidChildrenUnder1Tier2And3) {
        const children0To3Tier2And3Number = parseInt(children0To3Tier2And3);
        const medicaidChildrenUnder1Tier2And3Number = parseInt(medicaidChildrenUnder1Tier2And3);
        if (children0To3Tier2And3Number) {
          return `(${Math.floor((medicaidChildrenUnder1Tier2And3Number / children0To3Tier2And3Number) * 100)}% of Tier 2 & 3)`;
        }
      }
      return '';
    }
  },
];

interface IProps extends WithStylesType<typeof styles> {
  form: FormikProps<IServiceData>;
}

const ChildrenServed: FunctionComponent<IProps> = ({ classes, form }) => {
  return (
    <ServiceSectionLayout
      testId={testid}
      title="Children Served"
      customClass={classes.container}
      required
    >
      {childrenServedOptions.map((option, idx) => (
        <ServiceItem
          key={idx}
          inputValue={form.values[option.inputFormId]}
          noteInputValue={form.values[option.noteFormId]}
          errorMessage={form.errors[option.inputFormId]}
          inputType={Constants.INPUT_TYPES.NUMBER}
          isLastItem={idx === childrenServedOptions.length - 1}
          form={form}
          option={option}
        />
      ))}
    </ServiceSectionLayout>
  );
};

export default withStyles(styles)(ChildrenServed);
