import ServiceData from './serviceData';
import Calculator from './shared/base';

export default class HealthBirthSpacing extends Calculator {
  public serviceData: ServiceData;

  public needForContraception: number;

  public followedUpOnReferals: number;

  constructor({
    state,
    serviceData,
    lowEstimatedSlider,
    highEstimatedSlider,
    needForContraception,
    followedUpOnReferals
  }: {
    state: string;
    serviceData: ServiceData;
    lowEstimatedSlider: number;
    highEstimatedSlider: number;
    needForContraception: number;
    followedUpOnReferals: number;
  }) {
    super({ state });
    this.serviceData = serviceData;
    this.lowEstimatedSlider = lowEstimatedSlider / 100;
    this.highEstimatedSlider = highEstimatedSlider / 100;
    this.needForContraception = needForContraception;
    this.followedUpOnReferals = followedUpOnReferals / 100;
  }

  /**
   * @name births_tab_percentage_of_women_not_using_contraception_postpartum
   * @excel Excel Births Tab B2
   * @constant 5% (0.05)
   */
  public readonly birthsTabPercentageOfWomenNotUsingContraceptionPostpartum = this
    .constants.healthBirthSpacing
    .birthsTabPercentageOfWomenNotUsingContraceptionPostpartum;

  /**
   * @name births_tab_percentage_of_women_who_become_pregnant_over_one_year_of_not_using_contraception
   * @excel Excel Births Tab B3
   * @constant 85% (0.85)
   */
  public readonly birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception = this
    .constants.healthBirthSpacing
    .birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception;

  /**
   * @name births_tab_percentage_of_unintended_pregnancies_that_result_in_birth
   * @excel Excel Births Tab B6
   * @constant 38% (0.38)
   */
  public readonly birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth = this
    .constants.healthBirthSpacing
    .birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth;

  /**
   * @name births_tab_cost_per_publicly_funded_birth_short_term
   * @excel Excel Births Tab B7
   * @constant 10006
   */
  public readonly birthsTabCostPerPubliclyFundedBirthShortTerm = this.constants
    .healthBirthSpacing.birthsTabCostPerPubliclyFundedBirthShortTerm;

  /**
   * @name births_tab_cost_per_publicly_funded_birth_long_term
   * @excel Excel Births Tab B7
   * @constant 7536
   */
  public readonly birthsTabCostPerPubliclyFundedBirthLongTerm = this.constants
    .healthBirthSpacing.birthsTabCostPerPubliclyFundedBirthLongTerm;

  /**
   * @name births_tab_medical_price_inflation_factor
   * @excel Excel Births Tab B10
   * @constant 1.28
   */
  public readonly birthsTabMedicalPriceInflationFactor = this.constants
    .healthBirthSpacing.birthsTabMedicalPriceInflationFactor;

  /**
   * @name births_tab_general_price_inflation_factor
   * @excel Excel Births Tab B10
   * @constant 1.28
   */
  public readonly birthsTabGeneralPriceInflationFactor = this.constants
    .healthBirthSpacing.birthsTabGeneralPriceInflationFactor;

  /**
   * @name percentage_of_women_not_using_contraception_postpartum
   * @excel Excel C92
   * @reference births_tab_percentage_of_women_not_using_contraception_postpartum
   */
  public percentageOfWomenNotUsingContraceptionPostpartum = () => {
    return this.isFiniteNumber(
      this.birthsTabPercentageOfWomenNotUsingContraceptionPostpartum
    );
  };

  /**
   * @name births_tab_SNAP_short_term_result
   * @excel New Calculation
   * @formula 2028*60%*47%*birthsTabGeneralPriceInflationFactor
   */
  public birthsTabSNAPShortTermResult = () => {
    return this.isFiniteNumber(
      2028 * 0.60 * 0.47 * this.birthsTabGeneralPriceInflationFactor
    );
  };

  public birthsSNAPDiscountedFutureResults = () => {
    const numerator =
    this.birthsTabSNAPShortTermResult();
    const denominator = Math.pow(1 + 0.03, 4); 
    return this.isFiniteNumber(numerator / denominator);
  }

  /**
   * @name births_tab_HF_short_term_result
   * @excel New Calculation
   * @formula 1031*3%*68%*BirthsGeneralCPI
   */
  public birthsTabHFShortTermResult = () => {
    return this.isFiniteNumber(
      1031 * 0.03 * 0.68 * this.birthsTabGeneralPriceInflationFactor
    );
  };

  /**
   * @name births_tab_HF_long_term_result
   * @excel New Calculation
   * @formula ((birthsTabHFShortTermResult*3)/(1+0.3)^3
   */
  public birthsTabHFLongTermResult = () => {
    const numerator =
    this.birthsTabHFShortTermResult() * 3;
    const denominator = Math.pow(1 + 0.03, 3); 
    return this.isFiniteNumber(numerator / denominator);
  };

  /**
   * @name births_tab_WICm_short_term_result
   * @excel New Calculation
   * @formula 570*100%*90%*BirthsGeneralCPI 
   */
  public birthsTabWICmShortTermResult = () => {
    return this.isFiniteNumber(
      570 * 1 * 0.90 * this.birthsTabGeneralPriceInflationFactor
    );
  };
  
  /**
     * @name births_tab_WICm_long_term_result
     * @excel New Calculation
     * @formula (birthsTabWICmShortTermResult*0.5)/(1+0.3)^0.5
     */
  public birthsTabWICmLongTermResult = () => {
    const numerator =
      this.birthsTabWICmShortTermResult() * 0.5;
    const denominator = Math.pow(1 + 0.03, 0.5); 
    return this.isFiniteNumber(numerator / denominator);
  };

  /**
   * @name births_tab_WICc_short_term_result
   * @excel New Calculation
   * @formula 463*100%*90%*BirthsGeneralCPI
   */
  public birthsTabWICcShortTermResult = () => {
    return this.isFiniteNumber(
      463 * 1 * 0.90 * this.birthsTabGeneralPriceInflationFactor
    );
  };
    
  /**
 * @name births_tab_WICc_long_term_result
 * @excel New Calculation
 * @formula (birthsTabWICcShortTermResult*4)/(1+0.3)^4
 */
  public birthsTabWICcLongTermResult = () => {
    const numerator =
        this.birthsTabWICcShortTermResult() * 4;
    const denominator = Math.pow(1 + 0.03, 4); 
    return this.isFiniteNumber(numerator / denominator);
  };

  /**
   * @name births_tab_WFC_short_term_result
   * @excel New Calculation
   * @formula 17797*100%*1%*BirthsGeneralCPI
   */
  public birthsTabCWFCShortTermResult = () => {
    return this.isFiniteNumber(
      17797 * 1 * 0.01 * this.birthsTabGeneralPriceInflationFactor
    );
  };
      
  /**
   * @name births_tab_WFC_long_term_result
   * @excel New Calculation
   * @formula (birthsTabCWFCShortTermResult*0.5)/(1+0.3)^0.5
   */
  public birthsTabCWFCLongTermResult = () => {
    const numerator =
          this.birthsTabCWFCShortTermResult() * 0.5;
    const denominator = Math.pow(1 + 0.03, 0.5); 
    return this.isFiniteNumber(numerator / denominator);
  };

  /**
   * @name births_tab_EHS_short_term_result
   * @excel New Calculation
   * @formula 8121*86%*3%*BirthsGeneralCPI
   */
  public birthsTabEHSShortTermResult = () => {
    return this.isFiniteNumber(
      8121 * 0.86 * 0.03 * this.birthsTabGeneralPriceInflationFactor
    );
  };
        
  /**
     * @name births_tab_EHS_long_term_result
     * @excel New Calculation
     * @formula (birthsTabEHSShortTermResult)/(1+0.3)^2
     */
  public birthsTabCEHSLongTermResult = () => {
    const numerator =
            this.birthsTabEHSShortTermResult();
    const denominator = Math.pow(1 + 0.03, 2); 
    return this.isFiniteNumber(numerator / denominator);
  };

  /**
   * @name births_tab_HS3_short_term_result
   * @excel New Calculation
   * @formula (8121*86%*32%*BirthsGeneralCPI)/(1+0.3)^1
   */
  public birthsTabHS3LongTermResult = () => {
    const numerator =
    8121 * 0.86 * 0.32 * this.birthsTabGeneralPriceInflationFactor;
    const denominator = Math.pow(1 + 0.03, 1); 
    return this.isFiniteNumber(numerator / denominator);
  };

  /**
   * @name births_t
   * b_HS4_short_term_result
   * @excel New Calculation
   * @formula (8121*86%*84%*BirthsGeneralCPI)/(1+0.3)^1
   */
  public birthsTabHS4LongTermResult = () => {
    const numerator =
      8121 * 0.86 * 0.84 * this.birthsTabGeneralPriceInflationFactor;
    const denominator = Math.pow(1 + 0.03, 1); 
    return this.isFiniteNumber(numerator / denominator);
  };
    
  /**
   * @name births_tab_SSI_short_term_result
   * @excel New Calculation
   * @formula 6899*100%*0.9%*BirthsGeneralCPI
   */
  public birthsTabSSIShortTermResult = () => {
    return this.isFiniteNumber(
      6899 * 1 * 0.009 * this.birthsTabGeneralPriceInflationFactor
    );
  };
          
  /**
   * @name births_tab_SSI_long_term_result
   * @excel New Calculation
   * @formula (birthsTabEHSShortTermResult)/(1+0.3)^2
   */
  public birthsTabSSILongTermResult = () => {
    const numerator =
              this.birthsTabSSIShortTermResult() * 4;
    const denominator = Math.pow(1 + 0.03, 4); 
    return this.isFiniteNumber(numerator / denominator);
  };

  /**
   * @name births_tab_total_short_term_public_assistance_savings
   * @excel New Calculation
   * @formula birthsTabSNAPShortTermResult+birthsTabHFShortTermResult+birthsTabWICmShortTermResult+
   * birthsTabWICcShortTermResult+birthsTabCWFCShortTermResult+birthsTabEHSShortTermResult+birthsTabSSIShortTermResult
   */
  public birthsTabTotalShortTermPublicAssistanceSavings = () => {
    return this.isFiniteNumber(this.birthsTabSNAPShortTermResult()+
      this.birthsTabHFShortTermResult() + this.birthsTabWICmShortTermResult() + 
      this.birthsTabWICcShortTermResult()+ this.birthsTabCWFCShortTermResult()+ 
      this.birthsTabEHSShortTermResult()+ this.birthsTabSSIShortTermResult()
    );
  };

  public birthsTabTotalShortTermSavings = () => {
    return this.isFiniteNumber(this.birthsTabSNAPShortTermResult()+
      this.birthsTabHFShortTermResult() + this.birthsTabWICmShortTermResult() + 
      this.birthsTabWICcShortTermResult()+ this.birthsTabCWFCShortTermResult()+ 
      this.birthsTabEHSShortTermResult()+ this.birthsTabSSIShortTermResult() + this.savingsPerUnintendedPregnancyBirthAvertedMaternityThroughMonth60()
    );
  };

  /**
   * @name births_tab_total_combined_short_term_savings
   * @excel New Calculation
   * @formula birthsTabTotalShortTermPublicAssistanceSavings+(birthsTabCostPerPubliclyFundedBirthShortTerm*birthsTabMedicalPriceInflationFactor)
   */
  public birthsTabTotalCombinedShortTermSavings = () => {
    return this.isFiniteNumber(this.birthsTabTotalShortTermPublicAssistanceSavings()+
    (this.birthsTabCostPerPubliclyFundedBirthShortTerm * this.birthsTabMedicalPriceInflationFactor)
    );
  };

  /**
   * @name births_tab_total_long_term_public_assistance_savings
   * @excel New Calculation
   * @formula birthsTabSNAPLongTermResult+birthsTabHFLongTermResult+birthsTabWICmLongTermResult+
   * birthsTabWICcLongTermResult+birthsTabCWFCLongTermResult+birthsTabEHSLongTermResult+birthsTabHS3LongTermResult+birthsTabHS4LongTermResult+SSILongTermResult
   */
  public birthsTabTotalLongTermPublicAssistanceSavings = () => {
    return this.isFiniteNumber(this.birthsTabSNAPShortTermResult() + 
    this.birthsTabHFLongTermResult() + this.birthsTabWICmLongTermResult()+ 
    this.birthsTabWICcLongTermResult() + this.birthsTabCWFCLongTermResult()+ this.birthsTabCEHSLongTermResult()+
    this.birthsTabHS3LongTermResult() + this.birthsTabHS4LongTermResult() + this.birthsTabSSILongTermResult()
    );
  };

  /**
   * @name births_tab_total_combined_long_term_savings
   * @excel New Calculation
   * @formula birthsTabTotalLongTermPublicAssistanceSavings+(birthsTabCostPerPubliclyFundedBirthLongTerm*birthsTabMedicalPriceInflationFactor)
   */
  public birthsTabTotalCombinedLongTermSavings = () => {
    const numerator =
    this.birthsTabCostPerPubliclyFundedBirthLongTerm * this.birthsTabMedicalPriceInflationFactor;
    const denominator = Math.pow(1 + 0.03, 5); 
    return this.isFiniteNumber(this.birthsTabTotalLongTermPublicAssistanceSavings() + (numerator / denominator));
  };
 
  
  /**
   * @name estimated_number_of_HS_Medicaid_mothers_not_using_contraception_postpartum
   * @excel Excel C93
   * @formula percentage_of_women_not_using_contraception_postpartum * cs_input_4 (from Service Data Screen)
   */
  public estimatedNumberOfHSMedicaidMothersNotUsingContraceptionPostpartum = () => {
    return this.isFiniteNumber(
      this.percentageOfWomenNotUsingContraceptionPostpartum() *
        this.needForContraception
    );
  };

  /**
   * @name percentage_of_women_becoming_pregnant_during_one_year_of_not_using_contraception
   * @excel Excel C94
   * @reference births_tab_percentage_of_women_who_become_pregnant_over_one_year_of_not_using_contraception
   */
  public percentageOfWomenBecomingPregnantDuringOneYearOfNotUsingContraception = () => {
    return this.isFiniteNumber(
      this
        .birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception
    );
  };

  /**
   * @name estimated_number_of_HS_Medicaid_mothers_not_using_contraception_and_becoming_pregnant
   * @excel Excel C95
   * @formula percentage_of_women_becoming_pregnant_during_one_year_of_not_using_contraception * estimated_number_of_HS_Medicaid_mothers_not_using_contraception_postpartum
   */
  public estimatedNumberOfHSMedicaidMothersNotUsingContraceptionAndBecomingPregnant = () => {
    return this.isFiniteNumber(
      this.percentageOfWomenBecomingPregnantDuringOneYearOfNotUsingContraception() *
        this.estimatedNumberOfHSMedicaidMothersNotUsingContraceptionPostpartum()
    );
  };

  /**
   * @name percentage_of_unintended_pregnancies_that_result_in_birth
   * @excel Excel C96
   * @reference births_tab_percentage_of_unintended_pregnancies_that_result_in_birth
   */
  public percentageOfUnintendedPregnanciesThatResultInBirth = () => {
    return this.isFiniteNumber(
      this.birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth
    );
  };

  /**
   * @name estimated_number_of_HS_Medicaid_mothers_not_using_contraception_becoming_pregnant_and_giving_birth
   * @excel Excel C97
   * @formula estimated_number_of_HS_Medicaid_mothers_not_using_contraception_and_becoming_pregnant * percentage_of_unintended_pregnancies_that_result_in_birth
   */
  public estimatedNumberOfHSMedicaidMothersNotUsingContraceptionBecomingPregnantAndGivingBirth = () => {
    return this.isFiniteNumber(
      this.estimatedNumberOfHSMedicaidMothersNotUsingContraceptionAndBecomingPregnant() *
        this.percentageOfUnintendedPregnanciesThatResultInBirth()
    );
  };

  /**
   * (App) Estimated percentage of unintended pregnancies/birth averted due to healthySteps - Low Estimate
   * @name low_estimated_slider
   * @excel Excel C98
   */
  public lowEstimatedSlider;

  /**
   * (App) Estimated percentage of unintended pregnancies/birth averted due to healthySteps - High Estimate
   * @name high_estimated_slider
   * @excel Excel C99
   */
  public highEstimatedSlider;

  /**
   * @name estimated_number_of_unintended_pregnancies_births_averted_due_to_HS_low
   * @excel Excel C100
   * @formula low_estimated_slider * estimated_number_of_HS_Medicaid_mothers_not_using_contraception_becoming_pregnant_and_giving_birth
   */
  public estimatedNumberOfUnintendedPregnanciesBirthsAvertedDueToHSLow = () => {
    return this.isFiniteNumber( 0.85 * this.birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth *
      this.needForContraception * this.followedUpOnReferals *  this.lowEstimatedSlider
    );
  };

  /**
   * @name estimated_number_of_unintended_pregnancies_births_averted_due_to_HS_high
   * @excel Excel C101
   * @formula high_estimated_slider * estimated_number_of_HS_Medicaid_mothers_not_using_contraception_becoming_pregnant_and_giving_birth
   */
  public estimatedNumberOfUnintendedPregnanciesBirthsAvertedDueToHSHigh = () => {
    return this.isFiniteNumber(  0.85 * this.birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth *
      this.needForContraception * this.followedUpOnReferals *  this.highEstimatedSlider
    );
  };

  /**
   * @name births_tab_total_short_term_health_care_savings_low
   * @excel Excel C101
   * @formula estimatedNumberOfUnintendedPregnanciesBirthsAvertedDueToHSLow*(TotalShortTermPublicAssistanceSavings+(birthsTabCostPerPubliclyFundedBirthShortTerm*birthsTabMedicalPriceInflationFactor))
   */
  public birthsTabTotalShortTermHealthcareSavingsLow = () => {
    return this.isFiniteNumber( this.estimatedNumberOfUnintendedPregnanciesBirthsAvertedDueToHSLow() *
    (this.birthsTabTotalShortTermPublicAssistanceSavings() +(this.birthsTabCostPerPubliclyFundedBirthShortTerm * this.birthsTabMedicalPriceInflationFactor))
    );
  };

  /**
   * @name births_tab_total_short_term_health_care_savings_high
   * @excel Excel C101
   * @formula estimatedNumberOfUnintendedPregnanciesBirthsAvertedDueToHSHigh*(TotalShortTermPublicAssistanceSavings+(birthsTabCostPerPubliclyFundedBirthShortTerm*birthsTabMedicalPriceInflationFactor))
   */
  public birthsTabTotalShortTermHealthcareSavingsHigh = () => {
    return this.isFiniteNumber( this.estimatedNumberOfUnintendedPregnanciesBirthsAvertedDueToHSHigh() *
    (this.birthsTabTotalLongTermPublicAssistanceSavings() + (this.birthsTabCostPerPubliclyFundedBirthShortTerm * this.birthsTabMedicalPriceInflationFactor))
    );
  };

  /**
   * @name births_tab_total_long_term_health_care_savings_low
   * @excel Excel C101
   * @formula estimatedNumberOfUnintendedPregnanciesBirthsAvertedDueToHSLow*(TotalLongTermPublicAssistanceSavings+(birthsTabCostPerPubliclyFundedBirthLongTerm*birthsTabMedicalPriceInflationFactor))
   */
  public birthsTabTotalLongTermHealthcareSavingsLow = () => {
    return this.isFiniteNumber( this.estimatedNumberOfUnintendedPregnanciesBirthsAvertedDueToHSLow() *
    (this.birthsTabTotalLongTermPublicAssistanceSavings() +(this.birthsTabCostPerPubliclyFundedBirthLongTerm * this.birthsTabMedicalPriceInflationFactor))
    );
  };

  /**
   * @name births_tab_total_long_term_health_care_savings_low
   * @excel Excel C101
   * @formula estimatedNumberOfUnintendedPregnanciesBirthsAvertedDueToHSHigh*(TotalShortTermPublicAssistanceSavings+(birthsTabCostPerPubliclyFundedBirthShortTerm*birthsTabMedicalPriceInflationFactor))
   */
  public birthsTabTotalLongTermHealthcareSavingsHigh = () => {
    return this.isFiniteNumber( this.estimatedNumberOfUnintendedPregnanciesBirthsAvertedDueToHSHigh() *
    (this.birthsTabTotalLongTermPublicAssistanceSavings() + (this.birthsTabCostPerPubliclyFundedBirthShortTerm * this.birthsTabMedicalPriceInflationFactor))
    );
  };

  /**
   * @name savings_per_unintended_pregnancy_birth_averted_maternity_through_month_60
   * @excel Excel C102
   * @formula births_tab_cost_per_publicly_funded_birth_in_MA * births_tab_medical_price_inflation_factor
   */
  public savingsPerUnintendedPregnancyBirthAvertedMaternityThroughMonth60 = () => {
    return this.isFiniteNumber(
      this.birthsTabCostPerPubliclyFundedBirthShortTerm *
        this.birthsTabMedicalPriceInflationFactor
    );
  };

  /**
   * @name health_birth_spacing_estimated_savings_low
   * @excel Excel E89
   * @formula birthsTabTotalCombinedShortTermSavings*estimatedNumberOfUnintendedPregnanciesBirthsAvertedDueToHSLow
   */
  public healthBirthSpacingEstimatedSavingsLow = () => {
    return this.isFiniteNumber(
      this.birthsTabTotalCombinedShortTermSavings() * this.estimatedNumberOfUnintendedPregnanciesBirthsAvertedDueToHSLow()
    );
  };

  /**
   * @name health_birth_spacing_estimated_savings_high
   * @excel Excel F89
   * @formula birthsTabTotalCombinedShortTermSavings*estimatedNumberOfUnintendedPregnanciesBirthsAvertedDueToHSHigh
   */
  public healthBirthSpacingEstimatedSavingsHigh = () => {
    return this.isFiniteNumber(
      this.birthsTabTotalCombinedShortTermSavings() * this.estimatedNumberOfUnintendedPregnanciesBirthsAvertedDueToHSHigh()
    );
  };

  /**
   * @name health_birth_spacing_estimated_savings_low
   * @excel Excel E89
   * @formula birthsTabTotalCombinedLongTermSavings*estimatedNumberOfUnintendedPregnanciesBirthsAvertedDueToHSLow
   */
  public healthBirthSpacingLongTermEstimatedSavingsLow = () => {
    return this.isFiniteNumber(
      this.birthsTabTotalCombinedLongTermSavings() * this.estimatedNumberOfUnintendedPregnanciesBirthsAvertedDueToHSLow()
    );
  };
  
  /**
     * @name health_birth_spacing_estimated_savings_high
     * @excel Excel F89
     * @formula birthsTabTotalCombinedLongTermSavings*estimatedNumberOfUnintendedPregnanciesBirthsAvertedDueToHSHigh
     */
  public healthBirthSpacingLongTermEstimatedSavingsHigh = () => {
    return this.isFiniteNumber(
      this.birthsTabTotalCombinedLongTermSavings()* this.estimatedNumberOfUnintendedPregnanciesBirthsAvertedDueToHSHigh()
    );
  };

  /**
   * @name health_birth_spacing_estimated_savings_low
   * @excel Excel E89
   * @formula birthsTabTotalCombinedLongTermSavings*estimatedNumberOfUnintendedPregnanciesBirthsAvertedDueToHSLow
   */
  public healthBirthSpacingTotalTermEstimatedSavingsLow = () => {
    return this.isFiniteNumber(
      this.healthBirthSpacingEstimatedSavingsLow() + this.healthBirthSpacingLongTermEstimatedSavingsLow()
    );
  };
    
  /**
       * @name health_birth_spacing_estimated_savings_high
       * @excel Excel F89
       * @formula birthsTabTotalCombinedLongTermSavings*estimatedNumberOfUnintendedPregnanciesBirthsAvertedDueToHSHigh
       */
  public healthBirthSpacingTotalTermEstimatedSavingsHigh = () => {
    return this.isFiniteNumber(
      this.healthBirthSpacingEstimatedSavingsHigh() + this.healthBirthSpacingLongTermEstimatedSavingsHigh()
    );
  };

  /**
       * @name health_birth_spacing_estimated_savings_high
       * @excel Excel F89
       * @formula birthsTabTotalCombinedLongTermSavings*estimatedNumberOfUnintendedPregnanciesBirthsAvertedDueToHSHigh
       */
  public healthBirthSpacingTotalTermEstimatedSavingsAverage = () => {
    return this.isFiniteNumber(
      (this.healthBirthSpacingTotalTermEstimatedSavingsLow() + this.healthBirthSpacingTotalTermEstimatedSavingsHigh())/2
    );
  };

  /**
       * @name health_birth_spacing_estimated_savings_high
       * @excel Excel F89
       * @formula birthsTabTotalCombinedLongTermSavings*estimatedNumberOfUnintendedPregnanciesBirthsAvertedDueToHSHigh
       */
  public healthBirthSpacingShortTermEstimatedSavingsAverage = () => {
    return this.isFiniteNumber(
      (this.healthBirthSpacingEstimatedSavingsLow() + this.healthBirthSpacingEstimatedSavingsHigh())/2
    );
  };

  /**
       * @name health_birth_spacing_estimated_savings_high
       * @excel Excel F89
       * @formula birthsTabTotalCombinedLongTermSavings*estimatedNumberOfUnintendedPregnanciesBirthsAvertedDueToHSHigh
       */
  public healthBirthSpacingLongTermEstimatedSavingsAverage = () => {
    return this.isFiniteNumber(
      (this.healthBirthSpacingLongTermEstimatedSavingsLow() + this.healthBirthSpacingLongTermEstimatedSavingsHigh())/2
    );
  };

  /**
       * @name health_birth_spacing_estimated_savings_high
       * @excel Excel F89
       * @formula birthsTabTotalCombinedLongTermSavings*estimatedNumberOfUnintendedPregnanciesBirthsAvertedDueToHSHigh
       */
  public healthBirthSpacingShortTermMedicaidSavings = () => {
    return this.isFiniteNumber(
      (this.savingsPerUnintendedPregnancyBirthAvertedMaternityThroughMonth60() / 
      ((this.birthsTabCostPerPubliclyFundedBirthShortTerm * this.birthsTabMedicalPriceInflationFactor)
      + this.birthsTabTotalShortTermPublicAssistanceSavings()) )
    );
  };

    /**
       * @name health_birth_spacing_estimated_savings_high
       * @excel Excel F89
       * @formula birthsTabTotalCombinedLongTermSavings*estimatedNumberOfUnintendedPregnanciesBirthsAvertedDueToHSHigh
       */
    public healthBirthSpacingLongTermMedicaidSavingsLow = () => {
      return this.isFiniteNumber(
        (this.healthBirthSpacingEstimatedSavingsLow() * 
         this.birthsTabTotalCombinedLongTermSavings() / (this.birthsTabTotalCombinedLongTermSavings() + this.birthsTabTotalLongTermPublicAssistanceSavings())       
        )
      );
    };

    /**
       * @name health_birth_spacing_estimated_savings_high
       * @excel Excel F89
       * @formula birthsTabTotalCombinedLongTermSavings*estimatedNumberOfUnintendedPregnanciesBirthsAvertedDueToHSHigh
       */
    public healthBirthSpacingLongTermMedicaidSavingsHigh = () => {
      return this.isFiniteNumber(
        (this.healthBirthSpacingEstimatedSavingsHigh() * 
         this.birthsTabTotalCombinedLongTermSavings() / (this.birthsTabTotalCombinedLongTermSavings() + this.birthsTabTotalLongTermPublicAssistanceSavings())       
        )
      );
    };

    public healthBirthSpacingHealthcarMedicaid = () => {
      return this.isFiniteNumber(
        this.healthBirthSpacingTotalTermEstimatedSavingsAverage() * ((
          this.birthsTabCostPerPubliclyFundedBirthShortTerm* this.birthsTabMedicalPriceInflationFactor) /
          (this.birthsTabTotalCombinedShortTermSavings()+this.birthsTabTotalCombinedLongTermSavings())+
          (((this.birthsTabCostPerPubliclyFundedBirthLongTerm * this.birthsTabMedicalPriceInflationFactor)/(1+0.03)^5) / 
          (this.birthsTabTotalCombinedShortTermSavings()+ this.birthsTabTotalCombinedLongTermSavings())))
      );
    };

    public birthsTabTotalLongTermSavings = () => {
      return this.isFiniteNumber(
        this.birthsSNAPDiscountedFutureResults() + this.birthsTabHFLongTermResult() 
        + this.birthsTabWICcLongTermResult() + this.birthsTabWICmLongTermResult()
        + this.birthsTabCWFCLongTermResult() + this.birthsTabCEHSLongTermResult()
        + this.birthsTabHS3LongTermResult() + this.birthsTabHS4LongTermResult()
        + this.birthsTabSSILongTermResult() + this.birthsTabTotalCombinedLongTermSavings()
      )
    }

    public birthsTabTotalTermSavings = () => {
      return this.isFiniteNumber(
        this.birthsTabTotalShortTermSavings() + this.birthsTabTotalLongTermSavings()
      )
    }

    public birthsSNAPSavingsAverage = () => {
      console.log(this.birthsSNAPDiscountedFutureResults(),this.birthsTabTotalTermSavings())
      return this.isFiniteNumber(
       (this.birthsTabSNAPShortTermResult() + this.birthsSNAPDiscountedFutureResults())/ this.birthsTabTotalTermSavings()
      )
    }

    public birthsHFSavingsAverage = () => {
      return this.isFiniteNumber(
       (this.birthsTabHFShortTermResult() + this.birthsTabHFLongTermResult())/ this.birthsTabTotalTermSavings()
      )
    }

    public birthsWICcSavingsAverage = () => {
      return this.isFiniteNumber(
       (this.birthsTabWICcShortTermResult() + this.birthsTabWICcLongTermResult())/ this.birthsTabTotalTermSavings()
      )
    }

    public birthsWICmSavingsAverage = () => {
      return this.isFiniteNumber(
       (this.birthsTabWICmShortTermResult() + this.birthsTabWICmLongTermResult())/ this.birthsTabTotalTermSavings()
      )
    }

    public birthsCWFCSavingsAverage = () => {
      return this.isFiniteNumber(
       (this.birthsTabCWFCShortTermResult() + this.birthsTabCWFCLongTermResult())/ this.birthsTabTotalTermSavings()
      )
    }

    public birthsEHSSavingsAverage = () => {
      return this.isFiniteNumber(
       (this.birthsTabEHSShortTermResult() + this.birthsTabCEHSLongTermResult())/ this.birthsTabTotalTermSavings()
      )
    }

    public birthsHS3SavingsAverage = () => {
      return this.isFiniteNumber(
       (0 + this.birthsTabHS3LongTermResult())/ this.birthsTabTotalTermSavings()
      )
    }

    public birthsHS4SavingsAverage = () => {
      return this.isFiniteNumber(
       (0 + this.birthsTabHS4LongTermResult())/ this.birthsTabTotalTermSavings()
      )
    }

    public birthsSSISavingsAverage = () => {
      return this.isFiniteNumber(
       (this.birthsTabSSIShortTermResult() + this.birthsTabSSILongTermResult())/ this.birthsTabTotalTermSavings()
      )
    }

    public birthsPublicAssistanceSavings = () => {
      return this.isFiniteNumber(
        this.healthBirthSpacingTotalTermEstimatedSavingsAverage() * (
          this.birthsSNAPSavingsAverage() + this.birthsWICmSavingsAverage()
          + this.birthsWICmSavingsAverage() + this.birthsSSISavingsAverage()
        )
      )
    }

    public birthsHeadStartSavings = () => {
      return this.isFiniteNumber(
        this.healthBirthSpacingTotalTermEstimatedSavingsAverage() * (
          this.birthsEHSSavingsAverage() + this.birthsHS3SavingsAverage()
          + this.birthsHS4SavingsAverage()
        )
      )
    }

    public birthsChildWelfareSavings = () => {
      return this.isFiniteNumber(
        this.healthBirthSpacingTotalTermEstimatedSavingsAverage() * (
          this.birthsCWFCSavingsAverage() + this.birthsHFSavingsAverage()
        )
      )
    }

    public birthsShortTermAverage = () => {
      return this.isFiniteNumber( (this.birthsTabCostPerPubliclyFundedBirthShortTerm * this.birthsTabMedicalPriceInflationFactor)/ this.birthsTabTotalTermSavings())
    }

    public birthsLongTermAverage = () => {
      return this.isFiniteNumber( (this.birthsTabCostPerPubliclyFundedBirthLongTerm * this.birthsTabMedicalPriceInflationFactor)/ this.birthsTabTotalTermSavings())
    }

    public birthsHealthcareSavings = () => {
      return this.isFiniteNumber(
        this.healthBirthSpacingTotalTermEstimatedSavingsAverage() * (
          this.birthsShortTermAverage() + this.birthsLongTermAverage()
        )
      )
    }


}