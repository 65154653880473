const REGEX = {
  Password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{8,}/,
  reportNameField: /^[a-z0-9\s]+$/i
};

const USER_ROLES: { [key: string]: string } = {
  admin: 'admin',
  user: 'user',
  testUser: 'test user'
};

const REPORT = {
  URL_PARAM: ':reportName',
  NEW: 'new',
};

const ACCOUNT_STATUS = {
  active: 'Active',
  pending: 'Pending Request',
  inactive: 'Inactive',
};

const COGNITO_STATUS = {
  FORCE_CHANGE_PASSWORD: 'FORCE_CHANGE_PASSWORD',
  CONFIRMED: 'CONFIRMED'
};

const COGNITO_CHALLENGE = {
  newPasswordRequired: 'NEW_PASSWORD_REQUIRED',
};

const INPUT_TYPES = {
  PASSWORD: 'password',
  CURRENCY: 'currency',
  NUMBER: 'number',
  TEXTAREA: 'textarea',
  TEXT: 'text',
  PERCENTAGE: 'percentage',
  NUMBER_WITH_ADORNMENT: 'number_with_adornment',
};

const REPORT_STATUS = {
  FINISHED: 'finished',
  PENDING: 'pending',
  SUBMITTED: 'submitted'
};

const FORM_ERRORS = {
  REQUIRED_FIELD_ERROR: 'Field is required',
};

const ROI_CALCULATION_OPTION = {
  multiple_sites_region: 'multiple sites within a region or state',
};

const ROI_INTERMEDIARY_TOOLTIP = {
  intermediary_cost: 'Intermediary cost (if applicable)',
};

const ROI_POSTPARTUM_TOOLTIP = {
  postpartum: 'Percentage reduction in number of mothers with persistent depression due to HealthySteps',
};

const INTIMATE_PARTNER_VIOLENCE_TOOLTIP = {
  intimatePartner: 'Percentage of severe IPV cases with improvement due to HealthySteps',
};

const HEALTHY_BIRTH_SPACING_TOOLTIP = {
  unintended: 'Estimated percentage of unintended pregnancies/births averted due to HealthySteps',
};

const REPORT_STATUSES = {
  [REPORT_STATUS.FINISHED]: {
    dateText: 'Completed on',
    status: 'Finished',
  },
  [REPORT_STATUS.PENDING]: {
    dateText: 'Last edited on',
    status: 'In progress',
  },
  [REPORT_STATUS.SUBMITTED]: {
    dateText: 'Submitted on',
    status: 'Under review',
  },
};

const US_STATES = [
  {
    value: 'Alabama',
    id: 'AL',
  },
  {
    value: 'Alaska',
    id: 'AK',
  },
  // {
  //   value: 'American Samoa',
  //   id: 'AS',
  // },
  {
    value: 'Arizona',
    id: 'AZ',
  },
  {
    value: 'Arkansas',
    id: 'AR',
  },
  {
    value: 'California',
    id: 'CA',
  },
  {
    value: 'Colorado',
    id: 'CO',
  },
  {
    value: 'Connecticut',
    id: 'CT',
  },
  {
    value: 'Delaware',
    id: 'DE',
  },
  {
    value: 'District Of Columbia',
    id: 'DC',
  },
  // {
  //   value: 'Federated States Of Micronesia',
  //   id: 'FM',
  // },
  {
    value: 'Florida',
    id: 'FL',
  },
  {
    value: 'Georgia',
    id: 'GA',
  },
  // {
  //   value: 'Guam',
  //   id: 'GU',
  // },
  {
    value: 'Hawaii',
    id: 'HI',
  },
  {
    value: 'Idaho',
    id: 'ID',
  },
  {
    value: 'Illinois',
    id: 'IL',
  },
  {
    value: 'Indiana',
    id: 'IN',
  },
  {
    value: 'Iowa',
    id: 'IA',
  },
  {
    value: 'Kansas',
    id: 'KS',
  },
  {
    value: 'Kentucky',
    id: 'KY',
  },
  {
    value: 'Louisiana',
    id: 'LA',
  },
  {
    value: 'Maine',
    id: 'ME',
  },
  // {
  //   value: 'Marshall Islands',
  //   id: 'MH',
  // },
  {
    value: 'Maryland',
    id: 'MD',
  },
  {
    value: 'Massachusetts',
    id: 'MA',
  },
  {
    value: 'Michigan',
    id: 'MI',
  },
  {
    value: 'Minnesota',
    id: 'MN',
  },
  {
    value: 'Mississippi',
    id: 'MS',
  },
  {
    value: 'Missouri',
    id: 'MO',
  },
  {
    value: 'Montana',
    id: 'MT',
  },
  {
    value: 'Nebraska',
    id: 'NE',
  },
  {
    value: 'Nevada',
    id: 'NV',
  },
  {
    value: 'New Hampshire',
    id: 'NH',
  },
  {
    value: 'New Jersey',
    id: 'NJ',
  },
  {
    value: 'New Mexico',
    id: 'NM',
  },
  {
    value: 'New York',
    id: 'NY',
  },
  {
    value: 'North Carolina',
    id: 'NC',
  },
  {
    value: 'North Dakota',
    id: 'ND',
  },
  // {
  //   value: 'Northern Mariana Islands',
  //   id: 'MP',
  // },
  {
    value: 'Ohio',
    id: 'OH',
  },
  {
    value: 'Oklahoma',
    id: 'OK',
  },
  {
    value: 'Oregon',
    id: 'OR',
  },
  // {
  //   value: 'Palau',
  //   id: 'PW',
  // },
  {
    value: 'Pennsylvania',
    id: 'PA',
  },
  {
    value: 'Puerto Rico',
    id: 'PR',
  },
  {
    value: 'Rhode Island',
    id: 'RI',
  },
  {
    value: 'South Carolina',
    id: 'SC',
  },
  {
    value: 'South Dakota',
    id: 'SD',
  },
  {
    value: 'Tennessee',
    id: 'TN',
  },
  {
    value: 'Texas',
    id: 'TX',
  },
  {
    value: 'Utah',
    id: 'UT',
  },
  {
    value: 'Vermont',
    id: 'VT',
  },
  // {
  //   value: 'Virgin Islands',
  //   id: 'VI',
  // },
  {
    value: 'Virginia',
    id: 'VA',
  },
  {
    value: 'Washington',
    id: 'WA',
  },
  {
    value: 'West Virginia',
    id: 'WV',
  },
  {
    value: 'Wisconsin',
    id: 'WI',
  },
  {
    value: 'Wyoming',
    id: 'WY',
  },
];

const STRING_TYPES = {
  MONEY: 'money',
  PENCENTAGE: 'percentage',
  EMPTY: '--',
  NUMBER_WITH_COMMAS: 'numberWithCommas'
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum DIALOG_MODES {
  ADD = 'add',
  EDIT = 'edit',
  DELETE = 'delete',
};

const NOTE_OPTIONS: {
  [key: string]: {
    title: string;
    submitBtnText?: string;
  };
} = {
  [DIALOG_MODES.ADD]: {
    title: 'Add Note',
  },
  [DIALOG_MODES.EDIT]: {
    title: 'Edit Note',
  },
  [DIALOG_MODES.DELETE]: {
    title: 'Delete Note',
    submitBtnText: 'Delete',
  },
};

const HIGH_LOW_ESTIMATES_TOOLTIP = `That is, the child/family would not have experienced the improvement if they had not been in contact
                                    with the HealthySteps Specialist. Do not consider children/families in the practice who did not have
                                    at least one contact with a HealthySteps Specialist during the 12-month period.`;

const HEALTHY_BIRTH_SPACING_HIGH_LOW_TOOLTIP = `That is, the caregiver would not have pursued postpartum care or family planning services if
                                                they had not been in contact with the HealthySteps Specialist. Do not consider children/families
                                                in the practice who did not have at least one contact with a HealthySteps Specialist during the
                                                12-month period.`;

export default {
  REGEX,
  USER_ROLES,
  ACCOUNT_STATUS,
  COGNITO_CHALLENGE,
  US_STATES,
  INPUT_TYPES,
  REPORT_STATUS,
  REPORT_STATUSES,
  FORM_ERRORS,
  REPORT,
  COGNITO_STATUS,
  STRING_TYPES,
  ROI_CALCULATION_OPTION,
  ROI_INTERMEDIARY_TOOLTIP,
  ROI_POSTPARTUM_TOOLTIP,
  INTIMATE_PARTNER_VIOLENCE_TOOLTIP,
  DIALOG_MODES,
  NOTE_OPTIONS,
  HIGH_LOW_ESTIMATES_TOOLTIP,
  HEALTHY_BIRTH_SPACING_TOOLTIP,
  HEALTHY_BIRTH_SPACING_HIGH_LOW_TOOLTIP
};
