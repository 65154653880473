/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable react/no-array-index-key */
import React, { FunctionComponent } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import { Typography, Grid, Divider } from '@material-ui/core';
import { StringFormatter } from '../../../../utils';
import { ISummaryReport } from '../../../../interfaces/calculator.interface';
import hsLogo from '../../../../assets/icons/hs-logo-color.svg';
import styles from './ReportHeader.styles';

// Modified interface to make titleText and printClass optional
interface IProps extends WithStylesType<typeof styles> {
  report: ISummaryReport;
  titleText?: string| undefined;
  printClass?: any;
}

const ReportHeader: FunctionComponent<IProps> = ({ classes, report, titleText, printClass = '' }) => {
  const getSavingsPerDollar = () => 
    StringFormatter.money((report.shortTermROIMedicaidAverage + 1).toString());

  const getLongTermSavingsPerDollar = () => 
    StringFormatter.money((report.longTermROIAverage + 1).toString());

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} sm={4}>
        <div className={classes.leftHalf}>
          <img className={classes.hsLogo} src={hsLogo} alt="HealthySteps Logo" />
        </div>
      </Grid>
      <Grid item xs={12} sm={8}>
        <div className={classes.rightHalf}>
          <Typography variant="h1" className={`${classes.contentTitle} ${printClass}`}>
            {titleText && `${titleText}: `}HealthySteps Cost Savings Report
          </Typography> 
          <div className={classes.headerBanner}>
            <Divider variant="middle" className={classes.divider} />
            <p>
              For every $1 the State invests in HealthySteps, the State realizes an
              estimated {getSavingsPerDollar()} in Medicaid agency savings* over 1 year.
            </p>
            <p>
              For every $1 the State invests in HealthySteps, the State realizes an
              estimated {getLongTermSavingsPerDollar()} in savings* across state agencies over
              the longer term.
            </p>
            <Divider variant="middle" className={classes.divider} />
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(ReportHeader);