import ServiceData from './serviceData';
import Calculator from './shared/base';

export default class SmokingCessation extends Calculator {
  public serviceData: ServiceData;

  constructor({
    state,
    serviceData,
    sliderSmokerLowEstimate,
    sliderSmokerHighEstimate,
    firstInput,
  }: {
    state: string;
    serviceData: ServiceData;
    sliderSmokerLowEstimate: number;
    sliderSmokerHighEstimate: number;
    firstInput: number;
  }) {
    super({ state });
    this.serviceData = serviceData;
    this.firstInput = firstInput;
    this.sliderSmokerLowEstimate = sliderSmokerLowEstimate / 100;
    this.sliderSmokerHighEstimate = sliderSmokerHighEstimate / 100;
  }

  /**
   * @name smoking_tab_combined_child_and_adults_savings_over_3_years
   * @excel Excel Smoking Tab B12
   * @constant 1434
   */
  public readonly smokingTabCombinedChildAndAdultsSavingsOver3Years = this
    .constants.smokingCessation
    .smokingTabCombinedChildAndAdultsSavingsOver3Years;

  /**
   * @name smoking_tab_medicaid_price_inflaction_factor
   * @excel Excel Smoking Tab B13
   * @constant 1.17
   */
  public readonly smokingTabMedicaidPriceInflactionFactor = this.constants
    .smokingCessation.smokingTabMedicaidPriceInflactionFactor;

  /**
   * @name smoking_tab_relapse_rate_years_2to7
   * @excel Excel Smoking Tab B13
   * @constant 0.04
   */
  public readonly smokingTabRelapseRateYears2to7 = this.constants
    .smokingCessation.smokingTabRelapseRateYears2to7;

  /**
   * @name smoking_tab_relapse_rate_year_1
   * @excel Excel Smoking Tab B13
   * @constant 0.1
   */
  public readonly smokingTabRelapseRateYear1 = this.constants
    .smokingCessation.smokingTabRelapseRateYear1;

  /**
   * (App)
   * @name first_input
   */
  public firstInput;

  /**
   * @name number_of_HS_mothers_who_discussed_a_need_for_smoking_tobacco_cessation_advice
   * @excel Excel C147
   * @reference input_percentage_of_HS_mothers_who_discussed_a_smoking_tobacco_addiction * cs_input_4 (from Service Data Screen)
   */
  public numberOfHSMothersWhoDiscussedANeedForSmokingTobaccoCessationAdvice = () => {
    return this.isFiniteNumber(this.firstInput);
  };

  /**
   * (App) Estimated percentage of smokers treated due to HealthySteps - Low Estimate
   * @name slider_smoker_low_estimate
   * @excel Excel C151
   */
  public sliderSmokerLowEstimate;

  /**
   * (App) Estimated percentage of smokers treated due to HealthySteps - High Estimate
   * @name slider_smoker_high_estimate
   * @excel Excel C152
   */
  public sliderSmokerHighEstimate;

  /**
   * @name estimated_number_of_parents_who_discussed_smoking_took_up_treatment_and_quit_due_to_HS_low
   * @excel Excel C153
   * @formula number_of_HS_mothers_who_discussed_a_need_for_smoking_tobacco_cessation_advice * slider_smoker_low_estimate
   */
  public estimatedNumberOfParentsWhoDiscussedSmokingTookUpTreatmentAndQuitDueToHSLow = () => {
    return this.isFiniteNumber(
      this.numberOfHSMothersWhoDiscussedANeedForSmokingTobaccoCessationAdvice() *
        this.sliderSmokerLowEstimate * 0.28
    );
  };

  /**
   * @name estimated_number_of_parents_who_discussed_smoking_took_up_treatment_and_quit_due_to_HS_high
   * @excel Excel C154
   * @formula number_of_HS_mothers_who_discussed_a_need_for_smoking_tobacco_cessation_advice * slider_smoker_high_estimate
   */
  public estimatedNumberOfParentsWhoDiscussedSmokingTookUpTreatmentAndQuitDueToHSHigh = () => {
    return this.isFiniteNumber(
      this.numberOfHSMothersWhoDiscussedANeedForSmokingTobaccoCessationAdvice() *
        this.sliderSmokerHighEstimate * 0.28
    );
  };

  /**
   * @name combined_child_and_adult_medical_cost_savings_over_3_years_per_individual_who_quits
   * @excel Excel C155
   * @formula number_of_HS_mothers_who_discussed_a_need_for_smoking_tobacco_cessation_advice * slider_smoker_high_estimate
   */
  public combinedChildAndAdultMedicalCostSavingsOver3YearsPerIndividualWhoQuits = () => {
    return this.isFiniteNumber(
      this.smokingTabCombinedChildAndAdultsSavingsOver3Years *
        this.smokingTabMedicaidPriceInflactionFactor
    );
  };

  /**
   * @name smoking_cessation_estimated_savings_low
   * @excel Excel E145
   * @formula estimated_number_of_parents_who_discussed_smoking_took_up_treatment_and_quit_due_to_HS_low * combined_child_and_adult_medical_cost_savings_over_3_years_per_individual_who_quits
   */
  public smokingCessationEstimatedSavingsLow = () => {
    return this.isFiniteNumber(
      this.estimatedNumberOfParentsWhoDiscussedSmokingTookUpTreatmentAndQuitDueToHSLow() *
        this.combinedChildAndAdultMedicalCostSavingsOver3YearsPerIndividualWhoQuits()
    );
  };

  /**
   * @name smoking_cessation_estimated_savings_high
   * @excel Excel F145
   * @formula estimated_number_of_parents_who_discussed_smoking_took_up_treatment_and_quit_due_to_HS_high * combined_child_and_adult_medical_cost_savings_over_3_years_per_individual_who_quits
   */
  public smokingCessationEstimatedSavingsHigh = () => {
    return this.isFiniteNumber(
      this.estimatedNumberOfParentsWhoDiscussedSmokingTookUpTreatmentAndQuitDueToHSHigh() *
        this.combinedChildAndAdultMedicalCostSavingsOver3YearsPerIndividualWhoQuits()
    );
  };

  /**
   * @name smoking_tab_cost_per_individual_who_quits
   * @excel New Calculation
   * @formula ( 201 * smoking_tab_medicaid_price_inflaction_factor ) / 28%
   */
  public smokingTabCostPerIndividualWhoQuits = () => {
    const numerator = 201 * this.smokingTabMedicaidPriceInflactionFactor;
    const denominator = 0.28;
    return this.isFiniteNumber(numerator/denominator);   
  };

  /**
   * @name smoking_tab_annual_savings_for_smokers_who_quit
   * @excel New Calculation
   * @formula ( 3333 * smoking_tab_medicaid_price_inflaction_factor ) / 7.5
   */
  public smokingTabAnnualSavingsForSmokersWhoQuit = () => {
    const numerator = 3333 * this.smokingTabMedicaidPriceInflactionFactor;
    // const numerator = 3333;
    const denominator = 7.5;
    return this.isFiniteNumber(numerator / denominator);   
  };

  /**
   * @name annual_savings_for_children_of_smokers_who_quit
   * @excel New Calculation
   * @formula ( 190 * smoking_tab_medicaid_price_inflaction_factor ) / 7.5
   */
  public smokingTabAnnualSavingsForChildrenOfSmokersWhoQuit = () => {
    // const numerator = 190 * this.smokingTabMedicaidPriceInflactionFactor;
    const numerator = 190;
    const denominator = 7.5;
    return this.isFiniteNumber(numerator / denominator);   
  };

  /**
 * @name smoking_tab_total_savings_by_year_for_HS_smokers_who_successfully_quit_low
 * @excel New Calculation
 * @formula ( 190 * smoking_tab_medicaid_price_inflaction_factor ) / 7.5
 */
  public smokingTabTotalSavingsByYearForHSSmokersWhoSuccessfullyQuitLow = () => {
    const smokingTabRelapseRateYears1 = (1 - this.smokingTabRelapseRateYear1);
    const smokingTabRelapseRateYears2 = (1 - this.smokingTabRelapseRateYears2to7);
    const smokingTabRelapseRateYears3 = Math.pow(1 - this.smokingTabRelapseRateYears2to7, 2);
    const smokingTabRelapseRateYears4 = Math.pow(1 - this.smokingTabRelapseRateYears2to7, 3);
    const smokingTabRelapseRateYears5 = Math.pow(1 - this.smokingTabRelapseRateYears2to7, 4);
    const smokingTabRelapseRateYears6 = Math.pow(1 - this.smokingTabRelapseRateYears2to7, 5);
    const smokingTabRelapseRateYears7 = Math.pow(1 - this.smokingTabRelapseRateYears2to7, 5.5);

    return this.isFiniteNumber(
      (this.smokingTabAnnualSavingsForSmokersWhoQuit()* this.estimatedNumberOfParentsWhoDiscussedSmokingTookUpTreatmentAndQuitDueToHSLow()) +
      (this.smokingTabAnnualSavingsForSmokersWhoQuit()* (this.estimatedNumberOfParentsWhoDiscussedSmokingTookUpTreatmentAndQuitDueToHSLow() * smokingTabRelapseRateYears1)) +
      (this.smokingTabAnnualSavingsForSmokersWhoQuit()* (this.estimatedNumberOfParentsWhoDiscussedSmokingTookUpTreatmentAndQuitDueToHSLow() * smokingTabRelapseRateYears1 * smokingTabRelapseRateYears2)) +
      (this.smokingTabAnnualSavingsForSmokersWhoQuit()* (this.estimatedNumberOfParentsWhoDiscussedSmokingTookUpTreatmentAndQuitDueToHSLow() * smokingTabRelapseRateYears1 * smokingTabRelapseRateYears3)) +
      (this.smokingTabAnnualSavingsForSmokersWhoQuit()* (this.estimatedNumberOfParentsWhoDiscussedSmokingTookUpTreatmentAndQuitDueToHSLow() * smokingTabRelapseRateYears1 * smokingTabRelapseRateYears4)) +
      (this.smokingTabAnnualSavingsForSmokersWhoQuit()* (this.estimatedNumberOfParentsWhoDiscussedSmokingTookUpTreatmentAndQuitDueToHSLow() * smokingTabRelapseRateYears1 * smokingTabRelapseRateYears5)) +
      (this.smokingTabAnnualSavingsForSmokersWhoQuit()* (this.estimatedNumberOfParentsWhoDiscussedSmokingTookUpTreatmentAndQuitDueToHSLow() * smokingTabRelapseRateYears1 * smokingTabRelapseRateYears6)) +
      (this.smokingTabAnnualSavingsForSmokersWhoQuit()* (this.estimatedNumberOfParentsWhoDiscussedSmokingTookUpTreatmentAndQuitDueToHSLow() * smokingTabRelapseRateYears1 * smokingTabRelapseRateYears7))
    );   
  };

  /**
 * @name smoking_tab_total_savings_by_year_for_HS_smokers_who_successfully_quit_high
 * @excel New Calculation
 * @formula ( 190 * smoking_tab_medicaid_price_inflaction_factor ) / 7.5
 */
  public smokingTabTotalSavingsByYearForHSSmokersWhoSuccessfullyQuitHigh = () => { 
    const smokingTabRelapseRateYears1 = (1 - this.smokingTabRelapseRateYear1);
    const smokingTabRelapseRateYears2 = (1 - this.smokingTabRelapseRateYears2to7);
    const smokingTabRelapseRateYears3 = Math.pow(1 - this.smokingTabRelapseRateYears2to7, 2);
    const smokingTabRelapseRateYears4 = Math.pow(1 - this.smokingTabRelapseRateYears2to7, 3);
    const smokingTabRelapseRateYears5 = Math.pow(1 - this.smokingTabRelapseRateYears2to7, 4);
    const smokingTabRelapseRateYears6 = Math.pow(1 - this.smokingTabRelapseRateYears2to7, 5);
    const smokingTabRelapseRateYears7 = Math.pow(1 - this.smokingTabRelapseRateYears2to7, 5.5);

    return this.isFiniteNumber(
      (this.smokingTabAnnualSavingsForSmokersWhoQuit()* this.estimatedNumberOfParentsWhoDiscussedSmokingTookUpTreatmentAndQuitDueToHSHigh()) +
      (this.smokingTabAnnualSavingsForSmokersWhoQuit()* (this.estimatedNumberOfParentsWhoDiscussedSmokingTookUpTreatmentAndQuitDueToHSHigh() * smokingTabRelapseRateYears1)) +
      (this.smokingTabAnnualSavingsForSmokersWhoQuit()* (this.estimatedNumberOfParentsWhoDiscussedSmokingTookUpTreatmentAndQuitDueToHSHigh() * smokingTabRelapseRateYears1 * smokingTabRelapseRateYears2)) +
      (this.smokingTabAnnualSavingsForSmokersWhoQuit()* (this.estimatedNumberOfParentsWhoDiscussedSmokingTookUpTreatmentAndQuitDueToHSHigh() * smokingTabRelapseRateYears1 * smokingTabRelapseRateYears3)) +
      (this.smokingTabAnnualSavingsForSmokersWhoQuit()* (this.estimatedNumberOfParentsWhoDiscussedSmokingTookUpTreatmentAndQuitDueToHSHigh() * smokingTabRelapseRateYears1 * smokingTabRelapseRateYears4)) +
      (this.smokingTabAnnualSavingsForSmokersWhoQuit()* (this.estimatedNumberOfParentsWhoDiscussedSmokingTookUpTreatmentAndQuitDueToHSHigh() * smokingTabRelapseRateYears1 * smokingTabRelapseRateYears5)) +
      (this.smokingTabAnnualSavingsForSmokersWhoQuit()* (this.estimatedNumberOfParentsWhoDiscussedSmokingTookUpTreatmentAndQuitDueToHSHigh() * smokingTabRelapseRateYears1 * smokingTabRelapseRateYears6)) +
      (this.smokingTabAnnualSavingsForSmokersWhoQuit()* (this.estimatedNumberOfParentsWhoDiscussedSmokingTookUpTreatmentAndQuitDueToHSHigh() * smokingTabRelapseRateYears1 * smokingTabRelapseRateYears7))
    );
  };
  
  /**
 * @name smoking_tab_total_savings_by_year_for_HS_children_of_smokers_who_successfully_quit_low
 * @excel New Calculation
 * @formula ( 190 * smoking_tab_medicaid_price_inflaction_factor ) / 7.5
 */
  public smokingTabTotalSavingsByYearForHSChildrenOfSmokersWhoSuccessfullyQuitLow = () => {
    const smokingTabRelapseRateYears1 = (1 - this.smokingTabRelapseRateYear1);
    const smokingTabRelapseRateYears2 = (1 - this.smokingTabRelapseRateYears2to7);
    const smokingTabRelapseRateYears3 = Math.pow(1 - this.smokingTabRelapseRateYears2to7, 2);
    const smokingTabRelapseRateYears4 = Math.pow(1 - this.smokingTabRelapseRateYears2to7, 3);
    const smokingTabRelapseRateYears5 = Math.pow(1 - this.smokingTabRelapseRateYears2to7, 4);
    const smokingTabRelapseRateYears6 = Math.pow(1 - this.smokingTabRelapseRateYears2to7, 5);
    const smokingTabRelapseRateYears7 = Math.pow(1 - this.smokingTabRelapseRateYears2to7, 5.5);

    return this.isFiniteNumber(
      (this.smokingTabAnnualSavingsForChildrenOfSmokersWhoQuit()* this.estimatedNumberOfParentsWhoDiscussedSmokingTookUpTreatmentAndQuitDueToHSLow()) +
      (this.smokingTabAnnualSavingsForChildrenOfSmokersWhoQuit()* (this.estimatedNumberOfParentsWhoDiscussedSmokingTookUpTreatmentAndQuitDueToHSLow() * smokingTabRelapseRateYears1)) +
      (this.smokingTabAnnualSavingsForChildrenOfSmokersWhoQuit()* (this.estimatedNumberOfParentsWhoDiscussedSmokingTookUpTreatmentAndQuitDueToHSLow() * smokingTabRelapseRateYears1 * smokingTabRelapseRateYears2)) +
      (this.smokingTabAnnualSavingsForChildrenOfSmokersWhoQuit()* (this.estimatedNumberOfParentsWhoDiscussedSmokingTookUpTreatmentAndQuitDueToHSLow() * smokingTabRelapseRateYears1 * smokingTabRelapseRateYears3)) +
      (this.smokingTabAnnualSavingsForChildrenOfSmokersWhoQuit()* (this.estimatedNumberOfParentsWhoDiscussedSmokingTookUpTreatmentAndQuitDueToHSLow() * smokingTabRelapseRateYears1 * smokingTabRelapseRateYears4)) +
      (this.smokingTabAnnualSavingsForChildrenOfSmokersWhoQuit()* (this.estimatedNumberOfParentsWhoDiscussedSmokingTookUpTreatmentAndQuitDueToHSLow() * smokingTabRelapseRateYears1 * smokingTabRelapseRateYears5)) +
      (this.smokingTabAnnualSavingsForChildrenOfSmokersWhoQuit()* (this.estimatedNumberOfParentsWhoDiscussedSmokingTookUpTreatmentAndQuitDueToHSLow() * smokingTabRelapseRateYears1 * smokingTabRelapseRateYears6)) +
      (this.smokingTabAnnualSavingsForChildrenOfSmokersWhoQuit()* (this.estimatedNumberOfParentsWhoDiscussedSmokingTookUpTreatmentAndQuitDueToHSLow() * smokingTabRelapseRateYears1 * smokingTabRelapseRateYears7))
    );  
  };

  /**
 * @name smoking_tab_total_savings_by_year_for_HS_children_of_smokers_who_successfully_quit_low
 * @excel New Calculation
 * @formula ( 190 * smoking_tab_medicaid_price_inflaction_factor ) / 7.5
 */
  public smokingTabTotalSavingsByYearForHSChildrenOfSmokersWhoSuccessfullyQuitHigh = () => {
    const smokingTabRelapseRateYears1 = (1 - this.smokingTabRelapseRateYear1);
    const smokingTabRelapseRateYears2 = (1 - this.smokingTabRelapseRateYears2to7);
    const smokingTabRelapseRateYears3 = Math.pow(1 - this.smokingTabRelapseRateYears2to7, 2);
    const smokingTabRelapseRateYears4 = Math.pow(1 - this.smokingTabRelapseRateYears2to7, 3);
    const smokingTabRelapseRateYears5 = Math.pow(1 - this.smokingTabRelapseRateYears2to7, 4);
    const smokingTabRelapseRateYears6 = Math.pow(1 - this.smokingTabRelapseRateYears2to7, 5);
    const smokingTabRelapseRateYears7 = Math.pow(1 - this.smokingTabRelapseRateYears2to7, 5.5);

    return this.isFiniteNumber(
      (this.smokingTabAnnualSavingsForChildrenOfSmokersWhoQuit()* this.estimatedNumberOfParentsWhoDiscussedSmokingTookUpTreatmentAndQuitDueToHSHigh()) +
      (this.smokingTabAnnualSavingsForChildrenOfSmokersWhoQuit()* (this.estimatedNumberOfParentsWhoDiscussedSmokingTookUpTreatmentAndQuitDueToHSHigh() * smokingTabRelapseRateYears1)) +
      (this.smokingTabAnnualSavingsForChildrenOfSmokersWhoQuit()* (this.estimatedNumberOfParentsWhoDiscussedSmokingTookUpTreatmentAndQuitDueToHSHigh() * smokingTabRelapseRateYears1 * smokingTabRelapseRateYears2)) +
      (this.smokingTabAnnualSavingsForChildrenOfSmokersWhoQuit()* (this.estimatedNumberOfParentsWhoDiscussedSmokingTookUpTreatmentAndQuitDueToHSHigh() * smokingTabRelapseRateYears1 * smokingTabRelapseRateYears3)) +
      (this.smokingTabAnnualSavingsForChildrenOfSmokersWhoQuit()* (this.estimatedNumberOfParentsWhoDiscussedSmokingTookUpTreatmentAndQuitDueToHSHigh() * smokingTabRelapseRateYears1 * smokingTabRelapseRateYears4)) +
      (this.smokingTabAnnualSavingsForChildrenOfSmokersWhoQuit()* (this.estimatedNumberOfParentsWhoDiscussedSmokingTookUpTreatmentAndQuitDueToHSHigh() * smokingTabRelapseRateYears1 * smokingTabRelapseRateYears5)) +
      (this.smokingTabAnnualSavingsForChildrenOfSmokersWhoQuit()* (this.estimatedNumberOfParentsWhoDiscussedSmokingTookUpTreatmentAndQuitDueToHSHigh() * smokingTabRelapseRateYears1 * smokingTabRelapseRateYears6)) +
      (this.smokingTabAnnualSavingsForChildrenOfSmokersWhoQuit()* (this.estimatedNumberOfParentsWhoDiscussedSmokingTookUpTreatmentAndQuitDueToHSHigh() * smokingTabRelapseRateYears1 * smokingTabRelapseRateYears7))
    );   
  };

  /**
 * @name smoking_tab_total_savings_by_year_for_HS_children_of_smokers_who_successfully_quit_high_net_of_program_costs
 * @excel New Calculation
 * @formula ( 190 * smoking_tab_medicaid_price_inflaction_factor ) / 7.5
 */
  public smokingTabTotalSavingsByYearForHSChildrenOfSmokersWhoSuccessfullyQuitLowNetofProgramCosts = () => {
    const savings = this.smokingTabTotalSavingsByYearForHSChildrenOfSmokersWhoSuccessfullyQuitLow();
    const cost = this.smokingTabCostPerIndividualWhoQuits();
    const netSavings = savings - cost;

    if (!this.isFiniteNumber(netSavings) || netSavings < 0) {
      return this.smokingTabTotalSavingsByYearForHSChildrenOfSmokersWhoSuccessfullyQuitLow();
    }

    return netSavings;
  };

  
  /**
 * @name smoking_tab_total_savings_by_year_for_HS_children_of_smokers_who_successfully_quit_high_net_of_program_costs
 * @excel New Calculation
 * @formula ( 190 * smoking_tab_medicaid_price_inflaction_factor ) / 7.5
 */
  public smokingTabTotalSavingsByYearForHSChildrenOfSmokersWhoSuccessfullyQuitHighNetofProgramCosts = () => {
    const savings = this.smokingTabTotalSavingsByYearForHSChildrenOfSmokersWhoSuccessfullyQuitHigh();
    const cost = this.smokingTabCostPerIndividualWhoQuits();
    const netSavings = savings - cost;
  
    if (!this.isFiniteNumber(netSavings) || netSavings < 0) {
      return this.smokingTabTotalSavingsByYearForHSChildrenOfSmokersWhoSuccessfullyQuitHigh();
    }
  
    return netSavings;
  };

  /**
 * @name smoking_tab_total_savings_by_year_for_HS_smokers_who_successfully_quit_low_net_of_program_costs
 * @excel New Calculation
 * @formula ( 190 * smoking_tab_medicaid_price_inflaction_factor ) / 7.5
 */
  public smokingTabTotalSavingsByYearForHSSmokersWhoSuccessfullyQuitLowNetofProgramCosts = () => {
    const savings = this.smokingTabTotalSavingsByYearForHSSmokersWhoSuccessfullyQuitLow();
    const cost = this.smokingTabCostPerIndividualWhoQuits();
    const netSavings = savings - cost;
  
    if (!this.isFiniteNumber(netSavings) || netSavings < 0) {
      return this.smokingTabTotalSavingsByYearForHSSmokersWhoSuccessfullyQuitLow();
    }
  
    return netSavings; 
  };

  /**
 * @name smoking_tab_total_savings_by_year_for_HS_smokers_who_successfully_quit_high_net_of_program_costs
 * @excel New Calculation
 * @formula ( 190 * smoking_tab_medicaid_price_inflaction_factor ) / 7.5
 */
  public smokingTabTotalSavingsByYearForHSSmokersWhoSuccessfullyQuitHighNetofProgramCosts = () => {
    const savings = this.smokingTabTotalSavingsByYearForHSSmokersWhoSuccessfullyQuitHigh();
    const cost = this.smokingTabCostPerIndividualWhoQuits();
    const netSavings = savings - cost;
  
    if (!this.isFiniteNumber(netSavings) || netSavings < 0) {
      return this.smokingTabTotalSavingsByYearForHSSmokersWhoSuccessfullyQuitHigh();
    }
  
    return netSavings;   
  };
  
  /**
   * @name smoking_tab_total_discounted_future_savings_child_high
   * @excel New Calculation
   * @formula ( 190 * smoking_tab_medicaid_price_inflaction_factor ) / 7.5
   */
  public smokingTabTotalDiscountedFutureSavingsAdultLow = () => {   
    const numerator = this.smokingTabTotalSavingsByYearForHSSmokersWhoSuccessfullyQuitLowNetofProgramCosts();
    const denominator = Math.pow(1 + 0.03, 7.5);

    return this.isFiniteNumber(numerator / denominator);
  };

  /**
 * @name smoking_tab_total_discounted_future_savings_child_high
 * @excel New Calculation
 * @formula ( 190 * smoking_tab_medicaid_price_inflaction_factor ) / 7.5
 */
  public smokingTabTotalDiscountedFutureSavingsAdultHigh = () => {
    const numerator = this.smokingTabTotalSavingsByYearForHSSmokersWhoSuccessfullyQuitHighNetofProgramCosts();
    const denominator = Math.pow(1 + 0.03, 7.5);

    return this.isFiniteNumber(numerator / denominator);
  };


  /**
 * @name smoking_tab_total_discounted_future_savings_child_low
 * @excel New Calculation
 * @formula ( 190 * smoking_tab_medicaid_price_inflaction_factor ) / 7.5
 */
  public smokingTabTotalDiscountedFutureSavingsChildLow = () => {
    const numerator = this.smokingTabTotalSavingsByYearForHSChildrenOfSmokersWhoSuccessfullyQuitLowNetofProgramCosts();
    const denominator = Math.pow(1 + 0.03, 7.5);
    return this.isFiniteNumber(numerator / denominator);
  };

  /**
 * @name smoking_tab_total_discounted_future_savings_child_high
 * @excel New Calculation
 * @formula ( 190 * smoking_tab_medicaid_price_inflaction_factor ) / 7.5
 */
  public smokingTabTotalDiscountedFutureSavingsChildHigh = () => {
    const numerator = this.smokingTabTotalSavingsByYearForHSChildrenOfSmokersWhoSuccessfullyQuitHighNetofProgramCosts();
    const denominator = Math.pow(1 + 0.03, 7.5);
    return this.isFiniteNumber(numerator / denominator);
  };


  /**
 * @name smoking_tab_total_discounted_future_savings_child_high
 * @excel New Calculation
 * @formula ( 190 * smoking_tab_medicaid_price_inflaction_factor ) / 7.5
 */
  public smokingTabTotalSavingsAdultAndChildLow = () => {
    return this.isFiniteNumber( this.smokingTabTotalDiscountedFutureSavingsChildLow() + this.smokingTabTotalDiscountedFutureSavingsAdultLow() );
  };

  /**
 * @name smoking_tab_total_discounted_future_savings_child_high
 * @excel New Calculation
 * @formula ( 190 * smoking_tab_medicaid_price_inflaction_factor ) / 7.5
 */
  public smokingTabTotalSavingsAdultAndChildHigh = () => {
    return this.isFiniteNumber( this.smokingTabTotalDiscountedFutureSavingsChildHigh() + this.smokingTabTotalDiscountedFutureSavingsAdultHigh() );
  };

  /**
 * @name smoking_tab_total_discounted_future_savings_child_high
 * @excel New Calculation
 * @formula ( 190 * smoking_tab_medicaid_price_inflaction_factor ) / 7.5
 */
  public smokingTabTotalSavingsChildAverage = () => {
    return this.isFiniteNumber( (this.smokingTabTotalDiscountedFutureSavingsChildLow() + this.smokingTabTotalDiscountedFutureSavingsChildHigh())/2 );
  };

  /**
 * @name smoking_tab_total_discounted_future_savings_child_high
 * @excel New Calculation
 * @formula ( 190 * smoking_tab_medicaid_price_inflaction_factor ) / 7.5
 */
  public smokingTabTotalSavingsadultAverage = () => {
    return this.isFiniteNumber( (this.smokingTabTotalDiscountedFutureSavingsAdultLow() + this.smokingTabTotalDiscountedFutureSavingsAdultHigh())/2 );
  };

  /**
 * @name smoking_tab_total_discounted_future_savings_child_high
 * @excel New Calculation
 * @formula ( 190 * smoking_tab_medicaid_price_inflaction_factor ) / 7.5
 */
  public smokingTabTotalSavingsAdultAndChildAverage = () => {
    return this.isFiniteNumber( (this.smokingTabTotalSavingsAdultAndChildLow() + this.smokingTabTotalSavingsAdultAndChildHigh())/2 );
  };

}
