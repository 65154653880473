import Constants from './constants';

const capitalize = (text: string) => {
  if (text) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }
  return '';
};

const money = (text: string) =>
  `$ ${parseFloat(text || '0').toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}`;

const roundedMoney = (text: string) => {
  return `$${Math.round(parseFloat(text || '0')).toLocaleString('en-US')}`;
};
  
const interventionPercentage = (text: string) => {
  return `${Math.round(parseFloat(text || '0')).toLocaleString('en-US')}%`;
}; 

const percentageIntervention = (text:string) => {
  const number = parseFloat(text);
  return `${Math.round(number * 100)}%`;
};

const percentage = (
  text: string,
  minDecimals: number = 0,
  maxDecimals: number = 0
) =>
  `${parseFloat(text || '0').toLocaleString('en-US', {
    minimumFractionDigits: minDecimals,
    maximumFractionDigits: maxDecimals,
  })} %`;

const replaceWithReportName = (
  reportName: string,
  path: string,
  isNew?: boolean
) =>
  path.replace(
    isNew ? Constants.REPORT.NEW : Constants.REPORT.URL_PARAM,
    reportName || Constants.REPORT.NEW
  );


const getStateName = (state: string) => {
  const filteredState = Constants.US_STATES
    .filter((s: { id: string, value: string}) => s.id === state)[0];

  return filteredState ? filteredState.value : '';
};

const numberWithCommas = (text: string) => {
  return text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export default {
  capitalize,
  replaceWithReportName,
  money,
  roundedMoney,
  interventionPercentage,
  percentage,
  percentageIntervention,
  getStateName,
  numberWithCommas,
};
