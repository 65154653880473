import ServiceData from './serviceData';
import EmergencyDepartment from './emergencyDepartment';
import Calculator from './shared/base';

export default class Immunizations extends Calculator {
  public serviceData: ServiceData;

  public emergencyDepartment: EmergencyDepartment;

  public needForFluVaccinations: number;

  public numberOfCaregiversDiscussingECVWithHSS: number;

  constructor({
    state,
    serviceData,
    emergencyDepartment,
    lowEstimatedSlider,
    highEstimatedSlider,
    longTermLowEstimatedSlider,
    longTermHighEstimatedSlider,
    needForFluVaccinations,
    numberOfCaregiversDiscussingECVWithHSS,
  }: {
    state: string;
    serviceData: ServiceData;
    emergencyDepartment: EmergencyDepartment;
    lowEstimatedSlider: number;
    highEstimatedSlider: number;
    longTermLowEstimatedSlider: number;
    longTermHighEstimatedSlider: number;
    needForFluVaccinations: number;
    numberOfCaregiversDiscussingECVWithHSS: number;
  }) {
    super({ state });
    this.serviceData = serviceData;
    this.emergencyDepartment = emergencyDepartment;
    this.lowEstimatedSlider = lowEstimatedSlider / 100;
    this.highEstimatedSlider = highEstimatedSlider / 100;
    this.longTermLowEstimatedSlider = longTermLowEstimatedSlider / 100;
    this.longTermHighEstimatedSlider = longTermHighEstimatedSlider / 100;
    this.needForFluVaccinations = needForFluVaccinations;
    this.numberOfCaregiversDiscussingECVWithHSS = numberOfCaregiversDiscussingECVWithHSS;
  }

  /**
   * @name immunization_tab_flu_vaccination_rate_for_ma_populations_during_flu_season_children
   * @constant 52% (0.52)
   * @excel Excel Immunizations Tab B3
   */
  public readonly immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren = this
    .constants.immunizations
    .immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren;

  /**
   * @name immunizations_tab_percentage_of_unvaccinated_who_contract_flu_children
   * @constant 15.2% (0.152)
   * @excel Excel Immunizations Tab B6
   */
  public readonly immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren = this
    .constants.immunizations
    .immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren;

  /**
   * @name immunizations_tab_efficacy_of_flu_vaccination_for_children_percentage_reduction_children
   * @constant 75% (0.75)
   * @excel Excel Immunizations Tab B9
   */
  public readonly immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren = this
    .constants.immunizations
    .immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren;

  /**
   * @name immunizations_tab_medicaid_visit_percentage_for_children
   * @constant 67% (0.67)
   * @excel Excel Immunizations Tab B12
   */
  public readonly immunizationsTabMedicaidVisitPercentageForChildren = this
    .constants.immunizations.immunizationsTabMedicaidVisitPercentageForChildren;

  /**
   * @name percentage_of_children_under_age_5_without_flu_vaccination
   * @excel Excel C40
   * @formula 1 - immunization_tab_flu_vaccination_rate_for_ma_populations_during_flu_season_children
   */
  public percentageOfChildrenUnderAge5WithoutFluVaccination = () => {
    return this.isFiniteNumber(
      1 -
        this
          .immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren
    );
  };

  /**
   * @name estimated_number_of_hs_medicaid_children_who_would_go_without_flu_vaccine
   * @excel Excel C42
   * @formula percentage_of_children_under_age_5_without_flu_vaccination * cs_input_4 (from Service Data Screen)
   */
  public estimatedNumberOfHsMedicaidChildrenWhoWouldGoWithoutFluVaccine = () => {
    return this.isFiniteNumber(
      // this.percentageOfChildrenUnderAge5WithoutFluVaccination() *
      this.needForFluVaccinations
    );
  };

  /**
   * @name percentage_of_unvaccinated_children_who_contract_flu
   * @excel Excel C44
   * @reference immunizations_tab_percentage_of_unvaccinated_who_contract_flu_children
   */
  public percentageOfUnvaccinatedChildrenWhoContractFlu = () => {
    return this.isFiniteNumber(
      this.immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren
    );
  };

  /**
   * @name efficacy_of_flu_vaccination_for_children
   * @excel Excel C46
   * @reference immunizations_tab_percentage_of_unvaccinated_who_contract_flu_children
   */
  public efficacyOfFluVaccinationForChildren = () => {
    return this.isFiniteNumber(
      this
        .immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren
    );
  };

  /**
   * @name medical_visit_percentage_for_children_with_flu
   * @excel Excel C48
   * @reference immunizations_tab_medicaid_visit_percentage_for_children
   */
  public medicalVisitPercentageForChildrenWithFlu = () => {
    return this.isFiniteNumber(
      this.immunizationsTabMedicaidVisitPercentageForChildren
    );
  };

  /**
   * Estimated percentage of unvaccinated who would receive flu vaccine due to HS - low
   * @name low_estimated_slider
   * @excel Excel C50
   */
  public lowEstimatedSlider;

  /**
   * Estimated percentage of unvaccinated who would receive flu vaccine due to HS - high
   * @name high_estimated_slider
   * @excel Excel C51
   */
  public highEstimatedSlider;


  /**
   * Estimated percentage of unvaccinated who would receive flu vaccine due to HS - low
   * @name long_term_low_estimated_slider
   * @excel Excel C50
   */
  public longTermLowEstimatedSlider;

  /**
   * Estimated percentage of unvaccinated who would receive flu vaccine due to HS - high
   * @name long_term_high_estimated_slider
   * @excel Excel C51
   */
  public longTermHighEstimatedSlider;

  /**
   * @name estimated_number_of_unvaccinated_children_who_would_receive_flu_vaccine_due_to_HS_low
   * @excel Excel C52
   * @formula low_estimated_slider * estimated_number_of_hs_medicaid_children_who_would_go_without_flu_vaccine
   */
  public estimatedNumberOfUnvaccinatedChildrenWhoWouldReceiveFluVaccineDueToHSLow = () => {
    return this.isFiniteNumber(
      this.lowEstimatedSlider *
        this.estimatedNumberOfHsMedicaidChildrenWhoWouldGoWithoutFluVaccine()
    );
  };

  /**
   * @name estimated_number_of_unvaccinated_children_who_would_receive_flu_vaccine_due_to_HS_high
   * @excel Excel C53
   * @formula high_estimated_slider * estimated_number_of_hs_medicaid_children_who_would_go_without_flu_vaccine
   */
  public estimatedNumberOfUnvaccinatedChildrenWhoWouldReceiveFluVaccineDueToHSHigh = () => {
    return this.isFiniteNumber(
      this.highEstimatedSlider *
        this.estimatedNumberOfHsMedicaidChildrenWhoWouldGoWithoutFluVaccine()
    );
  };

  /**
   * @name estimated_number_of_unvaccinated_children_who_would_receive_flu_vaccine_due_to_HS_low
   * @excel Excel C52
   * @formula low_estimated_slider * estimated_number_of_hs_medicaid_children_who_would_go_without_flu_vaccine
   */
  public estimatedNumberOfUnvaccinatedChildrenWhoWouldReceiveFluVaccineDueToHSLongTermLow = () => {
    return this.isFiniteNumber(
      this.longTermLowEstimatedSlider *
        this.estimatedNumberOfHsMedicaidChildrenWhoWouldGoWithoutFluVaccine()
    );
  };

  /**
   * @name estimated_number_of_unvaccinated_children_who_would_receive_flu_vaccine_due_to_HS_high
   * @excel Excel C53
   * @formula high_estimated_slider * estimated_number_of_hs_medicaid_children_who_would_go_without_flu_vaccine
   */
  public estimatedNumberOfUnvaccinatedChildrenWhoWouldReceiveFluVaccineDueToHSLongTermHigh = () => {
    return this.isFiniteNumber(
      this.longTermHighEstimatedSlider *
        this.estimatedNumberOfHsMedicaidChildrenWhoWouldGoWithoutFluVaccine()
    );
  };

  /**
   * @name estimated_number_of_newly_vaccinated_children_with_averted_flu_and_medical_visit_due_to_HS_low
   * @excel Excel C54
   * @formula estimated_number_of_unvaccinated_children_who_would_receive_flu_vaccine_due_to_HS_low * percentage_of_unvaccinated_children_who_contract_flu * efficacy_of_flu_vaccination_for_children * medical_visit_percentage_for_children_with_flu
   */
  public estimatedNumberOfNewlyVaccinatedChildrenWithAvertedFluAndMedicalVisitDueToHSLow = () => {
    return this.isFiniteNumber(
      this.estimatedNumberOfUnvaccinatedChildrenWhoWouldReceiveFluVaccineDueToHSLow() *
        this.percentageOfUnvaccinatedChildrenWhoContractFlu() *
        this.efficacyOfFluVaccinationForChildren() *
        this.medicalVisitPercentageForChildrenWithFlu()
    );
  };

  /**
   * @name estimated_number_of_newly_vaccinated_children_with_averted_flu_and_medical_visit_due_to_HS_high
   * @excel Excel C55
   * @formula estimated_number_of_unvaccinated_children_who_would_receive_flu_vaccine_due_to_HS_high * percentage_of_unvaccinated_children_who_contract_flu * efficacy_of_flu_vaccination_for_children * medical_visit_percentage_for_children_with_flu
   */
  public estimatedNumberOfNewlyVaccinatedChildrenWithAvertedFluAndMedicalVisitDueToHSHigh = () => {
    return this.isFiniteNumber(
      this.estimatedNumberOfUnvaccinatedChildrenWhoWouldReceiveFluVaccineDueToHSHigh() *
        this.percentageOfUnvaccinatedChildrenWhoContractFlu() *
        this.efficacyOfFluVaccinationForChildren() *
        this.medicalVisitPercentageForChildrenWithFlu()
    );
  };

  /**
   * @name estimated_number_of_newly_vaccinated_children_with_averted_flu_and_medical_visit_due_to_HS_low
   * @excel Excel C54
   * @formula estimated_number_of_unvaccinated_children_who_would_receive_flu_vaccine_due_to_HS_low * percentage_of_unvaccinated_children_who_contract_flu * efficacy_of_flu_vaccination_for_children * medical_visit_percentage_for_children_with_flu
   */
  public estimatedNumberOfNewlyVaccinatedChildrenWithAvertedFluAndMedicalVisitDueToHSLongTermLow = () => {
    return this.isFiniteNumber(
      this.estimatedNumberOfUnvaccinatedChildrenWhoWouldReceiveFluVaccineDueToHSLongTermLow() *
        this.percentageOfUnvaccinatedChildrenWhoContractFlu() *
        this.efficacyOfFluVaccinationForChildren() *
        this.medicalVisitPercentageForChildrenWithFlu()
    );
  };

  /**
   * @name estimated_number_of_newly_vaccinated_children_with_averted_flu_and_medical_visit_due_to_HS_high
   * @excel Excel C55
   * @formula estimated_number_of_unvaccinated_children_who_would_receive_flu_vaccine_due_to_HS_high * percentage_of_unvaccinated_children_who_contract_flu * efficacy_of_flu_vaccination_for_children * medical_visit_percentage_for_children_with_flu
   */
  public estimatedNumberOfNewlyVaccinatedChildrenWithAvertedFluAndMedicalVisitDueToHSLongTermHigh = () => {
    return this.isFiniteNumber(
      this.estimatedNumberOfUnvaccinatedChildrenWhoWouldReceiveFluVaccineDueToHSLongTermHigh() *
        this.percentageOfUnvaccinatedChildrenWhoContractFlu() *
        this.efficacyOfFluVaccinationForChildren() *
        this.medicalVisitPercentageForChildrenWithFlu()
    );
  };

  /**
   * savings_per_averted_ed_visit_that_is_not_replaced_with_an_office_visit
   * savings_per_averted_office_visit
   * savings_per_averted_fqhc_visit
   * (from Emergency Department Use)
   * @name savings_per_averted_office_FQHC_ED_visit
   * @excel Excel C60
   * @formula ( savings_per_averted_ed_visit_that_is_not_replaced_with_an_office_visit * 0.5 ) + ( AVERAGE(savings_per_averted_office_visit, savings_per_averted_fqhc_visit) * 0.5)
   */
  public savingsPerAvertedOfficeFQHCEDvisit = () => {
    return this.isFiniteNumber(
      this.emergencyDepartment.savingsPerAvertedEdVisitThatIsNotReplacedWithAnOfficeVisit() *
        0.5 +
        ((this.emergencyDepartment.savingsPerAvertedOfficeVisit() +
          this.emergencyDepartment.savingsPerAvertedFqhcVisit()) /
          2) *
          0.5
    );
  };

  /**
   * @name immunizations_estimated_savings_low
   * @excel Excel E39
   * @formula Sum( estimated_number_of_newly_vaccinated_children_with_averted_flu_and_medical_visit_due_to_HS_low, estimated_number_of_newly_vaccinated_parents_with_averted_flu_and_medical_visit_due_to_HS_low ) * savings_per_averted_office_FQHC_ED_visit
   */
  public immunizationsEstimatedSavingsLow = () => {
    return this.isFiniteNumber(
      this.estimatedNumberOfNewlyVaccinatedChildrenWithAvertedFluAndMedicalVisitDueToHSLow() *
        this.savingsPerAvertedOfficeFQHCEDvisit()
    );
  };

  /**
   * @name immunizations_estimated_savings_high
   * @excel Excel F39
   * @formula Sum( estimated_number_of_newly_vaccinated_children_with_averted_flu_and_medical_visit_due_to_HS_high, estimated_number_of_newly_vaccinated_parents_with_averted_flu_and_medical_visit_due_to_HS_high) * savings_per_averted_office_FQHC_ED_visit
   */
  public immunizationsEstimatedSavingsHigh = () => {
    return this.isFiniteNumber(
      this.estimatedNumberOfNewlyVaccinatedChildrenWithAvertedFluAndMedicalVisitDueToHSHigh() *
        this.savingsPerAvertedOfficeFQHCEDvisit()
    );
  };

  /**
   * @name immunizations_tab_medical_price_inflation_factor
   * @constant 1.698898514851490000000000
   * @excel Excel Immunizations Tab B6
   */
  public readonly immunizationsTabMedicalPriceInflationFactor = this
    .constants.immunizations
    .immunizationsTabMedicalPriceInflationFactor;

  /**
   * @name immunizations_tab_final_ECV_estimated_savings_per_child
   * @formula 15.2% (0.152)
   * @excel Excel Immunizations Tab B6
   */
  public immunizationsTabFinalECVEstimatedSavingsPerChild = (): number => {
    const numerator = 2598.01040939501 * this.immunizationsTabMedicalPriceInflationFactor;
  
    const result = numerator * (1 - 0.1);
  
    return this.isFiniteNumber(result);
  }; 

  /**
   * @name percentage_of_final_ECV_estimated_savings_per_child
   * @excel Excel C44
   * @reference immunizations_tab_final_ECV_estimated_savings_per_child
   */
  public percentageOfFinalECVEstimatedSavingsPerChild = () => {
    return this.isFiniteNumber(
      this.immunizationsTabFinalECVEstimatedSavingsPerChild()
    );
  };


  /**
   * @name immunizations_number_unvaccinated_children_who_receive_ECV_due_to_HS_low
   * @excel New Calculation
   * @formula ( immunizations_tab_number_caregivers_discussing_ECV_with_HSS * immunizations_tab_percent_children_receiving_ECV_due_to_HS_low )
   */
  public immunizationsTabNumberUnvaccinatedChildrenWhoReceiveECVDueToHSLow = () => {
    return this.isFiniteNumber(
      this.numberOfCaregiversDiscussingECVWithHSS *
          this.longTermLowEstimatedSlider
    );
  };

  /**
   * @name immunizations_number_unvaccinated_children_who_receive_ECV_due_to_HS_high
   * @excel New Calculation
   * @formula ( immunizations_tab_number_caregivers_discussing_ECV_with_HSS * immunizations_tab_percent_children_receiving_ECV_due_to_HS_high )
   */
  public immunizationsTabNumberUnvaccinatedChildrenWhoReceiveECVDueToHSHigh = () => {
    return this.isFiniteNumber(
      this.numberOfCaregiversDiscussingECVWithHSS *
          this.longTermHighEstimatedSlider
    );
  };

  /**
   * @name immunizations_estimated_long_term_savings_low
   * @excel New Calculation
   * @formula ( immunizations_tab_final_ECV_estimated_savings_per_child * immunizations_tab_number_unvaccinated_children_who_receive_ECV_due_to_HS_low )
   */
  public immunizationsEstimatedLongTermSavingsLow = () => {
    const calculatedValue =
      this.percentageOfFinalECVEstimatedSavingsPerChild() *
      this.immunizationsTabNumberUnvaccinatedChildrenWhoReceiveECVDueToHSLow();
  
    return this.isFiniteNumber(calculatedValue);
  };
  

  /**
   * @name immunizations_estimated_long_term_savings_high
   * @excel New Calculation
   * @formula ( immunizations_tab_final_ECV_estimated_savings_per_child * immunizations_tab_number_unvaccinated_children_who_receive_ECV_due_to_HS_high )
   */
  public immunizationsEstimatedLongTermSavingsHigh = () => {
    return this.isFiniteNumber(
      this.percentageOfFinalECVEstimatedSavingsPerChild() *
        this.immunizationsTabNumberUnvaccinatedChildrenWhoReceiveECVDueToHSHigh()
    );
  };

  public immunizationsEstimatedTotalSavingsLow = () => {
    const totalSavingsLow = this.isFiniteNumber(
      this.immunizationsEstimatedSavingsLow() +
        this.immunizationsEstimatedLongTermSavingsLow()
    );
    return totalSavingsLow;
  };

  public immunizationsEstimatedTotalSavingsHigh = () => {
    const totalSavingsHigh = this.isFiniteNumber(
      this.immunizationsEstimatedSavingsHigh() +
        this.immunizationsEstimatedLongTermSavingsHigh()
    );
    return totalSavingsHigh;
  };

  public immunizationsEstimatedTotalSavingsAverage = () => {
    const totalSavingsHigh = this.isFiniteNumber(
      (this.immunizationsEstimatedTotalSavingsLow() +
        this.immunizationsEstimatedTotalSavingsHigh())/2
    );
    return totalSavingsHigh;
  };

  public immunizationsEstimatedShortTermSavingsAverage = () => {
    const totalSavingsHigh = this.isFiniteNumber(
      (this.immunizationsEstimatedSavingsLow() +
        this.immunizationsEstimatedSavingsHigh())/2
    );
    return totalSavingsHigh;
  };

  public immunizationsEstimatedLongTermSavingsAverage = () => {
    const totalSavingsHigh = this.isFiniteNumber(
      (this.immunizationsEstimatedLongTermSavingsLow() +
        this.immunizationsEstimatedLongTermSavingsHigh())/2
    );
    return totalSavingsHigh;
  };

}
