import Asthma from './asthma';
import Breastfeeding from './breastfeeding';
import EmergencyDepartment from './emergencyDepartment';
import HealthBirthSpacing from './healthBirthSpacing';
import Immunizations from './immunizations';
import IntimatePartner from './intimatePartnerViolence';
import OralHealth from './oralHealth';
import PostpartumMaternalDepression from './postpartumMaternalDepression';
import ServiceData from './serviceData';
import SmokingCessation from './smokingCessation';
import Diapers from './diapers';
import FoodSecurity from './foodSecurity';
import EarlyDetection from './earlyDetection';
import PositiveParenting from './positiveParenting';
import Calculator from './shared/base';
import { IInterventionsChecklist, InterventionsChecklistForm } from '../interfaces/interventions.interface';

export default class Main extends Calculator {
  public serviceData: ServiceData;

  public emergencyDepartment: EmergencyDepartment;

  public asthma: Asthma;

  public breastfeeding: Breastfeeding;

  public healthBirthSpacing: HealthBirthSpacing;

  public immunizations: Immunizations;

  public intimatePartner: IntimatePartner;

  public oralHealth: OralHealth;

  public postpartumMaternalDepression: PostpartumMaternalDepression;

  public smokingCessation: SmokingCessation;

  public diapers: Diapers;

  public foodSecurity: FoodSecurity;

  public interventionsChecklist: IInterventionsChecklist;

  public earlyDetection: EarlyDetection;

  public positiveParenting: PositiveParenting;

  constructor({
    state,
    serviceData,
    emergencyDepartment,
    asthma,
    breastfeeding,
    healthBirthSpacing,
    immunizations,
    intimatePartner,
    oralHealth,
    postpartumMaternalDepression,
    smokingCessation,
    diapers,
    foodSecurity,
    earlyDetection,
    positiveParenting,
    interventionsChecklist,
  }: {
    state: string;
    serviceData: ServiceData;
    emergencyDepartment: EmergencyDepartment;
    asthma: Asthma;
    breastfeeding: Breastfeeding;
    healthBirthSpacing: HealthBirthSpacing;
    immunizations: Immunizations;
    intimatePartner: IntimatePartner;
    oralHealth: OralHealth;
    postpartumMaternalDepression: PostpartumMaternalDepression;
    smokingCessation: SmokingCessation;
    diapers: Diapers;
    foodSecurity: FoodSecurity;
    earlyDetection: EarlyDetection;
    positiveParenting: PositiveParenting;
    interventionsChecklist: IInterventionsChecklist;
  }) {
    super({ state });
    this.serviceData = serviceData;
    this.emergencyDepartment = emergencyDepartment;
    this.asthma = asthma;
    this.breastfeeding = breastfeeding;
    this.healthBirthSpacing = healthBirthSpacing;
    this.immunizations = immunizations;
    this.intimatePartner = intimatePartner;
    this.oralHealth = oralHealth;
    this.postpartumMaternalDepression = postpartumMaternalDepression;
    this.smokingCessation = smokingCessation;
    this.diapers = diapers;
    this.foodSecurity = foodSecurity;
    this.earlyDetection = earlyDetection;
    this.positiveParenting = positiveParenting;
    this.interventionsChecklist = interventionsChecklist;
  }

  /**
   * @name children_low_savings
   * @excel Excel E11
   * @formula dental_low_savings + emergency_deparment_use_low_savings + inmunizations_low_savings + asthma_low_savings
   */
  public childrenLowSavings = () => {
    return (
      (this.interventionsChecklist[InterventionsChecklistForm.OralHealth] ? this.oralHealth.dentalEstimatedTotalTermSavingLow() : 0) +
      (this.interventionsChecklist.emergencyDepartmentUse ? this.emergencyDepartment.emergencyDepartmentEstimatedSavingLow() : 0) +
      (this.interventionsChecklist.immunizations ? this.immunizations.immunizationsEstimatedTotalSavingsLow() : 0) +
      (this.interventionsChecklist.asthma ? this.asthma.asthmaEstimatedSavingsLow() : 0)  +
      (this.interventionsChecklist.earlyDetection ? this.earlyDetection.earlyDetectionEstimatedLow() : 0)
      + (this.interventionsChecklist.diapers ? this.diapers.diapersEstimatedSavingsLow() : 0)
      + (this.interventionsChecklist.positiveParenting ? this.positiveParenting.positiveParentingEstimatedSavingsLow() : 0)
    );
  };

  /**
   * @name children_high_savings
   * @excel Excel F11
   * @formula dental_high_savings + emergency_deparment_use_high_savings + inmunizations_high_savings + asthma_high_savings
   */
  public childrenHighSavings = () => {
    return (
      (this.interventionsChecklist[InterventionsChecklistForm.OralHealth] ? this.oralHealth.dentalEstimatedTotalTermSavingHigh() : 0) +
      (this.interventionsChecklist.emergencyDepartmentUse ? this.emergencyDepartment.emergencyDepartmentEstimatedSavingHigh() : 0) +
      (this.interventionsChecklist.immunizations ? this.immunizations.immunizationsEstimatedTotalSavingsHigh() : 0) +
      (this.interventionsChecklist.asthma ? this.asthma.asthmaEstimatedSavingsHigh() : 0)  +
      (this.interventionsChecklist.earlyDetection ? this.earlyDetection.earlyDetectionEstimatedHigh() : 0)
      + (this.interventionsChecklist.diapers ? this.diapers.diapersEstimatedSavingsHigh() : 0)
      + (this.interventionsChecklist.positiveParenting ? this.positiveParenting.positiveParentingEstimatedSavingsHigh() : 0)
    );
  };

  /**
   * @name parent_low_savings
   * @excel Excel E68
   * @formula postpartum_maternal_depression_low_savings + breastfeeding_low_savings + smoking_cessation_low_savings + intimate_partner_violence_low_savings + healthy_birth_spacing_low_savings
   */
  public parentLowSavings = () => {
    return (
      (this.interventionsChecklist.postpartumMaternalDepression ? this.postpartumMaternalDepression.maternalDepressionEstimatedTotalTermSavingsLow() : 0) +
      (this.interventionsChecklist.breastfeeding ? this.breastfeeding.breastfeedingEstimatedSavingsLow() : 0) +
      (this.interventionsChecklist.smokeCessation ? this.smokingCessation.smokingTabTotalSavingsAdultAndChildLow() : 0) +
      (this.interventionsChecklist.intimidatePartnerViolence ? this.intimatePartner.intimatePartnerViolenceEstimatedSavingsLow() : 0) +
      (this.interventionsChecklist.healthyBirthSpacing ? this.healthBirthSpacing.healthBirthSpacingTotalTermEstimatedSavingsLow() : 0) +
      (this.interventionsChecklist.foodSecurity ? this.foodSecurity.foodSecurityEstimatedSavingsLow() : 0)
    );
  };

  /**
   * @name parent_high_savings
   * @excel Excel F68
   * @formula postpartum_maternal_depression_high_savings + breastfeeding_high_savings + smoking_cessation_high_savings + intimate_partner_violence_high_savings + healthy_birth_spacing_high_savings
   */
  public parentHighSavings = () => {
    return (
      (this.interventionsChecklist.postpartumMaternalDepression ? this.postpartumMaternalDepression.maternalDepressionEstimatedTotalTermSavingsHigh() : 0) +
      (this.interventionsChecklist.breastfeeding ? this.breastfeeding.breastfeedingEstimatedSavingsHigh() : 0) +
      (this.interventionsChecklist.smokeCessation ? this.smokingCessation.smokingTabTotalSavingsAdultAndChildHigh() : 0) +
      (this.interventionsChecklist.intimidatePartnerViolence ? this.intimatePartner.intimatePartnerViolenceEstimatedSavingsHigh() : 0) +
      (this.interventionsChecklist.healthyBirthSpacing ? this.healthBirthSpacing.healthBirthSpacingTotalTermEstimatedSavingsHigh() : 0) +
      (this.interventionsChecklist.foodSecurity ? this.foodSecurity.foodSecurityEstimatedSavingsHigh() : 0)
    );
  };

  /**
   * @name global_estimate_savings_low
   * @excel Excel E9
   * @formula children_low_savings + parent_low_savings
   */
  public globalEstimateSavingsLow = () => {
    return this.childrenLowSavings() + this.parentLowSavings();
  };

  /**
   * @name global_estimate_savings_high
   * @excel Excel F9
   * @formula children_high_savings + parent_high_savings
   */
  public globalEstimateSavingsHigh = () => {
    return this.childrenHighSavings() + this.parentHighSavings();
  };

  /**
   * @name total_savings_avg
   * @formula (global_estimate_savings_low + global_estimate_savings_high) / 2
   */
  public totalSavingsAvg = () => {
    return (
      (this.globalEstimateSavingsLow() + this.globalEstimateSavingsHigh()) / 2
    );
  };

  /**
   * FINAL REPORT
   * NOTES: SOME VALUES COMES FROM Overview Data Screen
   */

  /**
   * @name total_annual_medicaid_program_cost
   * @formula dc_input_5 * medicaid_perc_of_caseload
   */
  public totalAnnualMedicaidProgramCost = () => {
    return (
      this.serviceData.dcInput5() * this.serviceData.medicaidPercOfCaseload()
    );
  };

  /**
   * @name annual_cost_per_child
   * @formula total_annual_program_cost / cs_input_1
   */
  public annualCostPerChild = () => {
    return this.isFiniteNumber(
      this.serviceData.dcInput5() / this.serviceData.csInput1
    );
  };

  /**
   * @name annual_cost_per_medicaid_child
   * @formula total_annual_medicaid_program_cost / cs_input_2
   */
  public annualCostPerMedicaidChild = () => {
    return this.isFiniteNumber(
      this.totalAnnualMedicaidProgramCost() / this.serviceData.csInput2
    );
  };

  /**
   * @name cost_savings_per_medicaid_family
   * @formula total_savings_avg / cs_input_2
   */
  public costSavingsPerMedicaidFamily = () => {
    return this.isFiniteNumber(
      this.totalSavingsAvg() / this.serviceData.csInput2
    );
  };

  /**
   * @name annual_return_on_investment
   * @formula total_savings_avg - medicaid_anual_cost
   */
  public annualReturnOnInvestment = () => {
    return this.totalSavingsAvg() - this.totalAnnualMedicaidProgramCost();
  };

  /**
   * @name annual_medicaid_return_on_investment
   * @formula total_savings_avg * medicaid_perc_of_caseload
   */
  public annualMedicaidReturnOnInvestment = () => {
    return this.totalSavingsAvg() * this.serviceData.medicaidPercOfCaseload();
  };

  /**
   * @name national_office_demostrated_annualized_savings_to_medicaid_up_to_dollars_per_family
   * @formula global_estimate_savings_high / cs_input_2
   */
  public nationalOfficeDemostratedAnnualizedSavingsToMedicaidUpToDollarsPerFamily = () => {
    return this.isFiniteNumber(
      this.globalEstimateSavingsHigh() / this.serviceData.csInput2
    );
  };

  /**
   * percentage needs to be 75%, 168% (NOT 0.75, or 1.68)
   * @name annual_return_on_investment_perc
   * @formula ( total_savings_avg - total_annual_medicaid_program_cost) / total_annual_medicaid_program_cost
   */
  public annualReturnOnInvestmentPerc = () => {
    return this.isFiniteNumber(
      ((this.totalSavingsAvg() - this.totalAnnualMedicaidProgramCost()) /
        this.totalAnnualMedicaidProgramCost()) *
        100
    );
  };

  /**
   * assuming annual_return_on_investment_perc is 168% this calculation is
   * @name an_estimated_dollars_in_savings
   * @formula annual_return_on_investment_perc * 0.01
   */
  public anEstimatedDollarsInSavings = () => {
    return this.isFiniteNumber(this.annualReturnOnInvestmentPerc() * 0.01);
  };

  /**
   * @name medicaid_percentage_of_totals
   * @formula dc_input_5 * medicaid_perc_of_caseload
   */
  public medicaidPercentageOfTotals = () => {
    return (this.serviceData.csInput2 * 100) / this.serviceData.csInput1;
  };

  /**
   * @name return_per_medicaid_child
   * @formula costSavingsPerMedicaidFamily - annualCostPerChild
   */
  public returnPerMedicaidChild = () => {
    return this.costSavingsPerMedicaidFamily() - this.annualCostPerChild();
  };

  /**
   * @name return_per_medicaid_child
   * @formula costSavingsPerMedicaidFamily - annualCostPerChild
   */
  public totalSavingsByChild = () => {
    return (this.isFiniteNumber(
      (this.interventionsChecklist[InterventionsChecklistForm.OralHealth]
        ? this.oralHealth.dentalEstimatedSavingAverage()
        : 0) +
      (this.interventionsChecklist.emergencyDepartmentUse
        ? this.emergencyDepartment.emergencyDepartmentEstimatedSavingAverage()
        : 0) +
      (this.interventionsChecklist.immunizations
        ? this.immunizations.immunizationsEstimatedTotalSavingsAverage()
        : 0) +
      (this.interventionsChecklist.smokeCessation
        ? this.smokingCessation.smokingTabTotalSavingsChildAverage()
        : 0) +
      (this.interventionsChecklist.asthma
        ? this.asthma.asthmaEstimatedSavingsAverage()
        : 0) +
      (this.interventionsChecklist.earlyDetection
        ? this.earlyDetection.earlyDetectionEstimatedAverage()
        : 0) +
      (this.interventionsChecklist.diapers
        ? this.diapers.diapersEstimatedSavingsAverage()
        : 0) +
      (this.interventionsChecklist.positiveParenting
        ? this.positiveParenting.positiveParentingEstimatedSavingsAverage()
        : 0) +
      ((0.332917186849771 + 0.0636704119850187) *
        this.postpartumMaternalDepression.maternalDepressionEstimatedTotalTermSavingsAverage())
    ));
  }; 

  public totalSavingsByAdult = () => {
    return (this.isFiniteNumber(
      (this.interventionsChecklist.breastfeeding ? this.breastfeeding.breastfeedingEstimatedSavingsAverage() : 0) +
      (this.interventionsChecklist.smokeCessation ? this.smokingCessation.smokingTabTotalSavingsadultAverage() : 0) +
      (this.interventionsChecklist.intimidatePartnerViolence ? this.intimatePartner.intimatePartnerViolenceEstimatedSavingsAverage() : 0) +
      (this.interventionsChecklist.healthyBirthSpacing ? this.healthBirthSpacing.healthBirthSpacingTotalTermEstimatedSavingsAverage() : 0) +
      (this.interventionsChecklist.foodSecurity ? this.foodSecurity.foodSecurityEstimatedSavingsAverage() : 0) +
      (this.interventionsChecklist.foodSecurity ? this.positiveParenting.positiveParentingEstimatedSavingsAverage() : 0) +
      (0.60341240116521 * this.postpartumMaternalDepression.maternalDepressionEstimatedTotalTermSavingsAverage() )
    ));
  };


  /**
   * @name return_per_medicaid_child
   * @formula costSavingsPerMedicaidFamily - annualCostPerChild
   */
  public totalSavingsByShortTerm = () => {
    return (this.isFiniteNumber(
      (this.interventionsChecklist[InterventionsChecklistForm.OralHealth]
        ? this.oralHealth.dentalEstimatedShortTermAverage()
        : 0) +
      (this.interventionsChecklist.emergencyDepartmentUse
        ? this.emergencyDepartment.emergencyDepartmentEstimatedSavingAverage()
        : 0) +
      (this.interventionsChecklist.immunizations
        ? this.immunizations.immunizationsEstimatedShortTermSavingsAverage()
        : 0) +      
      (this.interventionsChecklist.asthma
        ? this.asthma.asthmaEstimatedSavingsAverage()
        : 0) +
      (this.interventionsChecklist.intimatePartnerViolence
        ? this.intimatePartner.intimatePartnerViolenceEstimatedSavingsAverage()
        : 0) +
      (this.interventionsChecklist.breastfeeding 
        ? this.breastfeeding.breastfeedingEstimatedSavingsAverage() 
        : 0) +
      (this.interventionsChecklist.postpartumMaternalDepression
        ? this.postpartumMaternalDepression.maternalDepressionEstimatedShortTermSavingsAverage()
        : 0) +
      (this.interventionsChecklist.healthyBirthSpacing ? this.healthBirthSpacing.healthBirthSpacingShortTermEstimatedSavingsAverage() : 0) +
      (this.interventionsChecklist.diapers
        ? this.diapers.diapersEstimatedSavingsAverage()
        : 0) +
      (this.interventionsChecklist.foodSecurity ? this.foodSecurity.foodSecurityEstimatedSavingsAverage() : 0)
      //  need to add for Diversion
    ));
  }; 

  /**
   * @name return_per_medicaid_child
   * @formula costSavingsPerMedicaidFamily - annualCostPerChild
   */
  public totalSavingsByLongTerm = () => {
    return (this.isFiniteNumber(
      (this.interventionsChecklist[InterventionsChecklistForm.OralHealth]
        ? this.oralHealth.dentalEstimatedLongTermAverage()
        : 0) +
        (this.interventionsChecklist.immunizations
          ? this.immunizations.immunizationsEstimatedLongTermSavingsAverage()
          : 0) +         
        (this.interventionsChecklist.postpartumMaternalDepression
          ? this.postpartumMaternalDepression.maternalDepressionEstimatedLongTermSavingsAverage()
          : 0) +
        (this.interventionsChecklist.smokeCessation ? this.smokingCessation.smokingTabTotalSavingsadultAverage() : 0) +
        (this.interventionsChecklist.healthyBirthSpacing ? this.healthBirthSpacing.healthBirthSpacingLongTermEstimatedSavingsAverage() : 0) +
        (this.interventionsChecklist.earlyDetection
          ? this.earlyDetection.earlyDetectionEstimatedAverage()
          : 0) +
        (this.interventionsChecklist.positiveParenting
          ? this.positiveParenting.positiveParentingEstimatedSavingsAverage()
          : 0)
    ));
  }; 


  public totalSavingsByPopulation = () => {
    return this.isFiniteNumber(this.totalSavingsByChild() + this.totalSavingsByAdult());
  };

  public totalSavingsChildPercentage = () => {
    return this.isFiniteNumber((this.totalSavingsByChild()/this.totalSavingsByPopulation()) * 100);
  };

  public totalSavingsAdultPercentage = () => {
    return this.isFiniteNumber((this.totalSavingsByAdult()/this.totalSavingsByPopulation()) * 100);
  };

  public totalSavingsPopulationPercentage = () => {
    return this.isFiniteNumber((this.totalSavingsChildPercentage()+this.totalSavingsAdultPercentage()));
  };

  public totalSavingsByTimeframe = () => {
    return this.isFiniteNumber(this.totalSavingsByShortTerm() + this.totalSavingsByLongTerm());
  };

  public totalSavingsShortTermPercentage = () => {
    return this.isFiniteNumber((this.totalSavingsByShortTerm()/this.totalSavingsByTimeframe()) * 100);
  };

  public totalSavingsLongTermPercentage = () => {
    return this.isFiniteNumber((this.totalSavingsByLongTerm()/this.totalSavingsByTimeframe()) * 100);
  };

  public totalSavingsTimeframePercentage = () => {
    return this.isFiniteNumber((this.totalSavingsShortTermPercentage()+this.totalSavingsLongTermPercentage()));
  };

  public shortTermLowSavings = () => {
    return this.isFiniteNumber( (this.interventionsChecklist[InterventionsChecklistForm.OralHealth]
      ? this.oralHealth.dentalEstimatedSavingLow()
      : 0) +
    (this.interventionsChecklist.emergencyDepartmentUse
      ? this.emergencyDepartment.emergencyDepartmentEstimatedSavingLow()
      : 0) +
    (this.interventionsChecklist.immunizations
      ? this.immunizations.immunizationsEstimatedSavingsLow()
      : 0) +      
    (this.interventionsChecklist.asthma
      ? this.asthma.asthmaEstimatedSavingsLow()
      : 0) +
    (this.interventionsChecklist.postpartumMaternalDepression
      ? this.postpartumMaternalDepression.maternalDepressionEstimatedSavingsLow()
      : 0) +    
    (this.interventionsChecklist.healthyBirthSpacing 
      ? this.healthBirthSpacing.healthBirthSpacingEstimatedSavingsLow() 
      : 0) +
    (this.interventionsChecklist.intimatePartnerViolence
      ? this.intimatePartner.intimatePartnerViolenceEstimatedSavingsLow()
      : 0) +
    (this.interventionsChecklist.breastfeeding 
      ? this.breastfeeding.breastfeedingEstimatedSavingsLow() 
      : 0) +
    (this.interventionsChecklist.diapers
      ? this.diapers.diapersEstimatedSavingsLow()
      : 0) +
    (this.interventionsChecklist.foodSecurity ? this.foodSecurity.foodSecurityEstimatedSavingsLow() : 0)
    );
  };

  public shortTermHighSavings = () => {
    return this.isFiniteNumber( (this.interventionsChecklist[InterventionsChecklistForm.OralHealth]
      ? this.oralHealth.dentalEstimatedSavingHigh()
      : 0) +
    (this.interventionsChecklist.emergencyDepartmentUse
      ? this.emergencyDepartment.emergencyDepartmentEstimatedSavingHigh()
      : 0) +
    (this.interventionsChecklist.immunizations
      ? this.immunizations.immunizationsEstimatedSavingsHigh()
      : 0) +      
    (this.interventionsChecklist.asthma
      ? this.asthma.asthmaEstimatedSavingsHigh()
      : 0) +
    (this.interventionsChecklist.postpartumMaternalDepression
      ? this.postpartumMaternalDepression.maternalDepressionEstimatedSavingsHigh()
      : 0) +    
    (this.interventionsChecklist.healthyBirthSpacing 
      ? this.healthBirthSpacing.healthBirthSpacingEstimatedSavingsHigh() 
      : 0) +
    (this.interventionsChecklist.intimatePartnerViolence
      ? this.intimatePartner.intimatePartnerViolenceEstimatedSavingsHigh()
      : 0) +
    (this.interventionsChecklist.breastfeeding 
      ? this.breastfeeding.breastfeedingEstimatedSavingsHigh() 
      : 0) +
    (this.interventionsChecklist.diapers
      ? this.diapers.diapersEstimatedSavingsHigh()
      : 0) +
    (this.interventionsChecklist.foodSecurity ? this.foodSecurity.foodSecurityEstimatedSavingsHigh() : 0)
    );
  };

  public shortTermSavingsAverage = () =>{
    return this.isFiniteNumber((this.shortTermLowSavings() + this.shortTermHighSavings())/2);
  };

  public shortTermLowMedicaidSavings = () =>{
    return this.isFiniteNumber(((this.interventionsChecklist[InterventionsChecklistForm.OralHealth]
      ? this.oralHealth.dentalEstimatedSavingLow()
      : 0)+
      (this.interventionsChecklist.emergencyDepartmentUse
        ? this.emergencyDepartment.emergencyDepartmentEstimatedSavingLow()
        : 0) +
      (this.interventionsChecklist.immunizations
        ? this.immunizations.immunizationsEstimatedSavingsLow()
        : 0) +      
      (this.interventionsChecklist.asthma
        ? this.asthma.asthmaEstimatedSavingsLow()
        : 0) +
      (this.interventionsChecklist.postpartumMaternalDepression
        ? this.postpartumMaternalDepression.maternalDepressionEstimatedSavingsLow()
        : 0) + 
      ((this.interventionsChecklist.healthyBirthSpacing 
        ? this.healthBirthSpacing.healthBirthSpacingEstimatedSavingsLow() 
        : 0) * (this.interventionsChecklist.healthyBirthSpacing 
        ? this.healthBirthSpacing.healthBirthSpacingShortTermMedicaidSavings()
        : 0)) +
      (this.interventionsChecklist.intimatePartnerViolence
        ? this.intimatePartner.intimatePartnerViolenceEstimatedSavingsLow()
        : 0) + 
      (this.interventionsChecklist.breastfeeding 
        ? this.breastfeeding.breastfeedingEstimatedSavingsLow() 
        : 0) +
      (this.interventionsChecklist.diapers
        ? this.diapers.diapersEstimatedSavingsLow()
        : 0) +
      (this.interventionsChecklist.foodSecurity ? this.foodSecurity.foodSecurityEstimatedSavingsLow() : 0)    
    )
    ) * this.serviceData.medicaidPercOfCaseload();
  };

  public shortTermHighMedicaidSavings = () => {
    return this.isFiniteNumber( ((this.interventionsChecklist[InterventionsChecklistForm.OralHealth]
      ? this.oralHealth.dentalEstimatedSavingHigh()
      : 0) +
    (this.interventionsChecklist.emergencyDepartmentUse
      ? this.emergencyDepartment.emergencyDepartmentEstimatedSavingHigh()
      : 0) +
    (this.interventionsChecklist.immunizations
      ? this.immunizations.immunizationsEstimatedSavingsHigh()
      : 0) +      
    (this.interventionsChecklist.asthma
      ? this.asthma.asthmaEstimatedSavingsHigh()
      : 0) +
    (this.interventionsChecklist.postpartumMaternalDepression
      ? this.postpartumMaternalDepression.maternalDepressionEstimatedSavingsHigh()
      : 0) +    
    ((this.interventionsChecklist.healthyBirthSpacing 
      ? this.healthBirthSpacing.healthBirthSpacingEstimatedSavingsHigh() 
      : 0) * (this.interventionsChecklist.healthyBirthSpacing 
      ? this.healthBirthSpacing.healthBirthSpacingShortTermMedicaidSavings()
      : 0)) +
    (this.interventionsChecklist.healthyBirthSpacing 
      ? this.healthBirthSpacing.healthBirthSpacingEstimatedSavingsHigh() 
      : 0) +
    (this.interventionsChecklist.intimatePartnerViolence
      ? this.intimatePartner.intimatePartnerViolenceEstimatedSavingsHigh()
      : 0) +
    (this.interventionsChecklist.breastfeeding 
      ? this.breastfeeding.breastfeedingEstimatedSavingsHigh() 
      : 0) +
    (this.interventionsChecklist.diapers
      ? this.diapers.diapersEstimatedSavingsHigh()
      : 0) +
    (this.interventionsChecklist.foodSecurity ? this.foodSecurity.foodSecurityEstimatedSavingsHigh() : 0)) * 
    this.serviceData.medicaidPercOfCaseload()
    );
  };

  public shortTermMedicaidSavingsAverage = () =>{
    return this.isFiniteNumber((this.shortTermLowMedicaidSavings() + this.shortTermHighMedicaidSavings())/2);
  };

  public longTermLowSavings = () => {
    return this.isFiniteNumber( (this.interventionsChecklist[InterventionsChecklistForm.OralHealth]
      ? this.oralHealth.dentalEstimatedLongTermSavingLow()
      : 0) +
    (this.interventionsChecklist.postpartumMaternalDepression
      ? this.postpartumMaternalDepression.maternalDepressionEstimatedLongTermSavingsLow()
      : 0) +   
    (this.interventionsChecklist.healthyBirthSpacing 
      ? this.healthBirthSpacing.healthBirthSpacingLongTermEstimatedSavingsLow() 
      : 0) +
    (this.interventionsChecklist.smokeCessation ? this.smokingCessation.smokingTabTotalSavingsAdultAndChildLow() : 0) +
    (this.interventionsChecklist.earlyDetection ? this.earlyDetection.earlyDetectionEstimatedLow() : 0) +
    (this.interventionsChecklist.positiveParenting ? this.positiveParenting.positiveParentingEstimatedSavingsLow() : 0) +
    (this.interventionsChecklist.immunizations
      ? this.immunizations.immunizationsEstimatedLongTermSavingsLow()
      : 0) 
    );
  };

  public longTermHighSavings = () => {
    return this.isFiniteNumber( (this.interventionsChecklist[InterventionsChecklistForm.OralHealth]
      ? this.oralHealth.dentalEstimatedLongTermSavingHigh()
      : 0) +
    (this.interventionsChecklist.postpartumMaternalDepression
      ? this.postpartumMaternalDepression.maternalDepressionEstimatedLongTermSavingsHigh()
      : 0) +   
    (this.interventionsChecklist.healthyBirthSpacing 
      ? this.healthBirthSpacing.healthBirthSpacingLongTermEstimatedSavingsHigh() 
      : 0) +
    (this.interventionsChecklist.smokeCessation ? this.smokingCessation.smokingTabTotalSavingsAdultAndChildHigh() : 0) +
    (this.interventionsChecklist.earlyDetection ? this.earlyDetection.earlyDetectionEstimatedHigh() : 0) +
    (this.interventionsChecklist.positiveParenting ? this.positiveParenting.positiveParentingEstimatedSavingsHigh() : 0) +
    (this.interventionsChecklist.immunizations
      ? this.immunizations.immunizationsEstimatedLongTermSavingsHigh()
      : 0) 
    );
  };

  public longTermSavingsAverage = () =>{
    return this.isFiniteNumber((this.longTermLowSavings() + this.longTermHighSavings())/2);
  };

  public longTermLowMedicaidSavings = () =>{
    return this.isFiniteNumber(((this.interventionsChecklist[InterventionsChecklistForm.OralHealth]
      ? this.oralHealth.dentalEstimatedLongTermSavingLow()
      : 0)+
      (this.interventionsChecklist.postpartumMaternalDepression
        ? this.postpartumMaternalDepression.maternalDepressionEstimatedLongTermSavingsLow()
        : 0) + 
      (this.interventionsChecklist.healthyBirthSpacing 
        ? this.healthBirthSpacing.healthBirthSpacingLongTermMedicaidSavingsLow() 
        : 0) +
      (this.interventionsChecklist.smokeCessation ? this.smokingCessation.smokingTabTotalSavingsAdultAndChildLow() : 0) +
      (this.interventionsChecklist.positiveParenting ? this.positiveParenting.positiveParentingLongTermMedicaidSavings() : 0) +
      (this.interventionsChecklist.immunizations
        ? this.immunizations.immunizationsEstimatedLongTermSavingsLow()
        : 0)) * this.serviceData.medicaidPercOfCaseload()      
    );
  };

  public longTermHighMedicaidSavings = () =>{
    return this.isFiniteNumber(((this.interventionsChecklist[InterventionsChecklistForm.OralHealth]
      ? this.oralHealth.dentalEstimatedLongTermSavingHigh()
      : 0)+
      (this.interventionsChecklist.postpartumMaternalDepression
        ? this.postpartumMaternalDepression.maternalDepressionEstimatedLongTermSavingsHigh()
        : 0) + 
      (this.interventionsChecklist.healthyBirthSpacing 
        ? this.healthBirthSpacing.healthBirthSpacingLongTermMedicaidSavingsHigh() 
        : 0) +
      (this.interventionsChecklist.smokeCessation ? this.smokingCessation.smokingTabTotalSavingsAdultAndChildHigh() : 0) +
      (this.interventionsChecklist.positiveParenting ? this.positiveParenting.positiveParentingLongTermMedicaidSavings() : 0) +
      (this.interventionsChecklist.immunizations
        ? this.immunizations.immunizationsEstimatedLongTermSavingsHigh()
        : 0)) *  this.serviceData.medicaidPercOfCaseload()
    );
  };

  public longTermMedicaidSavingsAverage = () =>{
    return this.isFiniteNumber((this.shortTermLowMedicaidSavings() + this.shortTermHighMedicaidSavings())/2);
  };

  public totalSavingsLow = () =>{
    return this.isFiniteNumber((this.shortTermLowSavings() + this.longTermLowSavings()));
  };

  public totalSavingsHigh = () =>{
    return this.isFiniteNumber((this.shortTermHighSavings() + this.longTermHighSavings()));
  };

  public totalSavingsAverage = () => {
    return this.isFiniteNumber((this.shortTermSavingsAverage() + this.longTermSavingsAverage()));
  };

  public totalSiteServiceDeliveryCost = () => {
    return this.isFiniteNumber((this.serviceData.dcInput1 + this.serviceData.dcInput2 + this.serviceData.dcInput3));
  };

  public shortTermLowROI = () => {
    return this.isFiniteNumber((this.shortTermLowSavings() - this.totalSiteServiceDeliveryCost())/ this.totalSiteServiceDeliveryCost() );
  };

  public shortTermHighROI = () => {
    return this.isFiniteNumber((this.shortTermHighSavings() - this.totalSiteServiceDeliveryCost())/ this.totalSiteServiceDeliveryCost() );
  };

  public shortTermROIAverage = () => {
    return this.isFiniteNumber((this.shortTermSavingsAverage() - this.totalSiteServiceDeliveryCost())/ this.totalSiteServiceDeliveryCost());
  };

  public shortTermLowMedicaidROI = () => {
    return this.isFiniteNumber((this.shortTermLowMedicaidSavings() - 
    (this.totalSiteServiceDeliveryCost() * this.serviceData.medicaidPercOfCaseload())) / (this.totalSiteServiceDeliveryCost() * this.serviceData.medicaidPercOfCaseload()) );
  };

  public shortTermHighMedicaidROI = () => {
    return this.isFiniteNumber((this.shortTermHighMedicaidSavings() - 
    (this.totalSiteServiceDeliveryCost() * this.serviceData.medicaidPercOfCaseload())) / (this.totalSiteServiceDeliveryCost() * this.serviceData.medicaidPercOfCaseload()) );
  };

  public shortTermROIMedicaidAverage = () => {
    return this.isFiniteNumber((this.shortTermMedicaidSavingsAverage() -
    (this.totalSiteServiceDeliveryCost() * this.serviceData.medicaidPercOfCaseload())) / (this.totalSiteServiceDeliveryCost() * this.serviceData.medicaidPercOfCaseload()) );
   
  };

  public longTermLowROI = () => {
    return this.isFiniteNumber((this.longTermLowSavings() - this.totalSiteServiceDeliveryCost())/ this.totalSiteServiceDeliveryCost() );
  };

  public longTermHighROI = () => {
    return this.isFiniteNumber((this.longTermHighSavings() - this.totalSiteServiceDeliveryCost())/ this.totalSiteServiceDeliveryCost() );
  };

  public longTermROIAverage = () => {
    return this.isFiniteNumber((this.longTermSavingsAverage() - this.totalSiteServiceDeliveryCost())/ this.totalSiteServiceDeliveryCost());
  };

  public longTermLowMedicaidROI = () => {
    return this.isFiniteNumber((this.longTermLowMedicaidSavings() - 
    (this.totalSiteServiceDeliveryCost() * this.serviceData.medicaidPercOfCaseload())) / (this.totalSiteServiceDeliveryCost() * this.serviceData.medicaidPercOfCaseload()) );

  };

  public longTermHighMedicaidROI = () => {
    return this.isFiniteNumber((this.longTermHighMedicaidSavings() - 
    (this.totalSiteServiceDeliveryCost() * this.serviceData.medicaidPercOfCaseload())) / (this.totalSiteServiceDeliveryCost() * this.serviceData.medicaidPercOfCaseload()) );
  };

  public longTermROIMedicaidAverage = () => {
    return this.isFiniteNumber((this.longTermMedicaidSavingsAverage() -
    (this.totalSiteServiceDeliveryCost() * this.serviceData.medicaidPercOfCaseload())) / (this.totalSiteServiceDeliveryCost() * this.serviceData.medicaidPercOfCaseload()) );
  };

  /**
   * @name children_low_savings
   * @excel Excel E11
   * @formula dental_low_savings + emergency_deparment_use_low_savings + inmunizations_low_savings + asthma_low_savings
   */
  public interventionForChildrensLow = () => {
    return (
      (this.interventionsChecklist[InterventionsChecklistForm.OralHealth] ? this.oralHealth.dentalEstimatedTotalTermSavingLow() : 0) +
        (this.interventionsChecklist.emergencyDepartmentUse ? this.emergencyDepartment.emergencyDepartmentEstimatedSavingLow() : 0) +
        (this.interventionsChecklist.immunizations ? this.immunizations.immunizationsEstimatedTotalSavingsLow() : 0) +
        (this.interventionsChecklist.asthma ? this.asthma.asthmaEstimatedSavingsLow() : 0)  +
        (this.interventionsChecklist.earlyDetection ? this.earlyDetection.earlyDetectionEstimatedLow() : 0)
        + (this.interventionsChecklist.diapers ? this.diapers.diapersEstimatedSavingsLow() : 0)
    );
  };
  
  /**
     * @name children_high_savings
     * @excel Excel F11
     * @formula dental_high_savings + emergency_deparment_use_high_savings + inmunizations_high_savings + asthma_high_savings
     */
  public interventionForChildrensHigh = () => {
    return (
      (this.interventionsChecklist[InterventionsChecklistForm.OralHealth] ? this.oralHealth.dentalEstimatedTotalTermSavingHigh() : 0) +
        (this.interventionsChecklist.emergencyDepartmentUse ? this.emergencyDepartment.emergencyDepartmentEstimatedSavingHigh() : 0) +
        (this.interventionsChecklist.immunizations ? this.immunizations.immunizationsEstimatedTotalSavingsHigh() : 0) +
        (this.interventionsChecklist.asthma ? this.asthma.asthmaEstimatedSavingsHigh() : 0)  +
        (this.interventionsChecklist.earlyDetection ? this.earlyDetection.earlyDetectionEstimatedHigh() : 0)
        + (this.interventionsChecklist.diapers ? this.diapers.diapersEstimatedSavingsHigh() : 0)
    );
  };
  
  /**
     * @name parent_low_savings
     * @excel Excel E68
     * @formula postpartum_maternal_depression_low_savings + breastfeeding_low_savings + smoking_cessation_low_savings + intimate_partner_violence_low_savings + healthy_birth_spacing_low_savings
     */
  public interventionForAdultsLow = () => {
    return (
      (this.interventionsChecklist.postpartumMaternalDepression ? this.postpartumMaternalDepression.maternalDepressionEstimatedTotalTermSavingsLow() : 0) +
        (this.interventionsChecklist.breastfeeding ? this.breastfeeding.breastfeedingEstimatedSavingsLow() : 0) +
        (this.interventionsChecklist.smokeCessation ? this.smokingCessation.smokingTabTotalSavingsAdultAndChildLow() : 0) +
        (this.interventionsChecklist.intimidatePartnerViolence ? this.intimatePartner.intimatePartnerViolenceEstimatedSavingsLow() : 0) +
        (this.interventionsChecklist.healthyBirthSpacing ? this.healthBirthSpacing.healthBirthSpacingTotalTermEstimatedSavingsLow() : 0) +
        (this.interventionsChecklist.foodSecurity ? this.foodSecurity.foodSecurityEstimatedSavingsLow() : 0) +
        (this.interventionsChecklist.positiveParenting? this.positiveParenting.positiveParentingEstimatedSavingsLow() : 0)
    );
  };
  
  /**
     * @name parent_high_savings
     * @excel Excel F68
     * @formula postpartum_maternal_depression_high_savings + breastfeeding_high_savings + smoking_cessation_high_savings + intimate_partner_violence_high_savings + healthy_birth_spacing_high_savings
     */
  public interventionForAdultsHigh = () => {
    return (
      (this.interventionsChecklist.postpartumMaternalDepression ? this.postpartumMaternalDepression.maternalDepressionEstimatedTotalTermSavingsHigh() : 0) +
        (this.interventionsChecklist.breastfeeding ? this.breastfeeding.breastfeedingEstimatedSavingsHigh() : 0) +
        (this.interventionsChecklist.smokeCessation ? this.smokingCessation.smokingTabTotalSavingsAdultAndChildHigh() : 0) +
        (this.interventionsChecklist.intimidatePartnerViolence ? this.intimatePartner.intimatePartnerViolenceEstimatedSavingsHigh() : 0) +
        (this.interventionsChecklist.healthyBirthSpacing ? this.healthBirthSpacing.healthBirthSpacingTotalTermEstimatedSavingsHigh() : 0) +
        (this.interventionsChecklist.foodSecurity ? this.foodSecurity.foodSecurityEstimatedSavingsHigh() : 0) +
        (this.interventionsChecklist.positiveParenting? this.positiveParenting.positiveParentingEstimatedSavingsLow() : 0)
    );
  };

  public interventionForChildrensAverage = () =>{
    return this.isFiniteNumber((this.interventionForChildrensLow() + this.interventionForChildrensHigh()/2));
  };

  public interventionForAdultsAverage = () =>{
    return this.isFiniteNumber((this.interventionForAdultsLow() + this.interventionForAdultsHigh()/2));
  };

  public totalNetSavingsLow = () => {
    return this.isFiniteNumber(this.totalSavingsLow() - this.totalSiteServiceDeliveryCost());
  };

  public totalNetSavingsHigh = () => {
    return this.isFiniteNumber(this.totalSavingsHigh() - this.totalSiteServiceDeliveryCost());
  };

  public totalNetSavingsAverage = () => {
    return this.isFiniteNumber(this.totalSavingsAverage() - this.totalSiteServiceDeliveryCost());
  };

  public totalChildWelfareSavings = () => {
    return this.isFiniteNumber(this.positiveParenting.positiveParentingAbuseFosterCare() + this.healthBirthSpacing.birthsChildWelfareSavings())
  }
  
  public totalPublicAssistanceSavings = () => {
    return this.isFiniteNumber(this.healthBirthSpacing.birthsPublicAssistanceSavings() + this.earlyDetection.earlydetectionTabEarlyEducationProgramsTotalNetSavingsAverage())
  }

  public totalSpecialEducationSavings = () => {
    return this.isFiniteNumber(this.positiveParenting.positiveParentingEducationService() + this.earlyDetection.earlydetectionTabEarlyInterventionTotalNetSavingsAverage())
  }

  public totalHealthCareSavings = () => {
    return this.isFiniteNumber(this.oralHealth.dentalEstimatedSavingAverage() + this.emergencyDepartment.emergencyDepartmentEstimatedSavingAverage()
    + this.immunizations.immunizationsEstimatedTotalSavingsAverage() + this.asthma.asthmaEstimatedSavingsAverage() + 
    this.postpartumMaternalDepression.maternalDepressionEstimatedTotalTermSavingsAverage() + this.healthBirthSpacing.birthsHealthcareSavings()
    + this.intimatePartner.intimatePartnerViolenceEstimatedSavingsAverage() + this.breastfeeding.breastfeedingEstimatedSavingsAverage() +
    this.smokingCessation.smokingTabTotalSavingsAdultAndChildAverage() + this.foodSecurity.foodSecurityEstimatedSavingsAverage() +
    this.diapers.diapersEstimatedSavingsAverage() + this.positiveParenting.positiveParentingTotalHealthService())
  }

  public totalSavingsSector = () => {
    return this.isFiniteNumber(this.totalHealthCareSavings() + this.totalSpecialEducationSavings() + this.totalPublicAssistanceSavings()
    + this.healthBirthSpacing.birthsHeadStartSavings() + this.totalChildWelfareSavings() + this.positiveParenting.positiveParentingCrime());
  }
  

}
  
