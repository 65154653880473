import ServiceData from './serviceData';
import Calculator from './shared/base';

export default class IntimatePartner extends Calculator {
  public serviceData: ServiceData;

  public receivedCounseling: number;

  constructor({
    state,
    serviceData,
    lowEstimatedSlider,
    highEstimatedSlider,
    receivedCounseling,
  }: {
    state: string;
    serviceData: ServiceData;
    lowEstimatedSlider: number;
    highEstimatedSlider: number;
    receivedCounseling: number;
  }) {
    super({ state });
    this.serviceData = serviceData;
    this.lowEstimatedSlider = lowEstimatedSlider / 100;
    this.highEstimatedSlider = highEstimatedSlider / 100;
    this.receivedCounseling = receivedCounseling;
  }

  /**
   * @name ipv_tab_percentage_of_woman_in_sample_with_higher_ipv_score
   * @excel Excel IPV Tab B5
   * @constant 12.280701754386
   */
  public readonly ipvTabPercentageOfWomanInSampleWithHigherIpvScore = this
    .constants.intimatePartner
    .ipvTabPercentageOfWomanInSampleWithHigherIpvScore;

  /**
   * @name ipv_tab_percentage_of_woman_in_sample_with_lower_ipv_score
   * @excel Excel IPV Tab B5
   * @constant 13.3333333333333
   */
  public readonly ipvTabPercentageOfWomanInSampleWithLowerIpvScore = this
    .constants.intimatePartner
    .ipvTabPercentageOfWomanInSampleWithLowerIpvScore;

  /**
   * @name ipv_tab_mean_difference_in_total_medicaid_expenditures_for_women_with_higher_IPV_compared_to_with_those_with_no_IPV
   * @excel Excel IPV Tab B6
   * @constant 1064
   */
  public readonly ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV = this
    .constants.intimatePartner
    .ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV;

  /**
   * @name ipv_tab_medical_price_inflaction_factor
   * @excel Excel IPV Tab B7
   * @constant 2.06
   */
  public readonly ipvTabMedicalPriceInflactionFactor = this.constants
    .intimatePartner.ipvTabMedicalPriceInflactionFactor;

  /**
   * @name percentage_of_medicaid_women_currently_experiencing_severe_ipv
   * @excel Excel C111
   * @reference ipv_tab_percentage_of_woman_in_sample_with_higher_ipv_score
   */
  public percentageOfMedicaidWomenCurrentlyExperiencingSevereIpv = () => {
    return this.isFiniteNumber(
      this.ipvTabPercentageOfWomanInSampleWithHigherIpvScore/(this.ipvTabPercentageOfWomanInSampleWithLowerIpvScore + this.ipvTabPercentageOfWomanInSampleWithHigherIpvScore)

    );
  };

  /**
   * @name estimated_number_of_HS_Medicaid_mothers_currently_experiencing_severe_ipv
   * @excel Excel C112
   * @formula percentage_of_medicaid_women_currently_experiencing_severe_ipv * cs_input_4 (from Service Data Screen)
   */
  public estimatedNumberOfHSMedicaidMothersCurrentlyExperiencingSevereIpv = () => {
    return this.isFiniteNumber(
      this.percentageOfMedicaidWomenCurrentlyExperiencingSevereIpv() *
        this.receivedCounseling
    );
  };

  /**
   * low_estimated_slider (from app)
   * @name low_estimated_slider
   * @excel Excel C113
   */
  public lowEstimatedSlider;

  /**
   * high_estimated_slider (from app)
   * @name high_estimated_slider
   * @excel Excel C114
   */
  public highEstimatedSlider;

  /**
   * @name estimated_number_of_severe_ipv_cases_with_improvement_due_to_HS_low
   * @excel Excel C115
   * @formula estimated_number_of_HS_Medicaid_mothers_currently_experiencing_severe_ipv * low_estimated_slider
   */
  public estimatedNumberOfSevereIpvCasesWithImprovementDueToHSLow = () => {
    return this.isFiniteNumber(
      this.estimatedNumberOfHSMedicaidMothersCurrentlyExperiencingSevereIpv() *
        this.lowEstimatedSlider
    );
  };

  /**
   * @name estimated_number_of_severe_ipv_cases_with_improvement_due_to_HS_high
   * @excel Excel C116
   * @formula estimated_number_of_HS_Medicaid_mothers_currently_experiencing_severe_ipv * high_estimated_slider
   */
  public estimatedNumberOfSevereIpvCasesWithImprovementDueToHSHigh = () => {
    return this.isFiniteNumber(
      this.estimatedNumberOfHSMedicaidMothersCurrentlyExperiencingSevereIpv() *
        this.highEstimatedSlider
    );
  };

  /**
   * @name savings_per_severe_IPV_case_with_improvement_annual
   * @excel Excel C117
   * @formula ipv_tab_mean_difference_in_total_medicaid_expenditures_for_women_with_higher_IPV_compared_to_with_those_with_no_IPV * ipv_tab_medical_price_inflaction_factor
   */
  public savingsPerSevereIPVCaseWithImprovementAnnual = () => {
    return this.isFiniteNumber(
      this
        .ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV *
        this.ipvTabMedicalPriceInflactionFactor
    );
  };

  /**
   * @name intimate_partner_violence_estimated_savings_low
   * @excel Excel E104
   * @formula estimated_number_of_severe_ipv_cases_with_improvement_due_to_HS_low * savings_per_severe_IPV_case_with_improvement_annual
   */
  public intimatePartnerViolenceEstimatedSavingsLow = () => {
    return this.isFiniteNumber(
      this.estimatedNumberOfSevereIpvCasesWithImprovementDueToHSLow() *
        this.savingsPerSevereIPVCaseWithImprovementAnnual()
    );
  };

  /**
   * @name intimate_partner_violence_estimated_savings_high
   * @excel Excel F104
   * @formula estimated_number_of_severe_ipv_cases_with_improvement_due_to_HS_low * savings_per_severe_IPV_case_with_improvement_annual
   */
  public intimatePartnerViolenceEstimatedSavingsHigh = () => {
    return this.isFiniteNumber(
      this.estimatedNumberOfSevereIpvCasesWithImprovementDueToHSHigh() *
        this.savingsPerSevereIPVCaseWithImprovementAnnual()
    );
  };

  /**
   * @name intimate_partner_violence_estimated_savings_high
   * @excel Excel F104
   * @formula estimated_number_of_severe_ipv_cases_with_improvement_due_to_HS_low * savings_per_severe_IPV_case_with_improvement_annual
   */
  public intimatePartnerViolenceEstimatedSavingsAverage = () => {
    return this.isFiniteNumber(
      (this.intimatePartnerViolenceEstimatedSavingsHigh() +
        this.intimatePartnerViolenceEstimatedSavingsLow()) /2
    );
  };

}
