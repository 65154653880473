import { createStyles, StyleRules, Theme } from '@material-ui/core/styles';
import { Colors } from '../../../assets/styles';

export default ({ typography }: Theme): StyleRules => {
  const { pxToRem } = typography;

  return createStyles({
    container: {
      padding: `${pxToRem(48)} ${pxToRem(140)}`,
    },
    header: {
      position: 'relative',
    },
    printBtn: {
      position: 'absolute',
      top: 10,
      right: 40,
    },
    emailBtn: {
      position: 'absolute',
      top: 10,
      right: 0,
    },
    title: {
      textAlign: 'center',
    },
    pdf: {
      transform: 'scale(0.8)',
      transformOrigin: '10% 5%',
      height: '10%',
      width: '980px',
      '& $pdfTitle': {
        display: 'block',
      },
    },
    pdfTitle: {
      textAlign:'center',     
      fontSize: 22,
    },
    emailInput: {
      width: 500,
    },
    completedDate: {
      textAlign: 'right',
      color: Colors.Gray6,
      paddingBottom: `0 !important`,
      '@media print': {
        display: 'block',
      }
    },
    contentTitle: {
      fontSize: 15,
      marginBottom: 24,
    },
  });
};
