import { createStyles, StyleRules, Theme } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { Colors, FontWeight } from '../../../../assets/styles';

const amount: CSSProperties = {
  width: '25%',
  color: Colors.Gray4,
  fontSize: 14,
  textAlign: 'center',
};

const title: CSSProperties = {
  color: Colors.Blue,
  fontSize: 12,
  fontWeight: FontWeight.Regular,
};

export default (): StyleRules => ({
  tableContainer: {
    display: 'flex',
    height: '100%',
    width: '100%',
    paddingLeft:'35px',
    pageBreakBefore: 'always'
  },
  table: {
    marginTop: 20,
    fontSize: '12px',
    height: '100%',
    width: '100%',
    display: 'table',
    border:'none'
  },
  columnTitle: {
    color: Colors.Gray,
    fontWeight: FontWeight.Light,
    fontSize: 14,
    textAlign: 'center',
  },
  sectionTitle: {
    ...title,
    color:'black',
    fontSize: '16px',
    fontWeight: 700,
  },
  contentTitle: {
    ...title,
    color:'black',
    fontSize: '18px',
    fontWeight: 700,
  },
  subsectionTitle: {
    ...title,
    color:'black',
    fontWeight: 300,
    fontSize: 16,
    margin:'5px',
  },
  sectionAmount: {
    ...amount,
  },
  subsectionAmount: {
    ...amount,
    backgroundColor: 'rgba(204,204,204,0.1)',
  },
  descriptionCol: {
    width: '30%',
    padding: '1px',
  },
  descriptionText: {
    margin: 0,
    fontSize: '14px',
    color: 'black',
    padding: '7px 0px 7px 0px',
  },
  noteText: {
    margin: '4px 8px',
    padding: '2px 4px',
    borderLeft: '1px solid #999898',
    marginLeft: '8px',
    borderTop: '1px solid #9998982e',
  },
  valuesCol: {
    padding: '0px',
    '& div:last-of-type': {
      border: 'none',
    },
  },
  valueCell: {
    display: 'flex',
    textAlign: 'center',
    border: 'none',
    borderRight: 'none',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    color:'black',
    fontWeight: 300,
    fontSize: 16,
  },
  full: {
    width: '70%',
    height: '100%',
  },
  half: {
    width: '20%',
    height: '100%',
  },
  third: {
    width: '13%',
    height: '100%',
  },
  smallText: {
    fontSize: 10,
    fontWeight: FontWeight.Light,
  },
  composedCell: {
    padding: 0,
    margin: 0,
    height: '100%',
  },
  multiCont: {
    display: 'flex',
    height: '100%',
    '& div:last-of-type': {
      border: 'none',
    },
  },
});

export const tableCellStyles = ({
  typography,
  breakpoints,
}: Theme): StyleRules => {
  return createStyles({
    root: {
      border: 'none',
    },
  });
};
