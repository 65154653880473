/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import {
  selectInterventionsChecklistValue,
  calculatorActions,
  selectInterventionsValue,
  selectServiceDataForm,
  selectState,
  selectTouched,
  selectReportName,
} from '../../../../redux/calculatorSlice';
import {
  InterventionsChecklistForm,
  PositiveParentingForm,
  IPositiveParenting,
  InterventionsCommonForm,
} from '../../../../interfaces/interventions.interface';
import { CalculatorEnum } from '../../../../interfaces/calculator.interface';
import {
  InterventionHeader,
  InterventionBody,
  InterventionFooter,
  InterventionContainer,
  InterventionFieldNoteDialog,
} from '../Intervention';
import { FormValidations, Constants, StringFormatter } from '../../../../utils';
import useDebounceEffect from '../../../../hooks/useDebounceEffect';
import { PositiveParenting as PositiveParentingClass } from '../../../../calculations';
import { paths } from '../../..';

const { DIALOG_MODES } = Constants;

const PositiveParenting: FunctionComponent = () => {
  const dispatch = useDispatch();
  const switchValue = useSelector(
    selectInterventionsChecklistValue(
      InterventionsChecklistForm.PositiveParenting
    )
  );
  const positiveParentingData = useSelector(
    selectInterventionsValue(CalculatorEnum.PositiveParenting)
  );

  const serviceData = useSelector(selectServiceDataForm);
  const selectedState = useSelector(selectState);
  const touched = useSelector(selectTouched);
  const reportName = useSelector(selectReportName);

  const history = useHistory();

  const [reinitialize, setReinitialize] = useState(true);
  const [noteName, setNoteName] = useState<string>('');
  const [noteAction, setNoteAction] = useState<'add' | 'edit' | 'delete'>(DIALOG_MODES.ADD);
  const [isNoteDialogOpen, setIsNoteDialogOpen] = useState(false);

  const fields = [
    {
      text: `Number of families (caregiver and Medicaid/CHIP-enrolled child) who are referred to a post-natal home visiting program
      `,
      id: PositiveParentingForm.NumberOfFamiliesReferredToPostNatalHomeVisitingPrograms,
      showError: true,
    },
  ];

  const subInterventionfields = [
    {
      text: `Number of parents/guardians/caretakers who are provided sustained positive parenting services and supports by HS Specialist OR referred to a parenting program like Triple P (e.g., ongoing parenting support and education from Specialists during visit)
      `,
      id: PositiveParentingForm.NumberOfGuardiansProvidedPositiveParentingOrReferredToParentingProgram,
      showError: true,
    },
  ];

  const onSubmit = (values: IPositiveParenting) => {
    if (touched) {
      dispatch(
        calculatorActions.saveReport({
          reportName,
          update: true,
        }));
    }
    history.push(
      StringFormatter.replaceWithReportName(
        reportName,
        paths.interventionsPostpartumMaternalDepression
      )
    );
  };

  useEffect(() => {
    if (switchValue) {
      const timeout = setTimeout(() => {
        setReinitialize(false);
      }, 250);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [switchValue]);

  const form = useFormik({
    initialValues: positiveParentingData,
    validationSchema:
      switchValue &&
      FormValidations.intervention({
        [PositiveParentingForm.NumberOfFamiliesReferredToPostNatalHomeVisitingPrograms]:
          FormValidations
            .required('')
            .test(FormValidations.getInterventionNumberOfChildsConfig(PositiveParentingForm.NumberOfFamiliesReferredToPostNatalHomeVisitingPrograms, serviceData.csInput3)),
        [PositiveParentingForm.NumberOfGuardiansProvidedPositiveParentingOrReferredToParentingProgram]:
            FormValidations
              .required('')
              .test(FormValidations.getInterventionNumberOfChildsConfig(PositiveParentingForm.NumberOfGuardiansProvidedPositiveParentingOrReferredToParentingProgram, serviceData.csInput3)),
      }),
    onSubmit,
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: reinitialize || !switchValue,
  });

  useEffect(() => {
    if (!switchValue) {
      form.resetForm();
      dispatch(
        calculatorActions.clearInterventionData(CalculatorEnum.PositiveParenting)
      );
    }
  }, [switchValue]);

  useDebounceEffect(() => {
    const hasBeenEdited = Object.keys(form.values).some(
      (key) => !!form.values[key]
    );
    if (!form.dirty && !hasBeenEdited) return;
    /** Calculations happen */
    const positiveParenting = new PositiveParentingClass({
      state: selectedState,
      serviceData,
      sliderPositiveParentingLowEstimate: +form.values[
        InterventionsCommonForm.LowEstimate
      ],
      sliderPositiveParentingHighEstimate: +form.values[
        InterventionsCommonForm.HighEstimate
      ],
      numberOfFamiliesReferredToPostNatalHomeVisitingPrograms: +form.values[PositiveParentingForm.NumberOfFamiliesReferredToPostNatalHomeVisitingPrograms],
      numberOfGuardiansProvidedPositiveParentingOrReferredToParentingProgram: +form.values[PositiveParentingForm.NumberOfGuardiansProvidedPositiveParentingOrReferredToParentingProgram],
    });

    const longTermSavingsLow = positiveParenting.positiveParentingEstimatedSavingsLow();
    const longTermSavingsHigh = positiveParenting.positiveParentingEstimatedSavingsHigh();

    if (
      +form.values[InterventionsCommonForm.LongTermSavingsLow] !== longTermSavingsLow ||
      +form.values[InterventionsCommonForm.LongTermSavingsHigh] !== longTermSavingsHigh
    ) {
      form.setValues({
        ...form.values,
        [InterventionsCommonForm.LongTermSavingsLow]: longTermSavingsLow.toString(),
        [InterventionsCommonForm.LongTermSavingsHigh]: longTermSavingsHigh.toString(),
      });
    }

    form.validateForm().then((errors) => {
      const isValid = !Object.keys(errors).length;

      if (form.dirty) {
        dispatch(
          calculatorActions.setInterventionData({
            intervention: CalculatorEnum.PositiveParenting,
            value: { ...form.values, isValid: !!isValid },
          })
        );
        dispatch(calculatorActions.setSummaryData());
      }
    });
  }, [form.values]);

  const onSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      calculatorActions.toggleInterventionsChecklistValue({
        key: InterventionsChecklistForm.PositiveParenting,
        value: e.target.checked,
      })
    );
  };

  const onNote = (notesName: string, notesAction: 'add' | 'edit' | 'delete') => {
    setNoteName(notesName);
    setNoteAction(notesAction);
    setIsNoteDialogOpen(true);
  };

  const onDialogNoteClose = () => {
    setIsNoteDialogOpen(false);
  };

  return (
    <>
      <InterventionContainer
        disabled={!switchValue}
        onSubmit={form.handleSubmit}
        header={
          <InterventionHeader
            title="Positive Parenting"
            checked={switchValue}
            onChange={onSwitchChange}
          />
        }
        body={
          <InterventionBody
            // description={
            //   <>
            //     Some HS Specialists talk with caregivers about smoking cessation. The following questions ask about the actions taken by caregivers in these situations during the 12-month date range.
            //   </>
            // }
            subDescription={
              <>
                Referrals to Post-Natal Home Visiting Programs
              </>
            }
            form={form}
            fields={fields}
            onNote={onNote}
          />
        }
        footer={
          <InterventionFooter
            form={form}
            savingsText="Positive Parenting"
            description="Percent of families (caregiver and Medicaid/CHIP-enrolled child) referred to the home visiting program who are successfully connected and receive services"
            onNote={onNote}
            hideShortTerm={true}
            subIntervention={   
              <InterventionBody
                subDescription={
                  <>
                    Positive Parenting Supports & Services
                  </>
                }
                form={form}
                fields={subInterventionfields}
                onNote={onNote}
              />
            }
          />
        }
      />
      <InterventionFieldNoteDialog
        id={`${noteName}__${noteAction}-dialog`}
        name={noteName}
        value={form.values[noteName]}
        mode={noteAction}
        open={isNoteDialogOpen}
        onClose={onDialogNoteClose}
        form={form}
      />
    </>
  );
};

export default PositiveParenting;
