/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FunctionComponent, useState } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import cx from 'classnames';
import { FormikProps } from 'formik';
import { Typography, Button } from '@material-ui/core';
import { IServiceData } from '../../../../interfaces/serviceData.interface';
import { InputText, Dialog, Tooltip } from '../../../../components';
import { Constants } from '../../../../utils';
import styles from './ServiceItem.styles';

interface IProps extends WithStylesType<typeof styles> {
  inputValue: any;
  noteInputValue: any;
  inputType: string;
  form: FormikProps<IServiceData>;
  isLastItem: boolean;
  errorMessage?: string | undefined;
  option: {
    title: string | React.ReactElement;
    extraDetails?: string;
    inputFormId: string;
    noteFormId: string;
    hasChild?: boolean;
    childDepth?: number;
    disabled?: boolean;
    readOnly?: boolean;
    subLabel?: string | ((form: FormikProps<IServiceData>) => string);
  };
}

const NOTE_ACTIONS = {
  ADD: 'add',
  EDIT: 'edit',
  DELETE: 'delete',
};

const ServiceItem: FunctionComponent<IProps> = ({
  classes,
  inputValue,
  noteInputValue,
  inputType,
  form,
  isLastItem,
  errorMessage,
  option,
}) => {
  const [note, setNote] = useState(noteInputValue);
  const [noteAction, setNoteAction] = useState(NOTE_ACTIONS.ADD);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const testid = 'delivery-costs-section';

  const onDialogOpen = (action: string) => () => {
    setNote(noteInputValue);
    setNoteAction(action);
    setIsDialogOpen(true);
  };

  const onDialogClose = () => {
    setIsDialogOpen(false);
  };

  const onDialogConfirm = () => {
    switch (noteAction) {
      case NOTE_ACTIONS.ADD:
      case NOTE_ACTIONS.EDIT:
        form.setValues({
          ...form.values,
          [option.noteFormId]: note,
        });
        break;
      case NOTE_ACTIONS.DELETE:
        form.setValues({
          ...form.values,
          [option.noteFormId]: '',
        });
        break;
    }
    setIsDialogOpen(false);
  };

  const TextArea = (
    <InputText
      id={option.noteFormId}
      name={option.noteFormId}
      labelText="Description"
      value={note}
      onChange={(e) => {
        setNote(e.currentTarget.value);
      }}
      placeholder="If useful, add comments here…"
      type={Constants.INPUT_TYPES.TEXTAREA}
      onEnterPress={onDialogConfirm}
      customClass={classes.description}
      maxLength={500}
      autoFocus
    />
  );

  const noteOptions: {
    [key: string]: {
      title: string;
      body: string | React.ReactElement;
      submitBtnText?: string;
      testid: string;
    };
  } = {
    [NOTE_ACTIONS.ADD]: {
      title: 'Add Note',
      body: TextArea,
      testid: `${option.noteFormId}__add-dialog`,
    },
    [NOTE_ACTIONS.EDIT]: {
      title: 'Edit Note',
      body: TextArea,
      testid: `${option.noteFormId}__edit-dialog`,
    },
    [NOTE_ACTIONS.DELETE]: {
      title: 'Delete Note',
      body: 'Are you sure you want to delete the note?',
      submitBtnText: 'Delete',
      testid: `${option.noteFormId}__delete-dialog`,
    },
  };

  return (
    <div
      className={cx(classes.container, {
        [classes.lastItem]: isLastItem,
        [classes.hasChild]: option.hasChild,
        [classes[`childDepth${option.childDepth}`]]: option.childDepth,
      })}
    >
      <Dialog
        id={noteOptions[noteAction].testid}
        title={noteOptions[noteAction].title}
        open={isDialogOpen}
        onClose={onDialogClose}
        customClass={classes.dialog}
        onSubmitBtnClick={onDialogConfirm}
        submitBtnText={noteOptions[noteAction].submitBtnText}
      >
        {noteOptions[noteAction].body}
      </Dialog>
      <div className={classes.content}>
        <div className={classes.titleWrapper}>
          {option.title === Constants.ROI_INTERMEDIARY_TOOLTIP.intermediary_cost ? (
            <Typography variant="body2">
              {option.title}
              <Tooltip
                id={`${testid}__delivery-costs-section`}
                title="An intermediary may include an organization that helps to run and/or manage your HealthySteps’ operations as part of a larger HealthySteps network or as part of a unique funding arrangement (e.g., philanthropic funder, federal government, or state funds, etc.)."
                placement="right"
              >
                <span></span>
              </Tooltip>
            </Typography>
          ):
            <div>
              <Typography variant="body2">{option.title}</Typography>
              {option.extraDetails && <p className={classes.extraDetails}>{option.extraDetails}</p>}
            </div>}
        </div>
        <div className={cx(option.readOnly ? classes.readOnlyInputWrapper : classes.inputWrapper)}>
          <InputText
            id={option.inputFormId}
            name={option.inputFormId}
            customClass={cx(option.readOnly ? classes.readOnlyInput : classes.input)}
            value={inputValue}
            type={inputType}
            onNumberChange={(value) => {
              form.setValues({
                ...form.values,
                [option.inputFormId]: value,
              });
            }}
            errorMessage={errorMessage}
            disabled={option.disabled}
            hideErrorMessage
          />
          <div className={classes.subWrapper}>
            {
              option.subLabel &&
              <Typography variant='subtitle2' className={classes.subLabel} >
                {typeof option.subLabel === 'function' ? option.subLabel(form) : option.subLabel}
              </Typography>
            }
            <div className={classes.subContentPush}></div>
            {!noteInputValue && (
              <Button
                className={classes.addNoteBtn}
                onClick={onDialogOpen(NOTE_ACTIONS.ADD)}
                data-testid={`${option.noteFormId}__add`}
              >
                Add note
              </Button>
            )}
          </div>
        </div>
      </div>
      {errorMessage &&
        errorMessage !== Constants.FORM_ERRORS.REQUIRED_FIELD_ERROR && (
          <Typography className={classes.errorMessage}>
            {errorMessage}
          </Typography>
        )}
      {noteInputValue && (
        <div className={classes.noteContainer}>
          <div className={classes.noteWrapper}>
            <Typography className={classes.note}>
              {`Note: ${noteInputValue}`}
            </Typography>
          </div>
          <div className={classes.noteActions}>
            <Button
              className={classes.deleteNoteBtn}
              onClick={onDialogOpen(NOTE_ACTIONS.DELETE)}
              data-testid={`${option.noteFormId}__delete`}
            >
              Delete note
            </Button>
            <Button
              className={classes.editNoteBtn}
              onClick={onDialogOpen(NOTE_ACTIONS.EDIT)}
              data-testid={`${option.noteFormId}__edit`}
            >
              Edit note
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(ServiceItem);
