/* eslint-disable @typescript-eslint/no-explicit-any */
import { IServiceData, serviceDataState } from './serviceData.interface';
import { Constants } from '../utils';
import {
  IInterventionsChecklist,
  IOralHealth,
  IEmergencyDepartmentUse,
  IImmunizations,
  IAsthma,
  IPostpartumMaternalDepression,
  IIntimatePartnerViolence,
  IHealthyBirthSpacing,
  IBreastfeeding,
  ISmokingCessation,
  interventionsChecklistState,
  oralHealthState,
  emergencyDepartmentUseState,
  immunizationsState,
  asthmaState,
  postpartumMaternalDepressionState,
  intimatePartnerViolenceState,
  healthyBirthSpacingState,
  breastfeedingState,
  smokingCessationState,
  IDiapers,
  diapersState,
  IFoodSecurity,
  foodSecurityState,
  IEarlyDetection,
  earlyDetectionState,
  IPositiveParenting,
  positiveParentingState,
} from './interventions.interface';

interface IIndex {
  [key: string]: any;
}

export enum CalculatorEnum {
  ServiceData = 'serviceData',
  InterventionsChecklist = 'interventionsChecklist',
  OralHealth = 'oralHealth',
  EmergencyDepartmentUse = 'emergencyDepartmentUse',
  Immunizations = 'immunizations',
  Asthma = 'asthma',
  PostpartumMaternalDepression = 'postpartumMaternalDepression',
  HealthyBirthSpacing = 'healthyBirthSpacing',
  IntimatePartnerViolence = 'intimidatePartnerViolence',
  Breastfeeding = 'breastfeeding',
  SmokingCessation = 'smokeCessation',
  Diapers = 'diapers',
  FoodSecurity = 'foodSecurity',
  EarlyDetection = 'earlyDetection',
  PositiveParenting = 'positiveParenting',
}

export interface IBreakdownReport {
  siteName: string;
  siteLocation: string;
  dateFrom: string;
  dateTo: string;
  calculationsApplyTo: string;
  numberOfSitesCalculationsApplyTo: string;
  numberOfSpecialistsDeliveringServices: string;
  numberOfSpecialistsDeliveringServicesNote: string;
  specialistSalaryCost: string;
  specialistSalaryCostNote: string;
  directCost: string;
  directCostNote: string;
  otherCost: string;
  otherCostNote: string;
  intermediaryCost: string;
  intermediaryCostNote: string;
  siteServiceCost: string;
  siteServiceCostNote: string;
  children0To3: string;
  children0To3Note: string;
  medicaidChildren0To3: string;
  medicaidChildren0To3Note: string;
  children0To3Tier2And3: string;
  children0To3Tier2And3Note: string;
  medicaidChildren0To3Tier2And3: string;
  medicaidChildren0To3Tier2And3Note: string;
  medicaidChildrenUnder1Tier2And3: string;
  medicaidChildrenUnder1Tier2And3Note: string;
  // Oral health
  oralHealth_needForOralHealth: string;
  oralHealth_needForOralHealthNote: string;
  oralHealth_lowEstimate: string;
  oralHealth_highEstimate: string;
  oralHealth_estimatesNote: string;
  // Emergengy department
  emergencyDepartment_contactedByPhone: string;
  emergencyDepartment_contactedByPhoneNote: string;
  emergencyDepartment_treatedAtHome: string;
  emergencyDepartment_treatedAtHomeNote: string;
  emergencyDepartment_clinicVisit: string;
  emergencyDepartment_clinicVisitNote: string;
  // Immunizations
  immunizations_needForFluVaccinations: string;
  immunizations_needForFluVaccinationsNote: string;
  immunizations_numberOfEncourageVisits: string;
  immunizations_numberOfEncourageVisitsNote: string;
  immunizations_lowEstimate: string;
  immunizations_highEstimate: string;
  immunizations_estimatesNote: string;
  // Asthma
  asthma_numberHaveAsthma: string;
  asthma_numberHaveAsthmaNote: string;
  asthma_lowEstimate: string;
  asthma_highEstimate: string;
  asthma_estimatesNote: string;
  // Postpartum Maternal Depression
  postpartumMaternalDepression_discussedProblem: string;
  postpartumMaternalDepression_discussedProblemNote: string;
  postpartumMaternalDepression_receivedCounseling: string;
  postpartumMaternalDepression_receivedCounselingNote: string;
  // postpartumMaternalDepression_referredForServices: string;
  // postpartumMaternalDepression_referredForServicesNote: string;
  postpartumMaternalDepression_lowEstimate: string;
  postpartumMaternalDepression_highEstimate: string;
  postpartumMaternalDepression_estimatesNote: string;
  // Healthy Birth Spacing
  healthyBirthSpacing_needForContraception: string;
  healthyBirthSpacing_needForContraceptionNote: string;
  healthyBirthSpacing_lowEstimate: string;
  healthyBirthSpacing_highEstimate: string;
  healthyBirthSpacing_estimatesNote: string;
  healthyBirthSpacing_followedUpOnReferals: string;
  healthyBirthSpacing_followedUpOnReferalsNote: string;
  // Intimate Partner Violence
  intimatePartnerViolence_discussedProblem: string;
  intimatePartnerViolence_discussedProblemNote: string;
  intimatePartnerViolence_receivedCounseling: string;
  intimatePartnerViolence_receivedCounselingNote: string;
  intimatePartnerViolence_referredForServices: string;
  intimatePartnerViolence_referredForServicesNote: string;
  intimatePartnerViolence_lowEstimate: string;
  intimatePartnerViolence_highEstimate: string;
  intimatePartnerViolence_estimatesNote: string;
  // Breastfeeding
  breastfeeding_discussedProblem: string;
  breastfeeding_discussedProblemNote: string;
  breastfeeding_receivedCounseling: string;
  breastfeeding_receivedCounselingNote: string;
  breastfeeding_referredForServices: string;
  breastfeeding_referredForServicesNote: string;
  breastfeeding_lowEstimate: string;
  breastfeeding_highEstimate: string;
  breastfeeding_estimatesNote: string;
  breastfeeding_lowEstimateExclusive: string;
  breastfeeding_highEstimateExclusive: string;
  breastfeeding_estimatesExclusiveNote: string;
  // Smoke Cessation
  smokeCessation_discussedProblem: string;
  smokeCessation_discussedProblemNote: string;
  smokeCessation_lowEstimate: string;
  smokeCessation_highEstimate: string;
  smokeCessation_estimatesNote: string;
  // Diapers
  diapers_numberCaregiversProvidedDiapersOrReferred: string;
  diapers_lowEstimate: string;
  diapers_highEstimate: string;
  diapers_estimatesNote: string;
  // Food Security
  foodSecurity_numberOfCaregiversFoodInsecureAndReferred: string;
  foodSecurity_numberOfCaregiversFoodInsecureAndReferredNote: string;
  foodSecurity_lowEstimate: string;
  foodSecurity_highEstimate: string;
  foodSecurity_estimatesNote: string;
  // Early Detection
  earlyDetection_numberOfChildrenReferredToEarlyEducationPrograms: string;
  earlyDetection_numberOfChildrenReferredToEarlyEducationProgramsNote: string;
  earlyDetection_lowEstimate: string;
  earlyDetection_highEstimate: string;
  earlyDetection_estimatesNote: string;
  earlyDetection_numberOfChildrenReferredToEarlyIntervention: string;
  earlyDetection_percentageOfChildrenReceivingEarlyInterventionServices: string;
  // Positive Parenting
  positiveParenting_numberOfFamiliesReferredToPostNatalHomeVisitingPrograms: string;
  positiveParenting_numberOfFamiliesReferredToPostNatalHomeVisitingProgramsNote: string;
  positiveParenting_lowEstimate: string;
  positiveParenting_highEstimate: string;
  positiveParenting_estimatesNote: string;
}

export const breakdownReportState: IBreakdownReport = {
  siteName: Constants.STRING_TYPES.EMPTY,
  siteLocation: Constants.STRING_TYPES.EMPTY,
  dateFrom: Constants.STRING_TYPES.EMPTY,
  dateTo: Constants.STRING_TYPES.EMPTY,
  calculationsApplyTo: Constants.STRING_TYPES.EMPTY,
  numberOfSitesCalculationsApplyTo: Constants.STRING_TYPES.EMPTY,
  numberOfSpecialistsDeliveringServices: Constants.STRING_TYPES.EMPTY,
  numberOfSpecialistsDeliveringServicesNote: '',
  specialistSalaryCost: Constants.STRING_TYPES.EMPTY,
  specialistSalaryCostNote: '',
  directCost: Constants.STRING_TYPES.EMPTY,
  directCostNote: '',
  otherCost: Constants.STRING_TYPES.EMPTY,
  otherCostNote: '',
  intermediaryCost: Constants.STRING_TYPES.EMPTY,
  intermediaryCostNote: '',
  siteServiceCost: Constants.STRING_TYPES.EMPTY,
  siteServiceCostNote: '',
  children0To3: Constants.STRING_TYPES.EMPTY,
  children0To3Note: '',
  medicaidChildren0To3: Constants.STRING_TYPES.EMPTY,
  medicaidChildren0To3Note: '',
  children0To3Tier2And3: Constants.STRING_TYPES.EMPTY,
  children0To3Tier2And3Note: '',
  medicaidChildren0To3Tier2And3: Constants.STRING_TYPES.EMPTY,
  medicaidChildren0To3Tier2And3Note: '',
  medicaidChildrenUnder1Tier2And3: Constants.STRING_TYPES.EMPTY,
  medicaidChildrenUnder1Tier2And3Note: '',
  //
  oralHealth_needForOralHealth: Constants.STRING_TYPES.EMPTY,
  oralHealth_needForOralHealthNote: '',
  oralHealth_lowEstimate: Constants.STRING_TYPES.EMPTY,
  oralHealth_highEstimate: Constants.STRING_TYPES.EMPTY,
  oralHealth_estimatesNote: '',
  //
  emergencyDepartment_contactedByPhone: Constants.STRING_TYPES.EMPTY,
  emergencyDepartment_contactedByPhoneNote: '',
  emergencyDepartment_treatedAtHome: Constants.STRING_TYPES.EMPTY,
  emergencyDepartment_treatedAtHomeNote: '',
  emergencyDepartment_clinicVisit: Constants.STRING_TYPES.EMPTY,
  emergencyDepartment_clinicVisitNote: '',
  //
  immunizations_needForFluVaccinations: Constants.STRING_TYPES.EMPTY,
  immunizations_needForFluVaccinationsNote: '',
  immunizations_numberOfEncourageVisits: Constants.STRING_TYPES.EMPTY,
  immunizations_numberOfEncourageVisitsNote: '',
  immunizations_lowEstimate: Constants.STRING_TYPES.EMPTY,
  immunizations_highEstimate: Constants.STRING_TYPES.EMPTY,
  immunizations_estimatesNote: '',
  //
  asthma_numberHaveAsthma: Constants.STRING_TYPES.EMPTY,
  asthma_numberHaveAsthmaNote: '',
  asthma_lowEstimate: Constants.STRING_TYPES.EMPTY,
  asthma_highEstimate: Constants.STRING_TYPES.EMPTY,
  asthma_estimatesNote: '',
  //
  postpartumMaternalDepression_discussedProblem: Constants.STRING_TYPES.EMPTY,
  postpartumMaternalDepression_discussedProblemNote: '',
  postpartumMaternalDepression_receivedCounseling: Constants.STRING_TYPES.EMPTY,
  postpartumMaternalDepression_receivedCounselingNote: '',
  // postpartumMaternalDepression_referredForServices: Constants.STRING_TYPES.EMPTY,
  // postpartumMaternalDepression_referredForServicesNote: '',
  postpartumMaternalDepression_lowEstimate: Constants.STRING_TYPES.EMPTY,
  postpartumMaternalDepression_highEstimate: Constants.STRING_TYPES.EMPTY,
  postpartumMaternalDepression_estimatesNote: '',
  //
  healthyBirthSpacing_needForContraception: Constants.STRING_TYPES.EMPTY,
  healthyBirthSpacing_needForContraceptionNote: '',
  healthyBirthSpacing_lowEstimate: Constants.STRING_TYPES.EMPTY,
  healthyBirthSpacing_highEstimate: Constants.STRING_TYPES.EMPTY,
  healthyBirthSpacing_estimatesNote: '',
  healthyBirthSpacing_followedUpOnReferals: Constants.STRING_TYPES.EMPTY,
  healthyBirthSpacing_followedUpOnReferalsNote: '',
  //
  intimatePartnerViolence_discussedProblem: Constants.STRING_TYPES.EMPTY,
  intimatePartnerViolence_discussedProblemNote: '',
  intimatePartnerViolence_receivedCounseling: Constants.STRING_TYPES.EMPTY,
  intimatePartnerViolence_receivedCounselingNote: '',
  intimatePartnerViolence_referredForServices: Constants.STRING_TYPES.EMPTY,
  intimatePartnerViolence_referredForServicesNote: '',
  intimatePartnerViolence_lowEstimate: Constants.STRING_TYPES.EMPTY,
  intimatePartnerViolence_highEstimate: Constants.STRING_TYPES.EMPTY,
  intimatePartnerViolence_estimatesNote: '',
  //
  breastfeeding_discussedProblem: Constants.STRING_TYPES.EMPTY,
  breastfeeding_discussedProblemNote: '',
  breastfeeding_receivedCounseling: Constants.STRING_TYPES.EMPTY,
  breastfeeding_receivedCounselingNote: '',
  breastfeeding_referredForServices: Constants.STRING_TYPES.EMPTY,
  breastfeeding_referredForServicesNote: '',
  breastfeeding_lowEstimate: Constants.STRING_TYPES.EMPTY,
  breastfeeding_highEstimate: Constants.STRING_TYPES.EMPTY,
  breastfeeding_estimatesNote: '',
  breastfeeding_lowEstimateExclusive: Constants.STRING_TYPES.EMPTY,
  breastfeeding_highEstimateExclusive: Constants.STRING_TYPES.EMPTY,
  breastfeeding_estimatesExclusiveNote: '',
  //
  smokeCessation_discussedProblem: Constants.STRING_TYPES.EMPTY,
  smokeCessation_discussedProblemNote: '',
  smokeCessation_lowEstimate: Constants.STRING_TYPES.EMPTY,
  smokeCessation_highEstimate: Constants.STRING_TYPES.EMPTY,
  smokeCessation_estimatesNote: '',
  //
  diapers_numberCaregiversProvidedDiapersOrReferred: Constants.STRING_TYPES.EMPTY,
  diapers_lowEstimate: Constants.STRING_TYPES.EMPTY,
  diapers_highEstimate: Constants.STRING_TYPES.EMPTY,
  diapers_estimatesNote: Constants.STRING_TYPES.EMPTY,
  //
  foodSecurity_numberOfCaregiversFoodInsecureAndReferred: '',
  foodSecurity_numberOfCaregiversFoodInsecureAndReferredNote:'',
  foodSecurity_lowEstimate: Constants.STRING_TYPES.EMPTY,
  foodSecurity_highEstimate: Constants.STRING_TYPES.EMPTY,
  foodSecurity_estimatesNote:Constants.STRING_TYPES.EMPTY,
  // Early Detection
  earlyDetection_numberOfChildrenReferredToEarlyEducationPrograms: Constants.STRING_TYPES.EMPTY,
  earlyDetection_numberOfChildrenReferredToEarlyEducationProgramsNote: '',
  earlyDetection_lowEstimate: Constants.STRING_TYPES.EMPTY,
  earlyDetection_highEstimate: Constants.STRING_TYPES.EMPTY,
  earlyDetection_estimatesNote: '',
  earlyDetection_numberOfChildrenReferredToEarlyIntervention: '',
  earlyDetection_percentageOfChildrenReceivingEarlyInterventionServices: '',
  //
  positiveParenting_numberOfFamiliesReferredToPostNatalHomeVisitingPrograms: '',
  positiveParenting_numberOfFamiliesReferredToPostNatalHomeVisitingProgramsNote: '',
  positiveParenting_lowEstimate: '',
  positiveParenting_highEstimate: '',
  positiveParenting_estimatesNote: '',
};
export interface ISummaryReport {
  totalChildren0to3: number;
  totalChildrenMedicaid0to3: number;
  medicaidPercentageOfTotals: number;
  totalAnnualProgramCost: number;
  annualCostPerChild: number;
  totalSavingsAvg: number;
  annualReturnOnInvestment: number;
  returnPerMedicaidChild: number;
  totalAnnualMedicaidProgramCost: number;
  annualCostPerMedicaidChild: number;
  costSavingsPerMedicaidFamily: number;
  annualMedicaidReturnOnInvestment: number;
  childrenLowSavings: number;
  childrenHighSavings: number;
  dentalEstimatedSavingLow: number;
  dentalEstimatedSavingHigh: number;
  dentalEstimatedTotalTermSavingLow: number;
  dentalEstimatedTotalTermSavingHigh: number;
  dentalEstimatedSavingAverage: number;
  emergencyDepartmentEstimatedSavingLow: number;
  emergencyDepartmentEstimatedSavingHigh: number;
  emergencyDepartmentEstimatedSavingAverage: number;
  immunizationsEstimatedSavingsLow: number;
  immunizationsEstimatedSavingsHigh: number;
  immunizationsEstimatedSavingsAverage: number;
  immunizationsEstimatedLongTermSavingsLow: number;
  immunizationsEstimatedLongTermSavingsHigh: number;
  immunizationsEstimatedLongTermSavingsAverage: number;
  immunizationsEstimatedTotalSavingsAverage: number;
  immunizationsEstimatedTotalSavingsLow: number;
  immunizationsEstimatedTotalSavingsHigh: number;
  asthmaEstimatedSavingsLow: number;
  asthmaEstimatedSavingsHigh: number;
  asthmaEstimatedSavingsAverage: number;
  parentLowSavings: number;
  parentHighSavings: number;
  maternalDepressionEstimatedSavingsLow: number;
  maternalDepressionEstimatedSavingsHigh: number;
  maternalDepressionEstimatedSavingsAverage: number;
  maternalDepressionEstimatedLongTermSavingsLow: number;
  maternalDepressionEstimatedLongTermSavingsHigh: number;
  maternalDepressionEstimatedLongTermSavingsAverage: number;
  maternalDepressionEstimatedTotalTermSavingsLow: number;
  maternalDepressionEstimatedTotalTermSavingsHigh: number;
  maternalDepressionEstimatedTotalTermSavingsAverage: number;
  healthBirthSpacingEstimatedSavingsLow: number;
  healthBirthSpacingEstimatedSavingsHigh: number;
  healthBirthSpacingEstimatedSavingsAverage: number;
  healthBirthSpacingTotalTermEstimatedSavingsAverage: number;
  healthBirthSpacingTotalTermEstimatedSavingsLow: number;
  healthBirthSpacingTotalTermEstimatedSavingsHigh: number;
  birthsPublicAssistanceSavings: number;
  birthsHeadStartSavings: number;
  birthsHealthcareSavings: number;
  birthsChildWelfareSavings: number;
  intimatePartnerViolenceEstimatedSavingsLow: number;
  intimatePartnerViolenceEstimatedSavingsHigh: number;
  intimatePartnerViolenceEstimatedSavingsAverage: number;
  breastfeedingEstimatedSavingsLow: number;
  breastfeedingEstimatedSavingsHigh: number;
  breastfeedingEstimatedSavingsAverage: number;
  smokingCessationEstimatedSavingsLow: number;
  smokingCessationEstimatedSavingsHigh: number;
  smokingCessationEstimatedSavingsChildAverage: number;
  smokingCessationEstimatedSavingsAdultAverage: number;
  smokingTabTotalDiscountedFutureSavingsChildLow: number;
  smokingTabTotalDiscountedFutureSavingsChildHigh: number;
  smokingTabTotalDiscountedFutureSavingsAdultLow: number;
  smokingTabTotalDiscountedFutureSavingsAdultHigh: number;
  smokingTabTotalSavingsAdultAndChildLow: number;
  smokingTabTotalSavingsAdultAndChildHigh: number;
  smokingTabTotalSavingsAdultAndChildAverage: number;
  diapersEstimatedSavingsLow: number;
  diapersEstimatedSavingsHigh: number;
  diapersEstimatedSavingsAverage: number;
  foodSecurityEstimatedSavingsLow: number;
  foodSecurityEstimatedSavingsHigh: number;
  foodSecurityEstimatedSavingsAverage: number;
  earlyDetectionEstimatedSavingsLow: number;
  earlyDetectionEstimatedSavingsHigh: number;
  earlyDetectionEstimatedAverage:number;
  earlydetectionTabEarlyInterventionTotalNetSavingsLow: number;
  earlydetectionTabEarlyInterventionTotalNetSavingsHigh: number;
  earlydetectionTabEarlyInterventionTotalNetSavingsAverage: number;
  earlydetectionTabEarlyEducationProgramsTotalNetSavingsLow: number;
  earlydetectionTabEarlyEducationProgramsTotalNetSavingsHigh: number;
  earlydetectionTabEarlyEducationProgramsTotalNetSavingsAverage: number;
  positiveParentingEstimatedSavingsLow: number;
  positiveParentingEstimatedSavingsHigh: number;
  positiveParentingEstimatedSavingsAverage: number;
  positiveparentingTabCalculatedSavingsForPositiveParentingSupportsAndServices: number;
  positiveparentingTabEstimatedSavingsFamiliesRecievingHomeVisitingServicesProgramsLow: number;
  positiveparentingTabEstimatedSavingsFamiliesRecievingHomeVisitingServicesProgramsHigh: number;
  positiveparentingTabEstimatedSavingsFamiliesRecievingHomeVisitingServicesProgramsAverage: number;
  positiveParentingEducationService: number;
  positiveParentingAbuseFosterCare: number;
  positiveParentingCrime: number;
  positiveParentingHealthService: number;
  positiveParentingTotalHealthService: number;
  positiveParentingTotalServices: number;
  totalSavingsByAdult: number;
  totalSavingsByChild: number;
  totalSavingsByPopulation: number;
  totalSavingsChildPercentage: number;
  totalSavingsAdultPercentage: number;
  totalSavingsPopulationPercentage: number;
  totalSavingsByShortTerm: number;
  totalSavingsByLongTerm: number;
  totalSavingsByTimeframe: number;
  totalSavingsShortTermPercentage: number;
  totalSavingsLongTermPercentage: number;
  totalSavingsTimeframePercentage: number;
  shortTermLowSavings:number;
  shortTermHighSavings:number;
  shortTermSavingsAverage:number;
  shortTermLowMedicaidSavings:number;
  shortTermHighMedicaidSavings:number;
  shortTermMedicaidSavingsAverage:number;
  longTermLowSavings:number;
  longTermHighSavings:number;
  longTermSavingsAverage:number;
  longTermLowMedicaidSavings:number;
  longTermHighMedicaidSavings:number;
  longTermMedicaidSavingsAverage:number;
  totalSavingsLow:number;
  totalSavingsHigh:number;
  totalSavingsAverage:number;
  totalSiteServiceDeliveryCost:number;
  shortTermLowROI:number;
  shortTermHighROI:number;
  shortTermROIAverage:number;
  shortTermLowMedicaidROI:number;
  shortTermHighMedicaidROI:number;
  shortTermROIMedicaidAverage:number;
  longTermLowROI:number;
  longTermHighROI:number;
  longTermROIAverage:number;
  longTermLowMedicaidROI:number;
  longTermHighMedicaidROI:number;
  longTermROIMedicaidAverage:number;
  interventionForChildrensAverage:number;
  interventionForChildrensLow:number;
  interventionForChildrensHigh:number;
  interventionForAdultsAverage:number;
  interventionForAdultsLow:number;
  interventionForAdultsHigh:number;
  totalNetSavingsLow: number;
  totalNetSavingsHigh: number;
  totalNetSavingsAverage: number;
  totalHealthCareSavings: number;
  totalChildWelfareSavings: number;
  totalPublicAssistanceSavings: number;
  totalSpecialEducationSavings: number;
  totalSavingsSector: number;
  globalEstimateSavingsLow: number;
  globalEstimateSavingsHigh: number;
  nationalOfficeDemostratedAnnualizedSavingsToMedicaidUpToDollarsPerFamily: number;
  annualReturnOnInvestmentPerc: number;
  anEstimatedDollarsInSavings: number;
  breakdownData: IBreakdownReport;
};
export interface IReportLine {
  description: string;
  values: string[];
  displayDefault: boolean;
  note?: string;
};
export interface IReportSubCategory {
  title: string;
  visible: boolean;
  lines: IReportLine[];
  isInfoBlock?: boolean;
  isFootnoteBlock?: boolean;
};
export interface IReportCategory {
  title: string;
  resultsHeaders?: string[];
  subCategories: IReportSubCategory[];
}

export const summaryReportState: ISummaryReport = {
  totalChildren0to3: 0,
  totalChildrenMedicaid0to3: 0,
  medicaidPercentageOfTotals: 0,
  totalAnnualProgramCost: 0,
  annualCostPerChild: 0,
  totalSavingsAvg: 0,
  annualReturnOnInvestment: 0,
  returnPerMedicaidChild: 0,
  totalAnnualMedicaidProgramCost: 0,
  annualCostPerMedicaidChild: 0,
  costSavingsPerMedicaidFamily: 0,
  annualMedicaidReturnOnInvestment: 0,
  childrenLowSavings: 0,
  childrenHighSavings: 0,
  dentalEstimatedSavingLow: 0,
  dentalEstimatedSavingHigh: 0,
  dentalEstimatedTotalTermSavingLow: 0,
  dentalEstimatedTotalTermSavingHigh: 0,
  dentalEstimatedSavingAverage: 0,
  emergencyDepartmentEstimatedSavingLow: 0,
  emergencyDepartmentEstimatedSavingHigh: 0,
  emergencyDepartmentEstimatedSavingAverage: 0,
  immunizationsEstimatedSavingsLow: 0,
  immunizationsEstimatedSavingsHigh: 0,
  immunizationsEstimatedSavingsAverage: 0,
  immunizationsEstimatedLongTermSavingsLow: 0,
  immunizationsEstimatedLongTermSavingsHigh: 0,
  immunizationsEstimatedLongTermSavingsAverage: 0,
  immunizationsEstimatedTotalSavingsAverage: 0,
  immunizationsEstimatedTotalSavingsLow: 0,
  immunizationsEstimatedTotalSavingsHigh: 0,
  asthmaEstimatedSavingsLow: 0,
  asthmaEstimatedSavingsHigh: 0,
  asthmaEstimatedSavingsAverage: 0,
  parentLowSavings: 0,
  parentHighSavings: 0,
  maternalDepressionEstimatedSavingsLow: 0,
  maternalDepressionEstimatedSavingsHigh: 0,
  maternalDepressionEstimatedSavingsAverage: 0,
  maternalDepressionEstimatedLongTermSavingsLow: 0,
  maternalDepressionEstimatedLongTermSavingsHigh: 0,
  maternalDepressionEstimatedLongTermSavingsAverage: 0,
  healthBirthSpacingEstimatedSavingsLow: 0,
  healthBirthSpacingEstimatedSavingsHigh: 0,
  healthBirthSpacingEstimatedSavingsAverage: 0,
  healthBirthSpacingTotalTermEstimatedSavingsAverage: 0,
  healthBirthSpacingTotalTermEstimatedSavingsLow: 0,
  healthBirthSpacingTotalTermEstimatedSavingsHigh: 0,
  birthsPublicAssistanceSavings: 0,
  birthsChildWelfareSavings: 0,
  birthsHeadStartSavings: 0,
  birthsHealthcareSavings: 0,
  intimatePartnerViolenceEstimatedSavingsLow: 0,
  intimatePartnerViolenceEstimatedSavingsHigh: 0,
  intimatePartnerViolenceEstimatedSavingsAverage: 0,
  breastfeedingEstimatedSavingsLow: 0,
  breastfeedingEstimatedSavingsHigh: 0,
  breastfeedingEstimatedSavingsAverage: 0,
  smokingCessationEstimatedSavingsLow: 0,
  smokingCessationEstimatedSavingsHigh: 0,
  smokingCessationEstimatedSavingsChildAverage: 0,
  smokingCessationEstimatedSavingsAdultAverage: 0,
  smokingTabTotalDiscountedFutureSavingsChildLow: 0,
  smokingTabTotalDiscountedFutureSavingsChildHigh: 0,
  smokingTabTotalDiscountedFutureSavingsAdultLow: 0,
  smokingTabTotalDiscountedFutureSavingsAdultHigh: 0,
  smokingTabTotalSavingsAdultAndChildLow: 0,
  smokingTabTotalSavingsAdultAndChildHigh: 0,
  smokingTabTotalSavingsAdultAndChildAverage: 0,
  diapersEstimatedSavingsLow: 0,
  diapersEstimatedSavingsHigh: 0,
  diapersEstimatedSavingsAverage:0,
  foodSecurityEstimatedSavingsLow: 0,
  foodSecurityEstimatedSavingsHigh: 0,
  foodSecurityEstimatedSavingsAverage: 0,
  earlyDetectionEstimatedSavingsLow: 0,
  earlyDetectionEstimatedSavingsHigh: 0,
  earlyDetectionEstimatedAverage:0,
  earlydetectionTabEarlyInterventionTotalNetSavingsLow: 0,
  earlydetectionTabEarlyInterventionTotalNetSavingsHigh: 0,
  earlydetectionTabEarlyInterventionTotalNetSavingsAverage: 0,
  earlydetectionTabEarlyEducationProgramsTotalNetSavingsLow: 0,
  earlydetectionTabEarlyEducationProgramsTotalNetSavingsHigh: 0,
  earlydetectionTabEarlyEducationProgramsTotalNetSavingsAverage: 0,
  maternalDepressionEstimatedTotalTermSavingsLow: 0,
  maternalDepressionEstimatedTotalTermSavingsHigh: 0,
  maternalDepressionEstimatedTotalTermSavingsAverage: 0,
  positiveParentingEstimatedSavingsLow: 0,
  positiveParentingEstimatedSavingsHigh: 0,
  positiveParentingEstimatedSavingsAverage: 0,
  positiveparentingTabCalculatedSavingsForPositiveParentingSupportsAndServices: 0,
  positiveparentingTabEstimatedSavingsFamiliesRecievingHomeVisitingServicesProgramsLow: 0,
  positiveparentingTabEstimatedSavingsFamiliesRecievingHomeVisitingServicesProgramsHigh: 0,
  positiveparentingTabEstimatedSavingsFamiliesRecievingHomeVisitingServicesProgramsAverage: 0,
  positiveParentingEducationService: 0,
  positiveParentingAbuseFosterCare: 0,
  positiveParentingCrime: 0,
  positiveParentingHealthService: 0,
  positiveParentingTotalHealthService: 0,
  positiveParentingTotalServices: 0,
  totalSavingsByAdult: 0,
  totalSavingsByChild: 0,
  totalSavingsByPopulation: 0,
  totalSavingsChildPercentage: 0,
  totalSavingsAdultPercentage: 0,
  totalSavingsPopulationPercentage:0,
  totalSavingsByShortTerm: 0,
  totalSavingsByLongTerm: 0,
  totalSavingsByTimeframe: 0,
  totalSavingsShortTermPercentage: 0,
  totalSavingsLongTermPercentage: 0,
  totalSavingsTimeframePercentage: 0,
  totalChildWelfareSavings: 0,
  totalPublicAssistanceSavings: 0,
  totalSpecialEducationSavings: 0,
  shortTermLowSavings:0,
  shortTermHighSavings:0,
  shortTermSavingsAverage:0,
  shortTermLowMedicaidSavings:0,
  shortTermHighMedicaidSavings:0,
  shortTermMedicaidSavingsAverage:0,
  longTermLowSavings:0,
  longTermHighSavings:0,
  longTermSavingsAverage:0,
  longTermLowMedicaidSavings:0,
  longTermHighMedicaidSavings:0,
  longTermMedicaidSavingsAverage:0,
  totalSavingsLow:0,
  totalSavingsHigh:0,
  totalSavingsAverage:0,
  totalSiteServiceDeliveryCost:0,
  shortTermLowROI:0,
  shortTermHighROI:0,
  shortTermROIAverage:0,
  shortTermLowMedicaidROI:0,
  shortTermHighMedicaidROI:0,
  shortTermROIMedicaidAverage:0,
  longTermLowROI:0,
  longTermHighROI:0,
  longTermROIAverage:0,
  longTermLowMedicaidROI:0,
  longTermHighMedicaidROI:0,
  longTermROIMedicaidAverage:0,
  interventionForChildrensAverage:0,
  interventionForChildrensLow:0,
  interventionForChildrensHigh:0,
  interventionForAdultsAverage:0,
  interventionForAdultsLow:0,
  interventionForAdultsHigh:0,
  totalNetSavingsLow: 0,
  totalNetSavingsHigh: 0,
  totalNetSavingsAverage: 0,
  totalHealthCareSavings: 0,
  globalEstimateSavingsLow: 0,
  globalEstimateSavingsHigh: 0,
  totalSavingsSector: 0,
  nationalOfficeDemostratedAnnualizedSavingsToMedicaidUpToDollarsPerFamily: 0,
  annualReturnOnInvestmentPerc: 0,
  anEstimatedDollarsInSavings: 0,
  breakdownData: breakdownReportState,
};

export interface ICalculator extends IIndex {
  name: string;
  serviceData: IServiceData;
  interventionsChecklist: IInterventionsChecklist;
  oralHealth: IOralHealth;
  emergencyDepartmentUse: IEmergencyDepartmentUse;
  immunizations: IImmunizations;
  asthma: IAsthma;
  postpartumMaternalDepression: IPostpartumMaternalDepression;
  healthyBirthSpacing: IHealthyBirthSpacing;
  intimidatePartnerViolence: IIntimatePartnerViolence;
  breastfeeding: IBreastfeeding;
  smokeCessation: ISmokingCessation;
  diapers: IDiapers;
  foodSecurity: IFoodSecurity;
  earlyDetection: IEarlyDetection;
  positiveParenting: IPositiveParenting;
  summary: {
    totalSavingsLow: string;
    totalSavingsHigh: string;
    report: ISummaryReport;
  };
  status: string;
  isLoading: boolean;
  touched: boolean;
  reportsList: {
    reports: any[],
    LastEvaluatedKey: object | null,
  };
  updatedISO: string;
}

export const calculatorState: ICalculator = {
  name: '',
  serviceData: serviceDataState,
  interventionsChecklist: interventionsChecklistState,
  oralHealth: oralHealthState,
  emergencyDepartmentUse: emergencyDepartmentUseState,
  immunizations: immunizationsState,
  asthma: asthmaState,
  postpartumMaternalDepression: postpartumMaternalDepressionState,
  healthyBirthSpacing: healthyBirthSpacingState,
  intimidatePartnerViolence: intimatePartnerViolenceState,
  breastfeeding: breastfeedingState,
  smokeCessation: smokingCessationState,
  diapers: diapersState,
  foodSecurity: foodSecurityState,
  earlyDetection: earlyDetectionState,
  positiveParenting: positiveParentingState,
  summary: {
    totalSavingsLow: '',
    totalSavingsHigh: '',
    report: summaryReportState,
  },
  status: 'pending',
  isLoading: false,
  touched: false,
  reportsList: {
    reports: [],
    LastEvaluatedKey: null,
  },
  updatedISO: '',
};
