/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import {
  selectInterventionsChecklistValue,
  calculatorActions,
  selectInterventionsValue,
  selectServiceDataForm,
  selectState,
  selectTouched,
  selectReportName,
} from '../../../../redux/calculatorSlice';
import {
  InterventionsChecklistForm,
  SmokingCessationForm,
  ISmokingCessation,
  InterventionsCommonForm,
} from '../../../../interfaces/interventions.interface';
import { CalculatorEnum } from '../../../../interfaces/calculator.interface';
import {
  InterventionHeader,
  InterventionBody,
  InterventionFooter,
  InterventionContainer,
  InterventionFieldNoteDialog,
} from '../Intervention';
import { FormValidations, Constants, StringFormatter } from '../../../../utils';
import useDebounceEffect from '../../../../hooks/useDebounceEffect';
import { SmokingCessation as SmokingCessationClass } from '../../../../calculations';
import { paths } from '../../..';

const { DIALOG_MODES } = Constants;

const SmokingCessation: FunctionComponent = () => {
  const dispatch = useDispatch();

  const history = useHistory();
  const switchValue = useSelector(
    selectInterventionsChecklistValue(
      InterventionsChecklistForm.SmokingCessation
    )
  );
  const smokingCessationData = useSelector(
    selectInterventionsValue(CalculatorEnum.SmokingCessation)
  );

  const serviceData = useSelector(selectServiceDataForm);
  const selectedState = useSelector(selectState);
  const touched = useSelector(selectTouched);
  const reportName = useSelector(selectReportName);

  const [reinitialize, setReinitialize] = useState(true);
  const [noteName, setNoteName] = useState<string>('');
  const [noteAction, setNoteAction] = useState<'add' | 'edit' | 'delete'>(DIALOG_MODES.ADD);
  const [isNoteDialogOpen, setIsNoteDialogOpen] = useState(false);

  const fields = [
    {
      text: `Number of Medicaid-enrolled caregivers who discussed a problem with smoking or any other tobacco product use with a HS Specialist and/or were referred for services
      `,
      id: SmokingCessationForm.DiscussedProblem,
      showError: true,
    },
  ];

  const onSubmit = (values: ISmokingCessation) => {
    if (touched) {
      dispatch(
        calculatorActions.saveReport({
          reportName,
          update: true,
        }));
    }
    history.push(
      StringFormatter.replaceWithReportName(
        reportName,
        paths.interventionsFoodSecurity
      )
    );
  };

  useEffect(() => {
    if (switchValue) {
      const timeout = setTimeout(() => {
        setReinitialize(false);
      }, 250);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [switchValue]);

  const form = useFormik({
    initialValues: smokingCessationData,
    validationSchema:
      switchValue &&
      FormValidations.intervention({
        [SmokingCessationForm.DiscussedProblem]:
          FormValidations
            .required('')
            .test(FormValidations.getInterventionNumberOfChildsConfig(SmokingCessationForm.DiscussedProblem, serviceData.csInput3)),
      }),
    onSubmit,
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: reinitialize || !switchValue,
  });

  useEffect(() => {
    if (!switchValue) {
      form.resetForm();
      dispatch(
        calculatorActions.clearInterventionData(CalculatorEnum.SmokingCessation)
      );
    }
  }, [switchValue]);

  useDebounceEffect(() => {
    const hasBeenEdited = Object.keys(form.values).some(
      (key) => !!form.values[key]
    );
    if (!form.dirty && !hasBeenEdited) return;
    /** Calculations happen */
    const smokingCessation = new SmokingCessationClass({
      state: selectedState,
      serviceData,
      sliderSmokerLowEstimate: +form.values[
        InterventionsCommonForm.LowEstimate
      ],
      sliderSmokerHighEstimate: +form.values[
        InterventionsCommonForm.HighEstimate
      ],
      firstInput: +form.values[SmokingCessationForm.DiscussedProblem],
    });

    // const savingsLow = smokingCessation.smokingCessationEstimatedSavingsLow();
    // const savingsHigh = smokingCessation.smokingCessationEstimatedSavingsHigh();

    const longTermSavingsLow = smokingCessation.smokingTabTotalSavingsAdultAndChildLow();
    const longTermSavingsHigh = smokingCessation.smokingTabTotalSavingsAdultAndChildHigh();

    if (
      +form.values[InterventionsCommonForm.LongTermSavingsLow] !== longTermSavingsLow ||
      +form.values[InterventionsCommonForm.LongTermSavingsHigh] !== longTermSavingsHigh
    ) {
      form.setValues({
        ...form.values,
        [InterventionsCommonForm.LongTermSavingsLow]: longTermSavingsLow.toString(),
        [InterventionsCommonForm.LongTermSavingsHigh]: longTermSavingsHigh.toString(),
      });
    }

    form.validateForm().then((errors) => {
      const isValid = !Object.keys(errors).length;

      if (form.dirty) {
        dispatch(
          calculatorActions.setInterventionData({
            intervention: CalculatorEnum.SmokingCessation,
            value: { ...form.values, isValid: !!isValid },
          })
        );
        dispatch(calculatorActions.setSummaryData());
      }
    });
  }, [form.values]);

  const onSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      calculatorActions.toggleInterventionsChecklistValue({
        key: InterventionsChecklistForm.SmokingCessation,
        value: e.target.checked,
      })
    );
  };

  const onNote = (noteName: string, noteAction: 'add' | 'edit' | 'delete') => {
    setNoteName(noteName);
    setNoteAction(noteAction);
    setIsNoteDialogOpen(true);
  };

  const onDialogNoteClose = () => {
    setIsNoteDialogOpen(false);
  };

  return (
    <>
      <InterventionContainer
        disabled={!switchValue}
        onSubmit={form.handleSubmit}
        header={
          <InterventionHeader
            title="Smoking Cessation"
            checked={switchValue}
            onChange={onSwitchChange}
          />
        }
        body={
          <InterventionBody
            description={
              <>
                Some HS Specialists talk with caregivers about smoking cessation. The following questions ask about the actions taken by caregivers in these situations during the 12-month date range.
              </>
            }
            form={form}
            fields={fields}
            onNote={onNote}
          />
        }
        footer={
          <InterventionFooter
            form={form}
            savingsText="Smoking cessation"
            description="Percentage of Medicaid-enrolled caregivers who smoke or use tobacco products, and quit due to HealthySteps"
            onNote={onNote}
            hideShortTerm={true}
          />
        }
      />
      <InterventionFieldNoteDialog
        id={`${noteName}__${noteAction}-dialog`}
        name={noteName}
        value={form.values[noteName]}
        mode={noteAction}
        open={isNoteDialogOpen}
        onClose={onDialogNoteClose}
        form={form}
      />
    </>
  );
};

export default SmokingCessation;
