import { createStyles, StyleRules, Theme } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { Colors, FontWeight } from '../../../../assets/styles';

const amount: CSSProperties = {
  width: '10% !important',
  color: Colors.Gray,
  fontSize: 14,
};

const title: CSSProperties = {
  fontSize: 14,
  fontWeight: FontWeight.Light,
  color: Colors.Black,
};

export default (): StyleRules => ({
  table: {
    marginBottom: 80,
    marginTop: 30,
  },
  columnTitle: {
    color: Colors.Black,
    fontWeight: FontWeight.Bold,
    fontSize: 16,
    padding: '12px',
    width:'70%'
  },
  columnSubTitle: {
    color: Colors.Black,
    fontWeight: FontWeight.Bold,
    fontSize: 16,
    padding: '12px',
  },
  sectionTitle: {
    ...title,
    padding: '12px',
    fontSize: '16px !important',
  },
  subsectionTitle: {
    ...title,
    fontSize: 16,
    paddingLeft:'30px',
    padding: '12px',
  },
  sectionAmount: {
    ...amount,
    padding: '12px',
  },
  subsectionAmount: {
    ...amount,
    padding: '12px',
  },
  contentTitle: {
    fontSize: 18,
    marginBottom: 24,
    color:'black'
  },
});

export const tableCellStyles = ({
  typography,
  breakpoints,
}: Theme): StyleRules => {
  return createStyles({
    root: {
      border: `1px solid ${Colors.Gray5}`,
    },
  });
};
