import React, { FunctionComponent, useState } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import { Button, IconButton, Typography } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { ExitToApp } from '@material-ui/icons';
import { AppThunkDispatch } from '../../interfaces/redux.interface';
import { InputText } from '..';
import {
  calculatorActions,
  selectReportName,
  selectTotals,
  selectTouched,
} from '../../redux/calculatorSlice';
import { paths, history } from '../../routes';
import AccountMenu from '../Menu/AccountMenu/AccountMenu';
import Dialog from '../Dialog/Dialog';
import styles from './Header.styles';
import { FormValidations, StringFormatter } from '../../utils';
import Main from '../../calculations/main';

const HEADER_ACTIONS = {
  RESET: 'reset',
  SAVE_AND_EXIT: 'save_and_exit',
  SAVE: 'save',
};

const Header: FunctionComponent<WithStylesType<typeof styles>> = ({
  classes,
}) => {
  const testid = 'header';
  const location = useLocation();
  const dispatch: AppThunkDispatch = useDispatch();
  const reportName = useSelector(selectReportName);
  const touched = useSelector(selectTouched);
  const totals = useSelector(selectTotals);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [headerAction, setHeaderAction] = useState(HEADER_ACTIONS.RESET);
  const dashboardHeader = location.pathname.includes(paths.dashboard);
  const isReportHeader = location.pathname.split('/').pop() === 'report';
  
  let totalSavingsStr = 0;

  if (totals instanceof Main && typeof totals.totalSavingsAvg === 'function') {
    totalSavingsStr = totals.totalSavingsAvg();
  }
  
  const totalSavings = totalSavingsStr.toString();
  

  const onSubmit = (values: { reportName: string }) => {};

  const form = useFormik({
    initialValues: {
      reportName: '',
    },
    onSubmit,
    validationSchema: Yup.object().shape({
      reportName: FormValidations.reportNameValidation(reportName),
    }),
    validateOnBlur: false,
    validateOnChange: false,
  });

  const headerOptions: {
    [key: string]: {
      title: string;
      body: string | React.ReactElement;
      submitBtnText?: string;
      cancelBtnText?: string;
      testid: string;
      closeOnSubmit?: boolean;
      onCancelBtnClick?: () => void;
    };
  } = {
    [HEADER_ACTIONS.RESET]: {
      title: 'Reset Data',
      body: `Your data will be completely removed and the information will be
      restored to its default values. Are you sure you want to continue?`,
      testid: `${testid}__start-again`,
      submitBtnText: 'Yes, Reset Data',
    },
    [HEADER_ACTIONS.SAVE]: {
      title: 'Save ROI Calculations',
      body: (
        <form onSubmit={form.handleSubmit}>
          <InputText
            id="reportName"
            name="reportName"
            onChange={form.handleChange}
            value={form.values.reportName}
            labelText="Name Your ROI Calculations"
            errorMessage={form.errors.reportName}
            customClass={classes.calculationsNameInput}
          />
        </form>
      ),
      testid: `${testid}__save`,
      submitBtnText: 'Save',
      closeOnSubmit: false,
    },
    [HEADER_ACTIONS.SAVE_AND_EXIT]: {
      title: 'Are you sure you want to leave this page?',
      body: (
        <>  
          <Typography className={classes.saveAndExitTitle}>
            Any unsaved changes will be lost
          </Typography>
          {!reportName && (
            <form onSubmit={form.handleSubmit}>
              <InputText
                id="reportName"
                name="reportName"
                onChange={form.handleChange}
                value={form.values.reportName}
                labelText="Name Your ROI Calculations"
                errorMessage={form.errors.reportName}
                customClass={classes.calculationsNameInput}
              />
            </form>
          )}
        </>
      ),
      onCancelBtnClick: () => history.push(paths.calculatorDashboard),
      testid: `${testid}__save`,
      submitBtnText: 'Save & Exit',
      cancelBtnText: 'Exit without saving',
      closeOnSubmit: false,
    },
  };

  const onDialogOpen = (action: string) => () => {
    setHeaderAction(action);
    setIsDialogOpen(true);
  };

  const onDialogConfirm = () => {
    switch (headerAction) {
      case HEADER_ACTIONS.RESET:
        dispatch(calculatorActions.resetAllData());
        setIsDialogOpen(false);
        break;
      case HEADER_ACTIONS.SAVE:
        form.validateForm().then((errors) => {
          if (!errors.reportName) {
            dispatch(
              calculatorActions.saveReport({
                reportName: form.values.reportName,
              })
            );
            setIsDialogOpen(false);
          }
        });
        break;
      case HEADER_ACTIONS.SAVE_AND_EXIT:
        if (!reportName) {
          form.validateForm().then((errors) => {
            if (!errors.reportName) {
              dispatch(
                calculatorActions.saveReport({
                  reportName: form.values.reportName,
                })
              ).then(() => history.push(paths.calculatorDashboard));
              setIsDialogOpen(false);
            }
          });
        } else {
          setIsDialogOpen(false);
          dispatch(calculatorActions.saveReport({ update: true })).then(() =>
            history.push(paths.calculatorDashboard)
          );
        }
        break;
    }
  };


  const saveChanges = () => {
    if (reportName) {
      dispatch(calculatorActions.saveReport({ update: true }));
    } else {
      onDialogOpen(HEADER_ACTIONS.SAVE)();
    }
  };

  const exitCalculator = () => {
    if (touched) {
      onDialogOpen(HEADER_ACTIONS.SAVE_AND_EXIT)();
    } else {
      history.push(paths.calculatorDashboard);
    }
  };

  const navigateToReport = () => {
    dispatch(calculatorActions.goToReport());
  };

  return (
    <div
      className={cx(classes.header, {
        [classes.dashboardHeader]: dashboardHeader,
      })}
      data-testid={testid}
    >
      {dashboardHeader ? (
        <AccountMenu />
      ) : (
        <>
          <div className={classes.homeBtnWrapper}>
            <IconButton className={classes.homeBtn} onClick={exitCalculator}>
              <ExitToApp />
              Exit
            </IconButton>
          </div>
          <div className={classes.content}>
            <div className={classes.navigation}>
              {!isReportHeader &&
                <Button
                  className={classes.navBtn}
                  data-testid={`${testid}__view_report-btn`}
                  onClick={navigateToReport}
                >
                  View Report
                </Button>}
              <Button
                className={classes.navBtn}
                data-testid={`${testid}__save-and-exit-btn`}
                onClick={saveChanges}
                disabled={!touched}
              >
                Save Changes
              </Button>
              <Dialog
                id={headerOptions[headerAction].testid}
                title={headerOptions[headerAction].title}
                open={isDialogOpen}
                onClose={() => {
                  setIsDialogOpen(false);
                  form.resetForm();
                }}
                onSubmitBtnClick={onDialogConfirm}
                submitBtnText={headerOptions[headerAction].submitBtnText}
                cancelBtnText={headerOptions[headerAction].cancelBtnText}
                closeOnSubmit={headerOptions[headerAction].closeOnSubmit}
                onCancelBtnClick={headerOptions[headerAction].onCancelBtnClick}
              >
                {headerOptions[headerAction].body}
              </Dialog>
              <Button
                className={classes.navBtn}
                onClick={onDialogOpen(HEADER_ACTIONS.RESET)}
                data-testid={`${testid}__start-again-btn`}
              >
                Start Again & Reset Data
              </Button>
            </div>

            <div className={classes.amountWrapper}>
              {reportName && (
                <Typography className={classes.reportName}>
                  {reportName}
                </Typography>
              )}
              <Typography className={classes.totalSavingsText}>
                Total Savings (avg):
              </Typography>
              <Typography
                className={cx(classes.totalSavingsAmount, {
                  [classes.totalSavingsAmountPositive]: +totalSavings,
                })}
                data-testid={`${testid}__total-savings`}
              >
                {StringFormatter.money(totalSavings)}
              </Typography>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default withStyles(styles)(Header);