import React, { FunctionComponent } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import { Table, TableRow, TableBody, TableCell as TC, TableHead, Typography } from '@material-ui/core';
import { StringFormatter } from '../../../../utils';
import { ISummaryReport } from '../../../../interfaces/calculator.interface';
import styles, { tableCellStyles } from './TotalSavings.styles';

export const TableCell = withStyles(tableCellStyles)(TC);

interface IProps extends WithStylesType<typeof styles> {
  report: ISummaryReport;
}

const TotalSavingsByPopulation: FunctionComponent<IProps> = ({ classes, report }) => {
  const tableRows = [
    {
      titleClass: classes.columnTitle,
      title: 'Children',
      firstAmountClass: classes.sectionAmount,
      firstAmount: StringFormatter.roundedMoney(report?.totalSavingsByChild?.toString() || '0'),
      secondAmountClass: classes.sectionAmount,
      secondAmount: StringFormatter.interventionPercentage(report?.totalSavingsChildPercentage?.toString() || '0'),
      visible: true,
    },
    {
      titleClass: classes.sectionTitle,
      title: 'Adults',
      firstAmountClass: classes.sectionAmount,
      firstAmount: StringFormatter.roundedMoney(report?.totalSavingsByAdult?.toString() || '0'),
      secondAmountClass: classes.sectionAmount,
      secondAmount: StringFormatter.interventionPercentage(
        report?.totalSavingsAdultPercentage?.toString() || '0'
      ),
      visible: true,
    },
    {
      titleClass: classes.subsectionTitle,
      title: 'Total',
      firstAmountClass: classes.subsectionAmount,
      firstAmount: StringFormatter.roundedMoney(
        report?.totalSavingsByPopulation?.toString() || '0'
      ),
      secondAmountClass: classes.subsectionAmount,
      secondAmount: StringFormatter.interventionPercentage(
        report?.totalSavingsPopulationPercentage?.toString() || '0'
      ),
      visible: true,
    },
  ];
  return (
    <div>
      <Typography variant="h2" className={classes.contentTitle}>Total Savings by Population</Typography>
      <Table style={{ backgroundColor: 'white', borderRadius:'5px' }}>
        <TableHead>
          <TableCell align="left">
            Population
          </TableCell>
          <TableCell align="center">
            $
          </TableCell>
          <TableCell align="center">
            %
          </TableCell>
        </TableHead>
        <TableBody>
          {tableRows.map((row, idx) => (
            row.visible ?
              <TableRow key={idx}>
                <TableCell component="th" scope="row">
                  {row.title}
                </TableCell>
                <TableCell className={row.firstAmountClass} align="right">
                  {row.firstAmount}
                </TableCell>
                <TableCell className={row.secondAmountClass} align="right">
                  {row.secondAmount}
                </TableCell>
              </TableRow>
              : <></>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default withStyles(styles)(TotalSavingsByPopulation);
