/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import {
  selectInterventionsChecklistValue,
  calculatorActions,
  selectInterventionsValue,
  selectServiceDataForm,
  selectEmergencyDepartment,
  selectReportName,
  selectState,
  selectTouched,
} from '../../../../redux/calculatorSlice';
import {
  InterventionsChecklistForm,
  ImmunizationsForm,
  IImmunizations,
  InterventionsCommonForm,
} from '../../../../interfaces/interventions.interface';
import { CalculatorEnum } from '../../../../interfaces/calculator.interface';
import { FormValidations, StringFormatter, Constants } from '../../../../utils';
import {
  InterventionHeader,
  InterventionBody,
  InterventionContainer,
  InterventionFieldNoteDialog,
} from '../Intervention';
import { Immunizations as ImmunizationsClass } from '../../../../calculations';
import useDebounceEffect from '../../../../hooks/useDebounceEffect';
import { paths } from '../../..';
import ImmunizationInterventionFooter from './ImmunizationInterventionFooter';
import ImmunizationInterventionCalculation from './ImmunizationInterventionCalculation';

const { DIALOG_MODES } = Constants;

const Immunizations: FunctionComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const switchValue = useSelector(
    selectInterventionsChecklistValue(InterventionsChecklistForm.Immunizations)
  );
  const immunizationsData = useSelector(
    selectInterventionsValue(CalculatorEnum.Immunizations)
  );

  const serviceData = useSelector(selectServiceDataForm);

  const reportName = useSelector(selectReportName);
  const selectedState = useSelector(selectState);

  const emergencyDepartment = useSelector(selectEmergencyDepartment);
  const touched = useSelector(selectTouched);

  const [reinitialize, setReinitialize] = useState(true);
  const [noteName, setNoteName] = useState<string>('');
  const [noteAction, setNoteAction] = useState<'add' | 'edit' | 'delete'>(DIALOG_MODES.ADD);
  const [isNoteDialogOpen, setIsNoteDialogOpen] = useState(false);

  const fields = [
    {
      text: `Number of caregivers who discussed a need for flu vaccines for their Medicaid/CHIP-enrolled children with a HS Specialist and/or were assisted in pursuing the flu vaccine
      `,
      id: ImmunizationsForm.NeedForFluVaccinations,
      showError: true,
    },
    {
      text: `Number of well-child visits for Medicaid/CHIP-enrolled children that occurred due to HS Specialist(s) contacting families to triage barriers and/or encourage attendance
      `,
      id: ImmunizationsForm.NumberOfEncourageVisits,
    },
  ];

  const earlyChildhoodFields = [
    {
      text: `Number of caregivers who discussed early childhood vaccinations for Medicaid/CHIP-enrolled children ages 0-18 months with a HS Specialist and/or were assisted in pursuing the vaccines
      `,
      id: ImmunizationsForm.NumberOfCaregiversDiscussingECVWithHSS,
      showError: true,
    },
    {
      text: `Number of well-child visits for Medicaid/CHIP-enrolled children that occurred due to HS Specialist(s) contacting families to triage barriers and/or encourage attendance
      `,
      id: ImmunizationsForm.NumberWCVsDueToHSSContact,
    },
  ];

  const onSubmit = (values: IImmunizations) => {
    if (touched) {
      dispatch(
        calculatorActions.saveReport({
          reportName,
          update: true,
        }));
    }

    history.push(
      StringFormatter.replaceWithReportName(
        reportName,
        paths.interventionsDiapers
      )
    );
  };

  useEffect(() => {
    if (switchValue) {
      const timeout = setTimeout(() => {
        setReinitialize(false);
      }, 250);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [switchValue]);

  const form = useFormik({
    initialValues: {
      ...immunizationsData,
      [InterventionsCommonForm.LongTermSavingsLow]:
        immunizationsData[InterventionsCommonForm.LongTermSavingsLow] || '0',
      [InterventionsCommonForm.LongTermSavingsHigh]:
        immunizationsData[InterventionsCommonForm.LongTermSavingsHigh] || '0',
    },
    onSubmit,
    validationSchema: switchValue && FormValidations.intervention({
      [ImmunizationsForm.NeedForFluVaccinations]:
        FormValidations.interventionNumberOfChilds(ImmunizationsForm.NeedForFluVaccinations, serviceData.csInput3),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: reinitialize || !switchValue,
  });

  useEffect(() => {
    if (!switchValue) {
      form.resetForm();
      dispatch(
        calculatorActions.clearInterventionData(CalculatorEnum.Immunizations)
      );
    }
  }, [switchValue]);

  useDebounceEffect(() => {
    const hasBeenEdited = Object.keys(form.values).some(
      (key) => !!form.values[key]
    );
    if (!form.dirty && !hasBeenEdited) return;
    /** Calculations happen */
    const immunizations = new ImmunizationsClass({
      state: selectedState,
      serviceData,
      emergencyDepartment,
      lowEstimatedSlider: +form.values[InterventionsCommonForm.LowEstimate],
      highEstimatedSlider: +form.values[InterventionsCommonForm.HighEstimate],
      longTermLowEstimatedSlider: +form.values[InterventionsCommonForm.LongTermLowEstimate],
      longTermHighEstimatedSlider: +form.values[InterventionsCommonForm.LongTermHighEstimate],
      needForFluVaccinations: +form.values[ImmunizationsForm.NeedForFluVaccinations],
      numberOfCaregiversDiscussingECVWithHSS: +form.values[ImmunizationsForm.NumberOfCaregiversDiscussingECVWithHSS],
    });

    const savingsLow = immunizations.immunizationsEstimatedSavingsLow();
    const savingsHigh = immunizations.immunizationsEstimatedSavingsHigh();

    const longTermSavingsLow = immunizations.immunizationsEstimatedLongTermSavingsLow();
    const longTermSavingsHigh = immunizations.immunizationsEstimatedLongTermSavingsHigh();

    if (
      +form.values[InterventionsCommonForm.SavingsLow] !== savingsLow ||
      +form.values[InterventionsCommonForm.SavingsHigh] !== savingsHigh ||
      +form.values[InterventionsCommonForm.LongTermSavingsLow] !== longTermSavingsLow ||
      +form.values[InterventionsCommonForm.LongTermSavingsHigh] !== longTermSavingsHigh
    ) {
      form.setValues({
        ...form.values,
        [InterventionsCommonForm.SavingsLow]: savingsLow.toString(),
        [InterventionsCommonForm.SavingsHigh]: savingsHigh.toString(),
        [InterventionsCommonForm.LongTermSavingsLow]: longTermSavingsLow.toString(),
        [InterventionsCommonForm.LongTermSavingsHigh]: longTermSavingsHigh.toString(),
      });
    }

    form.validateForm().then((errors) => {
      const isValid = !Object.keys(errors).length;

      if (form.dirty) {
        dispatch(
          calculatorActions.setInterventionData({
            intervention: CalculatorEnum.Immunizations,
            value: { ...form.values, isValid: !!isValid },
          })
        );
        dispatch(calculatorActions.setSummaryData());
      }
    });
  }, [form.values]);

  const onSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      calculatorActions.toggleInterventionsChecklistValue({
        key: InterventionsChecklistForm.Immunizations,
        value: e.target.checked,
      })
    );
  };

  const onNote = (noteName: string, noteAction: 'add' | 'edit' | 'delete') => {
    setNoteName(noteName);
    setNoteAction(noteAction);
    setIsNoteDialogOpen(true);
  };

  const onDialogNoteClose = () => {
    setIsNoteDialogOpen(false);
  };

  return (
    <>
      <InterventionContainer
        disabled={!switchValue}
        onSubmit={form.handleSubmit}
        header={
          <InterventionHeader
            title="Immunizations"
            checked={switchValue}
            onChange={onSwitchChange}
          />
        }
        body={
          <InterventionBody
            description={
              <>
                Some HS Specialists discuss the benefits of immunizations for children as well as contact families to encourage them to attend their well-child visits. The following questions ask about the actions taken by caregivers in these situations during the 12-month date range.
              </>
            }
            header="Flu Vaccines"
            form={form}
            fields={fields}
            onNote={onNote}
          />
        }
        longTermBody={
          <InterventionBody
            header="Early Childhood Vaccination Series"
            form={form}
            fields={earlyChildhoodFields}
            onNote={onNote}
          />
        }
        footer={
          <ImmunizationInterventionFooter
            form={form}
            savingsText="Flu vaccine"
            description="Percentage of Medicaid/CHIP-enrolled children who were unvaccinated and received the flu vaccine due to HealthySteps involvement"
            onNote={onNote}
          />
        }
        longTermFooter={
          <ImmunizationInterventionFooter
            hideSliders={true}
            form={form}
            savingsText="Early Childhood Vaccination Series"
            exclusiveDescription="Percentage of Medicaid/CHIP-enrolled children who were unvaccinated and received the vaccination series due to Healthy Steps"
            onNote={onNote}
          />
        }
        calculations={
          <ImmunizationInterventionCalculation 
            form={form}
          />
        }
      />
      <InterventionFieldNoteDialog
        id={`${noteName}__${noteAction}-dialog`}
        name={noteName}
        value={form.values[noteName]}
        mode={noteAction}
        open={isNoteDialogOpen}
        onClose={onDialogNoteClose}
        form={form}
      />
    </>
  );
};

export default Immunizations;
