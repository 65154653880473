import React, { FunctionComponent } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import { Table, TableRow, TableBody, TableCell as TC, TableHead, Typography } from '@material-ui/core';
import { StringFormatter } from '../../../../utils';
import { ISummaryReport } from '../../../../interfaces/calculator.interface';
import styles, { tableCellStyles } from './ROIBySavings.styles';
import { IInterventionsChecklist, InterventionsChecklistForm } from '../../../../interfaces/interventions.interface';

export const TableCell = withStyles(tableCellStyles)(TC);

interface IProps extends WithStylesType<typeof styles> {
  report: ISummaryReport;
  interventionsChecklist: IInterventionsChecklist;
}

const ROIByServiceProvider: FunctionComponent<IProps> = 
({ classes, report, interventionsChecklist }) => {
  const tableRows = [
    {
      titleClass: classes.columnTitle,
      title: 'Interventions for Childrens',
      firstAmountClass: classes.columnTitle,
      firstAmount: StringFormatter.roundedMoney(report?.interventionForChildrensAverage?.toString() || '0'),
      secondAmountClass: classes.columnTitle,
      secondAmount: StringFormatter.roundedMoney(report?.interventionForChildrensLow?.toString() || '0'),
      thirdAmountClass: classes.columnTitle,
      thirdAmount: StringFormatter.roundedMoney(report?.interventionForChildrensHigh?.toString() || '0'),
      visible: true,
    },
    {
      titleClass: classes.sectionTitle,
      title: 'Oral Health',
      firstAmountClass: classes.sectionAmount,
      firstAmount: StringFormatter.roundedMoney(
        report?.dentalEstimatedSavingAverage?.toString()
      ),
      secondAmountClass: classes.sectionAmount,
      secondAmount: StringFormatter.roundedMoney(
        report?.dentalEstimatedTotalTermSavingLow?.toString()
      ),
      thirdAmountClass: classes.sectionAmount,
      thirdAmount: StringFormatter.roundedMoney(
        report?.dentalEstimatedTotalTermSavingHigh?.toString()
      ),
      visible: interventionsChecklist[InterventionsChecklistForm.OralHealth],
    },
    {
      titleClass: classes.sectionTitle,
      title: 'Asthma',
      firstAmountClass: classes.sectionAmount,
      firstAmount: StringFormatter.roundedMoney(
        report?.asthmaEstimatedSavingsAverage?.toString()
      ),
      secondAmountClass: classes.sectionAmount,
      secondAmount: StringFormatter.roundedMoney(
        report.asthmaEstimatedSavingsLow.toString()
      ),
      thirdAmountClass: classes.sectionAmount,
      thirdAmount: StringFormatter.roundedMoney(
        report.asthmaEstimatedSavingsHigh.toString()
      ),
      visible: interventionsChecklist.asthma,
    },
    {
      titleClass: classes.sectionTitle,
      title: 'Appropriate Use of Care',
      firstAmountClass: classes.sectionAmount,
      firstAmount: StringFormatter.roundedMoney(
        report?.emergencyDepartmentEstimatedSavingAverage?.toString()
      ),
      secondAmountClass: classes.sectionAmount,
      secondAmount: StringFormatter.roundedMoney(
        report.emergencyDepartmentEstimatedSavingLow.toString()
      ),
      thirdAmountClass: classes.sectionAmount,
      thirdAmount: StringFormatter.roundedMoney(
        report.emergencyDepartmentEstimatedSavingHigh.toString()
      ),
      visible: interventionsChecklist.emergencyDepartmentUse,
    },
    {
      titleClass: classes.sectionTitle,
      title: 'Immunizations',
      firstAmountClass: classes.sectionAmount,
      firstAmount: StringFormatter.roundedMoney(
        report?.immunizationsEstimatedTotalSavingsAverage?.toString()
      ),
      secondAmountClass: classes.sectionAmount,
      secondAmount: StringFormatter.roundedMoney(
        report?.immunizationsEstimatedTotalSavingsLow?.toString()
      ),
      thirdAmountClass: classes.sectionAmount,
      thirdAmount: StringFormatter.roundedMoney(
        report?.immunizationsEstimatedTotalSavingsHigh?.toString()
      ),
      visible: interventionsChecklist.immunizations,
    },
    {
      titleClass: classes.subsectionTitle,
      title: 'Seasonal Flu',
      firstAmountClass: classes.subsectionAmount,
      firstAmount: StringFormatter.roundedMoney(
          report?.immunizationsEstimatedSavingsAverage?.toString() || '0'
      ),
      secondAmountClass: classes.subsectionAmount,
      secondAmount: StringFormatter.roundedMoney(
          report?.immunizationsEstimatedSavingsLow?.toString() || '0'
      ),
      thirdAmountClass: classes.subsectionAmount,
      thirdAmount: StringFormatter.roundedMoney(report?.immunizationsEstimatedSavingsHigh?.toString() || '0'),
      visible: true,
    },
    {
      titleClass: classes.subsectionTitle,
      title: 'Combo-7 Series',
      firstAmountClass: classes.subsectionAmount,
      firstAmount: StringFormatter.roundedMoney(
          report?.immunizationsEstimatedLongTermSavingsAverage?.toString() || '0'
      ),
      secondAmountClass: classes.subsectionAmount,
      secondAmount: StringFormatter.roundedMoney(
          report?.immunizationsEstimatedLongTermSavingsLow?.toString() || '0'
      ),
      thirdAmountClass: classes.subsectionAmount,
      thirdAmount: StringFormatter.roundedMoney(report?.immunizationsEstimatedLongTermSavingsHigh?.toString() || '0'),
      visible: true,
    },
    {
      titleClass: classes.sectionTitle,
      title: 'Diapers',
      firstAmountClass: classes.sectionAmount,
      firstAmount: StringFormatter.roundedMoney(
        report?.diapersEstimatedSavingsAverage?.toString() || '0'
      ),
      secondAmountClass: classes.sectionAmount,
      secondAmount: StringFormatter.roundedMoney(
        report?.diapersEstimatedSavingsLow?.toString() || '0'
      ),
      thirdAmountClass: classes.sectionAmount,
      thirdAmount: StringFormatter.roundedMoney(
        report?.diapersEstimatedSavingsHigh?.toString() || '0'
      ),
      visible: true,
    },
    {
      titleClass: classes.sectionTitle,
      title: 'Early Detection',
      firstAmountClass: classes.sectionAmount,
      firstAmount: StringFormatter.roundedMoney(
        report?.earlyDetectionEstimatedAverage?.toString() || '0'
      ),
      secondAmountClass: classes.sectionAmount,
      secondAmount: StringFormatter.roundedMoney(
        report?.earlyDetectionEstimatedSavingsLow?.toString() || '0'
      ),
      thirdAmountClass: classes.sectionAmount,
      thirdAmount: StringFormatter.roundedMoney(report?.earlyDetectionEstimatedSavingsHigh?.toString() || '0'),
      visible: true,
    },
    {
      titleClass: classes.subsectionTitle,
      title: 'Referral to Early Head Start/Other Preschool Programs',
      firstAmountClass: classes.subsectionAmount,
      firstAmount: StringFormatter.roundedMoney(
          report?.earlydetectionTabEarlyEducationProgramsTotalNetSavingsAverage?.toString() || '0'
      ),
      secondAmountClass: classes.subsectionAmount,
      secondAmount: StringFormatter.roundedMoney(
          report?.earlydetectionTabEarlyEducationProgramsTotalNetSavingsLow?.toString() || '0'
      ),
      thirdAmountClass: classes.subsectionAmount,
      thirdAmount: StringFormatter.roundedMoney(report?.earlydetectionTabEarlyEducationProgramsTotalNetSavingsHigh?.toString() || '0'),
      visible: true,
    },
    {
      titleClass: classes.subsectionTitle,
      title: 'Referral to Early Intervention/IDEA Part C',
      firstAmountClass: classes.subsectionAmount,
      firstAmount: StringFormatter.roundedMoney(
          report?.earlydetectionTabEarlyInterventionTotalNetSavingsAverage?.toString() || '0'
      ),
      secondAmountClass: classes.subsectionAmount,
      secondAmount: StringFormatter.roundedMoney(
          report?.earlydetectionTabEarlyInterventionTotalNetSavingsLow?.toString() || '0'
      ),
      thirdAmountClass: classes.subsectionAmount,
      thirdAmount: StringFormatter.roundedMoney(report?.earlydetectionTabEarlyInterventionTotalNetSavingsHigh?.toString() || '0'),
      visible: true,
    },
    {
      titleClass: classes.columnTitle,
      title: 'Interventions for Adults',
      firstAmountClass: classes.columnTitle,
      firstAmount: StringFormatter.roundedMoney(report?.interventionForAdultsAverage?.toString() || '0'),
      secondAmountClass: classes.columnTitle,
      secondAmount: StringFormatter.roundedMoney(report?.interventionForAdultsLow?.toString() || '0'),
      thirdAmountClass: classes.columnTitle,
      thirdAmount: StringFormatter.roundedMoney(report?.interventionForAdultsHigh?.toString() || '0'),
      visible: true,
    },
    {
      titleClass: classes.sectionTitle,
      title: 'Postpartum Maternal Depression',
      firstAmountClass: classes.sectionAmount,
      firstAmount: StringFormatter.roundedMoney(
        report?.maternalDepressionEstimatedTotalTermSavingsAverage?.toString() || '0'
      ),
      secondAmountClass: classes.sectionAmount,
      secondAmount: StringFormatter.roundedMoney(
        report?.maternalDepressionEstimatedTotalTermSavingsLow?.toString() || '0'
      ),
      thirdAmountClass: classes.sectionAmount,
      thirdAmount: StringFormatter.roundedMoney(report?.maternalDepressionEstimatedTotalTermSavingsHigh?.toString() || '0'),
      visible: true,
    },
    {
      titleClass: classes.sectionTitle,
      title: 'Birth Planning/Spacing',
      firstAmountClass: classes.sectionAmount,
      firstAmount: StringFormatter.roundedMoney(
        report?.healthBirthSpacingTotalTermEstimatedSavingsAverage?.toString() || '0'
      ),
      secondAmountClass: classes.sectionAmount,
      secondAmount: StringFormatter.roundedMoney(
        report?.healthBirthSpacingTotalTermEstimatedSavingsLow?.toString() || '0'
      ),
      thirdAmountClass: classes.sectionAmount,
      thirdAmount: StringFormatter.roundedMoney(report?.healthBirthSpacingTotalTermEstimatedSavingsHigh?.toString() || '0'),
      visible: true,
    },
    {
      titleClass: classes.sectionTitle,
      title: 'Reduction in Intimate Partner Violence',
      firstAmountClass: classes.sectionAmount,
      firstAmount: StringFormatter.roundedMoney(
        report?.intimatePartnerViolenceEstimatedSavingsAverage?.toString() || '0'
      ),
      secondAmountClass: classes.sectionAmount,
      secondAmount: StringFormatter.roundedMoney(
        report?.intimatePartnerViolenceEstimatedSavingsLow?.toString() || '0'
      ),
      thirdAmountClass: classes.sectionAmount,
      thirdAmount: StringFormatter.roundedMoney(report?.intimatePartnerViolenceEstimatedSavingsHigh?.toString() || '0'),
      visible: true,
    },
    {
      titleClass: classes.sectionTitle,
      title: 'Breastfeeding',
      firstAmountClass: classes.sectionAmount,
      firstAmount: StringFormatter.roundedMoney(
          report?.breastfeedingEstimatedSavingsAverage?.toString() || '0'
      ),
      secondAmountClass: classes.sectionAmount,
      secondAmount: StringFormatter.roundedMoney(
          report?.breastfeedingEstimatedSavingsLow?.toString() || '0'
      ),
      thirdAmountClass: classes.sectionAmount,
      thirdAmount: StringFormatter.roundedMoney(report?.breastfeedingEstimatedSavingsHigh?.toString() || '0'),
      visible: true,
    },
    {
      titleClass: classes.sectionTitle,
      title: 'Smoking Cessation',
      firstAmountClass: classes.sectionAmount,
      firstAmount: StringFormatter.roundedMoney(
          report?.smokingTabTotalSavingsAdultAndChildAverage?.toString() || '0'
      ),
      secondAmountClass: classes.sectionAmount,
      secondAmount: StringFormatter.roundedMoney(
          report?.smokingTabTotalSavingsAdultAndChildLow?.toString() || '0'
      ),
      thirdAmountClass: classes.sectionAmount,
      thirdAmount: StringFormatter.roundedMoney(report?.smokingTabTotalSavingsAdultAndChildHigh?.toString() || '0'),
      visible: true,
    },
    {
      titleClass: classes.subsectionTitle,
      title: 'Adult',
      firstAmountClass: classes.subsectionAmount,
      firstAmount: StringFormatter.roundedMoney(
          report?.smokingCessationEstimatedSavingsAdultAverage?.toString() || '0'
      ),
      secondAmountClass: classes.subsectionAmount,
      secondAmount: StringFormatter.roundedMoney(
          report?.smokingTabTotalDiscountedFutureSavingsAdultLow?.toString() || '0'
      ),
      thirdAmountClass: classes.subsectionAmount,
      thirdAmount: StringFormatter.roundedMoney(report?.smokingTabTotalDiscountedFutureSavingsAdultHigh?.toString() || '0'),
      visible: true,
    },
    {
      titleClass: classes.subsectionTitle,
      title: 'Child',
      firstAmountClass: classes.subsectionAmount,
      firstAmount: StringFormatter.roundedMoney(
          report?.smokingCessationEstimatedSavingsChildAverage?.toString() || '0'
      ),
      secondAmountClass: classes.subsectionAmount,
      secondAmount: StringFormatter.roundedMoney(
          report?.smokingTabTotalDiscountedFutureSavingsChildLow?.toString() || '0'
      ),
      thirdAmountClass: classes.subsectionAmount,
      thirdAmount: StringFormatter.roundedMoney(report?.smokingTabTotalDiscountedFutureSavingsChildHigh?.toString() || '0'),
      visible: true,
    },
    {
      titleClass: classes.sectionTitle,
      title: 'Food Security',
      firstAmountClass: classes.sectionAmount,
      firstAmount: StringFormatter.roundedMoney(
          report?.foodSecurityEstimatedSavingsAverage?.toString() || '0'
      ),
      secondAmountClass: classes.sectionAmount,
      secondAmount: StringFormatter.roundedMoney(
          report?.foodSecurityEstimatedSavingsLow?.toString() || '0'
      ),
      thirdAmountClass: classes.sectionAmount,
      thirdAmount: StringFormatter.roundedMoney(report?.foodSecurityEstimatedSavingsHigh?.toString() || '0'),
      visible: true,
    },
    {
      titleClass: classes.sectionTitle,
      title: 'Positive Parenting',
      firstAmountClass: classes.sectionAmount,
      firstAmount: StringFormatter.roundedMoney(
            report?.positiveParentingEstimatedSavingsAverage?.toString() || '0'
      ),
      secondAmountClass: classes.sectionAmount,
      secondAmount: StringFormatter.roundedMoney(
            report?.positiveParentingEstimatedSavingsLow?.toString() || '0'
      ),
      thirdAmountClass: classes.sectionAmount,
      thirdAmount: StringFormatter.roundedMoney(report?.positiveParentingEstimatedSavingsHigh?.toString() || '0'),
      visible: true,
    },
    {
      titleClass: classes.subsectionTitle,
      title: 'Referral to Home Visiting Programs',
      firstAmountClass: classes.subsectionAmount,
      firstAmount: StringFormatter.roundedMoney(
          report?.positiveparentingTabEstimatedSavingsFamiliesRecievingHomeVisitingServicesProgramsAverage?.toString() || '0'
      ),
      secondAmountClass: classes.subsectionAmount,
      secondAmount: StringFormatter.roundedMoney(
          report?.positiveparentingTabEstimatedSavingsFamiliesRecievingHomeVisitingServicesProgramsLow?.toString() || '0'
      ),
      thirdAmountClass: classes.subsectionAmount,
      thirdAmount: StringFormatter.roundedMoney(report?.positiveparentingTabEstimatedSavingsFamiliesRecievingHomeVisitingServicesProgramsHigh?.toString() || '0'),
      visible: true,
    },
    {
      titleClass: classes.subsectionTitle,
      title: 'Other Parenting Supports/Services',
      firstAmountClass: classes.subsectionAmount,
      firstAmount: StringFormatter.roundedMoney(
          report?.positiveparentingTabCalculatedSavingsForPositiveParentingSupportsAndServices?.toString() || '0'
      ),
      secondAmountClass: classes.subsectionAmount,
      secondAmount: StringFormatter.roundedMoney(
          report?.positiveparentingTabCalculatedSavingsForPositiveParentingSupportsAndServices?.toString() || '0'
      ),
      thirdAmountClass: classes.subsectionAmount,
      thirdAmount: StringFormatter.roundedMoney(report?.positiveparentingTabCalculatedSavingsForPositiveParentingSupportsAndServices?.toString() || '0'),
      visible: true,
    },
    {
      titleClass: classes.columnTitle,
      title: 'Total Savings',
      firstAmountClass: classes.columnTitle,
      firstAmount: StringFormatter.roundedMoney(report?.totalSavingsAverage?.toString() || '0'),
      secondAmountClass: classes.columnTitle,
      secondAmount: StringFormatter.roundedMoney(report?.totalSavingsLow?.toString() || '0'),
      thirdAmountClass: classes.columnTitle,
      thirdAmount: StringFormatter.roundedMoney(report?.totalSavingsHigh?.toString() || '0'),
      visible: true,
    },
    {
      titleClass: classes.columnTitle,
      title: 'Total Operating Costs',
      firstAmountClass: classes.columnTitle,
      firstAmount: StringFormatter.roundedMoney(report?.totalSiteServiceDeliveryCost?.toString() || '0'),
      secondAmountClass: classes.columnTitle,
      secondAmount: '-',
      thirdAmountClass: classes.columnTitle,
      thirdAmount: '-',
      visible: true,
    },
    {
      titleClass: classes.columnTitle,
      title: 'Total Net Savings',
      firstAmountClass: classes.columnTitle,
      firstAmount: StringFormatter.roundedMoney(report?.totalNetSavingsAverage?.toString() || '0'),
      secondAmountClass: classes.columnTitle,
      secondAmount: StringFormatter.roundedMoney(report?.totalNetSavingsLow?.toString() || '0'),
      thirdAmountClass: classes.columnTitle,
      thirdAmount: StringFormatter.roundedMoney(report?.totalNetSavingsHigh?.toString() || '0'),
      visible: true,
    },

  ];
  return (
    <div style={{ paddingLeft:'35px', marginTop:'20px' }}>
      <Typography variant="h2" className={classes.contentTitle}>Cost-savings Interventions</Typography>
      <Table style={{ backgroundColor: 'white', borderRadius:'5px' }}>
        <TableHead>
          <TableCell align="left" />
          <TableCell align="center">
            Average
          </TableCell>
          <TableCell align="center">
            Low Estimate
          </TableCell>
          <TableCell align="center">
            High Estimate
          </TableCell>
        </TableHead>
        <TableBody>
          {tableRows.map((row, idx) => (
            row.visible ?
              <TableRow key={idx}>
                <TableCell className={row.titleClass} scope="row">
                  {row.title}
                </TableCell>
                <TableCell className={row.firstAmountClass} align="right">
                  {row.firstAmount}
                </TableCell>
                <TableCell className={row.secondAmountClass} align="right">
                  {row.secondAmount}
                </TableCell>
                <TableCell className={row.thirdAmountClass} align="right">
                  {row.thirdAmount}
                </TableCell>
              </TableRow>
              : <></>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default withStyles(styles)(ROIByServiceProvider);
