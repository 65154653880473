import React, { FunctionComponent } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { FormikProps } from 'formik';
import { IServiceData } from '../../../../../interfaces/serviceData.interface';
import ServiceSectionLayout from '../../../ServiceSectionLayout/ServiceSectionLayout';
import { Constants, DateUtils } from '../../../../../utils';
import { Select, DatePicker, Tooltip, InputText } from '../../../../../components';
import styles from './ServiceData.styles';

interface IProps extends WithStylesType<typeof styles> {
  form: FormikProps<IServiceData>;
}

const ServiceData: FunctionComponent<IProps> = ({ classes, form }) => {
  const testid = 'service-data-section';
  const showSiteName = form.values.calculations_apply_to && form.values.calculations_apply_to !== 'multiple sites within a region or state';
  const showNumberOfSites = form.values.calculations_apply_to && ['multiple sites within a health system', 'multiple sites within a region or state'].includes(form.values.calculations_apply_to);

  const setDropdown = (value: string) => {
    form.setValues({
      ...form.values,
      calculations_apply_to: value,
      ...( (value === 'multiple sites within a region or state') && { site_name: '' } ) 
    });
  };

  return (
    <ServiceSectionLayout testId={testid} title="General Information" required>
      <div className={classes.content}>
        <Select
          id="calculations_apply_to"
          name="calculations_apply_to"
          labelText="Select if data for ROI calculations will apply to:"
          placeholder="- Select -"
          customClass={classes.select}
          options={[
            {
              value: 'a single site',
              id: 'a single site',
            },
            {
              value: 'multiple sites within a health system',
              id: 'multiple sites within a health system',
            },
            {
              value: 'multiple sites within a region or state',
              id: 'multiple sites within a region or state',
            },
          ]}
          value={form.values.calculations_apply_to}
          onChange={(event) => setDropdown(String(event.target.value))}
          errorMessage={form.errors.calculations_apply_to}
        />
        {showSiteName &&
          <InputText
            id="site_name"
            name="site_name"
            onChange={form.handleChange}
            value={form.values.site_name}
            labelText="HealthySteps Site/System Name"
            maxLength={50}
            errorMessage={form.errors.site_name}
            customClass={classes.input}
            hideMaxLengthMessage
          />}
        {showNumberOfSites &&
          <InputText
            id="number_of_sites_calculations_apply_to"
            name="number_of_sites_calculations_apply_to"
            onChange={form.handleChange}
            value={form.values.number_of_sites_calculations_apply_to}
            labelText="Number of sites ROI calculation will apply to:"
            maxLength={50}
            errorMessage={form.errors.number_of_sites_calculations_apply_to}
            customClass={classes.input}
            hideMaxLengthMessage
          />}
        <Select
          id="site_location"
          name="site_location"
          labelText="Select Site State Location"
          placeholder="- Select State -"
          options={Constants.US_STATES}
          customClass={classes.select}
          value={form.values.site_location}
          onChange={form.handleChange}
          errorMessage={form.errors.site_location}
        />
        <div className={classes.datePickerWrapper}>
          <Typography
            className={classes.datePickerText}
            data-testid={`${testid}__date-range-title`}
          >
            Enter Date Range
            <Tooltip
              id={`${testid}__date-range-tooltip`}
              title="Date range should be a 12-month timeframe and should not exceed 12 months (as this does not represent annualized short-term cost savings)."
              placement="right"
            >
              <span></span>
            </Tooltip>
          </Typography>
          <div className={classes.datePickerContainer}>
            <p className={classes.datePickerLabel}>From</p>
            <DatePicker
              id="date_from"
              name="date_from"
              value={form.values.date_from}
              onChange={(dateFrom) => {
                let dateTo = null;
                if (dateFrom && DateUtils.isValid(dateFrom)) {
                  dateTo = new Date(dateFrom);
                  const nextYear = dateTo.getFullYear() + 1;
                  dateTo.setFullYear(nextYear);
                }

                form.setValues({
                  ...form.values,
                  date_from: dateFrom,
                  date_to: dateTo,
                });
              }}
              errorMessage={form.errors.date_from}
            />
            <p className={classes.datePickerLabel}>To</p>
            <DatePicker
              id="date_to"
              name="date_to"
              value={form.values.date_to}
              customClass={classes.readOnlyPicker}
              disabled
            />
          </div>
        </div>
      </div>
    </ServiceSectionLayout>
  );
};

export default withStyles(styles)(ServiceData);


