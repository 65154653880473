import React, { FunctionComponent } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
  IInterventionsChecklist,
  InterventionsChecklistForm,
} from '../../../interfaces/interventions.interface';
import {
  calculatorActions,
  selectInterventionsChecklist,
  selectReportName,
} from '../../../redux/calculatorSlice';
import { Tooltip } from '../../../components';
import InterventionsChecklist from './InterventionsChecklist/InterventionsChecklist';
import Footer from '../Footer/Footer';
import { history, paths } from '../..';
import useDebounceEffect from '../../../hooks/useDebounceEffect';
import { snackbarActions } from '../../../redux/snackbarSlice';
import { StringFormatter } from '../../../utils';
import styles from './CalculatorInterventionsChecklist.styles';

const CalculatorInterventionsChecklist: FunctionComponent<WithStylesType<
  typeof styles
>> = ({ classes }) => {
  const testid = 'interventions-checklist';
  const interventions = useSelector(selectInterventionsChecklist);
  const reportName = useSelector(selectReportName);

  const dispatch = useDispatch();

  const childInterventions = [
    {
      title: 'Oral Health',
      formId: InterventionsChecklistForm.OralHealth,
      tooltip: 'Some HS Specialists advise caregivers about the importance and benefits of early dental visits, provide education to caregivers on wiping the teeth down after feeding, and make referrals to oral health services. The following questions ask about interactions with caregivers in these situations during the 12-month date range.',
    },
    {
      title: 'Asthma',
      formId: InterventionsChecklistForm.Asthma,
      tooltip: 'Some HS Specialists discuss asthma and wheezing with families. These conversations can help educate families about use of asthma-related medications, ensure they have their prescribed asthma-related medications available for use at home, and help avoid unnecessary ED visits as a result of an asthma or wheezing episode. The following questions ask about the actions taken by caregivers in these situations during the 12-month date range.',
    },
    {
      title: 'Appropriate Use of Care',
      formId: InterventionsChecklistForm.EmergencyDepartmentUse,
      tooltip: <span>While most HS Specialists are not licensed to give medical guidance, they often act as the point of contact for families, connecting them to nurse hotlines or other practice-specific medical resources if families ask for help. <br /><br />Conversations with HealthySteps sites indicate that HS Specialists sometimes field phone calls, text messages, and online portal requests from caregivers seeking advice about whether or not to bring a sick child in for a visit. The following questions ask about the actions taken by caregivers in these situations during the 12-month date range.<br /><br />Exclude phone or text advice provided for asthma exacerbations as they are captured on the asthma intervention page.</span>,
    },
    {
      title: 'Immunizations',
      formId: InterventionsChecklistForm.Immunizations,
      tooltip: 'Some HS Specialists discuss the benefits of immunizations for children as well as contact families to encourage them to attend their well-child visits. The following questions ask about the actions taken by caregivers in these situations during the 12-month date range.',
    },
    {
      title: 'Diapers',
      formId: InterventionsChecklistForm.Diapers,
      tooltip: 'Some HS Specialists discuss the benefits of diapers for children as well as contact families to encourage them to attend their well-child visits. The following questions ask about the actions taken by caregivers in these situations during the 12-month date range.',
    },
    {
      title: 'Early Detection',
      formId: InterventionsChecklistForm.EarlyDetection,
      tooltip: 'Some HS Specialists talk with caregivers about smoking cessation. The following questions ask about the actions taken by caregivers in these situations during the 12-month date range.',
    },
  ];

  const caregiverInterventions = [
    {
      title: 'Positive Parenting',
      formId: InterventionsChecklistForm.PositiveParenting,
      tooltip: 'Some HS Specialists talk with caregivers about smoking cessation. The following questions ask about the actions taken by caregivers in these situations during the 12-month date range.',
    },
    {
      title: 'Postpartum Maternal Depression',
      formId: InterventionsChecklistForm.PostpartumMaternalDepression,
      tooltip: 'Some HS Specialists talk with caregivers about postpartum maternal depression. The following questions ask about the actions taken by caregivers in these situations during the 12-month date range.',
    },
    {
      title: 'Healthy Birth Spacing',
      formId: InterventionsChecklistForm.HealthyBirthSpacing,
      tooltip: 'Some HS Specialists talk with mothers about attending postpartum visits, healthy birth spacing and family planning. The following questions ask about the actions taken by mothers in these situations during the 12-month date range.',
    },
    {
      title: 'Intimate Partner Violence',
      formId: InterventionsChecklistForm.IntimatePartnerViolence,
      tooltip: 'Some HS Specialists talk with caregivers about intimate partner violence (IPV). The following questions ask about the actions taken by caregivers in these situations during the 12-month date range.',
    },
    {
      title: 'Breastfeeding',
      formId: InterventionsChecklistForm.Breastfeeding,
      tooltip: 'Some HS Specialists talk with caregivers about breastfeeding. The following questions ask about the actions taken by caregivers in these situations during the 12-month date range.',
    },
    {
      title: 'Smoking Cessation',
      formId: InterventionsChecklistForm.SmokingCessation,
      tooltip: 'Some HS Specialists talk with caregivers about smoking cessation. The following questions ask about the actions taken by caregivers in these situations during the 12-month date range.',
    },
    {
      title: 'Food Security',
      formId: InterventionsChecklistForm.FoodSecurity,
      tooltip: 'Some HS Specialists talk with caregivers about food security. The following questions ask about the actions taken by caregivers in these situations during the 12-month date range.',
    },
  ];

  const onSubmit = (values: IInterventionsChecklist) => {
    if (Object.keys(values).some((key) => values[key])) {
      // dispatch(calculatorActions.setInterventionsChecklistData(values));
      history.push(
        StringFormatter.replaceWithReportName(
          reportName,
          paths.interventionsOralHealth
        )
      );
    } else {
      dispatch(
        snackbarActions.normal(
          'Please activate at least one of the items to continue'
        )
      );
    }
  };

  const form = useFormik({
    initialValues: interventions,
    onSubmit,
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
  });

  useDebounceEffect(() => {
    if (form.dirty) {
      dispatch(calculatorActions.setInterventionsChecklistData(form.values));
      dispatch(calculatorActions.setSummaryData());
    }
  }, [form.values]);

  return (
    <form onSubmit={form.handleSubmit}>
      <div className={classes.container}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography
              variant="h1"
              classes={{ root: classes.title }}
              data-testid={`${testid}__title`}
            >
              HealthySteps Cost Savings Interventions
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography data-testid={`${testid}__description`}>
              Please indicate below which interventions HS Specialist(s) discussed with families and/or provided appropriate supports and follow-up as necessary during the 12-month date range. Please note, HS Specialist(s) may not have delivered all interventions listed below.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <InterventionsChecklist
              testid={`${testid}__child-interventions`}
              options={childInterventions}
              form={form}
              title={
                <>
                  Child-Focused Interventions Provided by HS Specialist(s)
                  <Tooltip
                    id={`${testid}__child-interventions__tooltip`}
                    title="These interventions drive cost savings specific to children and focus on how HS Specialists directly support caregivers to access early preventive oral health care, access appropriate level of care to avoid the emergency department and increase timeliness of well-child visits to improve flu vaccine rates for their child(ren)."
                    variant="lightBlue"
                    placement="right"
                  >
                    <span />
                  </Tooltip>
                </>
              }
            />
          </Grid>
          <Grid item xs={12} classes={{ root: classes.marginTop }}>
            <InterventionsChecklist
              testid={`${testid}__caregiver-interventions`}
              options={caregiverInterventions}
              form={form}
              title={
                <>
                  Caregiver-Focused Interventions Provided by HS Specialist(s)
                  <Tooltip
                    id={`${testid}__caregiver-interventions__tooltip`}
                    title="These interventions drive cost savings specific to adults and include the work HS Specialists provide to support caregivers in breastfeeding, postpartum maternal depression, intimate partner violence, healthy birth spacing, and smoking cessation."
                    variant="lightBlue"
                    placement="right"
                  >
                    <span />
                  </Tooltip>
                </>
              }
            />
          </Grid>
        </Grid>
      </div>
      <Footer currentForm={form} />
    </form>
  );
};

export default withStyles(styles)(CalculatorInterventionsChecklist);
