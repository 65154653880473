/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import {
  selectInterventionsChecklistValue,
  calculatorActions,
  selectServiceDataForm,
  selectInterventionsValue,
  selectReportName,
  selectState,
  selectTouched,
} from '../../../../redux/calculatorSlice';
import {
  InterventionsChecklistForm,
  IDiapers,
  InterventionsCommonForm,
  DiapersForm,
} from '../../../../interfaces/interventions.interface';
import { CalculatorEnum } from '../../../../interfaces/calculator.interface';
import {
  InterventionHeader,
  InterventionBody,
  InterventionFooter,
  InterventionContainer,
  InterventionFieldNoteDialog,
} from '../Intervention';
import { FormValidations, StringFormatter, Constants } from '../../../../utils';
import useDebounceEffect from '../../../../hooks/useDebounceEffect';
import { Diapers as DiapersClass } from '../../../../calculations';
import { paths } from '../../..';

const { DIALOG_MODES } = Constants;

const Diapers: FunctionComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const switchValue = useSelector(
    selectInterventionsChecklistValue(InterventionsChecklistForm.Diapers)
  );

  const diapersData = useSelector(
    selectInterventionsValue(CalculatorEnum.Diapers)
  );

  const serviceData = useSelector(selectServiceDataForm);
  const selectedState = useSelector(selectState);

  const reportName = useSelector(selectReportName);
  const touched = useSelector(selectTouched);

  const [reinitialize, setReinitialize] = useState(true);
  const [noteName, setNoteName] = useState<string>('');
  const [noteAction, setNoteAction] = useState<'add' | 'edit' | 'delete'>(DIALOG_MODES.ADD);
  const [isNoteDialogOpen, setIsNoteDialogOpen] = useState(false);

  const fields = [
    {
      text: `Number of caregivers (of Medicaid/CHIP-enrolled children) provided diapers onsite or referred externally to a diaper bank/local non-profit
      `,
      id: DiapersForm.NumberOfCaregiversProvidedDiapersOrReferred,
      showError: true,
    },
  ];

  const onSubmit = (values: IDiapers) => {
    if (touched) {
      dispatch(
        calculatorActions.saveReport({
          reportName,
          update: true,
        }));
    }

    history.push(
      StringFormatter.replaceWithReportName(
        reportName,
        paths.interventionsEarlyDetection
      )
    );
  };

  useEffect(() => {
    if (switchValue) {
      const timeout = setTimeout(() => {
        setReinitialize(false);
      }, 250);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [switchValue]);

  const form = useFormik({
    initialValues: {
      ...diapersData,
      [InterventionsCommonForm.LongTermSavingsLow]:
        diapersData[InterventionsCommonForm.LongTermSavingsLow] || '0',
      [InterventionsCommonForm.LongTermSavingsHigh]:
        diapersData[InterventionsCommonForm.LongTermSavingsHigh] || '0',
    },
    onSubmit,
    validationSchema: switchValue && FormValidations.intervention({
      [DiapersForm.NumberOfCaregiversProvidedDiapersOrReferred]:
        FormValidations.interventionNumberOfChilds(DiapersForm.NumberOfCaregiversProvidedDiapersOrReferred, serviceData.csInput3),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: reinitialize || !switchValue,
  });

  useEffect(() => {
    if (!switchValue) {
      form.resetForm();
      dispatch(calculatorActions.clearInterventionData(CalculatorEnum.Diapers));
    }
  }, [switchValue]);

  useDebounceEffect(() => {
    const hasBeenEdited = Object.keys(form.values).some(
      (key) => !!form.values[key]
    );
    if (!form.dirty && !hasBeenEdited) return;
    /** Calculations happen */
    const diapers = new DiapersClass({
      state: selectedState,
      serviceData,
      lowEstimatedSlider: +form.values[InterventionsCommonForm.LowEstimate],
      highEstimatedSlider: +form.values[InterventionsCommonForm.HighEstimate],
      numberOfCaregiversProvidedDiapersOrReferred: +form.values[DiapersForm.NumberOfCaregiversProvidedDiapersOrReferred],
    });

    const savingsLow = diapers.diapersEstimatedSavingsLow();
    const savingsHigh = diapers.diapersEstimatedSavingsHigh();

    if (
      +form.values[InterventionsCommonForm.SavingsLow] !== savingsLow ||
      +form.values[InterventionsCommonForm.SavingsHigh] !== savingsHigh
    ) {
      form.setValues({
        ...form.values,
        [InterventionsCommonForm.SavingsLow]: savingsLow.toString(),
        [InterventionsCommonForm.SavingsHigh]: savingsHigh.toString(),
      });
    }

    form.validateForm().then((errors) => {
      const isValid = !Object.keys(errors).length;

      if (form.dirty) {
        dispatch(
          calculatorActions.setInterventionData({
            intervention: CalculatorEnum.Diapers,
            value: { ...form.values, isValid: !!isValid },
          })
        );
        dispatch(calculatorActions.setSummaryData());
      }
    });
  }, [form.values]);

  const onSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      calculatorActions.toggleInterventionsChecklistValue({
        key: InterventionsChecklistForm.Diapers,
        value: e.target.checked,
      })
    );
  };

  const onNote = (noteName: string, noteAction: 'add' | 'edit' | 'delete') => {
    setNoteName(noteName);
    setNoteAction(noteAction);
    setIsNoteDialogOpen(true);
  };

  const onDialogNoteClose = () => {
    setIsNoteDialogOpen(false);
  };

  return (
    <>
      <InterventionContainer
        disabled={!switchValue}
        onSubmit={form.handleSubmit}
        header={
          <InterventionHeader
            title="Diapers"
            checked={switchValue}
            onChange={onSwitchChange}
          />
        }
        body={
          <InterventionBody
            // description={
            //   <>
            //     Some HS Specialists discuss Diapers and wheezing with families. These conversations can help educate families about use of Diapers-related medications, ensure they have their prescribed Diapers-related medications available for use at home, and help avoid unnecessary ED visits as a result of an Diapers or wheezing episode. The following questions ask about the actions taken by caregivers in these situations during the 12-month date range.
            //   </>
            // }
            form={form}
            fields={fields}
            onNote={onNote}
          />
        }
        footer={
          <InterventionFooter
            hideLongTerm={true}
            form={form}
            savingsText="Diapers"
            description="Estimated percentage of caregivers(of Medicaid/CHIP-enrolled children) who receive diapers at no cost due to HealthySteps"
            onNote={onNote}
          />
        }
      />
      <InterventionFieldNoteDialog
        id={`${noteName}__${noteAction}-dialog`}
        name={noteName}
        value={form.values[noteName]}
        mode={noteAction}
        open={isNoteDialogOpen}
        onClose={onDialogNoteClose}
        form={form}
      />
    </>
  );
};

export default Diapers;
