/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import {
  selectInterventionsChecklistValue,
  calculatorActions,
  selectInterventionsValue,
  selectServiceDataForm,
  selectReportName,
  selectState,
  selectTouched,
} from '../../../../redux/calculatorSlice';
import {
  InterventionsChecklistForm,
  HealthyBirthSpacingForm,
  IHealthyBirthSpacing,
  InterventionsCommonForm,
} from '../../../../interfaces/interventions.interface';
import {
  InterventionHeader,
  InterventionBody,
  InterventionFooter,
  InterventionContainer,
  InterventionFieldNoteDialog,
} from '../Intervention';
import { FormValidations, StringFormatter, Constants } from '../../../../utils';
import { CalculatorEnum } from '../../../../interfaces/calculator.interface';
import { HealthBirthSpacing as HealthBirthSpacingClass } from '../../../../calculations';
import useDebounceEffect from '../../../../hooks/useDebounceEffect';
import { paths } from '../../..';

const { DIALOG_MODES } = Constants;

const HealthyBirthSpacing: FunctionComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const switchValue = useSelector(
    selectInterventionsChecklistValue(
      InterventionsChecklistForm.HealthyBirthSpacing
    )
  );
  const healthyBirthSpacingData = useSelector(
    selectInterventionsValue(CalculatorEnum.HealthyBirthSpacing)
  );

  const serviceData = useSelector(selectServiceDataForm);
  const selectedState = useSelector(selectState);

  const reportName = useSelector(selectReportName);
  const touched = useSelector(selectTouched);

  const [reinitialize, setReinitialize] = useState(true);
  const [noteName, setNoteName] = useState<string>('');
  const [noteAction, setNoteAction] = useState<'add' | 'edit' | 'delete'>(DIALOG_MODES.ADD);
  const [isNoteDialogOpen, setIsNoteDialogOpen] = useState(false);

  const fields = [
    {
      text: `Number of mothers who discussed a need for contraception or other family planning services with a HS Specialist and/or were referred for services (e.g., postpartum visit, family planning, contraception, etc.)
      `,
      id: HealthyBirthSpacingForm.NeedForContraception,
      showError: true,
    },
    {
      text: `Percentage of mothers who followed up on referrals for services (e.g., postpartum visit, family planning, contraception, etc.)
      `,
      id: HealthyBirthSpacingForm.FollowedUpOnReferals,
      percentage: true,
      showError: true,
      showSlider: true,
    },
  ];

  const onSubmit = (values: IHealthyBirthSpacing) => {
    if (touched) {
      dispatch(
        calculatorActions.saveReport({
          reportName,
          update: true,
        }));
    }

    history.push(
      StringFormatter.replaceWithReportName(
        reportName,
        paths.interventionsIntimatePartnerViolence
      )
    );
  };

  useEffect(() => {
    if (switchValue) {
      const timeout = setTimeout(() => {
        setReinitialize(false);
      }, 250);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [switchValue]);

  const form = useFormik({
    initialValues: {
      ...healthyBirthSpacingData,
      [InterventionsCommonForm.LongTermSavingsLow]:
      healthyBirthSpacingData[InterventionsCommonForm.LongTermSavingsLow] || '0',
      [InterventionsCommonForm.LongTermSavingsHigh]:
      healthyBirthSpacingData[InterventionsCommonForm.LongTermSavingsHigh] || '0',
    },
    onSubmit,
    validationSchema: switchValue && FormValidations.intervention({
      [HealthyBirthSpacingForm.NeedForContraception]:
        FormValidations.interventionNumberOfChilds(HealthyBirthSpacingForm.NeedForContraception, serviceData.csInput3),
      [HealthyBirthSpacingForm.FollowedUpOnReferals]: FormValidations.maxPercentageRequired('')
    }),
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: reinitialize || !switchValue,
  });

  useEffect(() => {
    if (!switchValue) {
      form.resetForm();
      dispatch(
        calculatorActions.clearInterventionData(
          CalculatorEnum.HealthyBirthSpacing
        )
      );
    }
  }, [switchValue]);

  useDebounceEffect(() => {
    const hasBeenEdited = Object.keys(form.values).some(
      (key) => !!form.values[key]
    );
    if (!form.dirty && !hasBeenEdited) return;
    /** Calculations happen */
    const healthyBirthSpacing = new HealthBirthSpacingClass({
      state: selectedState,
      serviceData,
      lowEstimatedSlider: +form.values[InterventionsCommonForm.LowEstimate],
      highEstimatedSlider: +form.values[InterventionsCommonForm.HighEstimate],
      needForContraception: +form.values[HealthyBirthSpacingForm.NeedForContraception],
      followedUpOnReferals: +form.values[HealthyBirthSpacingForm.FollowedUpOnReferals]
    });

    const savingsLow = healthyBirthSpacing.healthBirthSpacingEstimatedSavingsLow();
    const savingsHigh = healthyBirthSpacing.healthBirthSpacingEstimatedSavingsHigh();

    const longTermSavingsLow = healthyBirthSpacing.healthBirthSpacingLongTermEstimatedSavingsLow();
    const longTermSavingsHigh = healthyBirthSpacing.healthBirthSpacingLongTermEstimatedSavingsHigh();

    if (
      +form.values[InterventionsCommonForm.SavingsLow] !== savingsLow ||
      +form.values[InterventionsCommonForm.SavingsHigh] !== savingsHigh ||
      +form.values[InterventionsCommonForm.LongTermSavingsLow] !== longTermSavingsLow ||
      +form.values[InterventionsCommonForm.LongTermSavingsHigh] !== longTermSavingsHigh
    ) {
      form.setValues({
        ...form.values,
        [InterventionsCommonForm.SavingsLow]: savingsLow.toString(),
        [InterventionsCommonForm.SavingsHigh]: savingsHigh.toString(),
        [InterventionsCommonForm.LongTermSavingsLow]: longTermSavingsLow.toString(),
        [InterventionsCommonForm.LongTermSavingsHigh]: longTermSavingsHigh.toString(),
      });
    }

    form.validateForm().then((errors) => {
      const isValid = !Object.keys(errors).length;

      if (form.dirty) {
        dispatch(
          calculatorActions.setInterventionData({
            intervention: CalculatorEnum.HealthyBirthSpacing,
            value: { ...form.values, isValid: !!isValid },
          })
        );
        dispatch(calculatorActions.setSummaryData());
      }
    });
  }, [form.values]);

  const onSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      calculatorActions.toggleInterventionsChecklistValue({
        key: InterventionsChecklistForm.HealthyBirthSpacing,
        value: e.target.checked,
      })
    );
  };

  const onNote = (noteName: string, noteAction: 'add' | 'edit' | 'delete') => {
    setNoteName(noteName);
    setNoteAction(noteAction);
    setIsNoteDialogOpen(true);
  };

  const onDialogNoteClose = () => {
    setIsNoteDialogOpen(false);
  };

  return (
    <>
      <InterventionContainer
        disabled={!switchValue}
        onSubmit={form.handleSubmit}
        header={
          <InterventionHeader
            title="Healthy Birth Spacing "
            checked={switchValue}
            onChange={onSwitchChange}
          />
        }
        body={
          <InterventionBody
            description={
              <>
                Some HS Specialists talk with mothers about attending postpartum visits, healthy birth spacing and family planning. The following questions ask about the actions taken by mothers in these situations during the 12-month date range.
              </>
            }
            form={form}
            fields={fields}
            onNote={onNote}
          />
        }
        footer={
          <InterventionFooter
            form={form}
            savingsText="Healthy birth spacing"
            description="Estimated percentage of unintended pregnancies/births averted due to HealthySteps"
            onNote={onNote}
          />
        }
      />
      <InterventionFieldNoteDialog
        id={`${noteName}__${noteAction}-dialog`}
        name={noteName}
        value={form.values[noteName]}
        mode={noteAction}
        open={isNoteDialogOpen}
        onClose={onDialogNoteClose}
        form={form} 
      />
    </>
  );
};

export default HealthyBirthSpacing;
