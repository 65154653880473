import EmergencyDepartment from './emergencyDepartment';
import ServiceData from './serviceData';
import Calculator from './shared/base';

export default class Breastfeeding extends Calculator {
  public serviceData: ServiceData;

  public emergencyDepartment: EmergencyDepartment;

  constructor({
    state,
    serviceData,
    emergencyDepartment,
    sliderComponentContinuedBreastfeedingLow,
    sliderComponentContinuedBreastfeedingHigh,
    sliderComponentContinuedExclusiveBreastfeedingLow,
    sliderComponentContinuedExclusiveBreastfeedingHigh,
    input1TotalNumberOfHsMothersWhoDiscussedANeed,
    input2TotalNumberOfHsMothersWhoReceivedCounselling
  }: {
    state: string;
    serviceData: ServiceData;
    emergencyDepartment: EmergencyDepartment;
    sliderComponentContinuedBreastfeedingLow: number;
    sliderComponentContinuedBreastfeedingHigh: number;
    sliderComponentContinuedExclusiveBreastfeedingLow: number;
    sliderComponentContinuedExclusiveBreastfeedingHigh: number;
    input1TotalNumberOfHsMothersWhoDiscussedANeed: number;
    input2TotalNumberOfHsMothersWhoReceivedCounselling: number;
  }) {
    super({ state });
    this.serviceData = serviceData;
    this.emergencyDepartment = emergencyDepartment;
    this.sliderComponentContinuedBreastfeedingLow =
      sliderComponentContinuedBreastfeedingLow / 100;
    this.sliderComponentContinuedBreastfeedingHigh =
      sliderComponentContinuedBreastfeedingHigh / 100;
    this.sliderComponentContinuedExclusiveBreastfeedingLow =
      sliderComponentContinuedExclusiveBreastfeedingLow / 100;
    this.sliderComponentContinuedExclusiveBreastfeedingHigh =
      sliderComponentContinuedExclusiveBreastfeedingHigh / 100;
    this.input1TotalNumberOfHsMothersWhoDiscussedANeed = input1TotalNumberOfHsMothersWhoDiscussedANeed;
    this.input2TotalNumberOfHsMothersWhoReceivedCounselling = input2TotalNumberOfHsMothersWhoReceivedCounselling;
  }

  // NOTE: Breastfeeding Calculations Are Based On New Massachussets Spreadsheet

  /**
   * @name breastfeeding_tab_reduction_in_otitis_media_episodes_less_than_age_1_with_any_breastfeeding
   * @excel Excel Breastfeeding Tab B5
   * @constant 0.44
   */
  public readonly breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding = this
    .constants.breastfeeding
    .breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding;

  /**
   * @name breastfeeding_tab_reduction_in_otitis_media_episodes_less_than_age_1_with_exclusive_breastfeeding_for_three_months
   * @excel Excel Breastfeeding Tab B6
   * @constant 0.95
   */
  public readonly breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths = this
    .constants.breastfeeding
    .breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths;

  /**
   * @name breastfeeding_tab_rate_of_hospital_discharges_per_100000_related_to_infection_in_first_year_of_life
   * @excel Excel Breastfeeding Tab B9
   * @constant 443
   */
  public readonly breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife = this
    .constants.breastfeeding
    .breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife;

  /**
   * @name breastfeeding_tab_reduction_in_hospital_discharges_related_to_infection_in_first_year_of_life_with_any_increase_in_breastfeeding
   * @excel Excel Breastfeeding Tab B10
   * @constant 0.04
   */
  public readonly breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding = this
    .constants.breastfeeding
    .breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding;

  /**
   * @name breastfeeding_tab_reduction_in_hospital_discharges_related_to_infection_in_first_year_of_life_with_exclusive_breastfeeding
   * @excel Excel Breastfeeding Tab B11
   * @constant 0.12
   */
  public readonly breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding = this
    .constants.breastfeeding
    .breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding;

  /**
   * @name breastfeeding_tab_median_cost_of_hospitalization_for_infection_in_the_first_year_of_life
   * @excel Excel Breastfeeding Tab B12
   * @constant 16543
   */
  public readonly breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife = this
    .constants.breastfeeding
    .breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife;

  /**
   * @name breastfeeding_tab_medical_price_inflation_factor_2017_2019
   * @excel Excel Breastfeeding Tab B13
   * @constant 1.05
   */
  public readonly breastfeedingTabMedicalPriceInflationFactor20172019 = this
    .constants.breastfeeding
    .breastfeedingTabMedicalPriceInflationFactor20172019;

  /**
   * Missing references
   */
  public input1TotalNumberOfHsMothersWhoDiscussedANeed;


  /**
 * Missing references
 */
  public input2TotalNumberOfHsMothersWhoReceivedCounselling;

  /**
   * @name reduction_in_otitis_media_episodes_per_child_less_than_age_1_by_shifting_from_any_breastfeeding_to_exclusive_breastfeeding_for_3_months
   * @excel Excel C125
   * @formula breastfeeding_tab_reduction_in_otitis_media_episodes_less_than_age_1_with_exclusive_breastfeeding_for_three_months - breastfeeding_tab_reduction_in_otitis_media_episodes_less_than_age_1_with_any_breastfeeding
   */
  public reductionInOtitisMediaEpisodesPerChildLessThanAge1ByShiftingFromAnyBreastfeedingToExclusiveBreastfeedingFor3Months = () => {
    return this.isFiniteNumber(
      this
        .breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths -
        this
          .breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding
    );
  };

  /**
   * (App)
   * @name component_slider_from_app
   */
  public sliderComponentContinuedBreastfeedingLow;

  /**
   * @name estimated_percentage_of_HS_mothers_who_discussed_need_for_breastfeeding_advice_assistance_and_continued_breastfeeding_due_to_HS_but_did_not_breastfeed_exclusively_low
   * @excel Excel C126
   * @reference slider_component_continued_breastfeeding_low (from app) (REQUIRED)
   */
  public estimatedPercentageOfHSMothersWhoDiscussedNeedForBreastfeedingAdviceAssistanceAndContinuedBreastfeedingDueToHSButDidNotBreastfeedExclusivelyLow = () => {
    return this.isFiniteNumber(this.sliderComponentContinuedBreastfeedingLow);
  };

  /**
   * (App)
   * @name slider_component_continued_breastfeeding_high
   */
  public sliderComponentContinuedBreastfeedingHigh;

  /**
   * @name estimated_percentage_of_HS_mothers_who_discussed_need_for_breastfeeding_advice_assistance_and_continued_breastfeeding_due_to_HS_but_did_not_breastfeed_exclusively_high
   * @excel Excel C127
   * @reference slider_component_continued_breastfeeding_high (from app) (REQUIRED)
   */
  public estimatedPercentageOfHSMothersWhoDiscussedNeedForBreastfeedingAdviceAssistanceAndContinuedBreastfeedingDueToHSButDidNotBreastfeedExclusivelyHigh = () => {
    return this.isFiniteNumber(this.sliderComponentContinuedBreastfeedingHigh);
  };

  /**
   * (App)
   * @name slider_component_continued_exclusive_breastfeeding_low
   */
  public sliderComponentContinuedExclusiveBreastfeedingLow;

  /**
   * @name estimated_percentage_of_HS_mothers_who_discussed_need_for_breastfeeding_advice_assistance_and_continued_exclusive_breastfeeding_due_to_HS_low
   * @excel Excel C128
   * @reference slider_component_continued_exclusive_breastfeeding_low (from app) (REQUIRED)
   */
  public estimatedPercentageOfHSMothersWhoDiscussedNeedForBreastfeedingAdviceAssistanceAndContinuedExclusiveBreastfeedingDueToHSLow = () => {
    return this.isFiniteNumber(
      this.sliderComponentContinuedExclusiveBreastfeedingLow
    );
  };

  /**
   * (App)
   * @name slider_component_continued_exclusive_breastfeeding_high
   */
  public sliderComponentContinuedExclusiveBreastfeedingHigh;

  /**
   * @name estimated_percentage_of_HS_mothers_who_discussed_need_for_breastfeeding_advice_assistance_and_continued_exclusive_breastfeeding_due_to_HS_high
   * @excel Excel C129
   * @reference slider_component_continued_exclusive_breastfeeding_high (from app) (REQUIRED)
   */
  public estimatedPercentageOfHSMothersWhoDiscussedNeedForBreastfeedingAdviceAssistanceAndContinuedExclusiveBreastfeedingDueToHSHigh = () => {
    return this.isFiniteNumber(
      this.sliderComponentContinuedExclusiveBreastfeedingHigh
    );
  };


  /**
   * @name estimated_number_of_caregivers_who_continued_exclusive_breastfeeding_for_3_to_6_months_due_to_HS_low
   * @excel Excel C14
   * @formula (C10*C9) - (C12*C9)
   */
  public estimatedNumberOfCaregiversWhoContinuedExclusiveBreastFeedingFor3To6MonthsDueToHSLow = () => {
    return this.isFiniteNumber(
      (this.estimatedPercentageOfHSMothersWhoDiscussedNeedForBreastfeedingAdviceAssistanceAndContinuedBreastfeedingDueToHSButDidNotBreastfeedExclusivelyLow() *
    this.input2TotalNumberOfHsMothersWhoReceivedCounselling) - 
    ( this.estimatedPercentageOfHSMothersWhoDiscussedNeedForBreastfeedingAdviceAssistanceAndContinuedExclusiveBreastfeedingDueToHSLow() * this.input2TotalNumberOfHsMothersWhoReceivedCounselling)
    );
  };

  /**
   * @name estimated_number_of_caregivers_who_continued_exclusive_breastfeeding_for_3_to_6_months_due_to_HS_high
   * @excel Excel C14
   * @formula (C11*C9) - (C13*C9)
   */
  public estimatedNumberOfCaregiversWhoContinuedExclusiveBreastFeedingFor3To6MonthsDueToHSHigh = () => {
    return this.isFiniteNumber(
      (this.estimatedPercentageOfHSMothersWhoDiscussedNeedForBreastfeedingAdviceAssistanceAndContinuedBreastfeedingDueToHSButDidNotBreastfeedExclusivelyHigh() *
      this.input2TotalNumberOfHsMothersWhoReceivedCounselling) - 
      ( this.estimatedPercentageOfHSMothersWhoDiscussedNeedForBreastfeedingAdviceAssistanceAndContinuedExclusiveBreastfeedingDueToHSHigh() * this.input2TotalNumberOfHsMothersWhoReceivedCounselling)
    );
  };
  

  /**
   * @name estimated_number_of_breastfeeding_children_with_averted_otitis_media_and_medical_visit_due_to_HS_low
   * @excel Excel C130
   * @formula number_of_HS_mothers_who_discussed_a_need_for_breastfeeding_advice_assistance_with_HSS_in_past_year * reduction_in_otitis_media_episodes_per_child_less_than_age_1_by_shifting_from_any_breastfeeding_to_exclusive_breastfeeding_for_3_months * estimated_percentage_of_HS_mothers_who_discussed_need_for_breastfeeding_advice_assistance_and_continued_breastfeeding_due_to_HS_but_did_not_breastfeed_exclusively_low
   */
  public estimatedNumberOfBreastfeedingChildrenWithAvertedOtitisMediaAndMedicalVisitDueToHSLow = () => {
    return this.isFiniteNumber(
      this.estimatedNumberOfCaregiversWhoContinuedExclusiveBreastFeedingFor3To6MonthsDueToHSLow() * this.reductionInOtitisMediaEpisodesPerChildLessThanAge1ByShiftingFromAnyBreastfeedingToExclusiveBreastfeedingFor3Months()
    );
  };

  /**
   * @name estimated_number_of_breastfeeding_children_with_averted_otitis_media_and_medical_visit_due_to_HS_high
   * @excel Excel C131
   * @formula number_of_HS_mothers_who_discussed_a_need_for_breastfeeding_advice_assistance_with_HSS_in_past_year * reduction_in_otitis_media_episodes_per_child_less_than_age_1_by_shifting_from_any_breastfeeding_to_exclusive_breastfeeding_for_3_months * estimated_percentage_of_HS_mothers_who_discussed_need_for_breastfeeding_advice_assistance_and_continued_breastfeeding_due_to_HS_but_did_not_breastfeed_exclusively_high
   */
  public estimatedNumberOfBreastfeedingChildrenWithAvertedOtitisMediaAndMedicalVisitDueToHSHigh = () => {
    return this.isFiniteNumber(
      this.estimatedNumberOfCaregiversWhoContinuedExclusiveBreastFeedingFor3To6MonthsDueToHSHigh() * this.reductionInOtitisMediaEpisodesPerChildLessThanAge1ByShiftingFromAnyBreastfeedingToExclusiveBreastfeedingFor3Months()    );
  };

  /**
   * (from Emergency Department Use)
   * savings_per_averted_ed_visit_that_is_not_replaced_with_an_office_visit
   * savings_per_averted_office_visit
   * savings_per_averted_fqhc_visit
   * @name savings_per_averted_office_FQHC_ED_visit
   * @excel Excel C132
   * @formula ( savings_per_averted_ed_visit_that_is_not_replaced_with_an_office_visit * 0.5 ) + ( AVERAGE(savings_per_averted_office_visit, savings_per_averted_fqhc_visit) * 0.5)
   */
  public savingsPerAvertedOfficeFQHCEDVisit = () => {
    return this.isFiniteNumber(
      this.emergencyDepartment.savingsPerAvertedEdVisitThatIsNotReplacedWithAnOfficeVisit() *
        0.5 +
        ((this.emergencyDepartment.savingsPerAvertedOfficeVisit() +
          this.emergencyDepartment.savingsPerAvertedFqhcVisit()) /
          2) *
          0.5
    );
  };

  /**
   * @name breastfeeding_estimated_savings_low
   * @excel Excel E119
   * @reference estimated_savings_due_to_averted_otitis_media_and_medical_visit_due_to_HS_low + expected_savings_related_to_reduction_in_number_of_hospitalizations_for_infection_in_the_01_age_range_based_on_increases_in_nonexclusive_breastfeeding_low + expected_savings_related_to_reduction_in_number_of_hospitalizations_for_infection_in_the_01_age_range_based_on_increases_in_exclusive_breastfeeding_low
   */
  public breastfeedingEstimatedSavingsLow = () => {
    return this.isFiniteNumber(
      this.estimatedNumberOfBreastfeedingChildrenWithAvertedOtitisMediaAndMedicalVisitDueToHSLow() * this.savingsPerAvertedOfficeFQHCEDVisit()   );
  };

  /**
   * @name breastfeeding_estimated_savings_high
   * @excel Excel F119
   * @reference estimated_savings_due_to_averted_otitis_media_and_medical_visit_due_to_HS_high + expected_savings_related_to_reduction_in_number_of_hospitalizations_for_infection_in_the_01_age_range_based_on_increases_in_nonexclusive_breastfeeding_high + expected_savings_related_to_reduction_in_number_of_hospitalizations_for_infection_in_the_01_age_range_based_on_increases_in_exclusive_breastfeeding_high
   */
  public breastfeedingEstimatedSavingsHigh = () => {
    return this.isFiniteNumber(
      this.estimatedNumberOfBreastfeedingChildrenWithAvertedOtitisMediaAndMedicalVisitDueToHSHigh() * this.savingsPerAvertedOfficeFQHCEDVisit()    );
  };

  /**
   * @name breastfeeding_estimated_savings_high
   * @excel Excel F119
   * @reference estimated_savings_due_to_averted_otitis_media_and_medical_visit_due_to_HS_high + expected_savings_related_to_reduction_in_number_of_hospitalizations_for_infection_in_the_01_age_range_based_on_increases_in_nonexclusive_breastfeeding_high + expected_savings_related_to_reduction_in_number_of_hospitalizations_for_infection_in_the_01_age_range_based_on_increases_in_exclusive_breastfeeding_high
   */
  public breastfeedingEstimatedSavingsAverage = () => {
    return this.isFiniteNumber(
      (this.breastfeedingEstimatedSavingsLow() + this.breastfeedingEstimatedSavingsHigh())/2    );
  };
}
