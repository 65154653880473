/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FunctionComponent } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import cx from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import {
  selectInterventionsChecklistValue,
  selectInterventionsChecklist,
  selectCalculatorData,
  selectCheckForm,
  calculatorActions
} from '../../../../redux/calculatorSlice';
import {
  InterventionsChecklistForm,
} from '../../../../interfaces/interventions.interface';
import { CalculatorEnum } from '../../../../interfaces/calculator.interface';
import { paths } from '../../..';
import { CheckIcon } from '../../../../assets/icons';
import { StringFormatter } from '../../../../utils';
import styles from './InterventionsSideNav.styles';
import { Switch } from '../../../../components';

const SERVICE_DATA_FORM_KEYS = {
  LOCATION: 'location',
  COSTS: 'costs',
};


const InterventionsSideNav: FunctionComponent<WithStylesType<
  typeof styles
>> = ({ classes }) => {
  const dispatch = useDispatch();
  const interventionsChecklist = useSelector(selectInterventionsChecklist);
  const calculatorData = useSelector(selectCalculatorData);
  const checkForm = useSelector(selectCheckForm);
  
  const onSwitchChange = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
    dispatch(
      calculatorActions.toggleInterventionsChecklistValue({
        key,
        value: e.target.checked,
      })
    );
  };


  const { reportName }: { reportName: string } = useParams();
  const mainItems = [{
    title: 'HEALTHYSTEPS OVERVIEW DATA',
    items: [
      {
        id: 1,
        title: 'General Information & Delivery Costs',
        path: StringFormatter.replaceWithReportName(
          reportName,
          paths.calculatorServiceData
        ),
        enabled: true,
        isValid: true,
        key: SERVICE_DATA_FORM_KEYS.LOCATION,
      },
      {
        id: 2,
        title: 'Cost Savings Interventions',
        path: StringFormatter.replaceWithReportName(
          reportName,
          paths.calculatorInterventionsChecklist
        ),
        enabled: true,
        isValid: true,
        key: SERVICE_DATA_FORM_KEYS.COSTS,
      }
    ],
  }];

  const sectionItems = [

    {
      title: 'Child Interventions',
      items: [
        {
          id: 3,
          title: 'Oral Health',
          path: StringFormatter.replaceWithReportName(
            reportName,
            paths.interventionsOralHealth
          ),
          switchValue: useSelector(
            selectInterventionsChecklistValue(InterventionsChecklistForm.OralHealth)
          ),
          key: InterventionsChecklistForm.OralHealth,
          enabled: interventionsChecklist.oralHealth,
          isValid: calculatorData[CalculatorEnum.OralHealth].isValid,
          switch: false
        },
        {
          id: 4,
          title: 'Asthma',
          path: StringFormatter.replaceWithReportName(
            reportName,
            paths.interventionsAsthma
          ),
          switchValue: useSelector(
            selectInterventionsChecklistValue(InterventionsChecklistForm.Asthma)
          ),
          key: InterventionsChecklistForm.Asthma,
          enabled: interventionsChecklist.asthma,
          isValid: calculatorData[CalculatorEnum.Asthma].isValid,
          switch: false
        },
        {
          id: 5,
          title: 'Appropriate Use of Care',
          path: StringFormatter.replaceWithReportName(
            reportName,
            paths.interventionsEmergencyDepartmentUse
          ),
          switchValue: useSelector(
            selectInterventionsChecklistValue(InterventionsChecklistForm.EmergencyDepartmentUse)
          ),
          key: InterventionsChecklistForm.EmergencyDepartmentUse,
          enabled: interventionsChecklist.emergencyDepartmentUse,
          isValid:
            calculatorData[CalculatorEnum.EmergencyDepartmentUse].isValid,
          switch: false
        },
        {
          id: 6,
          title: 'Immunizations',
          path: StringFormatter.replaceWithReportName(
            reportName,
            paths.interventionsImmunizations
          ),
          switchValue: useSelector(
            selectInterventionsChecklistValue(InterventionsChecklistForm.Immunizations)
          ),
          key: InterventionsChecklistForm.Immunizations,
          enabled: interventionsChecklist.immunizations,
          isValid: calculatorData[CalculatorEnum.Immunizations].isValid,
          switch: false
        },
        {
          id: 12,
          title: 'Diapers',
          path: StringFormatter.replaceWithReportName(
            reportName,
            paths.interventionsDiapers
          ),
          switchValue: useSelector(
            selectInterventionsChecklistValue(InterventionsChecklistForm.Diapers)
          ),
          key: InterventionsChecklistForm.Diapers,
          enabled: interventionsChecklist.diapers,
          isValid: calculatorData[CalculatorEnum.Diapers].isValid,
          switch: false
        },
        {
          id: 14,
          title: 'Early Detection',
          path: StringFormatter.replaceWithReportName(
            reportName,
            paths.interventionsEarlyDetection
          ),
          switchValue: useSelector(
            selectInterventionsChecklistValue(InterventionsChecklistForm.EarlyDetection)
          ),
          key: InterventionsChecklistForm.EarlyDetection,
          enabled: interventionsChecklist.earlyDetection,
          isValid: calculatorData[CalculatorEnum.EarlyDetection].isValid,
          switch: false
        },
      ],
    },
    {
      title: 'Caregiver Interventions',
      items: [
        {
          id: 15,
          title: 'Positive Parenting',
          path: StringFormatter.replaceWithReportName(
            reportName,
            paths.interventionsPositiveParenting
          ),
          switchValue: useSelector(
            selectInterventionsChecklistValue(InterventionsChecklistForm.PositiveParenting)
          ),
          key: InterventionsChecklistForm.PositiveParenting,
          enabled: interventionsChecklist.positiveParenting,
          isValid: calculatorData[CalculatorEnum.PositiveParenting].isValid,
          switch: false
        },
        {
          id: 7,
          title: 'Postpartum Maternal Depression',
          path: StringFormatter.replaceWithReportName(
            reportName,
            paths.interventionsPostpartumMaternalDepression
          ),
          switchValue: useSelector(
            selectInterventionsChecklistValue(InterventionsChecklistForm.PostpartumMaternalDepression)
          ),
          key: InterventionsChecklistForm.PostpartumMaternalDepression,
          enabled: interventionsChecklist.postpartumMaternalDepression,
          isValid:
            calculatorData[CalculatorEnum.PostpartumMaternalDepression].isValid,
          switch: false
        },
        {
          id: 8,
          title: 'Healthy Birth Spacing',
          path: StringFormatter.replaceWithReportName(
            reportName,
            paths.interventionsHealthyBirthSpacing
          ),
          switchValue: useSelector(
            selectInterventionsChecklistValue(InterventionsChecklistForm.HealthyBirthSpacing)
          ),
          key: InterventionsChecklistForm.HealthyBirthSpacing,
          enabled: interventionsChecklist.healthyBirthSpacing,
          isValid: calculatorData[CalculatorEnum.HealthyBirthSpacing].isValid,
          switch: false
        },
        {
          id: 9,
          title: 'Intimate Partner Violence',
          path: StringFormatter.replaceWithReportName(
            reportName,
            paths.interventionsIntimatePartnerViolence
          ),
          switchValue: useSelector(
            selectInterventionsChecklistValue(InterventionsChecklistForm.IntimatePartnerViolence)
          ),
          key: InterventionsChecklistForm.IntimatePartnerViolence,
          enabled: interventionsChecklist.intimidatePartnerViolence,
          isValid:
            calculatorData[CalculatorEnum.IntimatePartnerViolence].isValid,
          switch: false
        },
        {
          id: 10,
          title: 'Breastfeeding',
          path: StringFormatter.replaceWithReportName(
            reportName,
            paths.interventionsBreastfeeding
          ),
          switchValue: useSelector(
            selectInterventionsChecklistValue(InterventionsChecklistForm.Breastfeeding)
          ),
          key: InterventionsChecklistForm.Breastfeeding,
          enabled: interventionsChecklist.breastfeeding,
          isValid: calculatorData[CalculatorEnum.Breastfeeding].isValid,
          switch: false
        },
        {
          id: 11,
          title: 'Smoking Cessation',
          path: StringFormatter.replaceWithReportName(
            reportName,
            paths.interventionsSmokingCessation
          ),
          switchValue: useSelector(
            selectInterventionsChecklistValue(InterventionsChecklistForm.SmokingCessation)
          ),
          key: InterventionsChecklistForm.SmokingCessation,
          enabled: interventionsChecklist.smokeCessation,
          isValid: calculatorData[CalculatorEnum.SmokingCessation].isValid,
          switch: false
        },
        {
          id: 13,
          title: 'Food Security',
          path: StringFormatter.replaceWithReportName(
            reportName,
            paths.interventionsFoodSecurity
          ),
          switchValue: useSelector(
            selectInterventionsChecklistValue(InterventionsChecklistForm.FoodSecurity)
          ),
          key: InterventionsChecklistForm.FoodSecurity,
          enabled: interventionsChecklist.foodSecurity,
          isValid: calculatorData[CalculatorEnum.FoodSecurity].isValid,
          switch: false
        },
      ],
    },
  ];

  const getItem = (section: any) => (
    <div key={section.title} className={classes.section}>
      <Typography variant="h2" className={classes.sectionTitle}>
        {section.title}
      </Typography>
      {section.items.map((item: any) => (
        <div key={`section__${item.key}`} className={classes.sectionItemSwitch}>
          {item.key && item.switch && 
            <Switch
              id="id" 
              checked={item.switchValue}
              onChange={(e) => onSwitchChange(e, item.key)}
            />}
          <NavLink
            key={item.title}
            to={item.path}
            activeClassName={classes.sectionItemActive}
            className={cx(classes.sectionItemLink, {
              [classes.sectionItemDisabled]: !item.enabled,
            })}
          >
            {(!item.isValid && !checkForm) && (
              <span className={classes.sectionItemStatus}>
                {item.enabled ? (
                  <Typography className={classes.sectionItemStatusContent} />
                ) : (
                  <div className={classes.sectionItemStatusContentDisabled} />
                )}
              </span>
            )}
            {item.isValid && <CheckIcon className={classes.isValidIcon} />}
            {(!item.isValid && item.enabled && checkForm) && <ErrorIcon className={classes.isErrorIcon} /> }
            <Typography className={classes.sectionItem}>
              {item.title}
            </Typography>
          </NavLink>
        </div>
      ))}
    </div>);
  return (
    <>
      {mainItems.map(getItem)}
      {sectionItems.map(getItem)}
    </>
  );
};

export default withStyles(styles)(InterventionsSideNav);
