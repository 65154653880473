/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { FunctionComponent, useState, useEffect } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import { useFormik } from 'formik';
import { Grid, Typography } from '@material-ui/core';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectServiceData,
  calculatorActions,
  selectIsLoading,
  selectReportName
} from '../../../redux/calculatorSlice';
import Footer from '../Footer/Footer';
import { history, paths } from '../..';
import {
  IServiceData,
  ServiceDataForm,
  serviceDataState,
} from '../../../interfaces/serviceData.interface';
import ServiceData from './Sections/ServiceData/ServiceData';
// import useFormikFocusError from '../../../hooks/useFormikFocusError';
import { FormValidations, StringFormatter } from '../../../utils';
import ServiceDeliveryCosts from './Sections/ServiceDeliveryCosts/ServiceDeliveryCosts';
import ChildrenServed from './Sections/ChildrenServed/ChildrenServed';
import { ServiceData as ServiceDataClass } from '../../../calculations';
import useDebounceEffect from '../../../hooks/useDebounceEffect';
import styles from './CalculatorServiceData.styles';

const CalculatorServiceData: FunctionComponent<WithStylesType<
  typeof styles
>> = ({ classes }) => {
  const testid = 'service-data';
  const serviceData = useSelector(selectServiceData);
  const isLoading = useSelector(selectIsLoading);
  const reportName = useSelector(selectReportName);
  const dispatch = useDispatch();
  const [initState, setInitState] = useState({ ...serviceDataState });

  const onSubmit = (values: IServiceData) => {
    history.push(
      StringFormatter.replaceWithReportName(
        reportName,
        paths.calculatorInterventionsChecklist
      )
    );
  };

  const validationSchema = Yup.object({
    [ServiceDataForm.SiteLocation]: FormValidations.required('Site location'),
    [ServiceDataForm.DateFrom]: FormValidations.date,
    [ServiceDataForm.CalculationsApplyTo]: FormValidations.required(''),
    [ServiceDataForm.Children0To3]: FormValidations.required(''),
    [ServiceDataForm.MedicaidChildren0To3]: FormValidations.required('').test(
      'Not bigger than Total children ages 0-3',
      'Total Medicaid children ages 0-3 served cannot be greater than Total children ages 0-3 served',
      function test(value) {
        if (!value) return false;

        if (+value > +(this.parent[ServiceDataForm.Children0To3] || '')) {
          return false;
        }

        return true;
      }
    ),
    [ServiceDataForm.Children0To3Tier2And3]: FormValidations.required('').test(
      'Total children in Tiers 2 & 3 served cannot be greater than Total children ages 0-3 served',
      'Total children in Tiers 2 & 3 served cannot be greater than Total children ages 0-3 served',
      function test(value) {
        if (!value) return false;

        if (+value > +this.parent[ServiceDataForm.Children0To3]) {
          return false;
        }

        return true;
      }
    ),
    [ServiceDataForm.MedicaidChildren0To3Tier2And3]: FormValidations.required(
      ''
    ).test(
      'Not bigger than Total children 0-3 in Tiers 2 & 3',
      'Total Medicaid children 0-3 in Tiers 2 & 3 served cannot be greater than Total children 0-3 in Tiers 2 & 3',
      function test(value) {
        if (!value) return false;

        if (+value > +(this.parent[ServiceDataForm.Children0To3Tier2And3] || '')) {
          return false;
        }

        return true;
      }
    ),
    [ServiceDataForm.MedicaidChildrenUnder1Tier2And3]: FormValidations.required(
      ''
    ).test(
      'Not bigger than Total Medicaid children 0-3 in Tiers 2 & 3',
      'Total Medicaid children, under age 1, in Tiers 2 & 3 served cannot be greater than Total Medicaid children 0-3 in Tiers 2 & 3 served',
      function test(value) {
        if (!value) return false;

        if (+value > +(this.parent[ServiceDataForm.MedicaidChildren0To3Tier2And3] || '')) {
          return false;
        }

        return true;
      }
    ),
  });

  const form = useFormik({
    initialValues: initState,
    onSubmit,
    validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
  });

  useEffect(() => {
    const isFullyLoaded = (!isLoading && reportName);
    if (isFullyLoaded) {
      serviceData.dirty ?
        setInitState(serviceData) : setInitState({ ...serviceDataState });
    }
  }, [reportName, isLoading, serviceData.dirty]);

  useDebounceEffect(() => {
    try {
      const serviceDataClass = new ServiceDataClass({
        state: form.values[ServiceDataForm.SiteLocation],
        dcInput1: +form.values[ServiceDataForm.SpecialistSalaryCost],
        dcInput2: +form.values[ServiceDataForm.DirectCost],
        dcInput3: +form.values[ServiceDataForm.OtherCost],
        dcInput4: +form.values[ServiceDataForm.IntermediaryCost],
        csInput1: +form.values[ServiceDataForm.Children0To3],
        csInput2: +form.values[ServiceDataForm.MedicaidChildren0To3],
        csInput3: +form.values[ServiceDataForm.Children0To3Tier2And3],
        csInput4: +form.values[ServiceDataForm.MedicaidChildren0To3Tier2And3],
        csInput5: +form.values[ServiceDataForm.MedicaidChildrenUnder1Tier2And3],
      });

      const dcInput5 = serviceDataClass.dcInput5();

      if (+form.values[ServiceDataForm.SiteServiceCost] !== dcInput5) {
        form.setValues({
          ...form.values,
          [ServiceDataForm.SiteServiceCost]: dcInput5.toString(),
        });
      }
    } catch (err) {
      if (err instanceof Error) {
        console.error(err.message);
      } else {
        console.error('An unknown error occurred', err);
      }
    }
  }, [form, form.values]);

  useDebounceEffect(() => {
    if (!isLoading && form.dirty) {
      dispatch(
        calculatorActions.addServiceData({ ...form.values, dirty: true })
      );
    }
  }, [form.values]);

  return (
    <form onSubmit={form.handleSubmit}>
      <div className={classes.container}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography
              variant="h1"
              classes={{ root: classes.title }}
              data-testid={`${testid}__title`}
            >
              HealthySteps Overview Data
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography data-testid={`${testid}__description`}>
              This form should be completed using HealthySteps service
              data. Please combine data from all HealthySteps Specialists (HS
              Specialists) for each response below. Data should reflect the
              12-month date range entered below as short-term cost savings are
              calculated on an annualized basis.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ServiceData form={form} />
          </Grid>
          <Grid item xs={12}>
            <ServiceDeliveryCosts form={form} />
          </Grid>
          <Grid item xs={12}>
            <ChildrenServed form={form} />
          </Grid>
        </Grid>
      </div>
      <Footer currentForm={form} />
    </form>
  );
};

export default withStyles(styles)(CalculatorServiceData);
