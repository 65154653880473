import React, { FunctionComponent } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import { FormikProps } from 'formik';
import {
  IServiceData,
  ServiceDataForm,
} from '../../../../../interfaces/serviceData.interface';
import ServiceSectionLayout from '../../../ServiceSectionLayout/ServiceSectionLayout';
import { Tooltip } from '../../../../../components';
import ServiceItem from '../../ServiceItem/ServiceItem';
import { Constants } from '../../../../../utils';
import styles from './ServiceDeliveryCosts.styles';

const testid = 'delivery-costs-section';

interface IProps extends WithStylesType<typeof styles> {
  form: FormikProps<IServiceData>;
}

const ServiceDeliveryCosts: FunctionComponent<IProps> = ({ classes, form }) => {
  const deliveryCostsOptions = [
    {
      inputFormId: ServiceDataForm.NumberOfSpecialistsDeliveringServices,
      noteFormId: ServiceDataForm.NumberOfSpecialistsDeliveringServicesNote,
      title: 'Total of HS Specialists delivering services',
      visible: true,
      type: Constants.INPUT_TYPES.NUMBER
    },
    {
      inputFormId: ServiceDataForm.SpecialistSalaryCost,
      noteFormId: ServiceDataForm.SpecialistSalaryCostNote,
      title: 'HS Specialist(s) salary and benefits (should include fringe)',
      visible: true
    },
    {
      inputFormId: ServiceDataForm.DirectCost,
      noteFormId: ServiceDataForm.DirectCostNote,
      title:
        'Other direct costs including professional services, printing, publications, supplies, communication, travel, and meetings. Do not include the costs of the initial HealthySteps Institute or professional development stipends',
      visible: true
    },
    {
      inputFormId: ServiceDataForm.OtherCost,
      noteFormId: ServiceDataForm.OtherCostNote,
      title:
        'Other (for example: food for parent groups mileage, annual cell phone cost, etc.)',
      visible: true
    },
    {
      inputFormId: ServiceDataForm.IntermediaryCost,
      noteFormId: ServiceDataForm.IntermediaryCostNote,
      title: 'Intermediary cost (if applicable)',
      visible: form && form.values.calculations_apply_to === Constants.ROI_CALCULATION_OPTION.multiple_sites_region,
    },
    {
      inputFormId: ServiceDataForm.SiteServiceCost,
      noteFormId: ServiceDataForm.SiteServiceCostNote,
      title: 'Total site service delivery cost during the 12-month date range',
      disabled: true,
      readOnly: true,
      visible: true
    },
  ];

  return (
    <ServiceSectionLayout
      testId={testid}
      title={
        <>
          HealthySteps Service Delivery Costs
          <Tooltip
            id={`${testid}__delivery-costs-tooltip`}
            title="Annual and ongoing costs associated with the delivery of HealthySteps services (e.g. salaries and fringe benefits), and other program-related costs that your HealthySteps site incurs (e.g., cell phone/cell phone plan, office supplies, printing, copying, materials, conference registrations and travel, training costs, mileage for home visits (if applicable), etc.)."
            variant="lightBlue"
            placement="right"
          >
            <span></span>
          </Tooltip>
        </>
      }
      customClass={classes.container}
    >
      <>
        {deliveryCostsOptions.filter((option) => option.visible).map((option, idx) => (
          <ServiceItem
            key={idx}
            inputValue={form.values[option.inputFormId]}
            noteInputValue={form.values[option.noteFormId]}
            inputType={option.type || Constants.INPUT_TYPES.CURRENCY}
            isLastItem={idx === deliveryCostsOptions.length - 1}
            form={form}
            option={option}
          />
        ))}
      </>
    </ServiceSectionLayout>
  );
};

export default withStyles(styles)(ServiceDeliveryCosts);
