/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-key */
import React, { FunctionComponent, Fragment } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import classnames from 'classnames';
import { Table, TableRow, TableBody, TableCell as TC, Typography } from '@material-ui/core';
import { Constants } from '../../../../utils';
import { IBreakdownReport } from '../../../../interfaces/calculator.interface';
import { ICategoryReport, ISubCategoryReport, ILineReport } from '../../../../interfaces/report.interface';
import { IInterventionsChecklist } from '../../../../interfaces/interventions.interface';
import styles, { tableCellStyles } from './CostSavingsReportBreakdown.styles';
import { getCostSavingsReportBreakdown } from './CostSavingsBreakdownMap';

export const TableCell = withStyles(tableCellStyles)(TC);

interface IProps extends WithStylesType<typeof styles> {
  report: IBreakdownReport;
  interventionsChecklist: IInterventionsChecklist;
}

const CELL_PARTITIONS: Record<string, string> =  { 
  1: 'full',
  2: 'half',
  3: 'third',
};

const CostSavingsReportBreakdown: FunctionComponent<IProps> = ({ classes, report, interventionsChecklist }) => {
  let rowIdx = 1;
  const getRowIndex = () => `row__${rowIdx++}`;

  const buildSubCells = (
    values: string[] | number[] = [],
    isDefault: boolean = true
  ) => {
    let partitionClass = CELL_PARTITIONS[3];
    let validValues = [...values];

    if (!isDefault) {
      validValues = (values as any[]).filter(t => t !== '');
      const numberOfValues = validValues.length;
      partitionClass = CELL_PARTITIONS[numberOfValues];
    }

    return validValues.map(val => (
      <div
        key={getRowIndex()}
        className={classnames(classes.valueCell, classes[partitionClass])}
      >
        {val === 'undefined' ? Constants.STRING_TYPES.EMPTY : val}
      </div>
    ));
  };

  const getLines = (lines: ILineReport[] = []) => {
    return lines.map(line =>
      <Fragment key={getRowIndex()}>
        <TableRow>
          <TableCell
            className={classnames(classes.descriptionCol, classes.smallText)}
          >
            <p className={classes.subsectionTitle}>{line.description}</p>
            {Boolean(line.note) && <p className={classes.noteText}>{`Note: ${line.note}`}</p>}
          </TableCell>
          <TableCell className={classes.composedCell}>
            <div className={classes.multiCont}>
              {buildSubCells(line.values, line.displayDefault)}
            </div>
          </TableCell>
        </TableRow>
      </Fragment>
    );
  };

  const getSubCategories = (subCategories: ISubCategoryReport[] = []) => {
    return subCategories.map(s =>
      s.visible ? (
        <Fragment key={getRowIndex()}>
          {/* Regular subcategory title */}
          {!s.isInfoBlock && !s.isFootnoteBlock && (
            <TableRow>
              <TableCell
                className={classnames(classes.descriptionCol, classes.sectionTitle)}
                colSpan={2}
              >
                <p className={classes.sectionTitle}>{s.title}</p>
              </TableCell>
            </TableRow>
          )}
  
          {/* Special handling for info and footnote blocks */}
          {s.isInfoBlock || s.isFootnoteBlock ? (
            <TableRow style={s.isFootnoteBlock ? { marginTop: '15px' } : {}}>
              <TableCell
                className={classnames(
                  classes.descriptionCol,
                  s.isInfoBlock ? classes.infoBlock : classes.footnoteBlock
                )}
                colSpan={2}
              >
                {s.lines.map(line => (
                  <div key={getRowIndex()} className={classes.specialBlock}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: line.description
                          .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
                          .replace(/\n\n/g, '<br/><br/>')
                      }}
                    />
                  </div>
                ))}
              </TableCell>
            </TableRow>
          ) : (
            // Regular line rendering for normal subcategories
            getLines(s.lines)
          )}
        </Fragment>
      ) : (
        <Fragment key={getRowIndex()}></Fragment>
      )
    );
  };

  return (
    <div className={classes.container} style={{ marginTop:'5px' }}>
      <Typography
        variant="h1"
        className={classes.contentTitle}
      >
        Cost-savings Intervention Data
      </Typography>
      <div className={classes.tableContainer}>
        <Table className={classes.table}>
          <TableBody>
            {
            getCostSavingsReportBreakdown(report, interventionsChecklist).map((cat:ICategoryReport) => {
              const catCols = cat.resultsHeaders;
    
              return (
                <Fragment key={getRowIndex()}>
                  <TableRow>
                    <TableCell
                      className={
                        classnames(classes.descriptionCol, classes.contentTitle)
                      } 
                      {...(!catCols && { colSpan: 2 })}
                    >
                      {cat.title}
                    </TableCell>
                    {
                      catCols &&
                      <TableCell className={
                        classnames(classes.composedCell, classes.sectionTitle)
                        }
                      >
                        <div className={classes.multiCont}>
                          {buildSubCells(catCols)}
                        </div>
                      </TableCell>
                    }
                  </TableRow>
                  {cat.subCategories && getSubCategories(cat.subCategories)}
                </Fragment>
              );
            })
          }
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default withStyles(styles)(CostSavingsReportBreakdown);
