import React, { FunctionComponent } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import { Table, TableRow, TableBody, TableCell as TC, TableHead, Typography } from '@material-ui/core';
import { StringFormatter } from '../../../../utils';
import { ISummaryReport } from '../../../../interfaces/calculator.interface';
import styles, { tableCellStyles } from './ROIBySavings.styles';

export const TableCell = withStyles(tableCellStyles)(TC);

interface IProps extends WithStylesType<typeof styles> {
  report: ISummaryReport;
}

const ROIBySavings: FunctionComponent<IProps> = ({ classes, report }) => {
  const tableRows = [
    {
      titleClass: classes.columnTitle,
      title: 'Total Savings',
      firstAmountClass: classes.columnTitle,
      firstAmount: StringFormatter.roundedMoney(report?.totalSavingsAverage?.toString() || '0'),
      secondAmountClass: classes.columnTitle,
      secondAmount: StringFormatter.roundedMoney(report?.totalSavingsLow?.toString() || '0'),
      thirdAmountClass: classes.columnTitle,
      thirdAmount: StringFormatter.roundedMoney(report?.totalSavingsHigh?.toString() || '0'),
      visible: true,
    },
    {
      titleClass: classes.sectionTitle,
      title: 'Short-Term Savings',
      firstAmountClass: classes.sectionAmount,
      firstAmount: StringFormatter.roundedMoney(report?.shortTermSavingsAverage?.toString() || '0'),
      secondAmountClass: classes.sectionAmount,
      secondAmount: StringFormatter.roundedMoney(
        report?.shortTermLowSavings?.toString() || '0'
      ),
      thirdAmountClass: classes.sectionAmount,
      thirdAmount: StringFormatter.roundedMoney(report?.shortTermHighSavings?.toString() || '0'),
      visible: true,
    },
    {
      titleClass: classes.subsectionTitle,
      title: 'Short-Term Medicaid Savings',
      firstAmountClass: classes.subsectionAmount,
      firstAmount: StringFormatter.roundedMoney(
        report?.shortTermMedicaidSavingsAverage?.toString() || '0'
      ),
      secondAmountClass: classes.subsectionAmount,
      secondAmount: StringFormatter.roundedMoney(
        report?.shortTermLowMedicaidSavings?.toString() || '0'
      ),
      thirdAmountClass: classes.sectionAmount,
      thirdAmount: StringFormatter.roundedMoney(report?.shortTermHighMedicaidSavings?.toString() || '0'),
      visible: true,
    },
    {
      titleClass: classes.sectionTitle,
      title: 'Long-Term Savings',
      firstAmountClass: classes.subsectionAmount,
      firstAmount: StringFormatter.roundedMoney(
        report?.longTermSavingsAverage?.toString() || '0'
      ),
      secondAmountClass: classes.subsectionAmount,
      secondAmount: StringFormatter.roundedMoney(
        report?.longTermLowSavings?.toString() || '0'
      ),
      thirdAmountClass: classes.sectionAmount,
      thirdAmount: StringFormatter.roundedMoney(report?.longTermHighSavings?.toString() || '0'),
      visible: true,
    },
    {
      titleClass: classes.subsectionTitle,
      title: 'Long-Term Medicaid Savings',
      firstAmountClass: classes.subsectionAmount,
      firstAmount: StringFormatter.roundedMoney(
        report?.longTermMedicaidSavingsAverage?.toString() || '0'
      ),
      secondAmountClass: classes.subsectionAmount,
      secondAmount: StringFormatter.roundedMoney(
        report?.longTermLowMedicaidSavings?.toString() || '0'
      ),
      thirdAmountClass: classes.sectionAmount,
      thirdAmount: StringFormatter.roundedMoney(report?.longTermHighMedicaidSavings?.toString() || '0'),
      visible: true,
    },
    {
      titleClass: classes.title,
      title: 'Total Operating Costs',
      firstAmountClass: classes.subsectionAmount,
      firstAmount: StringFormatter.roundedMoney(
        report?.totalSiteServiceDeliveryCost?.toString() || '0'
      ),
      secondAmountClass: classes.subsectionAmount,
      secondAmount: '',
      thirdAmountClass: classes.sectionAmount,
      thirdAmount: '',
      visible: true,
    },
    {
      titleClass: classes.title,
      title: 'Short-Term ROI (≤1 year)',
      firstAmountClass: classes.subsectionAmount,
      firstAmount: StringFormatter.percentageIntervention(
        report?.shortTermROIAverage?.toString() || '0'
      ),
      secondAmountClass: classes.subsectionAmount,
      secondAmount: StringFormatter.percentageIntervention(
        report?.shortTermLowROI?.toString() || '0'
      ),
      thirdAmountClass: classes.sectionAmount,
      thirdAmount: StringFormatter.percentageIntervention(report?.shortTermHighROI?.toString() || '0'),
      visible: true,
    },
    {
      titleClass: classes.subsectionTitle,
      title: 'Short-Term Medicaid ROI',
      firstAmountClass: classes.subsectionAmount,
      firstAmount: StringFormatter.percentageIntervention(
        report?.shortTermROIMedicaidAverage?.toString() || '0'
      ),
      secondAmountClass: classes.subsectionAmount,
      secondAmount: StringFormatter.percentageIntervention(
        report?.shortTermLowMedicaidROI?.toString() || '0'
      ),
      thirdAmountClass: classes.sectionAmount,
      thirdAmount: StringFormatter.percentageIntervention(report?.shortTermHighMedicaidROI?.toString() || '0'),
      visible: true,
    },
    {
      titleClass: classes.title,
      title: 'Long-Term ROI (>1 year)**',
      firstAmountClass: classes.subsectionAmount,
      firstAmount: StringFormatter.percentageIntervention(
        report?.longTermROIAverage?.toString() || '0'
      ),
      secondAmountClass: classes.subsectionAmount,
      secondAmount: StringFormatter.percentageIntervention(
        report?.longTermLowROI?.toString() || '0'
      ),
      thirdAmountClass: classes.sectionAmount,
      thirdAmount: StringFormatter.percentageIntervention(report?.longTermHighROI?.toString() || '0'),
      visible: true,
    },
    {
      titleClass: classes.subsectionTitle,
      title: 'Long-Term Medicaid ROI',
      firstAmountClass: classes.subsectionAmount,
      firstAmount: StringFormatter.percentageIntervention(
        report?.longTermROIMedicaidAverage?.toString() || '0'
      ),
      secondAmountClass: classes.subsectionAmount,
      secondAmount: StringFormatter.percentageIntervention(
        report?.longTermLowMedicaidROI?.toString() || '0'
      ),
      thirdAmountClass: classes.sectionAmount,
      thirdAmount: StringFormatter.percentageIntervention(report?.longTermHighMedicaidROI?.toString() || '0'),
      visible: true,
    },
  ];
  return (
    <div style={{ paddingLeft:'35px', marginTop:'20px' }}>
      <Typography variant="h2" className={classes.contentTitle}>Return on Investment</Typography>
      <Table style={{ backgroundColor: 'white', borderRadius:'5px' }}>
        <TableHead>
          <TableCell align="left">
            Savings Type
          </TableCell>
          <TableCell align="center">
            Average
          </TableCell>
          <TableCell align="center">
            Low
          </TableCell>
          <TableCell align="center">
            High
          </TableCell>
        </TableHead>
        <TableBody>
          {tableRows.map((row, idx) => (
            row.visible ?
              <TableRow key={idx}>
                <TableCell className={row.titleClass} scope="row">
                  {row.title}
                </TableCell>
                <TableCell className={row.firstAmountClass} align="right">
                  {row.firstAmount}
                </TableCell>
                <TableCell className={row.secondAmountClass} align="right">
                  {row.secondAmount}
                </TableCell>
                <TableCell className={row.thirdAmountClass} align="right">
                  {row.thirdAmount}
                </TableCell>
              </TableRow>
              : <></>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default withStyles(styles)(ROIBySavings);
