import { createStyles, StyleRules } from '@material-ui/core/styles';
import { Colors } from '../../../../../assets/styles';

export default (): StyleRules => {
  return createStyles({
    description: {
      color: Colors.Blue,
      fontSize: 14,
      borderBottom: `1px solid ${Colors.Gray5}`,
      paddingBottom: 24,
    },
    subDescription: {
      color: Colors.Blue,
      fontSize: 24,
      paddingTop: 24,
      paddingBottom: 24,
    },
    header:{
      paddingTop: 24
    }
  });
};
