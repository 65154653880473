/* eslint-disable @typescript-eslint/naming-convention */

/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { paths, history } from '../../routes';
import {
  calculatorState,
  CalculatorEnum,
} from '../../interfaces/calculator.interface';
import { AppThunk, IRootState } from '../../interfaces/redux.interface';
import {
  EmergencyDepartmentUseForm,
  InterventionsCommonForm,
  OralHealthForm,
  BreastfeedingForm,
  SmokingCessationForm,
  PostpartumMaternalDepressionForm,
  IntimatePartnerViolenceForm,
  DiapersForm,
  ImmunizationsForm,
  FoodSecurityForm,
  EarlyDetectionForm,
  HealthyBirthSpacingForm,
  PositiveParentingForm,
} from '../../interfaces/interventions.interface';
import {
  IServiceData,
  ServiceDataForm,
} from '../../interfaces/serviceData.interface';
import {
  ServiceData,
  OralHealth,
  EmergencyDepartment,
  Immunizations,
  Asthma,
  PostpartumMaternalDepression,
  HealthBirthSpacing,
  IntimatePartner,
  Breastfeeding,
  SmokingCessation,
  EarlyDetection,
  Main,
  Diapers,
  FoodSecurity,
  PositiveParenting
} from '../../calculations';

import { snackbarActions } from '../snackbarSlice';
import { dashboardActions } from '../dashboardSlice';
import reportsService from '../../services/reports.service';
import { Constants, StringFormatter, DateUtils } from '../../utils';

export const selectCalculatorData = (state: IRootState) => state.calculator;

export const selectReportName = (state: IRootState) => state.calculator.name;

export const selectSummaryReport = (state: IRootState) =>
  state.calculator.summary.report;

export const selectReportsList = (state: IRootState) =>
  state.calculator.reportsList.reports;

export const selectTouched = (state: IRootState) => state.calculator.touched;

export const selectCheckForm = (state: IRootState) => state.calculator.checkForm;

export const selectIsReportFinished = (state: IRootState) =>
  state.calculator.status === Constants.REPORT_STATUS.FINISHED;

export const selectIsReportSubmitted = (state: IRootState) =>
  state.calculator.status === Constants.REPORT_STATUS.SUBMITTED;
  
export const selectLastDateUpdated = (state: IRootState) => state.calculator.updatedISO;

export const selectInterventionsChecklist = (state: IRootState) =>
  state.calculator.interventionsChecklist;

export const selectIsLoading = (state: IRootState) =>
  state.calculator.isLoading;

export const selectInterventionsChecklistValue = (key: string) => (
  state: IRootState
) => state.calculator.interventionsChecklist[key];

export const selectInterventionsValue = (key: string) => (state: IRootState) => {
  return state.calculator[key];
};

export const selectState = (state: IRootState) =>
  state.calculator.serviceData.site_location;

export const selectServiceData = (state: IRootState) => ({
  ...state.calculator.serviceData,
  date_from:
    (state.calculator.serviceData.date_from &&
      new Date(state.calculator.serviceData.date_from)) ||
    null,
  date_to:
    (state.calculator.serviceData.date_to &&
      new Date(state.calculator.serviceData.date_to)) ||
    null,
});

export const areInterventionsValid = createSelector(
  selectInterventionsChecklist,
  selectCalculatorData,
  (interventionsChecklist, calculatorData) => {
    const activeInterventions = Object.keys(interventionsChecklist).filter(
      (key) => interventionsChecklist[key]
    );

    return activeInterventions.every(
      (intervention) => calculatorData[intervention].isValid
    );
  }
);

export const selectServiceDataForm = createSelector(
  selectState,
  selectServiceData,
  (state, serviceData) =>
    new ServiceData({
      state,
      dcInput1: +serviceData[ServiceDataForm.SpecialistSalaryCost],
      dcInput2: +serviceData[ServiceDataForm.DirectCost],
      dcInput3: +serviceData[ServiceDataForm.OtherCost],
      dcInput4: +serviceData[ServiceDataForm.IntermediaryCost],
      csInput1: +serviceData[ServiceDataForm.Children0To3],
      csInput2: +serviceData[ServiceDataForm.MedicaidChildren0To3],
      csInput3: +serviceData[ServiceDataForm.Children0To3Tier2And3],
      csInput4: +serviceData[ServiceDataForm.MedicaidChildren0To3Tier2And3],
      csInput5: +serviceData[ServiceDataForm.MedicaidChildrenUnder1Tier2And3],
    })
);

export const selectOralHealth = createSelector(
  selectState,
  selectServiceDataForm,
  selectInterventionsValue(CalculatorEnum.OralHealth),
  (state, serviceData, oralHealth) =>
    new OralHealth({
      state,
      serviceData,
      lowEstimatedSlider: +oralHealth[InterventionsCommonForm.LowEstimate],
      highEstimatedSlider: +oralHealth[InterventionsCommonForm.HighEstimate],
      needForOralHealth: +oralHealth[OralHealthForm.NeedForOralHealth],
    })
);

export const selectEmergencyDepartment = createSelector(
  selectState,
  selectServiceDataForm,
  selectInterventionsValue(CalculatorEnum.EmergencyDepartmentUse),
  (state, serviceData, emergencyDepartment) =>
    new EmergencyDepartment({
      state,
      serviceData,
      edInputNumber1: +emergencyDepartment[
        EmergencyDepartmentUseForm.ContactedByPhone
      ],
      edInputNumber2: +emergencyDepartment[
        EmergencyDepartmentUseForm.TreatedAtHome
      ],
      edInputNumber3: +emergencyDepartment[
        EmergencyDepartmentUseForm.ClinicVisit
      ],
      sliderLowEstimate: +emergencyDepartment[
        InterventionsCommonForm.LowEstimate
      ],
      sliderHighEstimate: +emergencyDepartment[
        InterventionsCommonForm.HighEstimate
      ],
      lowEstimatedSlider: +emergencyDepartment[
        InterventionsCommonForm.LowEstimate
      ],
      highEstimatedSlider: +emergencyDepartment[
        InterventionsCommonForm.HighEstimate
      ],
      ContactedByPhone: +emergencyDepartment[
        EmergencyDepartmentUseForm.ContactedByPhone
      ]
    })
);

export const selectImmunizations = createSelector(
  selectState,
  selectServiceDataForm,
  selectEmergencyDepartment,
  selectInterventionsValue(CalculatorEnum.Immunizations),
  (state, serviceData, emergencyDepartment, immunizations) =>
    new Immunizations({
      state,
      serviceData,
      emergencyDepartment,
      lowEstimatedSlider: +immunizations[InterventionsCommonForm.LowEstimate],
      highEstimatedSlider: +immunizations[InterventionsCommonForm.HighEstimate],
      longTermLowEstimatedSlider: +immunizations[InterventionsCommonForm.LongTermLowEstimate],
      longTermHighEstimatedSlider: +immunizations[InterventionsCommonForm.LongTermHighEstimate],
      needForFluVaccinations: +immunizations[ImmunizationsForm.NeedForFluVaccinations],
      numberOfCaregiversDiscussingECVWithHSS: +immunizations[ImmunizationsForm.NumberOfCaregiversDiscussingECVWithHSS]
    })
);

export const selectAsthma = createSelector(
  selectState,
  selectServiceDataForm,
  selectInterventionsValue(CalculatorEnum.Asthma),
  (state, serviceData, asthma) =>
    new Asthma({
      state,
      serviceData,
      lowEstimatedSlider: +asthma[InterventionsCommonForm.LowEstimate],
      highEstimatedSlider: +asthma[InterventionsCommonForm.HighEstimate],
      asthma_numberHaveAsthma: asthma[InterventionsCommonForm.NumberHaveAsthma],
    })
);

export const selectPostpartumMaternalDepression = createSelector(
  selectState,
  selectServiceDataForm,
  selectInterventionsValue(CalculatorEnum.PostpartumMaternalDepression),
  (state, serviceData, postpartumMaternalDepression) =>
    new PostpartumMaternalDepression({
      state,
      serviceData,
      lowEstimatedSlider: +postpartumMaternalDepression[
        InterventionsCommonForm.LowEstimate
      ],
      highEstimatedSlider: +postpartumMaternalDepression[
        InterventionsCommonForm.HighEstimate
      ],
      maternalDepressionReceivedCounseling: +postpartumMaternalDepression[
        PostpartumMaternalDepressionForm.ReceivedCounseling
      ]
    })
);

export const selectHealthyBirthSpacing = createSelector(
  selectState,
  selectServiceDataForm,
  selectInterventionsValue(CalculatorEnum.HealthyBirthSpacing),
  (state, serviceData, healthyBirthSpacing) =>
    new HealthBirthSpacing({
      state,
      serviceData,
      lowEstimatedSlider: +healthyBirthSpacing[
        InterventionsCommonForm.LowEstimate
      ],
      highEstimatedSlider: +healthyBirthSpacing[
        InterventionsCommonForm.HighEstimate
      ],
      needForContraception: +healthyBirthSpacing[
        HealthyBirthSpacingForm.NeedForContraception
      ],
      followedUpOnReferals: +healthyBirthSpacing[
        HealthyBirthSpacingForm.FollowedUpOnReferals
      ]
    })
);

export const selectIntimatePartnerViolence = createSelector(
  selectState,
  selectServiceDataForm,
  selectInterventionsValue(CalculatorEnum.IntimatePartnerViolence),
  (state, serviceData, intimatePartnerViolence) =>
    new IntimatePartner({
      state,
      serviceData,
      lowEstimatedSlider: +intimatePartnerViolence[
        InterventionsCommonForm.LowEstimate
      ],
      highEstimatedSlider: +intimatePartnerViolence[
        InterventionsCommonForm.HighEstimate
      ],
      receivedCounseling: +intimatePartnerViolence[
        IntimatePartnerViolenceForm.ReceivedCounseling
      ]
    }) 
);

export const selectBreastfeeding = createSelector(
  selectState,
  selectServiceDataForm,
  selectEmergencyDepartment,
  selectInterventionsValue(CalculatorEnum.Breastfeeding),
  (state, serviceData, emergencyDepartment, breastfeeding) =>
    new Breastfeeding({
      state,
      serviceData,
      emergencyDepartment,
      sliderComponentContinuedBreastfeedingLow: +breastfeeding[
        InterventionsCommonForm.LowEstimate
      ],
      sliderComponentContinuedBreastfeedingHigh: +breastfeeding[
        InterventionsCommonForm.HighEstimate
      ],
      sliderComponentContinuedExclusiveBreastfeedingLow: +breastfeeding[
        InterventionsCommonForm.LowEstimateExclusive
      ],
      sliderComponentContinuedExclusiveBreastfeedingHigh: +breastfeeding[
        InterventionsCommonForm.HighEstimateExclusive
      ],
      input1TotalNumberOfHsMothersWhoDiscussedANeed: +breastfeeding[
        BreastfeedingForm.DiscussedNeedForBreastfeeding
      ],
      input2TotalNumberOfHsMothersWhoReceivedCounselling: +breastfeeding[
        BreastfeedingForm.ReceivedCounseling
      ]
    })
);

export const selectSmokingCessation = createSelector(
  selectState,
  selectServiceDataForm,
  selectInterventionsValue(CalculatorEnum.SmokingCessation),
  (state, serviceData, smokingCessation) =>
    new SmokingCessation({
      state,
      serviceData,
      sliderSmokerLowEstimate: +smokingCessation[
        InterventionsCommonForm.LowEstimate
      ],
      sliderSmokerHighEstimate: +smokingCessation[
        InterventionsCommonForm.HighEstimate
      ],
      firstInput: +smokingCessation[SmokingCessationForm.DiscussedProblem],
    })
);

export const selectDiapers = createSelector(
  selectState,
  selectServiceDataForm,
  selectInterventionsValue(CalculatorEnum.Diapers),
  (state, serviceData, diapers) =>
    new Diapers({
      state,
      serviceData,
      lowEstimatedSlider: +diapers[
        InterventionsCommonForm.LowEstimate
      ],
      highEstimatedSlider: +diapers[
        InterventionsCommonForm.HighEstimate
      ],
      numberOfCaregiversProvidedDiapersOrReferred: +diapers[DiapersForm.NumberOfCaregiversProvidedDiapersOrReferred],
    })
);

export const selectFoodSecurity = createSelector(
  selectState,
  selectServiceDataForm,
  selectInterventionsValue(CalculatorEnum.FoodSecurity),
  (state, serviceData, foodSecurity) =>
    new FoodSecurity({
      state,
      serviceData,
      lowEstimatedSlider: +foodSecurity[
        InterventionsCommonForm.LowEstimate
      ],
      highEstimatedSlider: +foodSecurity[
        InterventionsCommonForm.HighEstimate
      ],
      numberOfCaregiversFoodInsecureAndReferred: +foodSecurity[FoodSecurityForm.NumberOfCaregiversFoodInsecureAndReferred],
    })
);

export const selectEarlyDetection = createSelector(
  selectState,
  selectServiceDataForm,
  selectInterventionsValue(CalculatorEnum.EarlyDetection),
  (state, serviceData, earlyDetection) =>
    new EarlyDetection({
      state,
      serviceData,
      sliderEarlyDetectionLowEstimate: +earlyDetection[
        InterventionsCommonForm.LowEstimate
      ],
      sliderEarlyDetectionHighEstimate: +earlyDetection[
        InterventionsCommonForm.HighEstimate
      ],
      numberOfChildrenReferredToEarlyEducationPrograms: +earlyDetection[EarlyDetectionForm.NumberOfChildrenReferredToEarlyEducationPrograms],
      numberOfChildrenReferredToEarlyIntervention: +earlyDetection[EarlyDetectionForm.NumberOfChildrenReferredToEarlyIntervention],
      percentageOfChildrenReceivingEarlyInterventionServices: + earlyDetection[EarlyDetectionForm.PercentageOfChildrenReceivingEarlyInterventionServices]

    })
);

export const selectPositiveParenting = createSelector(
  selectState,
  selectServiceDataForm,
  selectInterventionsValue(CalculatorEnum.PositiveParenting),
  (state, serviceData, positiveParenting) =>
    new PositiveParenting({
      state,
      serviceData,
      sliderPositiveParentingLowEstimate: +positiveParenting[
        InterventionsCommonForm.LowEstimate
      ],
      sliderPositiveParentingHighEstimate: +positiveParenting[
        InterventionsCommonForm.HighEstimate
      ],
      numberOfFamiliesReferredToPostNatalHomeVisitingPrograms: +positiveParenting[PositiveParentingForm.NumberOfFamiliesReferredToPostNatalHomeVisitingPrograms],
      numberOfGuardiansProvidedPositiveParentingOrReferredToParentingProgram: +positiveParenting[PositiveParentingForm.NumberOfGuardiansProvidedPositiveParentingOrReferredToParentingProgram]
    })
);


const selectHealthData = createSelector(
  selectEmergencyDepartment,
  selectAsthma,
  selectBreastfeeding,
  selectHealthyBirthSpacing,
  selectImmunizations,
  selectOralHealth,
  selectPostpartumMaternalDepression,
  selectSmokingCessation,
  (emergencyDepartment, asthma, breastfeeding, healthBirthSpacing, immunizations, oralHealth, postpartumMaternalDepression, smokingCessation) => ({
    emergencyDepartment,
    asthma,
    breastfeeding,
    healthBirthSpacing,
    immunizations,
    oralHealth,
    postpartumMaternalDepression,
    smokingCessation,
  })
);

export const selectTotals = createSelector(
  selectState,
  selectServiceDataForm,
  selectHealthData,
  selectIntimatePartnerViolence,
  selectDiapers,
  selectFoodSecurity,
  selectEarlyDetection,
  selectPositiveParenting,
  selectInterventionsChecklist,
  (
    state,
    serviceData,
    healthData,
    intimatePartner,
    diapers,
    foodSecurity,
    earlyDetection,
    positiveParenting,
    interventionsChecklist
  ) =>
    new Main({
      state,
      serviceData,
      ...healthData,
      intimatePartner,
      diapers,
      foodSecurity,
      earlyDetection,
      positiveParenting,
      interventionsChecklist,
    })
);


export const selectHasMoreToLoad = (state: IRootState) => {
  const { calculator: { reportsList } } = state;
  return reportsList.LastEvaluatedKey;
};
  

export const calculatorSlice = createSlice({
  name: 'calculator',
  initialState: calculatorState,
  reducers: {
    clearAllData: (state) => calculatorState,
    clearAllDataButName: (state) => ({
      ...calculatorState,
      name: state.name,
      touched: true,
      checkForm: false,
      reportsList: {
        reports: [],
        LastEvaluatedKey: null,
      },
    }),
    clearReports: (state) => ({
      ...state,
      reportsList: {
        reports: [],
        LastEvaluatedKey: null,
      },
    }),
    setCalculatorData: (state, action) => ({
      ...state,
      ...action.payload,
      isLoading: false,
      touched: false,
      checkForm: false,
    }),
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    clearInterventionData: (state, action) => {
      state[action.payload] = calculatorState[action.payload];
    },
    setInterventionData: (state, action) => {
      state[action.payload.intervention] = action.payload.value;
      state.touched = true;
    },
    setServiceData: (state, action) => {
      state.serviceData = action.payload;
      state.touched = true;
    },
    setInterventionsChecklistData: (state, action) => {
      state.interventionsChecklist = action.payload;
      state.touched = true;
    },
    setInterventionsChecklistValue: (state, action) => {
      state.interventionsChecklist[action.payload.key] = action.payload.value;
      state.touched = true;
    },
    setName: (state, action) => {
      state.name = action.payload;
    },
    setSummary: (state, action) => {
      state.summary = action.payload;
    },
    setReportList: (state, action) => {
      state.reportsList = {
        reports: [...state.reportsList.reports, ...action.payload.reports],
        LastEvaluatedKey: action.payload.LastEvaluatedKey,
      };
    },
    setTouched: (state, action) => {
      state.touched = action.payload;
    },
    setCheckForm: (state, action) => {
      state.checkForm = action.payload;
    },
  },
});

const {
  setServiceData,
  clearAllDataButName,
  setCalculatorData,
  setLoading,
  setName,
  setSummary,
  setReportList,
  setTouched,
  setCheckForm,
  clearReports,
  setInterventionsChecklistValue,
} = calculatorSlice.actions;

const resetAllData = (): AppThunk => (dispatch, getState) => {
  const { name } = getState().calculator;

  dispatch(clearAllDataButName());
  history.push(
    StringFormatter.replaceWithReportName(name, paths.calculatorServiceData)
  );
};

const addServiceData = (data: IServiceData): AppThunk => (dispatch) => {
  dispatch(
    setServiceData({
      ...data,
      date_from: data.date_from?.toISOString(),
      date_to: data.date_to?.toISOString(),
    })
  );
  dispatch(setSummaryData());
};

const deleteReport = (reportName: string): AppThunk => (dispatch) => {
  dispatch(setLoading(true));

  reportsService
    .deleteReport(reportName)
    .then(() => {
      dispatch(snackbarActions.success('Report deleted successfully!'));
      dispatch(clearReports());
      dispatch(getReports());
    })
    .catch((error) => {
      dispatch(snackbarActions.error(error.message));
      dispatch(setLoading(false));
    });
};

const goToReport = (): AppThunk => (dispatch, getState) => {
  const { name } = getState().calculator;

  const valid = areInterventionsValid(getState());

  if (valid) {
    history.push(
      StringFormatter.replaceWithReportName(name, paths.calculatorReport)
    );
  } else {
    dispatch(setCheckForm(true));
    dispatch(
      snackbarActions.normal(
        'One or more active interventions have missing values'
      )
    );
  }
};

const getReports = (): AppThunk => (dispatch, getState) => {
  dispatch(setLoading(true));
  const { calculator: { reportsList } } = getState();
  const moreKey = reportsList.LastEvaluatedKey;

  reportsService
    .getReports(moreKey)
    .then(({ Items, LastEvaluatedKey }) => {
      const parsedData = Items.map(
        (item: {
          name: string;
          status: string;
          summary: string;
          updated: number;
          updatedISO: string;
          interventionsChecklist: string;
        }) => ({
          ...item,
          summary: JSON.parse(item.summary),
          interventionsChecklist: JSON.parse(item.interventionsChecklist)
        })
      );

      dispatch(setReportList({ reports: parsedData, LastEvaluatedKey }));
      dispatch(setLoading(false));
    })
    .catch((error) => {
      dispatch(setLoading(false));
    });
};

const getReviewReports = (): AppThunk => (dispatch, getState) => {
  dispatch(setLoading(true));
  const { calculator: { reportsList } } = getState();
  const moreKey = reportsList.LastEvaluatedKey;

  reportsService
    .getReviewReports(moreKey)
    .then(({ Items, LastEvaluatedKey }) => {
      const parsedData = Items.map(
        (item: {
          name: string;
          status: string;
          summary: string;
          updated: number;
          updatedISO: string;
          interventionsChecklist: string;
        }) => ({
          ...item,
          summary: JSON.parse(item.summary),
          interventionsChecklist: JSON.parse(item.interventionsChecklist),
        })
      );

      dispatch(setReportList({ reports: parsedData, LastEvaluatedKey }));
      dispatch(setLoading(false));
    })
    .catch((error) => {
      dispatch(setLoading(false));
    });
};

const setSummaryData = (): AppThunk => (dispatch, getState) => {
  const totals = selectTotals(getState());
  const {
    serviceData,
    oralHealth,
    emergencyDepartmentUse,
    immunizations,
    asthma,
    postpartumMaternalDepression,
    healthyBirthSpacing,
    intimidatePartnerViolence,
    breastfeeding,
    smokeCessation,
    diapers,
    foodSecurity,
    earlyDetection,
    positiveParenting,
  } = getState().calculator;

  dispatch(
    setSummary({
      totalSavingsLow: totals.globalEstimateSavingsLow().toString(),
      totalSavingsHigh: totals.globalEstimateSavingsHigh().toString(),
      report: {
        totalChildren0to3: serviceData['children_served.children_0_to_3'],
        totalChildrenMedicaid0to3: serviceData['children_served.medicaid_children_0_to_3'],
        medicaidPercentageOfTotals: parseFloat(
          totals.medicaidPercentageOfTotals().toFixed(2)
        ),
        totalAnnualProgramCost: serviceData['delivery_costs.site_service_cost'],
        annualCostPerChild: totals.annualCostPerChild(),
        totalSavingsAvg: totals.totalSavingsAvg(),
        annualReturnOnInvestment: totals.annualReturnOnInvestment(),
        returnPerMedicaidChild: totals.returnPerMedicaidChild(),
        totalAnnualMedicaidProgramCost: totals.totalAnnualMedicaidProgramCost(),
        annualCostPerMedicaidChild: totals.annualCostPerMedicaidChild(),
        costSavingsPerMedicaidFamily: totals.costSavingsPerMedicaidFamily(),
        annualMedicaidReturnOnInvestment: totals.annualMedicaidReturnOnInvestment(),
        childrenLowSavings: totals.childrenLowSavings(),
        childrenHighSavings: totals.childrenHighSavings(),
        dentalEstimatedSavingLow: totals.oralHealth.dentalEstimatedSavingLow(),
        dentalEstimatedSavingHigh: totals.oralHealth.dentalEstimatedSavingHigh(),
        dentalEstimatedTotalTermSavingLow: totals.oralHealth.dentalEstimatedTotalTermSavingLow(),
        dentalEstimatedTotalTermSavingHigh: totals.oralHealth.dentalEstimatedTotalTermSavingHigh(),
        dentalEstimatedSavingAverage: totals.oralHealth.dentalEstimatedSavingAverage(),
        emergencyDepartmentEstimatedSavingLow: totals.emergencyDepartment.emergencyDepartmentEstimatedSavingLow(),
        emergencyDepartmentEstimatedSavingHigh: totals.emergencyDepartment.emergencyDepartmentEstimatedSavingHigh(),
        emergencyDepartmentEstimatedSavingAverage: totals.emergencyDepartment.emergencyDepartmentEstimatedSavingAverage(),
        immunizationsEstimatedSavingsLow: totals.immunizations.immunizationsEstimatedSavingsLow(),
        immunizationsEstimatedSavingsHigh: totals.immunizations.immunizationsEstimatedSavingsHigh(),
        immunizationsEstimatedSavingsAverage: totals.immunizations.immunizationsEstimatedShortTermSavingsAverage(),
        immunizationsEstimatedLongTermSavingsLow: totals.immunizations.immunizationsEstimatedLongTermSavingsLow(),
        immunizationsEstimatedLongTermSavingsHigh: totals.immunizations.immunizationsEstimatedLongTermSavingsHigh(),
        immunizationsEstimatedLongTermSavingsAverage: totals.immunizations.immunizationsEstimatedLongTermSavingsAverage(),
        immunizationsEstimatedTotalSavingsLow: totals.immunizations.immunizationsEstimatedTotalSavingsLow(),
        immunizationsEstimatedTotalSavingsHigh: totals.immunizations.immunizationsEstimatedTotalSavingsHigh(),
        immunizationsEstimatedTotalSavingsAverage: totals.immunizations.immunizationsEstimatedTotalSavingsAverage(),
        asthmaEstimatedSavingsLow: totals.asthma.asthmaEstimatedSavingsLow(),
        asthmaEstimatedSavingsHigh: totals.asthma.asthmaEstimatedSavingsHigh(),
        asthmaEstimatedSavingsAverage: totals.asthma.asthmaEstimatedSavingsAverage(),
        parentLowSavings: totals.parentLowSavings(),
        parentHighSavings: totals.parentHighSavings(),
        maternalDepressionEstimatedSavingsLow: totals.postpartumMaternalDepression.maternalDepressionEstimatedSavingsLow(),
        maternalDepressionEstimatedSavingsHigh: totals.postpartumMaternalDepression.maternalDepressionEstimatedSavingsHigh(),
        maternalDepressionEstimatedSavingsAverage: totals.postpartumMaternalDepression.maternalDepressionEstimatedTotalTermSavingsAverage(),
        maternalDepressionEstimatedTotalTermSavingsAverage: totals.postpartumMaternalDepression.maternalDepressionEstimatedTotalTermSavingsAverage(),
        maternalDepressionEstimatedTotalTermSavingsLow: totals.postpartumMaternalDepression.maternalDepressionEstimatedTotalTermSavingsLow(),
        maternalDepressionEstimatedTotalTermSavingsHigh: totals.postpartumMaternalDepression.maternalDepressionEstimatedTotalTermSavingsHigh(),
        healthBirthSpacingEstimatedSavingsLow: totals.healthBirthSpacing.healthBirthSpacingEstimatedSavingsLow(),
        healthBirthSpacingEstimatedSavingsHigh: totals.healthBirthSpacing.healthBirthSpacingEstimatedSavingsHigh(),
        healthBirthSpacingEstimatedSavingsAverage: totals.healthBirthSpacing.healthBirthSpacingTotalTermEstimatedSavingsAverage(),
        healthBirthSpacingTotalTermEstimatedSavingsAverage: totals.healthBirthSpacing.healthBirthSpacingTotalTermEstimatedSavingsAverage(),
        healthBirthSpacingTotalTermEstimatedSavingsLow: totals.healthBirthSpacing.healthBirthSpacingTotalTermEstimatedSavingsLow(),
        healthBirthSpacingTotalTermEstimatedSavingsHigh: totals.healthBirthSpacing.healthBirthSpacingTotalTermEstimatedSavingsHigh(),
        birthsChildWelfareSavings: totals.healthBirthSpacing.birthsChildWelfareSavings(),
        birthsPublicAssistanceSavings: totals.healthBirthSpacing.birthsPublicAssistanceSavings(),
        birthsHeadStartSavings: totals.healthBirthSpacing.birthsHeadStartSavings(),
        birthsHealthcareSavings: totals.healthBirthSpacing.birthsHealthcareSavings(),
        intimatePartnerViolenceEstimatedSavingsLow: totals.intimatePartner.intimatePartnerViolenceEstimatedSavingsLow(),
        intimatePartnerViolenceEstimatedSavingsHigh: totals.intimatePartner.intimatePartnerViolenceEstimatedSavingsHigh(),
        intimatePartnerViolenceEstimatedSavingsAverage: totals.intimatePartner.intimatePartnerViolenceEstimatedSavingsAverage(),
        breastfeedingEstimatedSavingsLow: totals.breastfeeding.breastfeedingEstimatedSavingsLow(),
        breastfeedingEstimatedSavingsHigh: totals.breastfeeding.breastfeedingEstimatedSavingsHigh(),
        breastfeedingEstimatedSavingsAverage: totals.breastfeeding.breastfeedingEstimatedSavingsAverage(),
        smokingCessationEstimatedSavingsLow: totals.smokingCessation.smokingCessationEstimatedSavingsLow(),
        smokingCessationEstimatedSavingsHigh: totals.smokingCessation.smokingCessationEstimatedSavingsHigh(),
        smokingCessationEstimatedSavingsChildAverage: totals.smokingCessation.smokingTabTotalSavingsChildAverage(),
        smokingCessationEstimatedSavingsAdultAverage: totals.smokingCessation.smokingTabTotalSavingsadultAverage(),
        smokingTabTotalDiscountedFutureSavingsChildLow: totals.smokingCessation.smokingTabTotalDiscountedFutureSavingsChildLow(),
        smokingTabTotalDiscountedFutureSavingsChildHigh: totals.smokingCessation.smokingTabTotalDiscountedFutureSavingsChildHigh(),
        smokingTabTotalDiscountedFutureSavingsAdultLow: totals.smokingCessation.smokingTabTotalDiscountedFutureSavingsAdultLow(),
        smokingTabTotalDiscountedFutureSavingsAdultHigh: totals.smokingCessation.smokingTabTotalDiscountedFutureSavingsAdultHigh(),
        smokingTabTotalSavingsAdultAndChildLow: totals.smokingCessation.smokingTabTotalSavingsAdultAndChildLow(),
        smokingTabTotalSavingsAdultAndChildHigh: totals.smokingCessation.smokingTabTotalSavingsAdultAndChildHigh(),
        smokingTabTotalSavingsAdultAndChildAverage: totals.smokingCessation.smokingTabTotalSavingsAdultAndChildAverage(),
        diapersEstimatedSavingsLow: totals.diapers.diapersEstimatedSavingsLow(),
        diapersEstimatedSavingsHigh: totals.diapers.diapersEstimatedSavingsHigh(),
        diapersEstimatedSavingsAverage: totals.diapers.diapersEstimatedSavingsAverage(),
        foodSecurityEstimatedSavingsLow: totals.foodSecurity.foodSecurityEstimatedSavingsLow(),
        foodSecurityEstimatedSavingsHigh: totals.foodSecurity.foodSecurityEstimatedSavingsHigh(),
        foodSecurityEstimatedSavingsAverage: totals.foodSecurity.foodSecurityEstimatedSavingsAverage(),
        earlyDetectionEstimatedSavingsLow: totals.earlyDetection.earlyDetectionEstimatedLow(),
        earlyDetectionEstimatedSavingsHigh: totals.earlyDetection.earlyDetectionEstimatedHigh(),
        earlydetectionTabEarlyInterventionTotalNetSavingsLow: totals.earlyDetection.earlydetectionTabEarlyInterventionTotalNetSavingsLow(),
        earlydetectionTabEarlyInterventionTotalNetSavingsHigh: totals.earlyDetection.earlydetectionTabEarlyInterventionTotalNetSavingsHigh(),
        earlydetectionTabEarlyInterventionTotalNetSavingsAverage: totals.earlyDetection.earlydetectionTabEarlyInterventionTotalNetSavingsAverage(),
        earlydetectionTabEarlyEducationProgramsTotalNetSavingsLow: totals.earlyDetection.earlydetectionTabEarlyEducationProgramsTotalNetSavingsLow(),
        earlydetectionTabEarlyEducationProgramsTotalNetSavingsHigh: totals.earlyDetection.earlydetectionTabEarlyEducationProgramsTotalNetSavingsHigh(),
        earlydetectionTabEarlyEducationProgramsTotalNetSavingsAverage: totals.earlyDetection.earlydetectionTabEarlyEducationProgramsTotalNetSavingsAverage(),
        earlyDetectionEstimatedAverage: totals.earlyDetection.earlyDetectionEstimatedAverage(),
        positiveParentingEstimatedSavingsLow: totals.positiveParenting.positiveParentingEstimatedSavingsLow(),
        positiveParentingEstimatedSavingsHigh: totals.positiveParenting.positiveParentingEstimatedSavingsHigh(),
        positiveParentingEstimatedSavingsAverage: totals.positiveParenting.positiveParentingEstimatedSavingsAverage(),
        positiveparentingTabEstimatedSavingsFamiliesRecievingHomeVisitingServicesProgramsLow: totals.positiveParenting.positiveparentingTabEstimatedSavingsFamiliesRecievingHomeVisitingServicesProgramsLow(),
        positiveparentingTabEstimatedSavingsFamiliesRecievingHomeVisitingServicesProgramsHigh: totals.positiveParenting.positiveparentingTabEstimatedSavingsFamiliesRecievingHomeVisitingServicesProgramsHigh(),
        positiveparentingTabEstimatedSavingsFamiliesRecievingHomeVisitingServicesProgramsAverage: totals.positiveParenting.positiveparentingTabEstimatedSavingsFamiliesRecievingHomeVisitingServicesProgramsAverage(),
        positiveparentingTabCalculatedSavingsForPositiveParentingSupportsAndServices: totals.positiveParenting.positiveparentingTabCalculatedSavingsForPositiveParentingSupportsAndServices(),
        positiveParentingEducationService: totals.positiveParenting.positiveParentingEducationService(),
        positiveParentingAbuseFosterCare: totals.positiveParenting.positiveParentingAbuseFosterCare(),
        positiveParentingCrime: totals.positiveParenting.positiveParentingCrime(),
        positiveParentingHealthService: totals.positiveParenting.positiveParentingHealthService(),
        positiveParentingTotalHealthService: totals.positiveParenting.positiveParentingTotalHealthService(),
        positiveParentingTotalServices: totals.positiveParenting.positiveParentingTotalServices(),
        totalSavingsByAdult: totals.totalSavingsByAdult(),
        totalSavingsByChild: totals.totalSavingsByChild(),
        totalSavingsByPopulation: totals.totalSavingsByPopulation(),
        totalSavingsChildPercentage: totals.totalSavingsChildPercentage(),
        totalSavingsAdultPercentage: totals.totalSavingsAdultPercentage(),
        totalSavingsPopulationPercentage: totals.totalSavingsPopulationPercentage(),
        totalSavingsByShortTerm: totals.totalSavingsByShortTerm(),
        totalSavingsByLongTerm: totals.totalSavingsByLongTerm(),
        totalSavingsByTimeframe: totals.totalSavingsByTimeframe(),
        totalSavingsShortTermPercentage: totals.totalSavingsShortTermPercentage(),
        totalSavingsLongTermPercentage: totals.totalSavingsLongTermPercentage(),
        totalSavingsTimeframePercentage: totals.totalSavingsTimeframePercentage(),
        shortTermLowSavings: totals.shortTermLowSavings(),
        shortTermHighSavings: totals.shortTermHighSavings(),
        shortTermSavingsAverage: totals.shortTermSavingsAverage(),
        shortTermLowMedicaidSavings: totals.shortTermLowMedicaidSavings(),
        shortTermHighMedicaidSavings: totals.shortTermHighMedicaidSavings(),
        shortTermMedicaidSavingsAverage: totals.shortTermMedicaidSavingsAverage(),
        longTermLowSavings: totals.longTermLowSavings(),
        longTermHighSavings: totals.longTermHighSavings(),
        longTermSavingsAverage: totals.longTermSavingsAverage(),
        longTermLowMedicaidSavings: totals.longTermLowMedicaidSavings(),
        longTermHighMedicaidSavings: totals.longTermHighMedicaidSavings(),
        longTermMedicaidSavingsAverage: totals.longTermMedicaidSavingsAverage(),
        totalSavingsLow: totals.totalSavingsLow(),
        totalSavingsHigh: totals.totalSavingsHigh(),
        totalSavingsAverage: totals.totalSavingsAverage(),
        totalSiteServiceDeliveryCost: totals.totalSiteServiceDeliveryCost(),
        shortTermLowROI: totals.shortTermLowROI(),
        shortTermHighROI: totals.shortTermHighROI(),
        shortTermROIAverage: totals.shortTermROIAverage(),
        shortTermLowMedicaidROI: totals.shortTermLowMedicaidROI(),
        shortTermHighMedicaidROI: totals.shortTermHighMedicaidROI(),
        shortTermROIMedicaidAverage: totals.shortTermROIMedicaidAverage(),
        longTermLowROI: totals.longTermLowROI(),
        longTermHighROI: totals.longTermHighROI(),
        longTermROIAverage: totals.longTermROIAverage(),
        longTermLowMedicaidROI: totals.longTermLowMedicaidROI(),
        longTermHighMedicaidROI: totals.longTermHighMedicaidROI(),
        longTermROIMedicaidAverage: totals.longTermROIMedicaidAverage(),
        interventionForChildrensAverage:totals.interventionForChildrensAverage(),
        interventionForChildrensLow:totals.interventionForChildrensLow(),
        interventionForChildrensHigh:totals.interventionForChildrensHigh(),
        interventionForAdultsAverage:totals.interventionForAdultsAverage(),
        interventionForAdultsLow:totals.interventionForAdultsHigh(),
        interventionForAdultsHigh:totals.interventionForAdultsLow(),
        totalNetSavingsLow: totals.totalNetSavingsLow(),
        totalNetSavingsHigh: totals.totalNetSavingsHigh(),
        totalNetSavingsAverage: totals.totalNetSavingsAverage(),
        totalHealthCareSavings: totals.totalHealthCareSavings(),
        totalChildWelfareSavings: totals.totalChildWelfareSavings(),
        totalPublicAssistanceSavings: totals.totalPublicAssistanceSavings(),
        totalSpecialEducationSavings: totals.totalSpecialEducationSavings(),
        totalSavingsSector: totals.totalSavingsSector(),
        globalEstimateSavingsLow: totals.globalEstimateSavingsLow(),
        globalEstimateSavingsHigh: totals.globalEstimateSavingsHigh(),
        nationalOfficeDemostratedAnnualizedSavingsToMedicaidUpToDollarsPerFamily: totals.nationalOfficeDemostratedAnnualizedSavingsToMedicaidUpToDollarsPerFamily(),
        annualReturnOnInvestmentPerc: parseFloat(
          totals.annualReturnOnInvestmentPerc().toFixed(2)
        ),
        anEstimatedDollarsInSavings: totals.anEstimatedDollarsInSavings(),
        breakdownData: {
          // HS Overview Data
          siteName: serviceData.site_name,
          siteLocation: StringFormatter.getStateName(serviceData.site_location),
          dateFrom: DateUtils.formatDate(serviceData.date_from),
          dateTo: DateUtils.formatDate(serviceData.date_to),
          calculationsApplyTo: serviceData.calculations_apply_to,
          numberOfSitesCalculationsApplyTo: serviceData.number_of_sites_calculations_apply_to,
          numberOfSpecialistsDeliveringServices: serviceData['delivery_costs.number_of_specialists_delivering_services'],
          numberOfSpecialistsDeliveringServicesNote: serviceData['delivery_costs.number_of_specialists_delivering_services_note'],
          specialistSalaryCost: serviceData['delivery_costs.specialist_salary_cost'],
          specialistSalaryCostNote: serviceData['delivery_costs.specialist_salary_cost_note'],
          directCost: serviceData['delivery_costs.direct_cost'],
          directCostNote: serviceData['delivery_costs.direct_cost_note'],
          otherCost: serviceData['delivery_costs.other_cost'],
          otherCostNote: serviceData['delivery_costs.other_cost_note'],
          intermediaryCost: serviceData['delivery_costs.intermediary_cost'],
          intermediaryCostNote: serviceData['delivery_costs.intermediary_cost_note'],
          siteServiceCost: serviceData['delivery_costs.site_service_cost'],
          siteServiceCostNote: serviceData['delivery_costs.site_service_cost_note'],
          children0To3: serviceData['children_served.children_0_to_3'],
          children0To3Note: serviceData['children_served.children_0_to_3_note'],
          medicaidChildren0To3: serviceData['children_served.medicaid_children_0_to_3'],
          medicaidChildren0To3Note: serviceData['children_served.medicaid_children_0_to_3_note'],
          children0To3Tier2And3: serviceData['children_served.children_0_to_3_tier_2_and_3'],
          children0To3Tier2And3Note: serviceData['children_served.children_0_to_3_tier_2_and_3_note'],
          medicaidChildren0To3Tier2And3: serviceData['children_served.medicaid_children_0_to_3_tier_2_and_3'],
          medicaidChildren0To3Tier2And3Note: serviceData['children_served.medicaid_children_0_to_3_tier_2_and_3_note'],
          medicaidChildrenUnder1Tier2And3: serviceData['children_served.medicaid_children_under_1_tier_2_and_3'],
          medicaidChildrenUnder1Tier2And3Note: serviceData['children_served.medicaid_children_under_1_tier_2_and_3_note'],
          // Interventions for Children
          oralHealth_needForOralHealth: oralHealth.need_for_oral_health,
          oralHealth_needForOralHealthNote: oralHealth.need_for_oral_health_note,
          oralHealth_lowEstimate: oralHealth.low_estimate,
          oralHealth_highEstimate: oralHealth.high_estimate,
          oralHealth_estimatesNote: oralHealth.estimates_note,
          //
          emergencyDepartment_contactedByPhone: emergencyDepartmentUse.contacted_by_phone,
          emergencyDepartment_contactedByPhoneNote: emergencyDepartmentUse.contacted_by_phone_note,
          emergencyDepartment_treatedAtHome: emergencyDepartmentUse.treated_at_home,
          emergencyDepartment_treatedAtHomeNote: emergencyDepartmentUse.treated_at_home_note,
          emergencyDepartment_clinicVisit: emergencyDepartmentUse.clinic_visit,
          emergencyDepartment_clinicVisitNote: emergencyDepartmentUse.clinic_visit_note,
          //
          immunizations_needForFluVaccinations: immunizations.need_for_flu_vaccinations,
          immunizations_needForFluVaccinationsNote: immunizations.need_for_flu_vaccinations_note,
          immunizations_numberOfCaregiversDiscussingECVWithHSS: immunizations.need_for_flu_vaccinations,
          immunizations_CaregiversDiscussingECVWithHSSNote: immunizations.need_for_flu_vaccinations_note,
          immunizations_numberOfEncourageVisits: immunizations.number_of_encourage_visits,
          immunizations_numberOfEncourageVisitsNote: immunizations.number_of_encourage_visits_note,
          immunizations_lowEstimate: immunizations.low_estimate,
          immunizations_highEstimate: immunizations.high_estimate,
          immunizations_estimatesNote: immunizations.estimates_note,
          //
          asthma_numberHaveAsthma: asthma.number_have_asthma,
          asthma_numberHaveAsthmaNote: asthma.number_have_asthma_note,
          asthma_lowEstimate: asthma.low_estimate,
          asthma_highEstimate: asthma.high_estimate,
          asthma_estimatesNote: asthma.estimates_note,
          // Interventions for Parents
          postpartumMaternalDepression_discussedProblem: postpartumMaternalDepression.discussed_problem,
          postpartumMaternalDepression_discussedProblemNote: postpartumMaternalDepression.discussed_problem_note,
          postpartumMaternalDepression_receivedCounseling: postpartumMaternalDepression.received_counseling,
          postpartumMaternalDepression_receivedCounselingNote: postpartumMaternalDepression.received_counseling_note,
          // postpartumMaternalDepression_referredForServices: postpartumMaternalDepression.referred_for_services,
          // postpartumMaternalDepression_referredForServicesNote: postpartumMaternalDepression.referred_for_services_note,
          postpartumMaternalDepression_lowEstimate: postpartumMaternalDepression.low_estimate,
          postpartumMaternalDepression_highEstimate: postpartumMaternalDepression.high_estimate,
          postpartumMaternalDepression_estimatesNote: postpartumMaternalDepression.estimates_note,
          //
          healthyBirthSpacing_needForContraception: healthyBirthSpacing.need_for_contraception,
          healthyBirthSpacing_needForContraceptionNote: healthyBirthSpacing.need_for_contraception_note,
          healthyBirthSpacing_lowEstimate: healthyBirthSpacing.low_estimate,
          healthyBirthSpacing_highEstimate: healthyBirthSpacing.high_estimate,
          healthyBirthSpacing_estimatesNote: healthyBirthSpacing.estimates_note,
          healthyBirthSpacing_followedUpOnReferals: healthyBirthSpacing.followed_up_on_referals,
          healthyBirthSpacing_followedUpOnReferalsNote: healthyBirthSpacing.followed_up_on_referals_note,
          //
          intimatePartnerViolence_discussedProblem: intimidatePartnerViolence.discussed_ipv,
          intimatePartnerViolence_discussedProblemNote: intimidatePartnerViolence.discussed_ipv_note,
          intimatePartnerViolence_receivedCounseling: intimidatePartnerViolence.received_counseling,
          intimatePartnerViolence_receivedCounselingNote: intimidatePartnerViolence.received_counseling_note,
          intimatePartnerViolence_referredForServices: intimidatePartnerViolence.referred_for_services,
          intimatePartnerViolence_referredForServicesNote: intimidatePartnerViolence.referred_for_services_note,
          intimatePartnerViolence_lowEstimate: intimidatePartnerViolence.low_estimate,
          intimatePartnerViolence_highEstimate: intimidatePartnerViolence.high_estimate,
          intimatePartnerViolence_estimatesNote: intimidatePartnerViolence.estimates_note,
          //
          breastfeeding_discussedProblem: breastfeeding.discussed_need_for_breastfeeding,
          breastfeeding_discussedProblemNote: breastfeeding.discussed_need_for_breastfeeding_note,
          breastfeeding_receivedCounseling: breastfeeding.received_counseling,
          breastfeeding_receivedCounselingNote: breastfeeding.received_counseling_note,
          breastfeeding_referredForServices: breastfeeding.referred_for_services,
          breastfeeding_referredForServicesNote: breastfeeding.referred_for_services_note,
          breastfeeding_lowEstimate: breastfeeding.low_estimate,
          breastfeeding_highEstimate: breastfeeding.high_estimate,
          breastfeeding_estimatesNote: breastfeeding.estimates_note,
          breastfeeding_lowEstimateExclusive: breastfeeding.low_estimate_exclusive,
          breastfeeding_highEstimateExclusive: breastfeeding.high_estimate_exclusive,
          breastfeeding_estimatesExclusiveNote: breastfeeding.estimates_exclusive_note,
          //
          smokeCessation_discussedProblem: smokeCessation.discussed_problem,
          smokeCessation_discussedProblemNote: smokeCessation.discussed_problem_note,
          smokeCessation_lowEstimate: smokeCessation.low_estimate,
          smokeCessation_highEstimate: smokeCessation.high_estimate,
          smokeCessation_estimatesNote: smokeCessation.estimates_note,
          //
          diapers_numberCaregiversProvidedDiapersOrReferred: diapers.number_of_caregivers_provided_diapers_or_referred,
          diapers_lowEstimate: diapers.low_estimate,
          diapers_highEstimate: diapers.high_estimate,
          //
          foodSecurity_numberOfCaregiversFoodInsecureAndReferred: foodSecurity.numberOfCaregiversFoodInsecureAndReferred,
          foodSecurity_numberOfCaregiversFoodInsecureAndReferredNote: foodSecurity.numberOfCaregiversFoodInsecureAndReferredNote,
          foodSecurity_lowEstimate: foodSecurity.low_estimate,
          foodSecurity_highEstimate: foodSecurity.high_estimate,
          foodSecurity_estimatesNote: foodSecurity.estimates_note,
          //
          earlyDetection_numberOfChildrenReferredToEarlyEducationPrograms: earlyDetection.number_of_children_referred_to_early_education_programs,
          earlyDetection_numberOfChildrenReferredToEarlyEducationProgramsNote: earlyDetection.number_of_children_referred_to_early_education_programs_note,
          earlyDetection_lowEstimate: earlyDetection.low_estimate,
          earlyDetection_highEstimate: earlyDetection.high_estimate,
          earlyDetection_estimatesNote: earlyDetection.estimates_note,
          earlyDetection_numberOfChildrenReferredToEarlyIntervention: earlyDetection.number_of_children_referred_to_early_intervention,
          earlyDetection_percentageOfChildrenReceivingEarlyInterventionServices: earlyDetection.percentage_of_children_receiving_early_intervention_services,
          //
          positiveParenting_numberOfFamiliesReferredToPostNatalHomeVisitingPrograms: positiveParenting.number_of_families_referred_to_post_natal_home_visiting_programs,
          positiveParenting_numberOfFamiliesReferredToPostNatalHomeVisitingProgramsNote: positiveParenting.number_of_guardians_provided_positive_parenting_or_referred_to_parenting_program,
          positiveParenting_lowEstimate: positiveParenting.low_estimate,
          positiveParenting_highEstimate: positiveParenting.high_estimate,
          positiveParenting_estimatesNote: positiveParenting.estimates_note,
        },
      },
    })
  );
};

const saveReport = ({
  reportName,
  update,
  status,
  username = ''
}: {
  reportName?: string;
  update?: boolean;
  status?: string;
  username?: string;
} = {}): AppThunk<Promise<any>> => (dispatch, getState) => {
  const { calculator } = getState();

  const newReport = {
    name: (reportName || calculator.name).trim(),
    serviceData: JSON.stringify(calculator.serviceData),
    oralHealth: JSON.stringify(calculator.oralHealth),
    emergencyDepartmentUse: JSON.stringify(calculator.emergencyDepartmentUse),
    immunizations: JSON.stringify(calculator.immunizations),
    interventionsChecklist: JSON.stringify(calculator.interventionsChecklist),
    asthma: JSON.stringify(calculator.asthma),
    postpartumMaternalDepression: JSON.stringify(
      calculator.postpartumMaternalDepression
    ),
    healthyBirthSpacing: JSON.stringify(calculator.healthyBirthSpacing),
    intimidatePartnerViolence: JSON.stringify(
      calculator.intimidatePartnerViolence
    ),
    breastfeeding: JSON.stringify(calculator.breastfeeding),
    smokeCessation: JSON.stringify(calculator.smokeCessation),
    diapers: JSON.stringify(calculator.diapers),
    foodSecurity: calculator.foodSecurity? JSON.stringify(calculator.foodSecurity) : '',
    earlyDetection: calculator.earlyDetection ? JSON.stringify(calculator.earlyDetection) : '0',
    positiveParenting: calculator.positiveParenting ? JSON.stringify(calculator.positiveParenting) : '0',
    summary: JSON.stringify(calculator.summary),
    // eslint-disable-next-line no-nested-ternary
    status: status || calculator.status,
    update: update || undefined,
    username
  };

  dispatch(setLoading(true));

  return reportsService
    .saveReport(JSON.stringify(newReport))
    .then(() => {
      const { pathname } = history.location;
      dispatch(setTouched(false));

      if (pathname.includes(Constants.REPORT.NEW)) {
        dispatch(setName(reportName?.trim())); 
      }

      dispatch(setLoading(false));
      dispatch(snackbarActions.success('Report saved successfully!'));

      if (status === Constants.REPORT_STATUS.SUBMITTED) {
        history.push(paths.calculatorDashboard);
      }
    })
    .catch((error) => {
      dispatch(snackbarActions.error(error.message));
      dispatch(setLoading(false));
    });
};

const updateReportStatus = (
  reportName: string,
  username: string,
  userEmail: string,
  status: string,
): AppThunk<Promise<any>> => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const report = await reportsService.getReport(reportName, username);
    const newReport = { ...report, status, username, update: true };
    delete newReport.updatedISO;
    delete newReport.updated;
    await reportsService.saveReport(JSON.stringify(newReport));
    await reportsService.reportActionNotification(status, JSON.stringify({ user_email: userEmail }));
    dispatch(setTouched(false));
    dispatch(snackbarActions.success('Report saved successfully!'));
  } catch (err) {
    // Assert that err is an instance of Error
    if (err instanceof Error) {
      dispatch(snackbarActions.error(err.message));
    } else {
      console.error('An unknown error occurred:', err);
    }
  } finally {
    dispatch(setLoading(false));
  }
};

const getReport = (reportName: string, reportUsername: string | undefined = undefined): AppThunk => (dispatch, getState) => {
  dispatch(setLoading(true));

  reportsService
    .getReport(reportName, reportUsername)
    .then((data) => {
      const report = {
        name: data.name,
        serviceData: JSON.parse(data.serviceData),
        interventionsChecklist: JSON.parse(data.interventionsChecklist),
        oralHealth: JSON.parse(data.oralHealth),
        emergencyDepartmentUse: JSON.parse(data.emergencyDepartmentUse),
        immunizations: JSON.parse(data.immunizations),
        asthma: JSON.parse(data.asthma),
        postpartumMaternalDepression: JSON.parse(
          data.postpartumMaternalDepression
        ),
        healthyBirthSpacing: JSON.parse(data.healthyBirthSpacing),
        intimidatePartnerViolence: JSON.parse(data.intimidatePartnerViolence),
        breastfeeding: JSON.parse(data.breastfeeding),
        smokeCessation: JSON.parse(data.smokeCessation),
        diapers: data.diapers? JSON.parse(data.diapers) : '0',
        foodSecurity: data.foodSecurity? JSON.parse(data.foodSecurity) : '0',
        earlyDetection: data.earlyDetection ? JSON.parse(data.earlyDetection) : '0',
        positiveParenting: data.positiveParenting ? JSON.parse(data.positiveParenting) : '0',
        summary: JSON.parse(data.summary),
        status: data.status,
        updatedISO: data.updatedISO,
      };

      dispatch(setCalculatorData(report));
    })
    .catch((error) => {
      dispatch(setLoading(false));
      history.push(paths.calculatorDashboard);
    });
};

const newReportNotification = (name: string, site: string, user: string): AppThunk => (dispatch) => {
  const notification = {
    name,
    name_of_site: site,
    user_email: user
  };
  dispatch(setLoading(true));
  reportsService
    .newReportNotification(JSON.stringify(notification))
    .then(()=> {
      dispatch(setLoading(false));
    })
    .catch((error) => {
      dispatch(setLoading(false));
      history.push(paths.calculatorDashboard);
    });
};

const copyReport = (referenceName: string, copyName: string): AppThunk => async (dispatch) => {
  dispatch(dashboardActions.setLoading(true));
  try {
    const _copyName = copyName.trim();
    await reportsService.copyReport(referenceName, _copyName);
    history.push(
      StringFormatter.replaceWithReportName(_copyName, paths.calculatorServiceData)
    );
  } catch (error: any) {
    dispatch(snackbarActions.error(error.message));
  } finally {
    dispatch(dashboardActions.setLoading(false));
  }
};

const toggleInterventionsChecklistValue = ({ key, value }: { key: string, value: boolean }): AppThunk => async (dispatch) => {
  dispatch(setInterventionsChecklistValue({ key, value }));
  dispatch(setSummaryData());
};

export const calculatorReducer = calculatorSlice.reducer;
export const calculatorActions = {
  addServiceData,
  resetAllData,
  saveReport,
  updateReportStatus,
  getReport,
  deleteReport,
  getReports,
  goToReport,
  setSummaryData,
  newReportNotification,
  getReviewReports,
  copyReport,
  toggleInterventionsChecklistValue,
  ...calculatorSlice.actions,
};
